import React, {useEffect} from 'react';
import styled from 'styled-components';
import Layout from '../../Layout';
import {withUser} from '../../../hooks/useAuth';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import MapPanel from './Panels/Map';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import TitlePanel from './Panels/Title';
import {useSelector} from 'react-redux';
import {selectedOrganizationSelector} from '../../../store/selectors/organization';
import Weather from './Panels/Weather';
import SeasonIndicesPanel from './Panels/SeasonIndices';
import usePolygon from '../../../hooks/usePolygon';
import {getUserGroups} from '../../authHOC';
import ToolPanel from './Panels/Tools';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import NotesPanel from './Panels/Notes';
import LatestImageryPanel from './Panels/LatestImagery';
import HarvestSoilHealthPanel from './Panels/HarvestSoilHealth';
import VineHealthHeartbeat from './Panels/VineHealthHeartbeat';
import DataRequirementsPanel from './Panels/DataRequirements';
import {useMediaQuery} from '@material-ui/core';
import MapOverlay from '../../../components/UI/MapOverlay';

const PageContainer = styled.div`
  background: #26313f;
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: scroll;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const TopPanelGroup = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const InnerTopPanelGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
  }
`;

const RightPanelGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const InnerRightPanelGroup = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const BottomContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const Dashboard = ({user}) => {
  const isMobile = useMediaQuery('(max-width: 950px)');
  const {activeFarm, selectedFarm, allFarms, handleFarmChange, loadingGetFarm, loadingGetUser} = useFarmSelection();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const {activePolygon, selectedPolygon, handleOnClickPolygon, handlePolygonChange, polygonClicked, handleSelectedPolygon} = usePolygon();

  const groups = getUserGroups(user);
  const isSuperAdmin = groups.includes('SUPERADMIN');
  const preUrl = groups.includes('VINESIGNAL') ? '/vine-signal' : '/soil-signal';

  // Get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const handleFarmSelection = (farmid: string) => {
    handleFarmChange(farmid);
  };

  const handleOnPolygonChange = (polygonId: string) => {
    const polygon = selectedFarm?.polygons?.find(polygon => polygon.id === polygonId);
    handleOnClickPolygon(polygon);
  };

  useEffect(() => {
    if (selectedFarm) {
      const selectedPolygonBelongsToFarm = selectedFarm?.polygons.find((polygon: IPolygonEntity) => polygon?.id === selectedPolygon?.polygonId);
      if (!selectedPolygon || !selectedPolygonBelongsToFarm) {
        handleOnClickPolygon(selectedFarm?.polygons[0]);
      }
    }
  }, [handleOnClickPolygon, selectedFarm, selectedPolygon]);

  const isLoading = !selectedOrganization || loadingGetFarm || loadingGetUser;

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      <PageContainer>
        <TitleContainer>
          <TopPanelGroup>
            {/* Title */}
            <TitlePanel
              selectedFarm={selectedFarm}
              selectedOrganization={selectedOrganization}
              allFarms={allFarms}
              handleFarmSelection={handleFarmSelection}
              selectedPolygon={selectedPolygon}
              handleOnPolygonChange={handleOnPolygonChange}
              isMobile={isMobile}
            />
            <InnerTopPanelGroup>
              {/* Vine Health Heartbeat */}
              <VineHealthHeartbeat selectedOrganization={selectedOrganization} selectedFarm={selectedFarm} selectedPolygon={selectedPolygon} preUrl={preUrl} isMobile={isMobile} />
              {/* TitlePanel */}
              <LatestImageryPanel selectedFarm={selectedFarm} isMobile={isMobile} />
            </InnerTopPanelGroup>
          </TopPanelGroup>
          {/* Map */}
          <MapPanel
            selectedFarm={selectedFarm}
            takeScreenshot={takeScreenshot}
            selectedPolygon={selectedPolygon}
            activePolygon={activePolygon}
            handleOnClickPolygon={handleOnClickPolygon}
            loadingGetUser={loadingGetUser}
            loadingGetFarm={loadingGetFarm}
            allFarms={allFarms}
            handlePolygonChange={handlePolygonChange}
            polygonClicked={polygonClicked}
            handleSelectedPolygon={handleSelectedPolygon}
            isMobile={isMobile}
          />
        </TitleContainer>
        <RightPanelGroup>
          <InnerRightPanelGroup>
            {/* Harvest/Soil Overview */}
            <HarvestSoilHealthPanel
              user={user}
              allFarms={allFarms}
              selectedFarm={selectedFarm}
              activeFarm={activeFarm}
              selectedPolygon={selectedPolygon}
              selectedOrganization={selectedOrganization}
              isMobile={isMobile}
            />
            <ContainerRow>
              {/* Notes */}
              <NotesPanel selectedOrganization={selectedOrganization} farm={selectedFarm} isSuperAdmin={isSuperAdmin} />
              {/* Data Requirements Checklist */}
              <DataRequirementsPanel selectedOrganization={selectedOrganization} isMobile={isMobile} />
            </ContainerRow>
          </InnerRightPanelGroup>
          <BottomContainer>
            {/* Tools */}
            <ToolPanel />
            {/* Season Indices */}
            <SeasonIndicesPanel
              selectedOrganization={selectedOrganization}
              selectedFarm={selectedFarm}
              selectedPolygon={selectedPolygon}
              preUrl={preUrl}
              handleOnClickPolygon={handleOnClickPolygon}
              isMobile={isMobile}
            />
          </BottomContainer>
        </RightPanelGroup>
        {/* Weather Forecast */}
        <Weather activeFarm={activeFarm} preUrl={preUrl} />
      </PageContainer>
    </Layout>
  );
};

export default withUser(Dashboard);
