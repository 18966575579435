import React, {useState, useMemo} from 'react';
import {Box, CircularProgress, FormControl, MenuItem, Select, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {useSeasonIndices} from 'apps/web-portal-ui/src/app/hooks/useSeasonIndices';
import {IOrganizationEntity, IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {useTranslation} from 'react-i18next';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {PanelTitleWithLink} from '../../../../../components/UI/DashboardPanel/PanelTitle';
import NoInfoOverlay from '../../../../../components/UI/DashboardPanel/NoInfoOverlay';
import Plots from '../../../SeasonIndices/Plots';

const SelectContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-bottom: 12px;
`;

const StyledSelect = styled(Select)`
  z-index: 2;
  min-width: 90px;
  width: fit-content;
`;

const PlotContainer = styled(Box)`
  height: 110%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -90px;
`;

const NoImageContainer = styled(Box)`
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  text-align: center;
`;

const LoaderContainer = styled(Box)`
  height: 85%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const plotStyle = {
  height: '490px',
  width: '40vw',
  backgroundColor: 'transparent',
  position: 'relative',
  left: 12,
};

const mobilePlotStyle = {
  ...plotStyle,
  height: '300px',
  width: '100vw',
};

interface Props {
  selectedOrganization: IOrganizationEntity;
  selectedFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  preUrl: string;
  handleOnClickPolygon: (polygon: IPolygonEntity) => void;
  isMobile: boolean;
}

const SeasonIndicesPanel = ({selectedOrganization, selectedFarm, selectedPolygon, preUrl, handleOnClickPolygon, isMobile}: Props) => {
  const {t} = useTranslation();
  const {seasonIndices, loadingSeasonIndices, seasonIndicesError, distanceUnit, getIndexTitle, generateMappedDates, getValuesIndex} = useSeasonIndices(
    selectedOrganization,
    selectedFarm,
    selectedPolygon
  );

  const [index, setIndex] = useState<string>('Growing Degree Days');

  const blockName = useMemo(() => selectedFarm?.polygons.find(polygon => polygon?.id === selectedPolygon?.polygonId)?.name, [selectedFarm, selectedPolygon]);

  const handleOnIndexChange = (index: string) => {
    setIndex(index);
  };

  return (
    <Panel width={'100%'} height={isMobile ? '250px' : '500px'} overflow="hidden">
      <PanelTitleWithLink link={preUrl + '/season-indices'}>{t('help.season.indices')}</PanelTitleWithLink>
      {seasonIndicesError || seasonIndices.length === 0 ? (
        <NoInfoOverlay name={t('help.season.indices')} />
      ) : loadingSeasonIndices ? (
        <LoaderContainer>
          <CircularProgress size={55} />
        </LoaderContainer>
      ) : selectedPolygon ? (
        <>
          <SelectContainer>
            <FormControl style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <StyledSelect id="index-select" labelId="index-label" value={index} onChange={(event: React.ChangeEvent<{value: string}>) => handleOnIndexChange(event.target.value)}>
                <MenuItem value="Growing Degree Days">GDD</MenuItem>
                <MenuItem value="NDVI">NDVI</MenuItem>
                <MenuItem value="NDWI">NDWI</MenuItem>
                <MenuItem value="Rainfall">{t('dashbaord.cumulative.rainfall')}</MenuItem>
              </StyledSelect>
            </FormControl>
          </SelectContainer>
          <PlotContainer>
            <Plots
              metric="index"
              index={index}
              seasonIndices={seasonIndices}
              showLegend={!isMobile}
              plotStyle={isMobile ? mobilePlotStyle : plotStyle}
              scale={0}
              loadingSeasonIndices={loadingSeasonIndices}
              seasonIndicesError={seasonIndicesError}
              title={' '}
              xTitle={blockName}
              displayModeBar={false}
              distanceUnit={distanceUnit}
              getIndexTitle={getIndexTitle}
              generateMappedDates={generateMappedDates}
              getValuesIndex={getValuesIndex}
            />
          </PlotContainer>
        </>
      ) : (
        <NoImageContainer>
          <Typography variant="h6" color="primary">
            {t('season.indices.overlay.instruction')}
          </Typography>
        </NoImageContainer>
      )}
    </Panel>
  );
};

export default SeasonIndicesPanel;
