import React from 'react';
import styled from 'styled-components';
import {IBlockHealthUI, IClimateFeature, IDashboardYield} from '@deep-planet/api-interfaces';
import {IPolygonWithArea} from '../../../store/reducers/farm';
import DashboardTable from './InfoBox/DashboardTable';
import {getDateString} from '../../../helpers/dateHelpers';
import theme from '../../../utilities/theme';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

// container with table data is responsive for screen size
const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 4.7rem;
  right: 3.3rem;
  width: 333px;
  justify: space-between;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

  ${theme.breakpoints.down('md')} {
    top: 7.5rem;
  }
  ${theme.breakpoints.only('xs')} {
    right: 3rem;
    margin-top: 86px;
  }
`;

interface Props {
  blockHealth: IBlockHealthUI[];
  climateFeature: IClimateFeature;
  yieldData: IDashboardYield;
  block: IPolygonWithArea;
  totalHectares: string;
  selectedImage?: boolean;
}

const BlockInfo = ({blockHealth, climateFeature, yieldData, block, totalHectares}: Props) => {
  const dashboardInfoTable = [];

  const {areaUnit, sugarUnit, convertArea, convertWeight, convertSugar} = useMeasurementConversion();

  // define icon types based on ndvi and ndwi change
  const ndviChange = blockHealth?.find(bh => bh.product === 'NDVI');
  const ndviValue = ndviChange?.increase || ndviChange?.decrease;
  const ndviColor = ndviValue ? (ndviValue > 0 ? 'green' : 'red') : 'none';
  const ndwiChange = blockHealth?.find(bh => bh.product === 'NDWI');
  const ndwiValue = ndwiChange?.increase || ndwiChange?.decrease;
  const ndwiColor = ndwiValue ? (ndwiValue > 0 ? 'green' : 'red') : 'none';

  const numberToString = (value: number) => {
    if (!value) return;
    return parseFloat(value.toString())?.toFixed(2);
  };

  const blockHealthData = {
    headerText: 'dashboard.block.title',
    blockName: block.name,
    dashboardData: [
      {
        label: areaUnit === 'acres' ? 'dashboard.acres' : 'dashboard.hectares',
        value: convertArea(block?.hectares).toString(),
        label2: 'dashboard.variety',
        value2: block?.geoJson?.properties?.cropType,
      },
      {
        label: 'dashboard.ndvi.change',
        value: ndviColor,
        label2: 'dashboard.ndwi.change',
        value2: ndwiColor,
        icon: true,
      },
      {
        label: areaUnit === 'acres' ? 'dashboard.total.acres' : 'dashboard.total.hectares',
        value: convertArea(parseInt(totalHectares)).toString(),
      },
    ],
  };

  const climateFeatures = {
    headerText: 'dashboard.climate.title',
    dashboardData: [
      {
        label: 'dashboard.cumulative.gdd',
        value: (climateFeature?.gdd && numberToString(climateFeature.gdd)) || '-',
        label2: 'dashboard.change.in.gdd.yoy',
        value2: (climateFeature?.gddYoY && numberToString(climateFeature.gddYoY)) || '-',
      },
      {
        label: 'dashbaord.cumulative.rainfall',
        value: (climateFeature?.amountInMM && numberToString(climateFeature.amountInMM)) || '-',
        label2: 'dashboard.change.in.rainfall.yoy',
        value2: (climateFeature?.rainfallYoY && numberToString(climateFeature.rainfallYoY)) || '-',
      },
      {
        label: 'dashboard.frostshock.events',
        value: (climateFeature?.frostshockCount && parseInt(climateFeature.frostshockCount.toString())?.toString()) || '0',
        label2: 'dashboard.heatshock.events',
        value2: (climateFeature?.heatshockCount && parseInt(climateFeature.heatshockCount.toString())?.toString()) || '0',
      },
    ],
  };
  const yieldAndHarvest = {
    headerText: 'dashboard.yield.harvest.title',
    dashboardData: [
      {
        label: 'dashboard.yield.prediction',
        value: (yieldData?.yield && numberToString(convertWeight(yieldData.yield))) || '-',
        label2: 'dashboard.yield.prediction.yoy',
        value2: (yieldData?.yieldChange && `${numberToString(yieldData.yieldChange)}(%)`) || '-',
      },
      {
        label: `dashboard.current.average.${sugarUnit}`,
        value: (yieldData?.avgBaume && convertSugar(yieldData.avgBaume)) || '-',
        label2: `dashboard.target.${sugarUnit}`,
        value2: (yieldData?.targetBaume && convertSugar(yieldData.targetBaume)) || '-',
      },
      {
        label: 'dashboard.estimated.harvest.date',
        value: (yieldData?.estimatedHarvestDate && getDateString(yieldData?.estimatedHarvestDate)) || '',
        label2: 'dashboard.estimated.harvest.date.yoy',
        value2: yieldData?.changeInEstimatedHarvestDate?.toString() || '-',
      },
    ],
  };

  // fill the details to mount the component
  dashboardInfoTable.push(blockHealthData);
  // dashboardInfoTable.push(climateFeatures);
  dashboardInfoTable.push(yieldAndHarvest);

  return (
    <Container>
      {dashboardInfoTable.map((df, idx) => (
        <DashboardTable key={idx + df.headerText} dashboardData={df.dashboardData} tableHeaderText={df.headerText} tableHeaderValue={df?.blockName || ''} />
      ))}
    </Container>
  );
};

export default React.memo(BlockInfo);
