import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button, ButtonGroup, List, ListItem, Tooltip, Typography, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import FarmPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/FarmPicker';
import DatePeriodPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/DatePeriodPicker';
import {handleDateFormat} from 'apps/web-portal-ui/src/app/helpers/dateHelpers';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {IImageWithBox} from 'apps/web-portal-ui/src/app/store/reducers/ndvi';
import {ISoilNutrientImageRes, NutrientTypeEnum} from '@deep-planet/api-interfaces';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
`;

const SideList = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

interface Props {
  type: string;
  nutrientType: string;
  activeFarm: IFarm;
  allFarms: IFarm[];
  images: ISoilNutrientImageRes[];
  startDate: Date;
  endDate: Date;
  selectedDate: number;
  error: unknown;
  disabled?: boolean;
  handleNoTypeData: () => void;
  handleChange: (farmId: string) => void;
  handlePostRequest: () => void;
  handleUpdateDateIndex: (idx: number, type: string) => void;
  handleStartDateSelection: (date: Date) => void;
  handleEndDateSelection: (date: Date) => void;
}

const typeUrls = {
  current: 'curr_url',
  average: 'avg_url',
  previous: 'prev_url',
};

const Sidebar = ({
  type,
  nutrientType,
  activeFarm,
  allFarms,
  images,
  startDate,
  endDate,
  selectedDate,
  error,
  disabled,
  handlePostRequest,
  handleChange,
  handleUpdateDateIndex,
  handleStartDateSelection,
  handleEndDateSelection,
  handleNoTypeData,
}: Props) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (images) {
      // Checks if there are any existing images of nutrient type and type (e.g. nitrogen had prev but potassium does not)
      const imagesOfNutrientAndType = images.some(image => image?.images?.some(innerImage => innerImage[nutrientType]?.[typeUrls[type]]));

      if (!imagesOfNutrientAndType) {
        handleNoTypeData(); // If it does not go back to default
      }
    }
  }, [images, nutrientType, type]);

  return (
    <Container>
      <SideList id={'cucc'}>
        <Box width="100%">
          <FarmPicker activeFarm={activeFarm?.farmid} allFarms={allFarms} handleChange={handleChange} disabled={disabled} />
        </Box>
        <DatePeriodPicker
          disableToolbar={false}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateSelection}
          handleEndDateChange={handleEndDateSelection}
          disableFuture={true}
          disabled={disabled}
        />
        <Button disabled={disabled} variant="contained" color="primary" onClick={handlePostRequest} style={{margin: '1rem 0'}}>
          {t('ndvi.sidebar.update')}
        </Button>
      </SideList>
      {error ? (
        <></>
      ) : (
        // sidebar holds both selected farm and images with dates
        // selected and active farms are global farms, they will change globally.
        // if user selects a different farm  on other feature, the selected and active
        // farms will change globally(only on action "update"), so images of previously
        // selected farm on the carbon page in the memory are invalid. Therefore, do the equlity check
        // images[0].farmid === activeFarm.id before showing the dates and images from the global memory.
        !!images?.length &&
        images[0].farmid === activeFarm.id && (
          <List style={{overflow: 'auto'}}>
            {images.map(({images, date}) => (
              <ListItem button key={date} style={{backgroundColor: selectedDate === date ? '#DBDBDB' : 'unset'}}>
                <ButtonGroup disabled={disabled} variant="text" size="small" aria-label="small outlined button group">
                  {images.some(i => i[nutrientType]['curr_url'] || i[nutrientType]['pred_url']) ? (
                    <Button
                      variant="contained"
                      style={{whiteSpace: 'nowrap'}}
                      color={date === selectedDate && (type === 'current' || type === 'predicted') ? 'primary' : 'default'}
                      onClick={() => handleUpdateDateIndex(date, images.some(i => i[nutrientType]['pred_url']) ? 'predicted' : 'current')}
                    >
                      {handleDateFormat(date) + (images.some(i => i[nutrientType]['pred_url']) ? ` - ${t('ndvi.sidebar.predicted')}` : '')}
                    </Button>
                  ) : null}
                  {images.some(i => i[nutrientType]['prev_url']) ? (
                    <Tooltip title={t('ndvi.sidebar.compare.previous.tooltip')} arrow>
                      <Button variant="contained" color={date === selectedDate && type === 'previous' ? 'primary' : 'default'} onClick={() => handleUpdateDateIndex(date, 'previous')}>
                        {t('ndvi.sidebar.previous')}
                      </Button>
                    </Tooltip>
                  ) : null}
                  {images.some(i => i[nutrientType]['avg_url']) ? (
                    <Tooltip title={t('ndvi.sidebar.compare.average.tooltip')} arrow>
                      <Button variant="contained" color={date === selectedDate && type === 'average' ? 'primary' : 'default'} onClick={() => handleUpdateDateIndex(date, 'average')}>
                        {t('ndvi.sidebar.average')}
                      </Button>
                    </Tooltip>
                  ) : null}
                </ButtonGroup>
              </ListItem>
            ))}
          </List>
        )
      )}

      {!images?.length && (
        <Box pr={2} pl={2}>
          <Typography align="center">{t('ndvi.sidebar.no.images')}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default Sidebar;
