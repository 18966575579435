import {useState, useEffect} from 'react';
import {IFarm} from '../store/reducers/farm';
import {IOrganizationEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {baseApiUrl} from '../config/const';
import axios from 'axios';

interface IVineHeartbeatResponse {
  date: string;
  product: string;
  farmName: string;
  blockName: string;
  hectares: number;
  type: string;
  mean: string;
}

const MAX_POLYGONS = 10; // Maximum polygons permitted on graph

export const useVineHeartbeat = (selectedOrganization: IOrganizationEntity, selectedFarm: IFarm, selectedPolygon: polygonInfoWindow) => {
  const [traces, setTraces] = useState<Plotly.Data[]>([]);
  const [loadingHeartbeat, setLoadingHearbeat] = useState<boolean>(true);
  const [product, setProduct] = useState<string>('NDVI');
  const [prevUrl, setPrevUrl] = useState<string>(''); // Tracks url changes - determines if a new fetch is needed

  // Change between NDVI and NDWI
  const handleProductChange = (product: string) => {
    setProduct(product);
  };

  const getPlotValues = (data: IVineHeartbeatResponse[]) => {
    const x = [];
    const y = [];

    // Get only mean and date values (x and y axes)
    data.forEach(slice => {
      x.push(slice.date);
      y.push(slice.mean);
    });

    return {x, y};
  };

  const createTrace = (name: string, x: any[], y: any[]) => {
    return {
      name: name,
      x: x,
      y: y,
      type: 'scatter',
      mode: 'lines',
    };
  };

  useEffect(() => {
    // Function to create trace for single polygon (farm has more than maximum permitted)
    const getTraceForPolygon = (data, polygonId) => {
      const traces = [];
      // Find block name
      const blockName = selectedFarm?.polygons?.find(polygon => polygon.id === polygonId)?.name;
      // Get x and y axes for polygon
      const {x, y} = getPlotValues(data.filter((slice: IVineHeartbeatResponse) => slice.blockName === blockName));

      // Create trace object
      const trace = createTrace(blockName, x, y);

      // Add to trace array
      traces.push(trace);

      return traces; // Return array of single trace (array data structure still required)
    };
    // Function to create traces for multiple blocks (farm has less than maximum permitted)
    const getTraces = (data: IVineHeartbeatResponse[]) => {
      // Find unique polygon names for farm
      const blockNames = Array.from(new Set(data.map(slice => slice.blockName)));

      const traces = [];
      blockNames.forEach(blockName => {
        // Get data for each polygon
        const {x, y} = getPlotValues(data.filter(slice => slice.blockName === blockName));

        // Create trace object
        const trace = createTrace(blockName, x, y);

        // Add to trace array
        traces.push(trace);
      });

      return traces; // Return array of traces
    };

    const filterData = (data: IVineHeartbeatResponse[]) => {
      // Get date value of four weeks ago (-28 days)
      const dateFourWeeksAgo = new Date();
      dateFourWeeksAgo.setDate(dateFourWeeksAgo.getDate() - 28);

      // Filter data to include only data within past 4 weeks
      const filteredData = data.filter(slice => {
        const sliceDate = new Date(slice.date);
        if (sliceDate > dateFourWeeksAgo) {
          return slice;
        }
        return null;
      });

      // Sort by latest
      filteredData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      // Fetch unique dates from data
      const dates = Array.from(new Set(filteredData.map(slice => slice.date)));

      // Do not allow data if it includes only a single value (cannot make a trace of this)
      if (dates.length <= 1) {
        return []; // Only one set of data so return empty
      }

      return filteredData;
    };

    const fetchData = async () => {
      // From date is beginning of previous month
      const fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 1);
      fromDate.setDate(1);

      // To date is a week ago
      const toDate = new Date();
      toDate.setDate(toDate.getDate() - 7);

      const url = `${baseApiUrl}/dashboard/product/download-mean-data?organizationId=${selectedOrganization.id}&farmId=${selectedFarm.farmid}&fromDate=${fromDate}&toDate=${toDate}&product=${product}`;

      // If url changes, fetch data
      if (url !== prevUrl) {
        try {
          const response = await axios.get(url);
          setPrevUrl(url);
          return response.data;
        } catch (error) {
          console.error('Error fetching heartbeat data:', error);
          setTraces([]);
          setLoadingHearbeat(false);
        }
      }
    };

    if (selectedFarm && selectedOrganization) {
      setLoadingHearbeat(true);
      fetchData().then((data: IVineHeartbeatResponse[]) => {
        if (data) {
          const heartbeatData = filterData(data); // Filter data to prev 4 weeks
          // If heartbeat data
          if (heartbeatData.length > 0) {
            // Check amount of polygons is permitted
            if (selectedFarm.polygons.length > MAX_POLYGONS) {
              // If not permitted, just fetch data for single polygon
              if (selectedPolygon) {
                setTraces(getTraceForPolygon(heartbeatData, selectedPolygon.polygonId));
              }
            } else {
              // Else fetch for all polygons
              setTraces(getTraces(heartbeatData));
            }
            setLoadingHearbeat(false);
          } else {
            setTraces([]);
          }
        } else {
          setLoadingHearbeat(false);
        }
      });
    }
  }, [setTraces, prevUrl, product, selectedFarm, selectedOrganization, selectedPolygon]);

  return {product, handleProductChange, loadingHeartbeat, traces};
};
