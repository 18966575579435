import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getStringDate} from '../helpers/dateHelpers';
import {getMaturityImages, maturityImageSetSelectedDate} from '../store/actions/maturity';
import {IFarm} from '../store/reducers/farm';
import {selectedOrganizationSelector} from '../store/selectors';
import {getMaturityImagesSelector, maturityActiveFarmSelector, maturityImagesSelector, maturitySelectedDateSelector} from '../store/selectors/maturity';
import {usePrevious} from './usePrevious';

export function useMaturityImages(activeFarm: IFarm, startDate: Date, endDate: Date, lazy = false) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<number>();
  const maturityImages = useSelector(maturityImagesSelector);
  const {loading, error} = useSelector(getMaturityImagesSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const maturitySelectedFarm = useSelector(maturityActiveFarmSelector);
  const prevMaturityImages = usePrevious(maturityImages);
  const prevFarm = usePrevious(maturitySelectedFarm);
  const activeSelectedDate = useSelector(maturitySelectedDateSelector);

  const handleDateChange = (date: number) => {
    setSelectedDate(date);
    dispatch(maturityImageSetSelectedDate(date));
  };

  // this will get the images for the first time when screen is initialized or selected organization changes
  useEffect(() => {
    if (activeFarm && !loading && !maturityImages && !lazy) {
      dispatch(getMaturityImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, lazy, loading, maturityImages, selectedOrganization, startDate]);

  // in case of selected organization is changed
  useEffect(() => {
    if (activeFarm && prevFarm && activeFarm.id !== prevFarm.id && !loading && !lazy) {
      dispatch(getMaturityImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, prevFarm, selectedOrganization, startDate, loading, lazy]);

  useEffect(() => {
    if (!selectedDate && activeSelectedDate) setSelectedDate(Number(new Date(activeSelectedDate).getTime()));
  }, [activeSelectedDate, selectedDate]);
  // get images on the selected farm
  const onGetMaturityImages = (selectedFarm: IFarm, disableSnackbar?: boolean) => {
    dispatch(getMaturityImages(selectedFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id, disableSnackbar));
  };

  return {
    loading,
    error,
    maturityImages,
    prevMaturityImages,
    selectedDate,
    onGetMaturityImages,
    handleDateChange,
  };
}
