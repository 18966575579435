import ReactPlayer from 'react-player';
import Layout from '../../Layout';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import React, {useEffect, useState} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components';
import ListItemText from '@material-ui/core/ListItemText';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MapOverlay from '../../../components/UI/MapOverlay';
import {baseApiUrl} from '../../../config/const';
import Box from '@material-ui/core/Box';
import {MapPlaceholderNoData} from '../../../components/UI/MapPlaceholder';
import {useTranslation} from 'react-i18next';
import {useHttp} from '../../../hooks/http';
import theme from '../../../utilities/theme';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {SoilMoistureInterpolationResult} from '@deep-planet/api-interfaces';
import {getUserGroups} from '../../authHOC';
import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import useTooltipText from '../../../hooks/useTooltipText';

const ListItemStyled = styled(ListItem)`
  && {
    cursor: pointer;
  }
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
  ${theme.breakpoints.down('sm')} {
    font-size: 1.5rem !important;
  }
`;

const StyledList = styled(List)`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 66px);
`;

const Video = ({user}: WithUserProps) => {
  const {t} = useTranslation();
  const {HelpSoilMoisture} = useTooltipText();
  const [selectedDateAndUser, setSelectedDateAndUser] = useState('');
  const [loading, videos] = useHttp<SoilMoistureInterpolationResult[]>(`${baseApiUrl}/soil-interpolation`);
  const sortedVideos = videos?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  const videosGroupedByDateAndUser = _.groupBy(sortedVideos, i => `${i.date}/${i.userName}`);
  const onSelectDate = date => setSelectedDateAndUser(date);

  useEffect(() => {
    if (!loading && Object.keys(videosGroupedByDateAndUser).length && !selectedDateAndUser) {
      const dates = Object.keys(videosGroupedByDateAndUser);
      setSelectedDateAndUser(dates[0]);
    }
  }, [loading, selectedDateAndUser, videosGroupedByDateAndUser]);

  const isSecondaryTitleNeeded = () => {
    const groups = getUserGroups(user);
    return groups.includes('SUPERADMIN');
  };

  return (
    <Layout>
      {loading && <MapOverlay />}
      {!loading && Object.keys(videosGroupedByDateAndUser).length === 0 && <MapPlaceholderNoData title={t('no.data.soil.moisture.interpolation')} />}

      <Container>
        <ContentLeftSidebar
          sidebar={
            <StyledList>
              {Object.keys(videosGroupedByDateAndUser).map(dateAndUser => {
                const videosForDate: SoilMoistureInterpolationResult[] = videosGroupedByDateAndUser[dateAndUser];
                return (
                  <ListItemStyled key={dateAndUser} button={true} selected={dateAndUser === selectedDateAndUser} onClick={() => onSelectDate(dateAndUser)}>
                    <ListItemText
                      primary={`${videosForDate[0].date}${isSecondaryTitleNeeded() ? ` (${videosForDate[0].userName})` : ''}`}
                      secondary={Boolean(videosForDate[0].type) && t(`ndvi.sidebar.${videosForDate[0].type.toLowerCase()}`)}
                    />
                  </ListItemStyled>
                );
              })}
            </StyledList>
          }
          content={
            <div>
              {Object.keys(videosGroupedByDateAndUser).length > 0 && (
                <Box display="flex" m={3} ml={5} style={{justifyContent: 'center', flexDirection: 'row'}}>
                  <Box style={{margin: '7px 7px'}}>
                    <HelpTooltip text={HelpSoilMoisture} icon />
                  </Box>
                  <StyledTypography variant="h4" gutterBottom>
                    {t('soil.moisture.interpolation.title')}
                  </StyledTypography>
                </Box>
              )}
              <div className="wrapper">
                <Grid container spacing={0} justify="center">
                  {videosGroupedByDateAndUser[selectedDateAndUser] &&
                    videosGroupedByDateAndUser[selectedDateAndUser].map(({depth, type, farmName, videoLocation}) => {
                      return (
                        <Grid item lg={5} xs={12}>
                          <Box mt={2} mb={4} ml={2} mr={2}>
                            {farmName && (
                              <Typography variant="h6" gutterBottom align="center" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                {t('soil.moisture.interpolation.farm.name')} {farmName}
                              </Typography>
                            )}
                            <ReactPlayer className="player" url={videoLocation} controls={true} width="99%" height="99%" />
                            <Typography variant="h6" gutterBottom align="center">
                              {t('soil.moisture.interpolation.depth', {depth})}
                              {`(${type})`}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </div>
          }
        />
      </Container>
    </Layout>
  );
};

export default withUser(Video);
