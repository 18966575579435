import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Plot from 'react-plotly.js';
import {Box, Typography} from '@material-ui/core';

import {IFarm} from '../../../store/reducers/farm';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';
import {colors, GROWING_DEGREE_DAYS} from '../../../config/const';
import {polygonInfoWindow, ISeasonIndicesResponse} from '@deep-planet/api-interfaces';
import {useSeasonIndices} from '../../../hooks/useSeasonIndices';

interface PlotsProps {
  metric: string;
  index: string;
  scale: number;
  smallScreen?: boolean;
  selectedFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  seasonIndices: any[];
  loadingSeasonIndices: boolean;
  seasonIndicesError: unknown;
}

const Plots = ({metric, index, scale, smallScreen, selectedPolygon, seasonIndices, loadingSeasonIndices, seasonIndicesError}: PlotsProps) => {
  const {t} = useTranslation();
  const {distanceUnit, convertMmInches} = useMeasurementConversion();
  const {getIndexTitle, generateMappedDates, extrapolateTraces} = useSeasonIndices();

  const [noData, setNoData] = useState(false);

  const newSeasonDetectionValue = 3.5 * 30 * 24 * 60 * 60 * 1000; // 3.5 months

  const plotStyle = {
    minHeight: smallScreen ? '50vh' : `calc(45vh * ${scale})`,
    maxHeight: smallScreen ? '50vh' : `calc(45vh * ${scale})`,
    width: smallScreen ? '100%' : `calc(33vw * ${scale * scale})`,
  };

  // Remove first color off colors for the graph colors to ensure it matches other
  const graphColors = colors.slice(1); // Used to color each trace
  const polygonId = selectedPolygon?.polygonId;

  // Gets data values for given index
  const getValuesIndex = (data: ISeasonIndicesResponse[], index: string) => {
    const traces = [];
    let xValues = [];
    let yValues = [];

    let originalDates = []; // Keeps track of the original date for each plot
    let colorIndex = 0; // Tracks trace color
    let prevDate = null; // Tracks date of previous plot for cross year functionality
    let crossedYear = false; // Used to check if plots have crossed past the next year (i.e. January) so that it can display correct year on hover

    data?.forEach(item => {
      // If polygon does not match do not show data
      if (item.polygonId !== polygonId) {
        return;
      }

      const currentDate = new Date(item.date);
      // If long date period between plots -> detects new season
      // If there is a distance between plots of longer than the detection value, this must be a new season to plot
      if (prevDate && prevDate.getTime() + newSeasonDetectionValue < currentDate.getTime()) {
        // Pushes separate trace for each season
        traces.push({
          x: xValues,
          y: yValues,
          originalDates: originalDates,
          name: crossedYear ? `${currentDate.getFullYear() - 1}/${currentDate.getFullYear() + -2000}` : currentDate.getFullYear() - 1,
          opacity: 0.35,
          hoverinfo: 'x+y',
          mode: 'lines',
          line: {
            color: graphColors[colorIndex],
          },
        });
        // Reset values and initialise for next curve
        crossedYear = false;
        xValues = [currentDate];
        yValues = [index === 'rainfall' ? convertMmInches(parseFloat(item[index])).toString() : item[index]];
        originalDates = [currentDate];
      }
      // No new trace detected -> continue plotting current plot
      else {
        // Checks if previous date has a different year
        if (prevDate && prevDate.getFullYear() === currentDate.getFullYear() - 1) {
          crossedYear = true;
        }
        // Adds plot values to array
        xValues.push(currentDate);
        yValues.push(index === 'rainfall' ? convertMmInches(parseFloat(item[index])).toString() : item[index]);
        originalDates.push(currentDate);
      }
      prevDate = currentDate;
      colorIndex++;
    });

    // Handle last trace
    if (xValues.length > 0) {
      traces.push({
        x: xValues,
        y: yValues,
        originalDates: originalDates,
        name: crossedYear ? `${new Date().getFullYear()}/${new Date().getFullYear() + -1999}` : new Date().getFullYear(),
        hoverinfo: 'x+y',
        mode: 'lines',
        line: {
          color: 'blue',
        },
      });
    }

    // Now all dates are plotted, extrapolate them so that they overlap
    return extrapolateTraces(traces);
  };

  // Structure for rendering a graph
  const renderPlot = (index: string) => {
    const data = getValuesIndex(seasonIndices, index);
    if (data.length === 0) {
      setNoData(true);
    }
    // Add slight padding to graph
    const paddingFactor = 0.2;
    const xValues = data.flatMap(trace => trace.x);
    const xMin = Math.min(...xValues);
    const xMax = Math.max(...xValues);
    const padding = (xMax - xMin) * paddingFactor;
    // Return plot component
    return (
      <Plot
        data={data}
        layout={{
          title: getIndexTitle(index),
          autosize: true,
          hovermode: 'x',
          xaxis: {
            title: 'Month',
            range: [xMin - padding, xMax + padding],
            autorange: false,
            type: 'date',
            tickformat: '%b', // Show name of month
            tickvals: generateMappedDates(2015, 2035), // List of exact start dates for each month from 2015 to 2035 so that month names line up correctly
            hoverformat: '%b %d',
          },
          yaxis: {
            title: getIndexTitle((index === 'rainfall' && `Rainfall (${(distanceUnit[0] === 'meters' && 'mm') || (distanceUnit[0] === 'feet' && 'in')})`) || (index === 'gdd' && 'GDD (ºC)') || index),
            autorange: true,
          },
        }}
        style={plotStyle}
      />
    );
  };

  return (
    <Box
      style={{
        paddingInline: 10,
        width: '100%',
        overflowY: metric === 'year' ? 'scroll' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: (seasonIndicesError || noData) && '80vh',
        justifyContent: (seasonIndicesError || noData) && 'center',
      }}
    >
      {!loadingSeasonIndices && (seasonIndicesError || noData) ? (
        <Typography variant="h5" style={{marginBottom: 100, textAlign: 'center', color: 'black', fontWeight: 'bold', opacity: 0.5}}>
          {t('season.indices.no.data')}
        </Typography>
      ) : !loadingSeasonIndices && index === 'All' ? (
        <>
          {renderPlot('ndvi')}
          {renderPlot('ndwi')}
          {renderPlot('gdd')}
          {renderPlot('rainfall')}
        </>
      ) : (
        renderPlot(index === GROWING_DEGREE_DAYS ? 'gdd' : index.toLowerCase())
      )}
    </Box>
  );
};

export default Plots;
