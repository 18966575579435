import React, {useCallback, useEffect, useState} from 'react';
import {Box, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TableRow, Typography, TableSortLabel, TablePagination} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {getLocalDateString} from '../../../helpers/dateHelpers';
import {IFarm} from '../../../store/reducers/farm';
import NoteDetails from './NoteDetails';
import theme from '../../../utilities/theme';
import {useDispatch, useSelector} from 'react-redux';
import {noteAttachmentDeleteSelector, noteAttachmentsAddSelector, noteDeleteSelector, notePutSelector} from '../../../store/selectors';
import {deleteNote, updateNote} from '../../../store/actions';
import {usePrevious} from '../../../hooks/usePrevious';
import {CreateNoteDTO, INoteEntity, INoteTypeEntity} from '@deep-planet/api-interfaces';
import useEdit, {ISelectedFile} from './withEdit';
import {createSortHandler, getComparator, Order, stableSort} from '../../../components/UI/Table/sort-utils';
import {getUserGroups} from '../../authHOC';
import {withUser, WithUserProps} from '../../../hooks/useAuth';

interface Props extends WithUserProps {
  notes: INoteEntity[];
  types: INoteTypeEntity[];
  farms: IFarm[];
  userNames: string[];
  claimTypes: INoteTypeEntity[];
}

const StyledTableCell = styled(TableCell)<{width?: string} & TableCellProps>`
  text-overflow: ellipsis;
  max-width: ${({width}) => width || 'auto'};
  overflow: hidden;
`;

const NoteDetailsContainer = styled.div`
  top: 64px;
  position: fixed;
  right: 0;
  height: calc(100vh - 64px);
  width: 500px;
  border-left: solid;
  background-color: white;
  z-index: 2;
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const ClickableRow = styled(TableRow)<{disabled: boolean}>`
  cursor: ${({disabled}) => (disabled ? 'unset' : 'pointer')};
`;

const StyledTypography = styled(Typography)<{$textWidth?: string}>`
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  width: ${({$textWidth}) => $textWidth || 'max-content'};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTableContainer = styled(TableContainer)<{$isDetailsOpen: boolean}>`
  z-index: 1;
  width: ${({$isDetailsOpen}) => ($isDetailsOpen ? 'calc(100% - 500px) !important' : '100%')};
  ${theme.breakpoints.down('sm')} {
    margin-top: 40px;
  }
`;

interface Data {
  reference: number;
  summary: string;
  type: INoteTypeEntity;
  claimType: INoteTypeEntity;
  fromUser: string;
  toUser: string;
  farm: string;
  polygon: string;
  date: Date;
  createdAt: Date;
}

const columnsToDisplay = [
  {
    id: 'reference' as keyof Data,
    label: 'note.table.id',
    width: '10%',
    align: 'center' as TableCellProps['align'],
  },
  {
    id: 'summary' as keyof Data,
    label: 'note.table.summary',
    width: '200px',
    textWidth: 'auto',
  },
  {
    id: 'type' as keyof Data,
    label: 'note.table.type',
    width: 'auto',
  },
  {
    id: 'claimType' as keyof Data,
    label: 'Claimed Block Status',
    width: 'auto',
  },
  {
    id: 'fromUser' as keyof Data,
    label: 'note.table.assigner',
    width: 'auto',
  },
  {
    id: 'toUser' as keyof Data,
    label: 'note.table.assignee',
    width: 'auto',
  },
  {
    id: 'visible' as keyof Data,
    label: `note.table.visible`,
    width: 'auto',
  },
  {
    id: 'farm' as keyof Data,
    label: 'note.table.farm',
    width: 'auto',
  },
  {
    id: 'polygon' as keyof Data,
    label: 'note.table.block',
    width: 'auto',
  },
  {
    id: 'date' as keyof Data,
    label: `note.table.date`,
    width: 'auto',
  },
  {
    id: 'createdAt' as keyof Data,
    label: `note.table.created`,
    width: 'auto',
  },
];

const NoteList = ({notes, farms, types, userNames, user, claimTypes}: Props) => {
  const {
    summary,
    location,
    description,
    errors,
    selectedType,
    selectedDate,
    selectedFarm,
    selectedPolygon,
    setErrors,
    selectedNote,
    selectedFiles,
    setSelectedFiles,
    selectedAssignee,
    selectedOrganization,
    selectedVisibility,
    selectedUserName,
    selectedClaimType,
    handleChangeSummary,
    handleChangeLocation,
    handleChangeDescription,
    handleTypeSelection,
    handleDateSelection,
    handleFarmSelection,
    handlePolygonSelection,
    handleNoteAttachmentChange,
    handleNoteAttachmentCancel,
    handleNoteSelection: setSelectedNote,
    handleAssigneeSelection,
    handleOrganizationSelection,
    handleUserNameSelection,
    handleClaimBlockTypeSelection,
  } = useEdit({types, farms, userNames, claimTypes});
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [isShareActionsOpen, setSetActionsOpen] = useState(false);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
  const {loading: isDeleteLoading} = useSelector(noteDeleteSelector);
  const {loading: isUpdateLoading} = useSelector(notePutSelector);
  const noteAttachmentDeletes = useSelector(noteAttachmentDeleteSelector);
  const {loading: isAttachmentUploadLoading} = useSelector(noteAttachmentsAddSelector);
  const dispatch = useDispatch();
  const prevIsDeleteLoading = usePrevious(isDeleteLoading);
  const prevIsUpdateLoading = usePrevious(isUpdateLoading);
  const prevIsAttachmentUploadLoading = usePrevious(isAttachmentUploadLoading);
  const prevNoteAttachmentDeletes = usePrevious(noteAttachmentDeletes);
  const prevNotes = usePrevious(notes);
  const {t} = useTranslation();
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of rows per page

  const formattedValue = (value: any, id: string) => {
    if (id === 'createdAt' || id === 'date') {
      return getLocalDateString(value);
    }
    if (id === 'type') {
      return t(`note.filter.type.${value.name.toLowerCase()}`);
    }
    if (id === 'claimType') {
      return value?.name || '-';
    }
    if (value instanceof Object) return value.name || value.username;
    return value;
  };

  const handleNoteSelection = (note: INoteEntity) => {
    setSelectedNote(note);
  };
  const handleDetailsClosing = useCallback(() => setSelectedNote(null), [setSelectedNote]);
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleOpenShareModal = () => setShareModalOpen(true);
  const handleCloseShareModal = () => setShareModalOpen(false);
  const handleOpenAction = () => setActionsOpen(true);
  const handleCloseAction = () => setActionsOpen(false);
  const handleOpenShareAction = () => setSetActionsOpen(true);
  const handleCloseShareAction = () => setSetActionsOpen(false);
  const handleNoteDelete = (noteId: string) => dispatch(deleteNote(noteId));
  const handleNoteUpdate = (noteId: string, params: CreateNoteDTO, files: ISelectedFile[]) => dispatch(updateNote(noteId, params, files));

  useEffect(() => {
    if (prevIsDeleteLoading === true && isDeleteLoading === false && notes.length !== prevNotes.length) {
      handleCloseDialog();
      handleCloseAction();
      handleDetailsClosing();
    }
    if (prevIsUpdateLoading === true && isUpdateLoading === false && notes !== prevNotes) {
      setSelectedNote(notes.find(note => note.id === selectedNote.id));
      setSelectedFiles([]);
    }
    if (prevIsAttachmentUploadLoading === true && isAttachmentUploadLoading === false && notes !== prevNotes) {
      setSelectedNote(notes.find(note => note.id === selectedNote.id));
    }
    if (selectedNote && noteAttachmentDeletes.length !== prevNoteAttachmentDeletes?.length) {
      const noteAttachmentDelete = prevNoteAttachmentDeletes?.find(({noteId}) => selectedNote?.id);
      if (noteAttachmentDelete) {
        setSelectedNote(notes.find(({id}) => noteAttachmentDelete.noteId === id));
      }
    }
  }, [
    handleDetailsClosing,
    isAttachmentUploadLoading,
    isDeleteLoading,
    isUpdateLoading,
    noteAttachmentDeletes.length,
    notes,
    prevIsAttachmentUploadLoading,
    prevIsDeleteLoading,
    prevIsUpdateLoading,
    prevNoteAttachmentDeletes,
    prevNotes,
    selectedNote,
    setSelectedFiles,
    setSelectedNote,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedNotes = notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const groups = getUserGroups(user);
  const isAdmin = groups && (groups.includes('ADMIN') || groups.includes('SUPERADMIN'));
  // claim type only visible for the admin accounts
  const columns = isAdmin && paginatedNotes.some(pn => pn.claimType) ? columnsToDisplay : columnsToDisplay.filter(c => !(c.id === 'claimType'));
  return (
    <Box display="flex" maxHeight={'calc(100vh - 65px)'}>
      <StyledTableContainer $isDetailsOpen={!!selectedNote}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns &&
                columns.map(column => (
                  <StyledTableCell width={column.width} key={column.id} align={column.align || 'left'}>
                    <TableSortLabel active={orderBy === column.id} direction={orderBy === column.id ? order : 'asc'} onClick={createSortHandler(column.id, orderBy, order, setOrder, setOrderBy)}>
                      <StyledTypography>{t(column.label)}</StyledTypography>
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort<INoteEntity>(paginatedNotes, getComparator<INoteEntity>(order, orderBy)).map(note => {
              return (
                <ClickableRow
                  disabled={isDialogOpen}
                  onClick={() => {
                    if (!isDialogOpen) handleNoteSelection(note);
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={note.id}
                >
                  {columns &&
                    columns.map(column => {
                      const value = note[column.id];
                      return (
                        <StyledTableCell width={column.width} key={column.id} align={column.align || 'left'} style={{maxWidth: column.width}}>
                          <StyledTypography $textWidth={column.textWidth}>{formattedValue(value, column.id) || '-'}</StyledTypography>
                        </StyledTableCell>
                      );
                    })}
                </ClickableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* Pagination */}
        <Box display="flex" justifyContent="flex-end" alignItems="center" padding="0px">
          <TablePagination component="div" count={notes.length} page={page} onChangePage={handleChangePage} rowsPerPage={rowsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        </Box>
      </StyledTableContainer>
      {selectedNote && (
        <NoteDetailsContainer>
          <NoteDetails
            selectedFiles={selectedFiles}
            handleNoteAttachmentCancel={handleNoteAttachmentCancel}
            handleNoteAttachmentChange={handleNoteAttachmentChange}
            errors={errors}
            summary={summary}
            location={location}
            description={description}
            selectedDate={selectedDate}
            selectedFarm={selectedFarm}
            selectedPolygon={selectedPolygon}
            selectedType={selectedType}
            selectedClaimType={selectedClaimType}
            setErrors={setErrors}
            handleChangeDescription={handleChangeDescription}
            handleChangeLocation={handleChangeLocation}
            handleChangeSummary={handleChangeSummary}
            handleDateSelection={handleDateSelection}
            handleFarmSelection={handleFarmSelection}
            handlePolygonSelection={handlePolygonSelection}
            handleTypeSelection={handleTypeSelection}
            note={selectedNote}
            types={types}
            claimTypes={claimTypes}
            farms={farms}
            closeDetails={handleDetailsClosing}
            isDialogOpen={isDialogOpen}
            isShareModalOpen={isShareModalOpen}
            handleOpenShareModal={handleOpenShareModal}
            handleCloseShareModal={handleCloseShareModal}
            isActionsOpen={isActionsOpen}
            isShareActionsOpen={isShareActionsOpen}
            isDeleteLoading={isDeleteLoading}
            handleOpenDialog={handleOpenDialog}
            handleCloseDialog={handleCloseDialog}
            handleOpenAction={handleOpenAction}
            handleOpenShareAction={handleOpenShareAction}
            handleCloseShareAction={handleCloseShareAction}
            handleCloseAction={handleCloseAction}
            handleNoteDelete={handleNoteDelete}
            handleNoteUpdate={handleNoteUpdate}
            selectedAssignee={selectedAssignee}
            handleAssigneeSelection={handleAssigneeSelection}
            userNames={userNames}
            selectedOrganization={selectedOrganization}
            selectedVisibility={selectedVisibility}
            selectedUserName={selectedUserName}
            handleOrganizationSelection={handleOrganizationSelection}
            handleUserNameSelection={handleUserNameSelection}
            handleClaimTypeSelection={handleClaimBlockTypeSelection}
          />
        </NoteDetailsContainer>
      )}
    </Box>
  );
};

export default withUser(NoteList);
