import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Plot from 'react-plotly.js';
import {Box, Typography} from '@material-ui/core';
import {GROWING_DEGREE_DAYS} from '../../../config/const';
import {ISeasonIndicesResponse} from '@deep-planet/api-interfaces';

interface PlotsProps {
  metric: string;
  index: string;
  scale: number;
  seasonIndices: any[];
  loadingSeasonIndices: boolean;
  seasonIndicesError: unknown;
  smallScreen?: boolean;
  plotStyle?: any;
  showLegend?: boolean;
  title?: string;
  xTitle?: string;
  yTitle?: string;
  displayModeBar?: boolean;
  distanceUnit: string;
  getIndexTitle: (index: string) => string;
  generateMappedDates: (startYear: number, endYear: number) => Date[];
  getValuesIndex: (data: ISeasonIndicesResponse[], index: string) => any[];
}

const Plots = ({
  metric,
  index,
  scale,
  seasonIndices,
  loadingSeasonIndices,
  seasonIndicesError,
  smallScreen = false,
  plotStyle,
  showLegend = true,
  title,
  xTitle,
  yTitle,
  displayModeBar = true,
  distanceUnit,
  getIndexTitle,
  generateMappedDates,
  getValuesIndex,
}: PlotsProps) => {
  const {t} = useTranslation();

  const [noData, setNoData] = useState(false);

  if (!seasonIndices) {
    return;
  }

  const defaultPlotStyle = {
    minHeight: smallScreen ? '50vh' : `calc(45vh * ${scale})`,
    maxHeight: smallScreen ? '50vh' : `calc(45vh * ${scale})`,
    width: smallScreen ? '100%' : `calc(33vw * ${scale * scale})`,
  };

  // Structure for rendering a graph
  const renderPlot = (index: string) => {
    const data = getValuesIndex(seasonIndices, index);
    if (data.length === 0) {
      setNoData(true);
    }
    // Add slight padding to graph
    const paddingFactor = 0.2;
    const xValues = data.flatMap(trace => trace.x);
    const xMin = Math.min(...xValues);
    const xMax = Math.max(...xValues);
    const padding = (xMax - xMin) * paddingFactor;
    // Return plot component
    return (
      <Plot
        data={data}
        layout={{
          title: title || getIndexTitle(index),
          autosize: true,
          hovermode: 'x',
          showlegend: showLegend,
          xaxis: {
            title: xTitle || 'Month',
            range: [xMin - padding, xMax + padding],
            autorange: false,
            type: 'date',
            tickformat: '%b', // Show name of month
            tickvals: generateMappedDates(2015, 2035), // List of exact start dates for each month from 2015 to 2035 so that month names line up correctly
            hoverformat: '%b %d',
          },
          yaxis: {
            title:
              yTitle ||
              getIndexTitle((index === 'rainfall' && `Rainfall (${(distanceUnit[0] === 'meters' && 'mm') || (distanceUnit[0] === 'feet' && 'in')})`) || (index === 'gdd' && 'GDD (ºC)') || index),
            autorange: true,
          },
        }}
        config={{displayModeBar: displayModeBar}}
        style={plotStyle || defaultPlotStyle}
      />
    );
  };

  return (
    <Box
      style={{
        paddingInline: 10,
        width: '100%',
        overflowY: metric === 'year' ? 'scroll' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: (seasonIndicesError || noData) && '80vh',
        justifyContent: (seasonIndicesError || noData) && 'center',
      }}
    >
      {!loadingSeasonIndices && (seasonIndicesError || noData) ? (
        <Typography variant="h6" style={{marginBottom: 100, textAlign: 'center', color: 'black'}}>
          {t('season.indices.no.data')}
        </Typography>
      ) : !loadingSeasonIndices && index === 'All' ? (
        <>
          {renderPlot('ndvi')}
          {renderPlot('ndwi')}
          {renderPlot('gdd')}
          {renderPlot('rainfall')}
        </>
      ) : (
        renderPlot(index === GROWING_DEGREE_DAYS ? 'gdd' : index.toLowerCase())
      )}
    </Box>
  );
};

export default Plots;
