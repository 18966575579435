import React from 'react';
import styled from 'styled-components';
import {Box, Typography, useTheme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const DatesContainer = styled.div`
  background-color: whitesmoke;
  flex-direction: column;
`;

const WeekdayRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
  padding-inline: 2px;
  justify-content: space-between;
`;

const WeekdayTypography = styled(Typography)`
  width: 10%;
  padding-block: 0px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
`;

const CalendarRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DateContainer = styled(Box)<{backgroundcolor?: string; secondarybackgroundcolor?: string}>`
  width: 10%;
  height: calc(10% * 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline: 2px;
  margin-block: 1px;
  border-radius: 2px;
  background-color: ${props => (props.secondarybackgroundcolor !== 'transparent' ? props.secondarybackgroundcolor : props.backgroundcolor)};
  background: ${props =>
    props.secondarybackgroundcolor !== 'transparent' && `linear-gradient(to right, ${props.backgroundcolor} 50%, ${props.secondarybackgroundcolor} 50%)`}; // 50/50 background color
  color: ${props => (props.backgroundcolor === 'transparent' ? 'black' : 'white')};
`;

const MonthTypography = styled(Typography)`
  font-size: x-small !important;
`;

const DateTypography = styled(Typography)`
  font-size: x-small !important;
`;

// Function to create an even matrix of calendar dates
const formatCalendar = (fromDate: Date, toDate: Date) => {
  const dayInMilliseconds = 86400000;

  // Ensures fromDate begins on a Monday
  while (fromDate.getDay() > 1) {
    fromDate.setDate(fromDate.getDate() - 1);
  }

  const fromDateTime = fromDate.getTime();

  const calendarDates = [];
  let step = 0;

  // Loop through 5 weeks
  for (let i = 0; i < 5; i++) {
    const calendarRow = [];
    // Loop through 7 days a week
    for (let j = 0; j < 7; j++) {
      const toAddDateTime = fromDateTime + dayInMilliseconds * step; // Increment day each time
      const dateToAdd = new Date(toAddDateTime);

      if (dateToAdd.getTime() > toDate.getTime()) {
        calendarRow.push(null);
      } else {
        // Push dates to row
        calendarRow.push(dateToAdd);
      }
      step++;
    }

    // Push row to calendar
    calendarDates.push(calendarRow);
  }
  return calendarDates; // return calendar
};

// Checks that the dates of two date objects are equal
const checkDatesMatch = (date1: Date, date2: Date) => {
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

interface Props {
  fromDate: Date;
  toDate: Date;
  esaImageDates?: Date[];
  planetImageDates?: Date[];
  disabled?: boolean;
}
const ImageryCalendar = ({fromDate, toDate, esaImageDates, planetImageDates, disabled = false}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const calendarDates = formatCalendar(fromDate, toDate);

  const months = [
    t('dashboard.latest.imagery.jan'),
    t('dashboard.latest.imagery.feb'),
    t('dashboard.latest.imagery.mar'),
    t('dashboard.latest.imagery.apr'),
    t('dashboard.latest.imagery.may'),
    t('dashboard.latest.imagery.jun'),
    t('dashboard.latest.imagery.jul'),
    t('dashboard.latest.imagery.aug'),
    t('dashboard.latest.imagery.sep'),
    t('dashboard.latest.imagery.oct'),
    t('dashboard.latest.imagery.nov'),
    t('dashboard.latest.imagery.dec'),
  ];
  const weekdays = [
    t('dashboard.latest.imagery.mon'),
    t('dashboard.latest.imagery.tue'),
    t('dashboard.latest.imagery.wed'),
    t('dashboard.latest.imagery.thu'),
    t('dashboard.latest.imagery.fri'),
    t('dashboard.latest.imagery.sat'),
    t('dashboard.latest.imagery.sun'),
  ];

  return (
    <CalendarContainer>
      <WeekdayRow>
        {weekdays.map((weekday: string, index) => {
          return (
            <WeekdayTypography key={index} style={{fontSize: 'xx-small', fontWeight: 'bold'}}>
              {weekday}
            </WeekdayTypography>
          );
        })}
      </WeekdayRow>
      <DatesContainer>
        {calendarDates &&
          calendarDates.map((calendarRow: Date[], rowIndex: number) => {
            return (
              <CalendarRow key={rowIndex}>
                {calendarRow.map((calendarDate: Date, dateIndex: number) => {
                  return calendarDate ? (
                    <DateContainer
                      key={dateIndex}
                      backgroundcolor={
                        !disabled && esaImageDates?.some((date: Date) => checkDatesMatch(date, calendarDate))
                          ? theme.palette.primary.light
                          : !disabled && planetImageDates?.some((date: Date) => checkDatesMatch(date, calendarDate))
                          ? theme.palette.secondary.light
                          : 'transparent'
                      }
                      secondarybackgroundcolor={
                        !disabled && esaImageDates?.some((date: Date) => checkDatesMatch(date, calendarDate)) && planetImageDates?.some((date: Date) => checkDatesMatch(date, calendarDate))
                          ? theme.palette.secondary.light
                          : 'transparent'
                      }
                    >
                      <MonthTypography>{months[calendarDate?.getMonth()]}</MonthTypography>
                      <DateTypography>{calendarDate?.getDate()}</DateTypography>
                    </DateContainer>
                  ) : (
                    <DateContainer key={dateIndex} />
                  );
                })}
              </CalendarRow>
            );
          })}
      </DatesContainer>
    </CalendarContainer>
  );
};

export default ImageryCalendar;
