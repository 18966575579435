import MomentUtils from '@date-io/moment';
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import i18n from '../../../../i18n';
import {getLocalDateString} from '../../../helpers/dateHelpers';

moment.locale(i18n.language || window.navigator.language);

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return getLocalDateString(date);
  }
}

interface Props {
  value: Date;
  onChange: (date: Date) => void;
  label?: string;
  disabled?: boolean;
  disableToolbar?: boolean;
  variant?: 'inline' | 'outlined';
  helperText?: string;
  error?: boolean;
  disableFuture?: boolean;
  maxDate?: Date;
}

const SimpleDatePicker = ({value: initStartDate, onChange, helperText, error, maxDate, label = '', disabled = false, disableToolbar = false, variant = 'inline', disableFuture = false}: Props) => {
  const {t} = useTranslation();
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={i18n.language || window.navigator.language}>
      {variant === 'inline' ? (
        <DatePicker
          emptyLabel={t('date.picker.placeholder')}
          style={{width: 'inherit'}}
          disableToolbar={disableToolbar}
          autoOk
          format="LL"
          disabled={disabled}
          label={label}
          value={initStartDate}
          onChange={moment => onChange(moment.toDate())}
          disableFuture={disableFuture}
          error={error}
          helperText={helperText || ''}
          maxDate={maxDate}
        />
      ) : (
        <KeyboardDatePicker
          error={error}
          helperText={helperText || ''}
          disabled={disabled}
          autoOk
          emptyLabel={t('date.picker.placeholder')}
          label={label}
          variant="inline"
          inputVariant="outlined"
          format="LL"
          value={initStartDate}
          InputAdornmentProps={{position: 'end'}}
          onChange={moment => onChange(moment.toDate())}
          disableFuture={disableFuture}
          maxDate={maxDate}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

export default SimpleDatePicker;
