import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getPredictedMaturity, getHistoricalMaturity} from '../store/actions/yield';
import {IFarm} from '../store/reducers/farm';
import {getPredictedMaturitySelector, maturityPredictionsSelector, getHistoricalMaturitySelector, maturityHistoricalSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export function useMaturityPrediction(selectedFarm: IFarm, selectedOrganization) {
  const dispatch = useDispatch();
  const {loading: loadingGetMaturity} = useSelector(getPredictedMaturitySelector);
  const maturityPredictions = useSelector(maturityPredictionsSelector);
  const prevSelectedFarm = usePrevious(selectedFarm);
  const prevMaturityPredictions = usePrevious(maturityPredictions);

  const {loading: loadingHistoricalMaturity, error: historicalMaturityError} = useSelector(getHistoricalMaturitySelector);
  const historicalMaturity = useSelector(maturityHistoricalSelector);

  useEffect(() => {
    if (selectedFarm) {
      if (selectedFarm !== prevSelectedFarm && selectedOrganization) {
        dispatch(getPredictedMaturity(selectedFarm.farmid, selectedOrganization.id));
      }
    }
  }, [dispatch, prevSelectedFarm, selectedFarm, selectedOrganization]);

  return {
    loadingGetMaturity,
    maturityPredictions,
    prevMaturityPredictions,
    getHistoricalMaturity,
    loadingHistoricalMaturity,
    historicalMaturityError,
    historicalMaturity,
    selectedOrganization,
  };
}
