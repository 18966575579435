import {IFileManagerDTO, IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import React, {useState, useEffect} from 'react';
import {Map, MapProps} from '../../../../components/UI/Map/Map';
import PolygonWithInfo from '../../../../components/UI/PolygonWithInfo/PolygonWithInfo';
import Marker from 'apps/web-portal-ui/src/app/components/UI/Marker/Marker';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {useMarker} from 'apps/web-portal-ui/src/app/hooks/useMarker';
import MapOverlay from 'apps/web-portal-ui/src/app/components/UI/MapOverlay';
import {useDispatch, useSelector} from 'react-redux';
import {filePostSelector, markerDeleteSelector} from 'apps/web-portal-ui/src/app/store/selectors';
import MapInfoWindow from 'apps/web-portal-ui/src/app/components/UI/PolygonWithInfo/InfoWindow';
import {FileManager} from 'apps/web-portal-ui/src/app/components/UI/FileManager/FileManager';
import {createFile} from 'apps/web-portal-ui/src/app/store/actions/file';
import {ISelectedFile} from 'apps/web-portal-ui/src/app/components/UI/FileManager/FileUploadModal';
import MenuIcons from 'apps/web-portal-ui/src/app/components/UI/UtilityMenu/MenuIcons';
import BlockSelectionNote from '../SelectedBlocksNote';
import DownloadMeanDataModal from 'apps/web-portal-ui/src/app/components/UI/Tools/DownloadMeanData';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 130px)',
};

interface Props extends MapProps {
  secondaryTitle?: boolean;
  yieldPredictions: {
    polygonId: string;
    yieldPrediction: number;
  }[];
  farm: IFarm;
  selectedPolygon?: polygonInfoWindow;
  polygonClicked: boolean;
  handleSelectedPolygon?: (selectedPolygon: polygonInfoWindow) => void;
  handleOnClickPolygon?: (polygon: IPolygonEntity) => void;
  disableInfoBox?: boolean;
  takeScreenshot: () => void;
}

const DashboardMap = ({
  farm,
  polygons,
  center,
  bbox,
  secondaryTitle,
  yieldPredictions,
  selectedPolygon,
  polygonClicked,
  handleSelectedPolygon,
  handleOnClickPolygon,
  disableInfoBox,
  takeScreenshot,
}: Props) => {
  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  const {
    isModalOpen,
    loading,
    postMarkerLoading,
    selectedMarkerFeature,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    markerPolygon,
    selectedPin,
    setSelectedPin,
    handleCloseModal,
    handleOnClickMap,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
  } = useMarker(farm);
  const isLoading = loading || postMarkerLoading || false;
  const {loading: isPostFileLoading} = useSelector(filePostSelector);
  const [showBlockName, setShowBlockName] = useState(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [isFileManagerModalOpen, setIsFileManagerModalOpen] = useState(false);
  const [isDownloadMeanDataModalOpen, setIsDownloadMeanDataModalOpen] = useState(false);
  const [isEnabledClaimBlocks, setIsEnabledClaimBlocks] = useState(false);
  const [selectedBlocks, setSelectedBlocks] = useState<IPolygonEntity[]>();

  const handleBlockSelection = () => {
    setShowBlockName(true);
  };

  useEffect(() => {
    // Handles situation where new farm is selected -> hides block name
    setShowBlockName(false);
  }, [farm]);

  const handleFileUploadButton = () => {
    setIsFileUploadModalOpen(!isFileUploadModalOpen);
  };
  const handleFileManagerButton = () => {
    setIsFileManagerModalOpen(!isFileUploadModalOpen);
  };

  const handleDownloadMeanData = () => {
    // download mean data
    setIsDownloadMeanDataModalOpen(!isDownloadMeanDataModalOpen);
  };

  const closeModal = () => {
    setIsFileUploadModalOpen(false);
    setIsFileManagerModalOpen(false);
    setIsDownloadMeanDataModalOpen(false);
  };

  const dispatch = useDispatch();
  // create a new marker with note
  const handleFileSubmit = (form: IFileManagerDTO, files: ISelectedFile[]) => {
    dispatch(createFile(form, files, closeModal));
  };

  const handleMultiBlockSelection = () => {
    // disable selected marker pin if it is previously selected when claim-blocks feature is enabled;
    setSelectedPin(null);
    // toggle the claim-blocks feature button
    setIsEnabledClaimBlocks(!isEnabledClaimBlocks);
    // reset all selected blocks when calim-blocks feature is disabled
    const isEnabled = !isEnabledClaimBlocks;
    if (!isEnabled) {
      setSelectedBlocks(null);
    }
  };

  const handleReset = () => {
    setSelectedBlocks(null);
  };

  // capture blocks that are selected
  const handlePolygonClick = (polygon: IPolygonEntity) => {
    const alreadySelected = selectedBlocks?.length && selectedBlocks.find(p => p.id === polygon.id);
    // if polygon is already selected remove it from selection when user clicks the same polygon twice
    if (isEnabledClaimBlocks && alreadySelected) setSelectedBlocks(prev => [...prev.filter(p => p.id !== polygon.id)]);
    // add new blocks
    isEnabledClaimBlocks &&
      !alreadySelected &&
      setSelectedBlocks(prevItems => {
        if (!prevItems) return [polygon];
        return [...prevItems, polygon];
      });
    handleOnClickPolygon(polygon);
  };
  return (
    <>
      {isMarkerDeleteLoading && <MapOverlay position="relative" />}
      {isLoading && <MapOverlay />}
      {!isLoading && !isMarkerDeleteLoading && (
        <Map
          center={center}
          mapContainerStyle={containerStyle}
          bbox={bbox}
          polygons={polygons}
          selectedPolygon={selectedPolygon}
          polygonClicked={polygonClicked}
          displayRows
          selectedShowMarker={selectedMarkerFeature}
          handleMarkerClick={handleMarkerClick}
          handleMarkerMouseOver={handleMarkerMouseOver}
          handleMarkerMouseOut={handleMarkerMouseOut}
          handleBlockSelection={handleBlockSelection}
          showBlockName={false}
        >
          <MenuIcons
            handleMultiBlockSelection={handleMultiBlockSelection}
            handleFileUpload={handleFileUploadButton}
            handleFileManager={handleFileManagerButton}
            handleDownloadMeanData={handleDownloadMeanData}
          />
          <BlockSelectionNote farm={farm} isMultiBlockSelection={isEnabledClaimBlocks} selectedBlocks={selectedBlocks} handleReset={handleReset} />
          <FileManager
            isLoading={isPostFileLoading}
            isFileUploadModalOpen={isFileUploadModalOpen}
            isFileManagerModalOpen={isFileManagerModalOpen}
            handleSubmit={handleFileSubmit}
            handleCloseModal={closeModal}
          />
          {isDownloadMeanDataModalOpen && <DownloadMeanDataModal isOpen={isDownloadMeanDataModalOpen} handleClose={closeModal} submitLoading={isLoading} />}
          {farm && polygons && (
            <Marker
              selectedMarker={selectedMarker}
              farm={farm}
              polygon={markerPolygon}
              featureName={'DASHBOARD'}
              isModalOpen={isModalOpen}
              isOpenModalNoteUpdate={isOpenModalNoteUpdate}
              handleAddPin={handleSelectedPin}
              handleMarkerPostRequest={handleMarkerPostRequest}
              handleMarkerFeatureSelection={handleMarkerFeatureSelection}
              handleCloseModal={handleCloseModal}
              handleMarkerSubmit={handleMarkerSubmit}
              handleNoteUpdateClose={handleNoteUpdateClose}
              handleDeleteMarker={handleDeleteMarker}
              takeScreenshot={takeScreenshot}
              isEnabledClaimBlocks={isEnabledClaimBlocks}
            />
          )}
          {polygons?.map(polygon => {
            const yieldPrediction = yieldPredictions?.find(({polygonId}) => polygonId === polygon.id)?.yieldPrediction;
            return (
              <>
                <PolygonWithInfo
                  key={polygon.id}
                  polygon={polygon}
                  secondaryTitle={secondaryTitle && 'Crop Type'}
                  secondaryDescription={(secondaryTitle && polygon?.geoJson?.properties?.cropType) || null}
                  hectares={polygon?.hectares}
                  yieldPrediction={yieldPrediction}
                  selectedPolygon={selectedPolygon}
                  handleSelectedPolygon={handleSelectedPolygon}
                  disableInfoWindow={disableInfoBox} // disables the info box
                  autoPan={true}
                  handlePolygonClick={handlePolygonClick} // handles when polygon is clicked on map
                  handleOnClickMap={handleOnClickMap}
                  claimedBlocks={selectedBlocks}
                  selectedPin={!!selectedPin}
                />
                {mouseOverMarker && isOpenMarkerInfoWindow && (
                  <MapInfoWindow key={mouseOverMarker.latitude + mouseOverMarker.longitude} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                )}
              </>
            );
          })}
        </Map>
      )}
    </>
  );
};

export default DashboardMap;
