import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Box, Button, Tooltip, Typography} from '@material-ui/core';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import {makeStyles} from '@material-ui/core/styles';

import {IPolygonEntity} from '@deep-planet/api-interfaces';

import Layout from '../../Layout';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import FarmSelectionSidebar from '../../../components/UI/Sidebar/FarmSelection';
import MapOverlay from '../../../components/UI/MapOverlay';
import Spinner from '../../../components/UI/Spinner';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import {MapPlaceholderNoData} from '../../../components/UI/MapPlaceholder';
import SugarMeasureSwitcher from '../../../components/UI/Switch/HistoricalSugarMeasureSwitch';
import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import Plot from './Plot';

import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useMaturityPrediction} from '../../../hooks/useMaturityPrediction';
import useTooltipText from '../../../hooks/useTooltipText';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import {PageTitle} from '../../../components/UI/PageTitle';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

const useStyles = makeStyles(theme => ({
  screenshot: {
    position: 'absolute',
    width: 40,
    height: 40,
    backgroundColor: 'white',
    borderRadius: '2.5px',
    paddingInline: '20px',
    zIndex: 5,
    top: '11px',
    right: '12px',
    [theme.breakpoints.down('md')]: {
      top: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '7px',
      width: 20,
      right: '2px',
    },
  },
}));

const HistoricalHarvest = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {allFarms, loadingGetUser, loadingGetFarm, selectedFarm, handleFarmChange} = useFarmSelection();
  const {historicalMaturity, loadingHistoricalMaturity, historicalMaturityError, getHistoricalMaturity, selectedOrganization} = useMaturityPrediction(selectedFarm);
  const {HelpHarvest} = useTooltipText();
  const {sugarUnit} = useMeasurementConversion();
  const [metric, setMetric] = useState<string>(sugarUnit || 'baume');
  const [multiplier, setMultiplier] = useState<number>(1);
  const [polygonName, setPolygonName] = useState<string>('');
  const [selectedPolygon, setSelectedPolygon] = useState<IPolygonEntity | null>(null);

  const loadingCurve = loadingHistoricalMaturity;
  const isLoading = loadingGetFarm || loadingGetUser;
  const maturityError = historicalMaturityError;
  const maturityPrediction = historicalMaturity;

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const handlePolygonChange = (polygon: IPolygonEntity) => {
    // Set polygon on change
    setSelectedPolygon(polygon);
  };

  const handleSugarSwitch = value => {
    // Set metric when user selects new one
    setMetric(value);
  };

  // useEffect to set initial polygon when page loads
  useEffect(() => {
    if (selectedFarm) {
      setSelectedPolygon(selectedFarm.polygons[0]);
    }
  }, [selectedFarm, setSelectedPolygon]);

  useEffect(() => {
    // Change value of metric multiplier when state of 'metric' changes
    const metricMultipliers = {
      baume: 1,
      brix: 1.8,
      sugar: 18,
    };
    setMultiplier(metricMultipliers[metric] || 1);
  }, [metric]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getHistoricalMaturity(selectedFarm.farmid, selectedPolygon.id, selectedOrganization.id));
      setPolygonName(selectedPolygon.name);
    };
    if (selectedFarm && selectedOrganization && selectedPolygon) {
      fetchData();
    }
  }, [dispatch, getHistoricalMaturity, selectedFarm, selectedOrganization, selectedPolygon]);

  return (
    <Box style={{margin: 0, justifyContent: 'start'}}>
      <Layout>
        {isLoading && <MapOverlay />}
        {!isLoading && !allFarms?.length && <MapPlaceholder />}
        {!isLoading && selectedFarm?.polygons?.length === 0 && <MapPlaceholderNoPolygons activeFarm={selectedFarm.farmid} />}

        {/* Loading wheel */}
        {loadingCurve && (
          <Box style={{position: 'absolute', width: '99%', height: '78%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Spinner size={75} />
          </Box>
        )}

        {!isLoading && selectedFarm && (
          <div id="layout">
            <ContentLeftSidebar
              sidebar={
                <FarmSelectionSidebar
                  polygons={selectedFarm?.polygons}
                  allFarms={allFarms}
                  activeFarm={selectedFarm.farmid}
                  handleFarmChange={handleFarmChange}
                  loadingGetFarm={loadingGetFarm}
                  handlePolygonChange={handlePolygonChange}
                />
              }
              content={
                <>
                  {/* Page title */}
                  <PageTitle title={t('harvest.curves')} tooltip={HelpHarvest}>
                    <Typography variant="body2" style={{marginLeft: 10, marginTop: 4.5}}>
                      {t('harvest.curves.instructions')}
                    </Typography>
                  </PageTitle>
                  {/* Screenshot button */}
                  {!loadingCurve && (
                    <Box className={classes.screenshot} boxShadow={1}>
                      <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title={'screenshot'} placement={'left'} arrow>
                          <Button aria-label={'screenshot'} onClick={takeScreenshot} style={{bottom: '3px', color: 'secondary'}}>
                            <ControlCameraIcon color="secondary" fontSize="large" style={{transform: 'rotate(45deg)'}} />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}

                  {maturityPrediction && selectedPolygon && !loadingCurve && !maturityError && (
                    <Box style={{height: '80vh', padding: '1rem 0 0 1rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
                      {/* Sugar measure switcher */}
                      <Box style={{width: '67vw', alignItems: 'center'}}>
                        <Box style={{marginLeft: 40}}>
                          <SugarMeasureSwitcher metric={metric} handleSwitch={handleSugarSwitch} withSugar harvest />
                        </Box>
                      </Box>

                      {/* Plot */}
                      <Box style={{height: '100%', width: '67vw', alignItems: 'center', justifyContent: 'center', marginTop: -40}}>
                        <Plot maturityPrediction={maturityPrediction} metric={metric} polygonName={polygonName} multiplier={multiplier} />
                      </Box>
                    </Box>
                  )}

                  {/* No curve data for given block */}
                  {!loadingCurve && maturityError && <MapPlaceholderNoData light title={t('harvest.forecast.no.data')} />}
                </>
              }
            />
          </div>
        )}
      </Layout>
    </Box>
  );
};

export {HistoricalHarvest};
