import axios from 'axios';
import {Dispatch} from 'redux';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {CreateNoteDTO, IMarker, IMarkerColor} from '@deep-planet/api-interfaces';
import i18n from '../../../i18n';
import {baseApiUrl} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {ISelectedFile} from '../../containers/pages/Notes/withEdit';
import {getFormData, uploadFile} from './utils';
import {noteDeleteSuccess} from './note';

/////////////////////////////////////////////
// GET marker types
/////////////////////////////////////////////

interface IMarkerColorGetStart {
  type: ActionTypes.MARKER_COLOR_GET_START;
}

export const markerColorGetStart = (): IMarkerColorGetStart => ({
  type: ActionTypes.MARKER_COLOR_GET_START,
});

interface IMarkerColorGetSuccess {
  type: ActionTypes.MARKER_COLOR_GET_SUCCESS;
  markerColors: IMarkerColor[];
}

export const markerColorGetSuccess = (markerColors: IMarkerColor[]): IMarkerColorGetSuccess => ({
  type: ActionTypes.MARKER_COLOR_GET_SUCCESS,
  markerColors,
});

interface IMarkerColorGetFail {
  type: ActionTypes.MARKER_COLOR_GET_FAIL;
  error: unknown;
}

export const markerColorGetFail = (error): IMarkerColorGetFail => ({
  type: ActionTypes.MARKER_COLOR_GET_FAIL,
  error,
});

export const getMarkerTypes = () => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(markerColorGetStart());
    const markerColorGetUrl = `${baseApiUrl}/marker/marker-colors`;
    try {
      const {data} = await axios.get<IMarkerColor[]>(markerColorGetUrl);
      dispatch(markerColorGetSuccess(data));
    } catch (err) {
      console.log(err);
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(markerColorGetFail(err));
    }
  };
};

/////////////////////////////////////////////
// GET Marker
/////////////////////////////////////////////

interface IMarkerImageGetStart {
  type: ActionTypes.MARKER_GET_START;
}

export const markerGetStart = (): IMarkerImageGetStart => ({
  type: ActionTypes.MARKER_GET_START,
});

interface IMarkerGetSuccess {
  type: ActionTypes.MARKER_GET_SUCCESS;
  images: IMarker[];
  farmId: string;
  activeFarm: IFarm;
}

export const markerGetSuccess = (images: IMarker[], farmId: string, activeFarm: IFarm): IMarkerGetSuccess => ({
  type: ActionTypes.MARKER_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface IMarkerImageGetFail {
  type: ActionTypes.MARKER_GET_FAIL;
  error: unknown;
}

export const markerImageGetFail = (error): IMarkerImageGetFail => ({
  type: ActionTypes.MARKER_GET_FAIL,
  error,
});

interface IMarkerSetSelectedDate {
  type: ActionTypes.MARKER_SET_SELECTED_DATE;
  selectedDate: number;
}

export const markerSetSelectedDate = (selectedDate: number): IMarkerSetSelectedDate => ({
  type: ActionTypes.MARKER_SET_SELECTED_DATE,
  selectedDate,
});

export const getMarkers = (farm: IFarm, startDate: string, endDate: string, organizationId?: string, username?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    const userName = username === 'ALL' ? null : username;
    dispatch(markerGetStart());
    const markerImageGetUrl = `${baseApiUrl}/marker/farm/${farm.id}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}${
      userName ? `&noteUser=${userName}` : ''
    }`;
    try {
      const {data} = await axios.get<IMarker[]>(markerImageGetUrl);
      if (data?.length) {
        dispatch(markerGetSuccess(data, farm.id, farm));
      } else {
        dispatch(markerGetSuccess([], farm.id, farm));
        raiseError(farm.name, 'MARKERS.NOT.FOUND', dispatch);
      }
    } catch (err) {
      dispatch(markerGetSuccess([], farm.id, farm));
      raiseError(farm.name, 'MARKERS.NOT.FOUND', dispatch);
    }
  };
};

const raiseError = (farmName: string, err, dispatch) => {
  console.log(err);
  dispatch(enqueueSnackbar({message: i18n.t('error.http.response.markers.not.found', {name: farmName}), options: {variant: 'error'}}));
  dispatch(markerImageGetFail(err));
};

////////////////////////////////////
// POST Marker
////////////////////////////////////

interface IMarkerPostInit {
  type: ActionTypes.MARKER_POST_INIT;
}

export const markerPostInit = (): IMarkerPostInit => ({
  type: ActionTypes.MARKER_POST_INIT,
});

interface IMarkerPostStart {
  type: ActionTypes.MARKER_POST_START;
}

export const markerPostStart = (): IMarkerPostStart => ({
  type: ActionTypes.MARKER_POST_START,
});

interface IMarkerPostSuccess {
  type: ActionTypes.MARKER_POST_SUCCESS;
  marker: IMarker;
}

interface IMarkerPutSuccess {
  type: ActionTypes.MARKER_PUT_SUCCESS;
  marker: IMarker;
}

export const markerPutSuccess = (marker: IMarker): IMarkerPutSuccess => ({
  type: ActionTypes.MARKER_PUT_SUCCESS,
  marker,
});

export const markerPostSuccess = (marker: IMarker): IMarkerPostSuccess => ({
  type: ActionTypes.MARKER_POST_SUCCESS,
  marker,
});

interface IMarkerPostFail {
  type: ActionTypes.MARKER_POST_FAIL;
  error: unknown;
}

export const markerPostFail = (error): IMarkerPostFail => ({
  type: ActionTypes.MARKER_POST_FAIL,
  error,
});

export const postMarker = (params: CreateNoteDTO, marker: IMarker, files: ISelectedFile[], closeModal: () => void) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(markerPostStart());
    if (files.length) {
      const data = await Promise.all(
        files.map(async file =>
          getFormData(
            {
              name: file.name,
              contentType: file.type,
            },
            file,
            `${baseApiUrl}/note/upload-url`
          )
        )
      );
      await Promise.all(data.map(({bucketUrl, formData}) => uploadFile(bucketUrl, formData)));
      params.attachments = data.map(({name, url, size, uploadedAt}) => ({name, url, size, uploadedAt}));
    }
    marker.note = {...params};
    const url = `${baseApiUrl}/marker/create`;
    try {
      const response = await axios.post<IMarker>(url, marker);
      const newMarker = {...response.data};
      dispatch(markerPostSuccess(newMarker));
      closeModal();
      dispatch(enqueueSnackbar({message: i18n.t('marker.saved'), options: {variant: 'success'}}));
    } catch (e) {
      console.log(e);
      dispatch(markerPostFail(e));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// DELETE MARKER
////////////////////////////////////

interface IMarkerDeleteStart {
  type: ActionTypes.MARKER_DELETE_START;
}

export const markerDeleteStart = (): IMarkerDeleteStart => ({
  type: ActionTypes.MARKER_DELETE_START,
});

interface IMarkerDeleteSuccess {
  type: ActionTypes.MARKER_DELETE_SUCCESS;
  payload: string;
}

export const markerDeleteSuccess = (markerId: string): IMarkerDeleteSuccess => ({
  type: ActionTypes.MARKER_DELETE_SUCCESS,
  payload: markerId,
});

interface IMarkerDeleteFail {
  type: ActionTypes.MARKER_DELETE_FAIL;
}

export const markerDeleteFail = (): IMarkerDeleteFail => ({
  type: ActionTypes.MARKER_DELETE_FAIL,
});

export const deleteMarker = (markerId: string, noteId: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(markerDeleteStart());
    const url = `${baseApiUrl}/marker/${markerId}`;
    try {
      await axios.delete(url);
      dispatch(markerDeleteSuccess(markerId));
      if (noteId) dispatch(noteDeleteSuccess(noteId));
      dispatch(enqueueSnackbar({message: i18n.t('marker.deleted'), options: {variant: 'success'}}));
    } catch (e) {
      console.log(e);
      dispatch(markerDeleteFail());
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

export type Action =
  | ReturnType<typeof markerGetStart>
  | ReturnType<typeof markerGetSuccess>
  | ReturnType<typeof markerImageGetFail>
  | ReturnType<typeof markerSetSelectedDate>
  | ReturnType<typeof markerPostInit>
  | ReturnType<typeof markerPostStart>
  | ReturnType<typeof markerPostSuccess>
  | ReturnType<typeof markerPutSuccess>
  | ReturnType<typeof markerPostFail>
  | ReturnType<typeof markerColorGetStart>
  | ReturnType<typeof markerColorGetSuccess>
  | ReturnType<typeof markerColorGetFail>
  | ReturnType<typeof markerDeleteStart>
  | ReturnType<typeof markerDeleteSuccess>
  | ReturnType<typeof markerDeleteFail>
  | ReturnType<typeof noteDeleteSuccess>;
