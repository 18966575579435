import {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {IFarm} from '../store/reducers/farm';
import {
  soilNutrientFarmsSelector,
  soilNutrientFarmGetSelector,
  soilNutrientImagesSelector,
  soilNutrientTypeImagesSelector,
  soilNutrientImagesGetSelector,
  soilNutrientActiveFarmSelector,
  soilNutrientTypeSelector,
  soilNutrientSelectedDataSelector,
} from './../store/selectors/soil-nutrient';
import {getSoilNutrientFarms, getSoilNutrientImages, soilNutrientSetSelectedDate} from './../store/actions/soilNutrient';
import {getStringDate} from '../helpers/dateHelpers';
import {selectedOrganizationSelector} from '../store/selectors';

/**
  1. First farm is a default selection on initialization
  2. First farm is a default selection if some NON-NUTRIENT farm is selected on another feature.
  3. select a nutrient farm and click update button,  switch to other feature and select a NON-NUTRIENT farm on other feature,
     previously selected nutrient farm will be remain active on the soil nutrient page.
 *  */
export const useSoilNutrientsSelection = (allFarms: IFarm[], activeFarm: IFarm, selectedFarm: IFarm) => {
  const [soilNutrientSelectedFarm, setSoilNutrientSelectedFarm] = useState<IFarm>();
  const [soilNutrientActiveFarm, setSoilNutrientActiveFarm] = useState<IFarm>();
  const [selectedDate, setSelectedDate] = useState<number>();
  const soilNutrientFarms = useSelector(soilNutrientFarmsSelector);
  const soilNutrientImages = useSelector(soilNutrientImagesSelector);
  const soilNutrientTypeImages = useSelector(soilNutrientTypeImagesSelector);
  const activeSoilNutrientFarm = useSelector(soilNutrientActiveFarmSelector);
  const selectedNutrientType = useSelector(soilNutrientTypeSelector);
  const soilNutientsSelectedDate = useSelector(soilNutrientSelectedDataSelector);
  const [nutrientType, setNutrientType] = useState<string>('nitrogen');
  const {loading: loadingImages, error} = useSelector(soilNutrientImagesGetSelector);
  const {loading: loadingNutrientFarms} = useSelector(soilNutrientFarmGetSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const dispatch = useDispatch();

  const handleDateChange = (date: number) => {
    setSelectedDate(date);
    dispatch(soilNutrientSetSelectedDate(new Date(date)));
  };

  const handleSoilNutrientType = (nutrientType: string) => {
    setNutrientType(nutrientType);
  };

  const getSoilNutirentTypeImages = useCallback(
    (farm: IFarm, startDate: Date, endDate: Date, disableSnackbar?: boolean) => {
      dispatch(getSoilNutrientImages(farm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id, disableSnackbar));
      if (selectedNutrientType) setNutrientType(selectedNutrientType);
    },
    [dispatch, selectedNutrientType, selectedOrganization]
  );

  useEffect(() => {
    // selectedFarm and ActiveFarm is set globally across all the features upon action of update on the sidebar,
    // but sometimes selected farm on one feature may not available in other features.
    // therefore, keep the previous farm remain selected if previously selected on the irrigation page, else set first farm as a default farm.
    if (selectedFarm && soilNutrientFarms && !soilNutrientSelectedFarm) {
      const isFound = soilNutrientFarms.some(farm => farm.id === selectedFarm.id);
      if (!isFound) setSoilNutrientSelectedFarm(activeSoilNutrientFarm || soilNutrientFarms[0]);
      else setSoilNutrientSelectedFarm(selectedFarm);
    }
    // if global activeFarm is not available in the nutrient farms, set previously selected farm, else first farm as default one
    if (activeFarm && soilNutrientFarms && !soilNutrientActiveFarm) {
      const soilNutrienFarm = soilNutrientFarms.some(f => f.id === activeFarm.id);
      if (!soilNutrienFarm) setSoilNutrientActiveFarm(activeSoilNutrientFarm || soilNutrientFarms[0]);
      else setSoilNutrientActiveFarm(activeFarm);
    }
  }, [selectedFarm, soilNutrientFarms, activeFarm, soilNutrientActiveFarm, soilNutrientSelectedFarm, activeSoilNutrientFarm]);

  useEffect(() => {
    if (!soilNutrientFarms && allFarms) {
      dispatch(getSoilNutrientFarms(allFarms));
    }
  }, [soilNutrientFarms, dispatch, allFarms]);

  useEffect(() => {
    if (!selectedDate && soilNutientsSelectedDate) {
      setSelectedDate(Number(new Date(soilNutientsSelectedDate).getTime()));
    }
  }, [selectedDate, soilNutientsSelectedDate]);

  return {
    soilNutrientTypeImages,
    soilNutrientImages,
    soilNutrientFarms,
    nutrientType,
    loadingNutrientFarms,
    loadingImages,
    error,
    selectedDate,
    soilNutrientSelectedFarm,
    soilNutrientActiveFarm,
    setSoilNutrientSelectedFarm,
    setSoilNutrientActiveFarm,
    setSelectedDate,
    handleSoilNutrientType,
    getSoilNutirentTypeImages,
    handleDateChange,
  };
};
