import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import Layout from '../../Layout';
import {useHttp} from '../../../hooks/http';
import {baseApiUrl} from '../../../config/const';
import MapOverlay from '../../../components/UI/MapOverlay';
import CreateNoteModal from './CreateNoteModal';
import {ICreateNoteDTO, INoteTypeEntity} from '@deep-planet/api-interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {getUsersSelector, notePostSelector, notesGetSelector, notesSelector, userNamesSelector} from '../../../store/selectors';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import NoteSideBar from './NoteSidebar';
import NoteList from './NoteList';
import styled from 'styled-components';
import {getNotes, createNote} from '../../../store/actions';
import {usePrevious} from '../../../hooks/usePrevious';
import {isEqual, some} from 'lodash';
import moment from 'moment';
import {ISelectedFile} from './withEdit';
import {getOrganizationUsers, getUsers} from '../../../store/actions/user';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {getUserGroups} from '../../authHOC';

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const Notes = ({user}: WithUserProps) => {
  const [isTypesLoading, types] = useHttp<INoteTypeEntity[]>(`${baseApiUrl}/note/types`);
  const [isClamiTypesLoading, claimTypes] = useHttp<INoteTypeEntity[]>(`${baseApiUrl}/note/claiming-block-types`);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState<Map<string, boolean>>(new Map());
  const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const dispatch = useDispatch();
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const {loading: isPostLoading} = useSelector(notePostSelector);
  const {loading: isGetNotesLoading} = useSelector(notesGetSelector);
  const {loading: isGetUsersLoading} = useSelector(getUsersSelector);
  const {loadingGetFarm: isFarmsLoading, allFarms: farms} = useFarmSelection();
  const notes = useSelector(notesSelector);
  const userNames = useSelector(userNamesSelector);
  const prevTypes = usePrevious(types);
  const prevSelectedStartDate = usePrevious(selectedStartDate);
  const prevSelectedEndDate = usePrevious(selectedEndDate);
  const prevNotes = usePrevious(notes);
  const {t} = useTranslation();

  useLayoutEffect(() => {
    if (prevNotes !== notes && !isGetNotesLoading && prevNotes === undefined) {
      dispatch(getNotes());
    }
  }, [dispatch, notes, isGetNotesLoading, prevNotes]);

  useLayoutEffect(() => {
    const groups: string[] = getUserGroups(user);
    const isSuperAdmin = groups.includes('SUPERADMIN');
    if (isSuperAdmin) dispatch(getUsers());
    else dispatch(getOrganizationUsers(user.username));
  }, [dispatch, user]);

  useEffect(() => {
    if (types?.length && !isEqual(types, prevTypes)) {
      const initialState = new Map<string, boolean>();
      for (const type of types) {
        initialState.set(type.name, false);
      }
      setSelectedTypes(initialState);
    }
  }, [selectedTypes, types, prevTypes]);

  const handleSubmit = (form: ICreateNoteDTO, files: ISelectedFile[]) => {
    dispatch(createNote(form, files, closeModal));
  };

  const handleTypeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = new Map(selectedTypes);
    temp.set(event.target.name, event.target.checked);
    setSelectedTypes(temp);
  };

  const handleStartDateSelection = (date: Date) => setSelectedStartDate(date);
  const handleEndDateSelection = (date: Date) => setSelectedEndDate(date);

  const getFilteredNotes = () => {
    let filteredNotes = [...notes];
    if (some(Array.from(selectedTypes.values()), e => e)) {
      filteredNotes = filteredNotes.filter(({type}) => selectedTypes.get(type.name));
    }
    if (!moment(selectedStartDate).isSame(prevSelectedStartDate, 'day')) {
      filteredNotes = filteredNotes.filter(({date}) => moment(date).isSameOrAfter(selectedStartDate));
    }
    if (!moment(selectedEndDate).isSame(prevSelectedEndDate, 'day')) {
      filteredNotes = filteredNotes.filter(({date}) => moment(date).isSameOrBefore(selectedEndDate));
    }
    return filteredNotes.sort((a, b) => (a.date < b.date ? 1 : -1)); // descending order - from latest date to old date
  };

  const isLoading = isGetNotesLoading || isTypesLoading || isFarmsLoading || isGetUsersLoading || isClamiTypesLoading;
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      <Container>
        {!isLoading && (
          <ContentLeftSidebar
            headerText={t('navigation.accordion.notes')}
            sidebar={
              <NoteSideBar
                types={types}
                openModal={openModal}
                handleTypeSelection={handleTypeSelection}
                selectedTypes={selectedTypes}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                handleEndDateSelection={handleEndDateSelection}
                handleStartDateSelection={handleStartDateSelection}
              />
            }
            content={<NoteList notes={getFilteredNotes()} types={types} claimTypes={claimTypes} farms={farms} userNames={userNames} />}
          />
        )}
      </Container>
      {isModalOpen && <CreateNoteModal isOpen={isModalOpen} handleClose={closeModal} types={types} farms={farms} handleSubmit={handleSubmit} submitLoading={isPostLoading} userNames={userNames} />}
    </Layout>
  );
};

export default withUser(Notes);
