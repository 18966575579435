import {ImageType, ProductType} from '@deep-planet/api-interfaces';
import React from 'react';
import styled from 'styled-components';
import {createStyles, Theme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import {useTranslation} from 'react-i18next';
import {GnRDY1DiscreteColorGradient, primaryColorGradient, RdYlGnDiscreteColorGradient} from '../../../utilities/theme';
import {makeStyles} from '@material-ui/core';
import {InfoOutlined as InfoIcon} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

const Container = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 2rem;
  right: 4rem;
  width: 330px;
`;

const ndwiGradient = `90deg, rgb(165,0,38) 0%, rgb(214,47,39) 10%, rgb(243,107,66) 20%, rgb(252,170,95) 30%, rgb(254,220,140) 40%, rgb(255,252,186) 50%, rgb(228,244,241) 60%, rgb(178,221,235) 70%, rgb(125,180,213) 80%, rgb(77,127,185) 90%, rgb(49,54,149) 100%`;
const ndwiGradientIrrigation = `90deg, rgb(49,54,149) 0%, rgb(77,127,185) 10%, rgb(125,180,213) 20%, rgb(178,221,235) 30%, rgb(228,244,241) 40%, rgb(255,252,186) 50%, rgb(254,220,140) 60%, rgb(252,170,95) 70%, rgb(243,107,66) 80%, rgb(214,47,39) 90%, rgb(165,0,38) 100%`;
const ndviComparisonGradient = `90deg, rgb(165,0,38) 0%, rgb(214,47,39) 10%, rgb(243,107,66) 20%, rgb(252,170,95) 30%, rgb(254,220,136) 40%, rgb(255,252,186) 50%, rgb(221,241,145) 60%, rgb(173,220,111) 70%, rgb(112,193,100) 80%, rgb(39,159,83) 90%, rgb(0,104,55) 100%`;
const ndwiComparisonGradient = `90deg, rgb(255,0,0) 0%, rgb(255,50,50) 10%, rgb(255,100,100) 20%, rgb(255,150,150) 30%, rgb(255,200,200) 40%, rgb(255,250,250) 50%, rgb(210,210,255) 60%, rgb(160,160,255) 70%, rgb(110,110,255) 80%, rgb(60,60,255) 90%, rgb(0,0,255) 100%`;
const soilNutrientComparisonGradient = `90deg, rgb(215, 48, 39) 0%, rgb(215, 48, 39) 10%, rgb(244, 109, 67) 20%, rgb(253, 174, 97) 30%, rgb(254, 224, 139) 40%, rgb(217, 239, 139) 50%, rgb(217, 239, 139) 60%, rgb(166, 217, 106) 70%, rgb(102, 189, 99) 80%, rgb(26, 152, 80) 90%, rgb(26, 152, 80) 100%`;

const ColorScale = styled.div<{gradient: string}>`
  min-width: 100%;
  height: 20px;
  position: relative;
  margin-top: 2rem;
  background: rgb(255, 255, 255);
  background: ${p => `linear-gradient(${p.gradient})`};
`;

const Scale = styled.ul<{justifyContent: string; left?: string; width: string; top?: string}>`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  position: absolute;
  top: ${p => p.top || '-100%'};
  left: ${p => p.left};
  justify-content: ${p => p.justifyContent || 'normal'};

  & li {
    min-width: ${p => p.width};
    max-width: ${p => p.width};
    display: flex;
    align-items: center;
    justify-content: center;
    & * {
      font-size: 70% !important;
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);

const formatScaleNumber = (num, isPercentage = false) => {
  // Parse the number
  const value = Number(num);

  if (!Number.isFinite(value)) {
    return num; //Return as-is if not a valid number
  }

  if (isPercentage) {
    // Multiply by 100 and remove unnecessary trailing zeros
    return `${Number((value * 100).toPrecision(3))}%`;
  }

  // For other cases, return the number with unnecessary zeros (at the end) removed
  return Number(value.toPrecision(3));
};

interface Props {
  product: ProductType | string;
  type: ImageType | string;
  dynamicScale?: DynamicScaleType;
  text?: string;
  isCalibrated?: boolean;
}

export type DynamicScaleType = [number, number, number, number, number, number, number, number];

const Legend = ({product, type, dynamicScale, text, isCalibrated = true}: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const ndviScale = ['0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8'];
  const ndwiScale = ['-0.3', '-0.2', '-0.1', '0.0', '0.1', '0.2', '0.3', '0.4', '0.5'];
  const comparisonScale = [t('ndvi.legend.decline'), t('ndvi.legend.increase')];

  const getScale = (product: ProductType | string, type: ImageType | string) => {
    // soil organic carbon(SOC) image types
    if (product === 'soilOrganicCarbon') {
      product = type === 'CURRENT' ? 'socRedBlue' : 'socRedGreen';
      type = 'current';
    }
    switch (type) {
      case 'current':
        if (product === 'ndvi' || product === 'socRedBlue') return {scale: product === 'socRedBlue' ? dynamicScale : ndviScale, width: '13.8%', gradient: primaryColorGradient, left: '-14px'};
        if (product === 'maturity_map' || product === 'yield_per_pixel') {
          return {
            // Returns empty array if dynamicScale contains only 0s as to not block uncalibrated text
            scale: dynamicScale.filter(s => s !== 0).length === 0 ? [] : dynamicScale.filter(s => s === 0 || !!s), // Does not allow null values, accepts 0
            width: '13.8%',
            gradient: RdYlGnDiscreteColorGradient,
            left: '-14px',
          };
        }
        if (product === 'soil_nutrients' || product === 'socRedGreen')
          return {
            scale: dynamicScale.filter(s => s === 0 || !!s), // Does not allow null values, accepts 0

            width: '13.8%',
            gradient: RdYlGnDiscreteColorGradient,
            left: '-14px',
          };
        if (product === 'irrigation')
          return {
            scale: dynamicScale.filter(s => s === 0 || !!s), // Does not allow null values, accepts 0

            width: '13.8%',
            gradient: ndwiGradientIrrigation,
            left: '-14px',
          };
        if (product === 'disease')
          return {
            scale: dynamicScale.filter(s => s === 0 || !!s), // Does not allow null values, accepts 0
            width: '13.8%',
            gradient: GnRDY1DiscreteColorGradient,
            left: '-14px',
          };
        return {scale: ndwiScale, width: '12.1%', gradient: ndwiGradient, left: '-14px'};
      case 'predicted':
      case 'average':
      case 'previous':
        if (product === 'ndvi') return {scale: comparisonScale, width: 'auto', gradient: ndviComparisonGradient, left: '0px', justifyContent: 'space-between'};
        if (product === 'soil_nutrients') return {scale: comparisonScale, width: 'auto', gradient: soilNutrientComparisonGradient, left: '0px', justifyContent: 'space-between'};
        if (product === 'irrigation') return {scale: dynamicScale, width: '13.8%', gradient: ndwiGradient, left: '-14px'};
        return {scale: comparisonScale, width: 'auto', gradient: ndwiComparisonGradient, left: '0px', justifyContent: 'space-between'};
      default:
        if (product === 'ndwi') {
          return {scale: ndwiScale, width: '12.1%', gradient: ndwiGradient, left: '-14px'};
        }
        return {scale: ndviScale, width: '13.8%', gradient: primaryColorGradient, left: '-14px'};
    }
  };

  const getTooltipTexts = () => {
    switch (product) {
      case 'maturity_map':
        return {tooltipTitle: t('uncalibrated.maturity.map.title'), tooltipDescription: t('uncalibrated.maturity.map.description')};
      case 'soil_nutrients':
        return {tooltipTitle: t('uncalibrated.soil.nutrient.title'), tooltipDescription: t('uncalibrated.soil.nutrient.description')};
      case 'soilOrganicCarbon':
        return {tooltipTitle: t('uncalibrated.soil.carbon.title'), tooltipDescription: t('uncalibrated.soil.carbon.description')};
      default:
        return {tooltipTitle: null, tooltipDescription: null};
    }
  };

  const {scale, width, gradient, left, justifyContent} = getScale(product, type);
  const {tooltipTitle, tooltipDescription} = getTooltipTexts();

  const getLegendTitle = () => {
    switch (product) {
      case 'ndvi':
        return t('ndvi.title');
      case 'ndwi':
        return t('ndwi.title');
      case 'maturity_map':
        return `${t('maturity.map')} - ${text || ''}`;
      case 'yield_per_pixel':
        return `${t('yield.per.pixel')} - (${text || ''})`;
      case 'soil_nutrients':
        return `${t('soil.nutrients')} - ${text || ''}`;
      case 'irrigation':
        return `${t('irrigation.evaportranspiration')} - (${text || ''})`;
      case 'soilOrganicCarbon':
        return `${t('soil.organic.carbon')} - (${text || ''})`;
      case 'disease':
        return `${t('disease')}`;
      default:
        return null;
    }
  };

  const title = getLegendTitle();

  return (
    <Hidden smDown implementation="css">
      <Container>
        <Paper className={classes.root}>
          <Typography variant="body1">
            <strong>{title}</strong>
          </Typography>
          {dynamicScale && <Typography variant="caption">{t('maturity.map.hover.text')}</Typography>}
          <ColorScale gradient={gradient}>
            {scale?.length > 0 && (
              <Scale width={width} left={left} justifyContent={justifyContent}>
                {scale.map((scaleValue, idx) => {
                  if (scaleValue == null) return <li key={idx} />; // Return empty list so scale value still takes spot on scale
                  return (
                    <li key={idx}>
                      <Typography variant="body1">
                        {
                          !Number.isFinite(Number(scaleValue))
                            ? scaleValue // Return scaleValue as string
                            : product === 'disease'
                            ? formatScaleNumber(Number(scaleValue), true) // Display as percentage
                            : formatScaleNumber(Number(scaleValue)) // Round to 3 sig figs & remove unnecessary zeros
                        }
                      </Typography>
                    </li>
                  );
                })}
              </Scale>
            )}
            {!isCalibrated && (
              <Scale width={width} top={'-125%'} justifyContent={'center'}>
                <Typography variant="body2">
                  <strong>{t('uncalibrated')}</strong>
                </Typography>
                <Tooltip
                  title={
                    <>
                      <Typography color="inherit" align="center">
                        {tooltipTitle}
                      </Typography>
                      <Typography color="inherit">{tooltipDescription}</Typography>
                    </>
                  }
                  placement={'top'}
                  arrow
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Scale>
            )}
          </ColorScale>
        </Paper>
      </Container>
    </Hidden>
  );
};

export default React.memo(Legend);
