import {IFarmEntity, IFarmSettingsUI, IOrganizationEntity, IOrganizationSettingsUI} from '@deep-planet/api-interfaces';
import {Box, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Switch, TextField, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import {Autocomplete} from '@material-ui/lab';
import {sortBy} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SimpleDatePicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/SimpleDatePicker';
import {ORGANIZATION} from 'apps/web-portal-ui/src/app/config/const';

interface Props {
  selectedOrganization: IOrganizationEntity;
  selectedFarm: IFarmEntity;
  groups: string[];
  currentFeaturesEnabled: string[];
  selectedType: string;
  organizationSettings: IOrganizationSettingsUI;
  farmSettings: IFarmSettingsUI;
  handleHeatShockMonth: (value: number) => void;
  handleFrostShockMonth: (value: number) => void;
  handleFarmSelection: (farm: IFarmEntity) => void;
  handleSubmit: (value: Date, name: string) => void;
  handleToggle: (value: string) => void;
}

export const AddFeatureToFarm = ({
  selectedFarm,
  selectedOrganization,
  groups,
  currentFeaturesEnabled,
  selectedType,
  organizationSettings,
  farmSettings,
  handleFrostShockMonth,
  handleHeatShockMonth,
  handleFarmSelection,
  handleSubmit,
  handleToggle,
}: Props) => {
  const months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const featuresSettings = selectedType === ORGANIZATION ? organizationSettings : farmSettings;

  const {baselineDate, heatShockMonth, frostShockMonth, seasonStartDate, seasonEndDate, fruitSetDate, imageGenerationDate} = {...featuresSettings};

  const {t} = useTranslation();

  return (
    <Box padding="2rem 0 0">
      <FormControl fullWidth variant="outlined" margin="normal">
        {selectedType === 'Farm' && (
          <Autocomplete
            value={selectedFarm}
            options={sortBy(
              selectedOrganization.organizationToFarms.map(({farm}) => farm),
              ({name}) => name.toLowerCase()
            )}
            onChange={(e, farm) => handleFarmSelection(farm as IFarmEntity)}
            getOptionLabel={({name}) => name}
            renderInput={params => <TextField {...params} label="Select a farm" variant="outlined" />}
          />
        )}

        <Box marginTop="2rem" width="100%" style={{display: 'flex'}}>
          <Box width="100%" style={{paddingRight: '10px'}}>
            <SimpleDatePicker value={seasonStartDate ? new Date(seasonStartDate) : null} onChange={date => handleSubmit(date, 'seasonStartDate')} label={'Season Start Date'} />
          </Box>
          <Box width="100%" style={{paddingRight: '10px'}}>
            <SimpleDatePicker value={seasonEndDate ? new Date(seasonEndDate) : null} onChange={date => handleSubmit(date, 'seasonEndDate')} label={'Season End Date'} />
          </Box>
        </Box>
        <Box marginTop="2rem" width="100%" style={{display: 'flex'}}>
          <Box width="100%" style={{paddingRight: '10px'}}>
            <SimpleDatePicker value={fruitSetDate ? new Date(fruitSetDate) : null} onChange={date => handleSubmit(date, 'fruitSetDate')} label={'Fruitset Date'} />
          </Box>
          <Box width="100%" style={{paddingRight: '10px'}}>
            <SimpleDatePicker value={baselineDate ? new Date(baselineDate) : null} onChange={date => handleSubmit(date, 'baselineDate')} label={'Baseline Date'} />
          </Box>
        </Box>
        <Box marginTop="2rem" width="100%" style={{display: 'flex'}}>
          <Box width="100%" style={{paddingRight: '10px'}}>
            <SimpleDatePicker value={imageGenerationDate ? new Date(imageGenerationDate) : null} onChange={date => handleSubmit(date, 'imageGenerationDate')} label={'Image Generation Date'} />
          </Box>
        </Box>
        <Box marginTop="2rem" width="100%" style={{display: 'flex'}}>
          <Box width="100%" style={{paddingRight: '10px'}}>
            <Autocomplete
              options={sortBy(months, m => m)}
              onChange={(e, month) => handleHeatShockMonth(Number(month))}
              value={heatShockMonth || null}
              getOptionLabel={month => month.toString()}
              renderInput={params => <TextField {...params} label="Select Heat Shock Month" variant="outlined" />}
            />
          </Box>
          <Box width="100%" style={{paddingRight: '10px'}}>
            <Autocomplete
              options={sortBy(months, m => m)}
              onChange={(e, month) => handleFrostShockMonth(Number(month))}
              value={frostShockMonth || null}
              getOptionLabel={month => month.toString()}
              renderInput={params => <TextField {...params} label="Select Frost Shock Month" variant="outlined" />}
            />
          </Box>
        </Box>
        <Typography variant="body2" noWrap>
          * Enable permissions to generate the imagery manually or schedule to auto-generate for selected farms.
        </Typography>
        <Typography variant="body2" noWrap>
          * Please turn off the permission after manual generation if auto-generating imagery is not required.
        </Typography>
        <List subheader={<ListSubheader>{t('admin.permissions.groups')}</ListSubheader>}>
          {groups &&
            groups.map(groupName => (
              <ListItem key={groupName}>
                <ListItemText id="switch-list-label-wifi" primary={groupName} />
                <ListItemSecondaryAction>
                  <Switch
                    disabled={selectedOrganization === null}
                    edge="end"
                    onChange={() => handleToggle(groupName)}
                    checked={currentFeaturesEnabled.includes(groupName)}
                    inputProps={{
                      'aria-labelledby': 'switch-list-label-wifi',
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </FormControl>
    </Box>
  );
};
