import React from 'react';
import styled from 'styled-components';
import MapPlaceholder from '../../../../../../components/UI/MapPlaceholder';
import MapOverlay from '../../../../../../components/UI/MapOverlay';
import Map from '../../../../Dashboard/Map';
import {MapPlaceholderNoPolygons} from '../../../../../../components/UI/MapPlaceholder';
import FarmSelectionSidebar from '../../../../../../components/UI/Sidebar/FarmSelection';
import {useMarkerColor} from '../../../../../../hooks/useMarkerColor';
import {getScreenshot} from '../../../../NDVI/DownloadScreenshot';
import {Button, Fade} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {IFarm, IPolygonWithArea} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const FarmSelectionContainer = styled.div`
  width: 300px;
  padding-top: 33px;
  height: calc(100vh - 130px);
  background-color: white;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 15px;
`;

interface Props {
  selectedFarm: IFarm;
  loadingGetUser: boolean;
  loadingGetFarm: boolean;
  allFarms: IFarm[];
  activePolygon: IPolygonEntity | IPolygonWithArea;
  selectedPolygon: polygonInfoWindow;
  polygonClicked: boolean;
  handleCloseModal: () => void;
  handlePolygonChange: (polygon: IPolygonEntity) => void;
  handleSelectedPolygon: (selectedBlock: polygonInfoWindow) => void;
  handleOnClickPolygon: (polygon: IPolygonEntity) => void;
}

const MapExpanded = ({
  handleCloseModal,
  selectedFarm,
  loadingGetUser,
  loadingGetFarm,
  allFarms,
  activePolygon,
  handlePolygonChange,
  selectedPolygon,
  polygonClicked,
  handleSelectedPolygon,
  handleOnClickPolygon,
}: Props) => {
  const {t} = useTranslation();

  // Initialize the marker colors
  useMarkerColor();

  // Get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const isLoading = loadingGetUser || loadingGetFarm;

  return (
    <>
      {isLoading && <MapOverlay />}
      {!isLoading && selectedFarm?.polygons?.length === 0 && <MapPlaceholderNoPolygons activeFarm={selectedFarm?.id} />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && selectedFarm && (
        <Fade in timeout={500}>
          <Container>
            <FarmSelectionContainer>
              <FarmSelectionSidebar
                polygons={selectedFarm?.polygons}
                allFarms={allFarms}
                activeFarm={selectedFarm.id}
                activePolygon={activePolygon}
                loadingGetFarm={loadingGetFarm}
                handlePolygonChange={handlePolygonChange}
                handleFarmChange={null}
                disableFarmSelection
              />
              <ButtonContainer>
                <Button color="primary" variant="contained" onClick={handleCloseModal}>
                  {t('close')}
                </Button>
              </ButtonContainer>
            </FarmSelectionContainer>
            <Map
              farm={selectedFarm}
              polygons={selectedFarm?.polygons}
              center={selectedFarm.farmCenter}
              bbox={selectedFarm.bbox}
              selectedPolygon={selectedPolygon}
              polygonClicked={polygonClicked}
              handleSelectedPolygon={handleSelectedPolygon}
              handleOnClickPolygon={handleOnClickPolygon}
              takeScreenshot={takeScreenshot}
              yieldPredictions={[]}
              disableInfoBox
              disableScreenshot
            />
          </Container>
        </Fade>
      )}
    </>
  );
};

export default MapExpanded;
