import {Box, Button, ButtonGroup} from '@material-ui/core';
import {MAGNESIUM, NITROGEN, PHOSPHORUS, POTASSIUM} from 'apps/web-portal-ui/src/app/helpers/imageTransformers';
import React from 'react';

interface Props {
  list: string[];
  handleSoilNutrientType: (type: string) => void;
  nutrientType: string;
  disabled: boolean;
}

// button names on UI
export const SoilNutrientsList = ['N', 'P', 'K'];

// Map for nutrient type initialization
const nutrientMap = {
  N: NITROGEN,
  P: PHOSPHORUS,
  K: POTASSIUM,
  MG: MAGNESIUM,
};

export const ButtonGroupBar = ({list, handleSoilNutrientType, nutrientType, disabled}: Props) => {
  // Initialize nutrient abbreviation
  const nutrientAbbr = Object.keys(nutrientMap).find(key => nutrientMap[key] === nutrientType) || 'N';

  const handleSoilNutrientTypeChange = (abbr: string) => {
    const type = nutrientMap[abbr.toUpperCase()];
    if (type) {
      handleSoilNutrientType(type);
    }
  };

  return (
    <Box
      m={2}
      ml={2}
      display="flex"
      alignItems="center"
      style={{
        backgroundColor: 'white',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        position: 'absolute',
        right: '50px',
        marginTop: '20px',
      }}
    >
      <ButtonGroup disabled={disabled} size="small" aria-label="small contained button group">
        {list.map((item, idx) => (
          <Button key={idx + nutrientAbbr} variant="contained" color={item === nutrientAbbr ? 'secondary' : 'default'} onClick={() => handleSoilNutrientTypeChange(item)}>
            {item}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};
