import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, makeStyles, Tooltip} from '@material-ui/core';
import {useMobileView} from 'apps/web-portal-ui/src/app/hooks/useMobileView';

interface StyleProps {
  isMobileView: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: (props: StyleProps) => (props.isMobileView ? 53 : 10),
    left: (props: StyleProps) => (props.isMobileView ? 10 : 184),
    zIndex: 1,
    height: 40,
    borderRadius: 4.5,
    display: 'flex',
    flexDirection: 'row',
  },
  buttonSpacing: {
    marginRight: '5px',
  },
}));

interface FertilizerButtonGroupProps {
  showIndex: boolean;
  showFertilizer: boolean;
  fertilizerButtonConditions: boolean;
  indexButtonConditions: boolean;
  rightbarOpen: boolean;
  vraButtonConditions: boolean;
  toggleIndex: () => void;
  toggleFertilizer: () => void;
  toggleRightbar: () => void;
}

const FertilizerButtonGroup = ({
  showIndex,
  toggleIndex,
  showFertilizer,
  toggleFertilizer,
  fertilizerButtonConditions,
  indexButtonConditions,
  rightbarOpen,
  toggleRightbar,
  vraButtonConditions,
}: FertilizerButtonGroupProps) => {
  const {t} = useTranslation();
  const {isMobileView} = useMobileView();
  const classes = useStyles({isMobileView});

  return (
    <Box className={classes.container}>
      {vraButtonConditions && (
        <Tooltip title={t('soil.nutrients.vra.tooltip')} arrow>
          <Button variant="contained" color={rightbarOpen ? 'primary' : 'default'} onClick={toggleRightbar} className={classes.buttonSpacing}>
            VRA
          </Button>
        </Tooltip>
      )}
      {indexButtonConditions && (
        <Button variant="contained" color={showIndex ? 'primary' : 'default'} onClick={toggleIndex} className={classes.buttonSpacing}>
          {t('soil.nutrients.index')}
        </Button>
      )}
      {fertilizerButtonConditions && (
        <Button variant="contained" color={showFertilizer ? 'primary' : 'default'} onClick={toggleFertilizer} className={classes.buttonSpacing}>
          {t('soil.nutrients.fertilizer')}
        </Button>
      )}
    </Box>
  );
};

export default FertilizerButtonGroup;
