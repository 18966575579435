import React, {useState, useEffect} from 'react';
import {Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Layout from '../../Layout';
import Map from './Map';
import Sidebar from './Sidebar';
import Overlay from './Overlay/Overlay';
import MobileOverlay from './Overlay/MobileOverlay';
import MapPlaceholder from '../../../components/UI/MapPlaceholder';
import MapOverlay from '../../../components/UI/MapOverlay';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import {PageTitle} from '../../../components/UI/PageTitle';

import {withUser} from '../../../hooks/useAuth';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import usePolygon from '../../../hooks/usePolygon';
import useTooltipText from '../../../hooks/useTooltipText';
import {getSeasonIndicesSelector, seasonIndicesSelector, selectedOrganizationSelector} from '../../../store/selectors';
import {getSeasonIndices} from '../../../store/actions/seasonIndices';

import {INDEX, MAP, HALF, FULL} from '../../../config/const';

const SeasonIndices = () => {
  const {t} = useTranslation();
  const {HelpSeasonIndices} = useTooltipText();
  const smallScreen = window.innerHeight < 500 || window.innerWidth < 700;

  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const {loading: loadingSeasonIndices, error: seasonIndicesError} = useSelector(getSeasonIndicesSelector);
  const seasonIndices = useSelector(seasonIndicesSelector);

  const {allFarms, loadingGetUser, loadingGetFarm, selectedFarm, setSelectedFarm} = useFarmSelection();
  const {polygonClicked, selectedPolygon, setSelectedPolygon, handleOnClickPolygon} = usePolygon();

  const [screenMode, setScreenMode] = useState(MAP);
  const [polygonName, setPolygonName] = useState(null);
  const [index, setIndex] = useState('All');

  const metric = INDEX;

  const handleChange = (id: string) => {
    setSelectedFarm(allFarms.find(farm => farm.id === id));
    setSelectedPolygon(null);
  };

  // On polygon click -> pops out overlay if not already showing
  const showOverlay = () => {
    if (smallScreen) {
      setScreenMode(FULL);
    } else if (screenMode === MAP) {
      setScreenMode(HALF);
    }
  };

  useEffect(() => {
    const getPolygonName = (polygon: any) => {
      const foundPolygon = selectedFarm && polygon ? selectedFarm.polygons.find(({id}) => id === polygon.polygonId) : null;
      return foundPolygon ? foundPolygon.name : null;
    };

    setPolygonName(getPolygonName(selectedPolygon));
  }, [selectedFarm, selectedPolygon]);

  useEffect(() => {
    if (selectedOrganization && selectedFarm) {
      setSelectedPolygon(null);
      setScreenMode(MAP);
      const currentDate = new Date(Date.now());
      const fiveYearsPrev = new Date();
      fiveYearsPrev.setFullYear(currentDate.getFullYear() - 5);
      dispatch(getSeasonIndices(selectedFarm.id, selectedOrganization.id, fiveYearsPrev, currentDate));
    }
  }, [dispatch, selectedFarm, selectedOrganization, setSelectedPolygon]);

  const isLoading = loadingGetUser || loadingGetFarm || loadingSeasonIndices;

  return (
    <Layout>
      <div style={{position: 'relative', overflow: 'hidden', height: 'calc(100% - 64px)'}}>
        {isLoading && <MapOverlay />}
        {!isLoading && selectedFarm?.polygons?.length === 0 && <MapPlaceholderNoPolygons activeFarm={selectedFarm?.id} />}
        {!isLoading && !allFarms?.length && <MapPlaceholder />}
        {!isLoading && selectedFarm && (
          <div style={{position: 'relative'}} id="layout">
            <ContentLeftSidebar
              content={
                <>
                  <PageTitle title={t('navigation.help.season.indices.title')} tooltip={HelpSeasonIndices} />
                  <Box style={{display: 'flex', flexDirection: smallScreen ? 'column' : 'row', backgroundColor: 'white', marginLeft: 3}}>
                    <Map
                      bbox={selectedFarm.bbox}
                      center={selectedFarm.farmCenter}
                      polygons={selectedFarm?.polygons}
                      selectedPolygon={selectedPolygon}
                      polygonClicked={polygonClicked}
                      handleOnClick={handleOnClickPolygon}
                      screenMode={screenMode}
                      smallScreen={smallScreen}
                      showOverlay={showOverlay}
                    />
                    {smallScreen ? (
                      <MobileOverlay
                        screenMode={screenMode}
                        setScreenMode={setScreenMode}
                        metric={metric}
                        index={index}
                        smallScreen={smallScreen}
                        polygonName={polygonName}
                        selectedFarm={selectedFarm}
                        selectedPolygon={selectedPolygon}
                        seasonIndices={seasonIndices}
                        loadingSeasonIndices={loadingSeasonIndices}
                        seasonIndicesError={seasonIndicesError}
                      />
                    ) : (
                      <Overlay
                        screenMode={screenMode}
                        setScreenMode={setScreenMode}
                        metric={metric}
                        index={index}
                        smallScreen={smallScreen}
                        polygonName={polygonName}
                        selectedFarm={selectedFarm}
                        selectedPolygon={selectedPolygon}
                        seasonIndices={seasonIndices}
                        loadingSeasonIndices={loadingSeasonIndices}
                        seasonIndicesError={seasonIndicesError}
                      />
                    )}
                  </Box>
                </>
              }
              sidebar={
                <Sidebar
                  activeFarm={selectedFarm}
                  allFarms={allFarms}
                  handleChange={handleChange}
                  index={index}
                  setIndex={setIndex}
                  selectedPolygon={selectedPolygon}
                  setSelectedPolygon={setSelectedPolygon}
                  polygonName={polygonName}
                  screenMode={screenMode}
                  setScreenMode={setScreenMode}
                  smallScreen={smallScreen}
                />
              }
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withUser(SeasonIndices);
