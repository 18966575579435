import React, {useEffect, useState} from 'react';
import Layout from '../../../Layout';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import Sidebar from './Sidebar';
import Map from './Map';
import MapOverlay from '../../../../components/UI/MapOverlay';
import {Error} from '../../../../components/UI/Error/Error';
import {MapPlaceholderNoPolygons} from '../../../../components/UI/MapPlaceholder';
import MapPlaceholder from '../../../../components/UI/MapPlaceholder';
import {withUser, WithUserProps} from '../../../../hooks/useAuth';
import {useFarmSelection} from '../../../../hooks/useFarmSelection';
import {useSoilNutrientsSelection} from 'apps/web-portal-ui/src/app/hooks/useSoilNutrientImage';
import {ButtonGroupBar, SoilNutrientsList} from './ButtonGroup';
import {useTranslation} from 'react-i18next';
import {DynamicScaleType} from '../../NDVI/Legend';
import {getSoilNutreintsThresholds} from 'apps/web-portal-ui/src/app/helpers/imageTransformers';
import InfoSection from '../../NDVI/InfoSection';
import {useMarkerColor} from 'apps/web-portal-ui/src/app/hooks/useMarkerColor';
import {getScreenshot} from '../../NDVI/DownloadScreenshot';
import usePolygon from '../../../../hooks/usePolygon';
import Rightbar from 'apps/web-portal-ui/src/app/components/UI/Rightbar';
import VariableRateApplication from './VRA/VariableRateApplication';
import useTooltipText from '../../../../hooks/useTooltipText';
import {PageTitle} from 'apps/web-portal-ui/src/app/components/UI/PageTitle';
import {Box} from '@material-ui/core';
import {useVariableRateApplication} from 'apps/web-portal-ui/src/app/hooks/useVariableRateApplication';

const SoilNutrientsUI = ({user}: WithUserProps) => {
  //initialize the marker colors
  useMarkerColor();
  const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
  const [dynamicScale, setDynamicScale] = useState<DynamicScaleType>(initialDynamicScale);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [type, setType] = useState<'current' | 'predicted' | 'previous' | 'average'>('current');

  const {t} = useTranslation();
  const {HelpSoilNutrients} = useTooltipText();
  const {allFarms, activeFarm, selectedFarm, setSelectedFarm, setActiveFarm, loadingGetFarm: loadingFarms} = useFarmSelection();
  const {polygonClicked, selectedPolygon, handleOnClickPolygon} = usePolygon();
  const {
    soilNutrientImages,
    nutrientType,
    loadingNutrientFarms,
    loadingImages,
    error,
    selectedDate,
    setSelectedDate,
    soilNutrientActiveFarm,
    setSoilNutrientActiveFarm,
    setSoilNutrientSelectedFarm,
    handleDateChange,
    handleSoilNutrientType,
    getSoilNutirentTypeImages,
  } = useSoilNutrientsSelection(allFarms, activeFarm, selectedFarm);
  const imagesOnSelectedDate = soilNutrientImages?.find(({date, farmid}) => date === selectedDate && farmid === selectedFarm.id);
  const {
    zones,
    zonesCalculated,
    reference,
    isMean,
    fromDate,
    toDate,
    loadingZones,
    zoneError,
    zoneThresholds,
    userZoneLevels,
    totalInput,
    vraZoneData,
    fetchZoneData,
    handleZoneInputChange,
    handleReferenceChange,
    handleIsMeanChange,
    handleFromDateChange,
    handleToDateChange,
    resetZones,
  } = useVariableRateApplication({selectedFarm, nutrientType, selectedDate, handleSoilNutrientType, imagesOnSelectedDate});
  const [rightbarOpen, setRightbarOpen] = useState<boolean>(imagesOnSelectedDate ? true : false);

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleUpdateDateIndex = (date: number, type: 'current' | 'predicted' | 'previous' | 'average') => {
    setType(type);
    handleDateChange(date);
  };

  const handleClickCompare = type => {
    setType(type);
  };

  const handleGetRequest = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
      setSoilNutrientActiveFarm(selectedFarm);
    }
    getSoilNutirentTypeImages(selectedFarm, startDate, endDate);
  };

  const handleFarmChange = (farmId: string) => {
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setSoilNutrientSelectedFarm(selectedFarm);
    setSelectedFarm(selectedFarm);
  };

  const handlePolygonHover = (id: string) => {
    if (id) {
      const {thresholdLow, thresholdHigh} = getSoilNutreintsThresholds(imagesOnSelectedDate?.images, id, nutrientType, type);
      const step = (thresholdHigh - thresholdLow) / 7;
      // @ts-ignore
      const currentDynamicScale: DynamicScaleType = [];
      for (let i = 0; i <= 7; i++) {
        currentDynamicScale.push(Number((thresholdLow + step * i).toFixed(2)));
      }
      setDynamicScale(currentDynamicScale);
    } else {
      setDynamicScale(initialDynamicScale);
    }
  };

  //get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const toggleRightbar = () => {
    setRightbarOpen(!rightbarOpen);
  };

  const handleNoTypeData = () => {
    setType('current'); // Reset's to default initial state
  };

  const isLoading = loadingNutrientFarms || loadingFarms || loadingImages;
  const selectedActiveFarm = activeFarm || soilNutrientActiveFarm || (allFarms && allFarms[0]);
  const prevImageUrl = imagesOnSelectedDate?.images.some(i => i[nutrientType].prev_url);
  const nutrientName = nutrientType.charAt(0).toUpperCase() + nutrientType.substring(1, nutrientType.length).toLowerCase();
  const imageDates = soilNutrientImages?.map(image => image.date).sort((a, b) => a - b); // Sort image dates so that they appear in date order

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && selectedActiveFarm && !selectedActiveFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={selectedActiveFarm.id} />}
      {!isLoading && error && <Error onClick={handleGetRequest} />}
      {!isLoading && selectedActiveFarm && (
        <div style={{overflow: 'hidden', position: 'relative'}} id="layout">
          <ContentLeftSidebar
            sidebar={
              <Sidebar
                type={type}
                activeFarm={selectedFarm}
                allFarms={allFarms}
                images={soilNutrientImages}
                startDate={startDate}
                endDate={endDate}
                selectedDate={selectedDate}
                nutrientType={nutrientType}
                error={error}
                disabled={loadingZones}
                handleNoTypeData={handleNoTypeData}
                handleChange={handleFarmChange}
                handlePostRequest={handleGetRequest}
                handleUpdateDateIndex={handleUpdateDateIndex}
                handleStartDateSelection={handleStartDateSelection}
                handleEndDateSelection={handleEndDateSelection}
              />
            }
            content={
              <>
                <ButtonGroupBar list={SoilNutrientsList} handleSoilNutrientType={handleSoilNutrientType} nutrientType={nutrientType} disabled={loadingZones} />
                <Box style={{maxWidth: '60vw'}}>
                  <PageTitle
                    title={
                      <InfoSection
                        selectedDate={selectedDate}
                        type={type}
                        product={nutrientType}
                        description={t('soil.nutrients') + ' - ' + nutrientType.charAt(0).toUpperCase() + nutrientType.substring(1).toLowerCase()}
                        handleClickCompare={handleClickCompare}
                        prevImageUrl={prevImageUrl}
                      />
                    }
                    tooltip={HelpSoilNutrients}
                  />
                </Box>
                <Box style={{display: 'flex', flexDirection: 'row', backgroundColor: 'white'}}>
                  <Map
                    farm={selectedActiveFarm}
                    center={selectedActiveFarm.farmCenter}
                    imagesOnSelectedDate={imagesOnSelectedDate}
                    nutrientType={nutrientType}
                    type={type}
                    bbox={selectedActiveFarm.bbox}
                    polygons={selectedActiveFarm.polygons}
                    handlePolygonHover={handlePolygonHover}
                    takeScreenshot={takeScreenshot}
                    polygonClicked={polygonClicked}
                    selectedPolygon={selectedPolygon}
                    handleOnClickPolygon={handleOnClickPolygon}
                    dynamicScale={dynamicScale}
                    nutrientName={nutrientName}
                    rightbarOpen={rightbarOpen}
                    toggleRightbar={toggleRightbar}
                    vraZoneData={vraZoneData}
                  />
                  {selectedFarm && (
                    <Rightbar open={rightbarOpen} handleBack={() => setRightbarOpen(false)}>
                      <VariableRateApplication
                        selectedFarm={selectedFarm}
                        imageDates={imageDates}
                        zones={zones}
                        zonesCalculated={zonesCalculated}
                        reference={reference}
                        isMean={isMean}
                        fromDate={fromDate}
                        toDate={toDate}
                        loadingZones={loadingZones}
                        zoneError={zoneError}
                        zoneThresholds={zoneThresholds}
                        userZoneLevels={userZoneLevels}
                        totalInput={totalInput}
                        fetchZoneData={fetchZoneData}
                        handleZoneInputChange={handleZoneInputChange}
                        handleReferenceChange={handleReferenceChange}
                        handleIsMeanChange={handleIsMeanChange}
                        handleFromDateChange={handleFromDateChange}
                        handleToDateChange={handleToDateChange}
                        resetZones={resetZones}
                      />
                    </Rightbar>
                  )}
                </Box>
              </>
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default withUser(SoilNutrientsUI);
