import React from 'react';
import {Switch, Box, Typography, withStyles, useMediaQuery} from '@material-ui/core';
import {SOILORGANICCARBON} from '../../../config/const';

const title1Color = '#3f51b5';
const title2Color = '#f50057';

const CustomSwitch = withStyles({
  switchBase: {
    color: title1Color,
    '&$checked': {
      color: title2Color,
    },
    '&$checked + $track': {
      backgroundColor: title2Color,
    },
    '& + $track': {
      backgroundColor: title1Color,
    },
  },
})(Switch);

const typographyStyle: React.CSSProperties = {
  fontWeight: 400,
  display: 'flex',
  flexDirection: 'row',
  whiteSpace: 'nowrap',
};

interface DualSwitchProps {
  checked: boolean;
  title1: string;
  title2: string;
  handleChange: () => void;
  page?: string;
  backgroundColor?: string;
}

const Switcher: React.FC<DualSwitchProps> = ({handleChange, checked, title1, title2, page, backgroundColor}) => {
  const smallSwitch = useMediaQuery('(max-height:500px)') ? 'small' : 'medium';
  const verySmallScreen = useMediaQuery('(max-width:600px)');

  const boxStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backgroundColor ? backgroundColor : 'white',
    zIndex: 7,
    padding: '1px 7px',
    borderRadius: '2px',
  };

  // Do not render the component at all if the screen is very small
  if (verySmallScreen && page === SOILORGANICCARBON) {
    return null;
  }

  return (
    <Box style={boxStyle}>
      <Typography style={typographyStyle}>{checked ? title1 : <b>{title1}</b>}</Typography>
      <CustomSwitch onChange={handleChange} checked={checked} size={smallSwitch} />
      <Typography style={typographyStyle}>{checked ? <b>{title2}</b> : title2}</Typography>
    </Box>
  );
};

export default Switcher;
