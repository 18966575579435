import {useState, useEffect} from 'react';
import axios from 'axios';
import {baseSoilNutrientUrl, NDVI} from '../config/const';
import {IFarm} from '../store/reducers/farm';
import {IUserZoneLevels} from '@deep-planet/api-interfaces';

export const useVariableRateApplication = (selectedFarm: IFarm, nutrientType: string, selectedDate: number, handleSoilNutrientType: (nutrientType: string) => void, imagesOnSelectedDate: any) => {
  const [zones, setZones] = useState<number>(3); // Counts the amount of user selected zones (static for now)
  const [zonesCalculated, setZonesCalculated] = useState<boolean>(false); // Confirms zones are calculated
  const [reference, setReference] = useState<string>(nutrientType); // Tracks the selected index/reference
  const [isMean, setIsMean] = useState<boolean>(false); // Determines if user has selected mean values
  const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 7))); // From date
  const [toDate, setToDate] = useState<Date>(new Date()); // To date
  const [loadingZones, setLoadingZones] = useState<boolean>(false); // Loading status of fetch request
  const [zoneError, setZoneError] = useState<string>(null); // Fetch error
  const [zoneThresholds, setZoneThresholds] = useState<number[]>(null); // A list of zone thresholds
  const [userZoneLevels, setUserZoneLevels] = useState<IUserZoneLevels>({}); // Tracks zone thresholds as well as user's inputted values
  const [totalInput, setTotalInput] = useState<number>(0); // Total input
  const [vraZoneData, setVraZoneData] = useState(null); // Tracks VRA data
  const [prevNutrientType, setPrevNutrientType] = useState<string>(null);

  const validateThresholds = (thresholds: [string, string][]) => {
    const validatedThresholds = [];
    for (let i = 0; i < thresholds.length; i++) {
      // Extracts each number assigned to each threshold
      validatedThresholds.push(Number(thresholds[i][1]));
    }
    // Returns list of ascending threshold value
    return validatedThresholds;
  };

  const fetchZoneData = async () => {
    try {
      setLoadingZones(true);
      setZoneError(null);
      // If mean values are selected -> request body includes toDate
      const reqBody = isMean
        ? {
            farmId: selectedFarm.farmid,
            fromDate: Number(fromDate.getTime()),
            toDate: Number(toDate.getTime()),
            reference: reference,
          }
        : {
            farmId: selectedFarm.farmid,
            date: Number(fromDate.getTime()),
            reference: reference,
          };
      // Different URL path for mean values
      const urlExtension = isMean ? 'vegetation-mean' : 'vegetation-and-fertilizer-zone';
      const url = `${baseSoilNutrientUrl}/soil/nutrients/${urlExtension}/farm`;

      // Send post request
      const response = await axios.post(url, reqBody);
      const data = response.data;
      // Set zone data to response data
      setVraZoneData(data);
      // Set zone thresholds
      setZoneThresholds(validateThresholds(Object.entries(data.vineyardZoneSizes)));
      setZonesCalculated(true);
    } catch (e) {
      setZoneError(e);
      console.error('Error fetching zone data:', e);
    } finally {
      setLoadingZones(false);
    }
  };

  const handleZoneChange = event => {
    setZones(event.target.value);
  };

  const handleReferenceChange = event => {
    if (!loadingZones) {
      const input = event.target.value;
      if (input !== NDVI) {
        // Change nutrient type unless NDVI
        handleSoilNutrientType(input.toLowerCase());
      }
      setReference(input);
    }
  };

  const handleIsMeanChange = () => {
    setIsMean(!isMean);
  };

  const handleFromDateChange = (date: number) => {
    if (!loadingZones) {
      setFromDate(new Date(date));
    }
  };

  const handleToDateChange = (date: number) => {
    if (!loadingZones) {
      setToDate(new Date(date));
    }
  };

  // Updates total input while user types
  const handleZoneInputChange = (event: any, index: number) => {
    const userInput = event.target.value;
    // .replace(/ /g, ''); // Removes all whitespace
    // Allow empty input or numeric input ONLY
    if (userInput === '' || !isNaN(userInput)) {
      // Take copy of current userZoneLevels object
      const newUserZoneLevels = {...userZoneLevels};
      // Add new index and input value at index user is typing in
      newUserZoneLevels[index] = Number(userInput);
      // Set old user zone levels to current value
      setUserZoneLevels(newUserZoneLevels);
      // Calculate total input
      handleCalculateTotalInput(newUserZoneLevels);
    }
  };

  const handleCalculateTotalInput = (userZoneLevels: any) => {
    const userZoneLevelsList = Object.entries(userZoneLevels);
    let totalInput = 0;
    userZoneLevelsList.forEach(levelList => {
      // Fetch value of threshold zone levvel
      const zoneLevel = levelList[0];
      // Add to total input threshold zone level multiplied by user input
      totalInput += Number(levelList[1]) * zoneThresholds[Number(zoneLevel) - 1];
    });
    // Round total input
    setTotalInput(Math.round(totalInput * 100) / 100);
  };

  // Reset zone values on back
  const resetZones = () => {
    setZonesCalculated(false);
    setUserZoneLevels({});
    setVraZoneData(null);
    setZoneError(null);
  };

  // Handles external changes in selected date
  useEffect(() => {
    if (selectedDate) {
      // Maps selectedDate to start and end date selectors
      const selectedDateAsDate = new Date(selectedDate);
      setFromDate(isMean ? new Date(selectedDateAsDate.setDate(selectedDateAsDate.getDate() - 7)) : new Date(selectedDate)); // 1 week less
      isMean && setToDate(new Date(selectedDate));

      // If normal nutrient images selected -> user has clicked a date from left sidebar so reset zone panel
      if (imagesOnSelectedDate) {
        resetZones();
      }
    }

    // If no soil nutrient imagery -> reset zone data to null
    if (!imagesOnSelectedDate) {
      setVraZoneData(null);
    }
  }, [selectedDate, imagesOnSelectedDate, isMean]);

  // Handles external changes in nutrient type
  useEffect(() => {
    // Maps nutrient type to reference index selector
    if (nutrientType) {
      setReference(nutrientType);

      // Handles case where user selects other nutrient type but leaves isMean switch on
      if (nutrientType !== NDVI) {
        setIsMean(false); // Ensures isMean switch is off
      } else {
        setIsMean(true);
      }

      if (nutrientType !== prevNutrientType && zonesCalculated) {
        resetZones();
      }

      setPrevNutrientType(nutrientType);
    }
  }, [nutrientType]);

  return {
    zones,
    zonesCalculated,
    reference,
    isMean,
    fromDate,
    toDate,
    loadingZones,
    zoneError,
    zoneThresholds,
    userZoneLevels,
    totalInput,
    vraZoneData,
    fetchZoneData,
    handleZoneChange,
    handleZoneInputChange,
    handleReferenceChange,
    handleIsMeanChange,
    handleFromDateChange,
    handleToDateChange,
    resetZones,
  };
};
