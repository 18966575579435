import React, {useState} from 'react';
import {GroundOverlay} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {Map, MapProps} from '../../../components/UI/Map/Map';
import {IImageWithBox} from '../../../store/reducers/ndvi';
import PolygonWithInfo from '../../../components/UI/PolygonWithInfo/PolygonWithInfo';
import OpacityPanel from '../../../components/UI/Panel/OpacityPanel';
import {useMarker} from '../../../hooks/useMarker';
import {IFarm} from '../../../store/reducers/farm';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapInfoWindow from '../../../components/UI/PolygonWithInfo/InfoWindow';
import Marker from '../../../components/UI/Marker/Marker';
import {markerDeleteSelector} from '../../../store/selectors';
import {useSelector} from 'react-redux';
import {renderURL} from './DownloadScreenshot';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 130px)',
};

const renderImageURL = (type: string, image: IImageWithBox['images'][0]) => {
  switch (type) {
    case 'current':
      return image.imageUrl;
    case 'previous':
      return image.imagePreviousUrl;
    case 'average':
      return image.imageAverageUrl;
    case 'predicted':
      return image.imagePredictedUrl;
    default:
      return '';
  }
};

const renderImage = (type: string, image: IImageWithBox['images'][0]) => {
  const url = renderImageURL(type, image);
  return renderURL(url);
};

interface Props extends MapProps {
  farm: IFarm;
  type: string;
  images: IImageWithBox['images'];
  takeScreenshot: () => void;
  handleOnClickPolygon: (polygon: IPolygonEntity) => void;
}

const NDVIMap = ({farm, center, images, type, bbox, polygons, takeScreenshot, polygonClicked, selectedPolygon, handleOnClickPolygon}: Props) => {
  const farmBBox = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));
  const {t} = useTranslation();

  const [opacity, setOpacity] = useState(100);
  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  // handle markers and event listeners
  const {
    isModalOpen,
    loading,
    postMarkerLoading,
    selectedMarkerFeature,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    markerPolygon,
    selectedPin,
    handleCloseModal,
    handleOnClickMap,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
  } = useMarker(farm);

  const handleOpacityChange = (event, newValue) => {
    newValue = newValue / 100;
    setOpacity(newValue);
  };

  const isLoading = loading || postMarkerLoading || false;
  return (
    <>
      {isMarkerDeleteLoading && <MapOverlay position="relative" />}
      {isLoading && <MapOverlay />}
      {!isLoading && !isMarkerDeleteLoading && (
        <Map
          center={center}
          mapContainerStyle={containerStyle}
          bbox={bbox}
          polygons={polygons}
          displayRows
          selectedShowMarker={selectedMarkerFeature}
          handleMarkerClick={handleMarkerClick}
          handleMarkerMouseOver={handleMarkerMouseOver}
          handleMarkerMouseOut={handleMarkerMouseOut}
          polygonClicked={polygonClicked}
          selectedPolygon={selectedPolygon}
          focus
        >
          {images && (
            <>
              <OpacityPanel opacity={opacity} handleOpacityChange={handleOpacityChange} />
              <Marker
                selectedMarker={selectedMarker}
                farm={farm}
                polygon={markerPolygon}
                featureName={'NDVI'}
                isModalOpen={isModalOpen}
                isOpenModalNoteUpdate={isOpenModalNoteUpdate}
                handleAddPin={handleSelectedPin}
                handleMarkerPostRequest={handleMarkerPostRequest}
                handleMarkerFeatureSelection={handleMarkerFeatureSelection}
                handleCloseModal={handleCloseModal}
                handleMarkerSubmit={handleMarkerSubmit}
                handleNoteUpdateClose={handleNoteUpdateClose}
                handleDeleteMarker={handleDeleteMarker}
                takeScreenshot={takeScreenshot}
              />
            </>
          )}
          {polygons?.map(polygon => {
            const correspondingImage = images?.find(({polygonid}) => polygonid === polygon.id);
            if (correspondingImage) {
              const bounds = correspondingImage.boundBox
                ? new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(correspondingImage.boundBox[1], correspondingImage.boundBox[0]),
                    new window.google.maps.LatLng(correspondingImage.boundBox[3], correspondingImage.boundBox[2])
                  )
                : farmBBox;
              return (
                <>
                  <GroundOverlay key={correspondingImage.imageUrl + correspondingImage.imagePredictedUrl + type} url={renderImage(type, correspondingImage)} bounds={bounds} opacity={opacity} />;
                  <PolygonWithInfo key={polygon.id} polygon={polygon} handlePolygonClick={handleOnClickPolygon} handleOnClickMap={handleOnClickMap} selectedPin={!!selectedPin} />;
                  {mouseOverMarker && isOpenMarkerInfoWindow && (
                    <MapInfoWindow key={mouseOverMarker.latitude + mouseOverMarker.longitude} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                  )}
                </>
              );
            } else {
              return images?.length ? (
                <PolygonWithInfo
                  key={polygon.id}
                  polygon={polygon}
                  secondaryTitle={t('ndvi.planet.cloudy')}
                  defaultOpacity={0.3}
                  handlePolygonClick={handleOnClickPolygon}
                  handleOnClickMap={handleOnClickMap}
                />
              ) : null;
            }
          })}
        </Map>
      )}
    </>
  );
};

export default NDVIMap;
