import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Typography, Box, FormControl, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FarmPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/FarmPicker';
import {Skeleton} from '@material-ui/lab';
import {IOrganizationEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {IFarm, IPolygonWithArea} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {useTranslation} from 'react-i18next';
import Panel from '../../../../../../components/UI/DashboardPanel/Panel';
import {PanelTitle} from '../../../../../../components/UI/DashboardPanel/PanelTitle';

const StyledBox = styled(Box)`
  margin-top: 5px;
  display: flex;
  width: 48%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const SelectorContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SkeletonContainer = styled(Box)`
  margin-top: 20px;
  width: 100%;
`;

const StyledSkeleton = styled(Skeleton)`
  min-height: 35px;
  width: 100%;
`;

const OrganizationTypography = styled(Typography)`
  overflow: hidden !important;
`;

interface Props {
  selectedOrganization: IOrganizationEntity;
  selectedFarm: IFarm;
  allFarms: IFarm[];
  handleFarmSelection: (farmId: string) => void;
  selectedPolygon: polygonInfoWindow;
  handleOnPolygonChange: (polygonId: string) => void;
  isMobile: boolean;
}

const FarmSelect = ({selectedOrganization, selectedFarm, allFarms, handleFarmSelection, selectedPolygon, handleOnPolygonChange, isMobile}: Props) => {
  const {t} = useTranslation();

  const currentPolygon = useMemo(() => {
    if (!selectedFarm) return null;
    const currentPolygonId = selectedPolygon ? selectedPolygon.polygonId : '';
    return selectedFarm.polygons.find(polygon => polygon.id === currentPolygonId);
  }, [selectedFarm, selectedPolygon]);

  return (
    <Panel width={isMobile ? '100%' : '75%'} height={isMobile ? 'fit-content' : '125px'} right={!isMobile}>
      <PanelTitle>{t('yield.historical.table.organization')}</PanelTitle>
      <OrganizationTypography variant="h6">{selectedOrganization ? selectedOrganization.name : <Skeleton />}</OrganizationTypography>
      <SelectorContainer>
        {/* Farm selection */}
        <StyledBox>
          {selectedFarm ? (
            <FarmPicker activeFarm={selectedFarm.farmid} allFarms={allFarms} handleChange={handleFarmSelection} />
          ) : (
            <SkeletonContainer>
              <StyledSkeleton />
            </SkeletonContainer>
          )}
        </StyledBox>
        {/* Block selection */}
        <StyledBox>
          {selectedFarm ? (
            <StyledFormControl>
              <Autocomplete
                value={currentPolygon || null}
                options={selectedFarm.polygons}
                getOptionLabel={polygon => polygon.name}
                onChange={(event, polygon: IPolygonWithArea | null) => {
                  handleOnPolygonChange(polygon ? polygon.id : '');
                }}
                renderInput={params => <TextField {...params} label={t('note.table.block')} />}
                disableClearable
              />
            </StyledFormControl>
          ) : (
            <SkeletonContainer>
              <StyledSkeleton />
            </SkeletonContainer>
          )}
        </StyledBox>
      </SelectorContainer>
    </Panel>
  );
};

export default FarmSelect;
