import React from 'react';
import styled, {css} from 'styled-components';
import {withRouter, Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import {ListItem as MaterialListItem, Tooltip} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import {FaCrown} from 'react-icons/fa';
import TooltipDescription from './TooltipText';
import {useTranslation} from 'react-i18next';

const Item = styled(MaterialListItem)<{isactive: string; group: string}>`
  && {
    ${p =>
      p.isactive === 'true'
        ? css`
            background: #1f2d3b;
          `
        : css`
            background: #253647;
          `}
  }
`;

const Text = styled(ListItemText)<{group: string}>`
  && {
    & span {
      font-size: ${g => (g.group ? '80% !important' : '100% !important')};
    }
  }
`;

const ListItem = ({slug, title, group, isFeatureDisabledForUser, location}: {slug: string; title: string; group: string; isFeatureDisabledForUser?: boolean} & any) => {
  const isActive = value => {
    return location.pathname === value;
  };
  const {t} = useTranslation();
  return (
    //render item with tooltip for disabled features
    <Tooltip title={isFeatureDisabledForUser ? <TooltipDescription title={title} /> : ''} interactive={true} placement={'right'} arrow>
      <div style={{display: 'flex', paddingRight: '16px', alignItems: 'center'}}>
        <Item
          button
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          component={Link}
          to={{
            pathname: slug,
            state: {
              prevPath: location.pathname,
              group: group,
            },
          }}
          isactive={isActive(slug).toString()}
          group={group}
          disabled={slug.includes('soil-temperature') || slug.includes('soil-salinity') || isFeatureDisabledForUser}
        >
          <Text group={group} primary={t(title)} />
        </Item>
        {isFeatureDisabledForUser && <FaCrown size={15} style={{fill: '#FFD700'}} />}
        {group ? <></> : <Divider />}
      </div>
    </Tooltip>
  );
};

export default withRouter(ListItem);
