import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import {GenerateDiseaseMapRequest, IFarmEntity, IOrganizationEntity} from '@deep-planet/api-interfaces';
import styled from 'styled-components';
import SimpleDatePicker from '../../../../../../../components/UI/Pickers/SimpleDatePicker';
import Spinner from '../../../../../../../components/UI/Spinner';
import {baseDiseaseMapUrl} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';
import theme from '../../../../../../../utilities/theme';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  farm: IFarmEntity;
  organization: IOrganizationEntity;
}

export const DiseaseMap = ({farm, organization}: Props) => {
  const [seasonStartDate, setSeasonStartDate] = useState<Date>(new Date());
  const [predictionDate, setPredictionDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        // seasonStartDate: moment(seasonStartDate).format('YYYY-MM-DD'),
        predictionDate: moment(predictionDate).format('YYYY-MM-DD'),
        farmId: farm.id,
      };
      await axios.post(`${baseDiseaseMapUrl}/disease/farm`, payload);
      dispatch(enqueueSnackbar({message: 'Disease maps are generated', options: {variant: 'success'}}));
    } catch (e) {
      const msg = e?.response?.data?.message || e?.response?.data?.message;
      dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <Box display="display" flexDirection="column">
            {/* <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={seasonStartDate} onChange={date => setSeasonStartDate(date)} label={'Season start date'} disabled={loading} />
            </Box> */}
            <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={predictionDate} onChange={date => setPredictionDate(date)} label={'Prediction date'} disabled={loading} />
            </Box>
            <Box mt={4} mb={4} display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
                Submit
                {loading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </Box>
        </form>
      </FormWrapper>
    </Box>
  );
};
