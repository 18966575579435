import React from 'react';
import styled from 'styled-components';
import FarmSelect from './FarmSelect';
import TotalHectares from './TotalHectares';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {IOrganizationEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 950px) {
    flex-direction: column;
    width: 100%;
  }
`;

interface Props {
  selectedFarm: IFarm;
  selectedOrganization: IOrganizationEntity;
  allFarms: IFarm[];
  handleFarmSelection: (farmId: string) => void;
  selectedPolygon: polygonInfoWindow;
  handleOnPolygonChange: (polygonId: string) => void;
  isMobile: boolean;
}

const TitlePanel = ({selectedFarm, selectedOrganization, allFarms, handleFarmSelection, selectedPolygon, handleOnPolygonChange, isMobile}: Props) => {
  return (
    <Container>
      <FarmSelect
        selectedOrganization={selectedOrganization}
        selectedFarm={selectedFarm}
        allFarms={allFarms}
        handleFarmSelection={handleFarmSelection}
        selectedPolygon={selectedPolygon}
        handleOnPolygonChange={handleOnPolygonChange}
        isMobile={isMobile}
      />
      {!isMobile && <TotalHectares selectedFarm={selectedFarm} />}
    </Container>
  );
};

export default TitlePanel;
