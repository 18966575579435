import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 7,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 18,
      width: '100%',
      textAlign: 'center',
    },
    probabilityContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      marginBottom: 5,
    },
    selectLabel: {
      marginBlock: 10,
      fontSize: 14,
      fontWeight: 400,
    },
    selectContainer: {
      display: 'flex',
      textAlign: 'left',
      flexDirection: 'column',
      backgroundColor: 'whitesmoke',
      borderRadius: 5,
      padding: 5,
      paddingInline: 10,
    },
    thresholdLabel: {
      marginBlock: 10,
      fontSize: 14,
      fontWeight: 'bold',
    },
    separator: {
      display: 'flex',
      flex: 1,
    },
    sliderContainer: {
      marginTop: 35,
      marginInline: 10,
    },
    slider: {
      marginInline: 5,
    },
    sliderText: {
      marginInline: 10,
      marginBlock: 10,
      fontSize: 10,
      fontWeight: 500,
    },
    sliderTextPercentage: {
      color: '#3e4daa',
    },
    buttonListContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'whitesmoke',
      marginTop: 10,
      borderRadius: 5,
      marginBottom: 0,
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
    },
    formControlLabel: {
      width: 160,
      textAlign: 'center',
    },
    radioButtton: {
      marginLeft: 5,
      marginRight: 3,
    },
    formControlLabelText: {
      fontSize: 14,
      fontWeight: 500,
    },
    diseaseColorBox: {
      width: 20,
      height: 20,
      marginInline: 5,
      border: '1px solid #181818',
      borderRadius: '50%',
      opacity: 0.7,
    },
    mixtureContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 5,
      opacity: 0,
      transform: 'translateY(-20px)',
      transition: 'opacity 0.2s ease, transform 0.2s ease',
      '&.visible': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    multipleText: {
      fontSize: 10,
      fontWeight: 500,
      marginLeft: 5,
      maxWidth: '65%',
      marginBlock: 10,
    },
    colorBoxRow: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: 5,
    },
    smallDiseaseColorBox: {
      width: 15,
      height: 15,
      marginLeft: 4,
      border: '1px solid #181818',
      borderRadius: '50%',
      opacity: 0.7,
    },
  })
);

export default useStyles;
