import {IFarmEntity, IFarmSettingsUI, IOrganizationEntity, IOrganizationSettingsUI} from '@deep-planet/api-interfaces';
import {Box, Button, TextField, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Autocomplete} from '@material-ui/lab';
import axios from 'axios';
import {sortBy} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import MapOverlay from '../../../../../components/UI/MapOverlay';
import Spinner from '../../../../../components/UI/Spinner';
import {baseApiUrl, FARM, ORGANIZATION} from '../../../../../config/const';
import {useHttp} from '../../../../../hooks/http';
import {enqueueSnackbar} from '../../../../../store/actions';
import theme from '../../../../../utilities/theme';
import {AddFeatureToFarm} from './AddFeatureToFarm';
import {RadioButton} from 'apps/web-portal-ui/src/app/components/UI/Switch/RadioButton';
import {FarmSettingsObject, OrganizationSettingsObject} from './Objects';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const ManageFeaturePermission = () => {
  const [isLoadingOrganizations, organizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);
  const [isLoadingGroups, groups] = useHttp<string[]>(`${baseApiUrl}/farm-settings/feature`);
  const [featureGroup, setFeatureGroup] = useState<string[]>();
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>(null);
  const [selectedFarm, setSelectedFarm] = useState<IFarmEntity>(null);
  const [isLoadingSavePermissions, setIsLoadingSavePermissions] = useState(false);
  const [selectedType, setSelectedType] = useState<string>(FARM);
  const [organizationSettings, setOrganizationSettings] = useState<IOrganizationSettingsUI>(null);
  const [farmSettings, setFarmSettings] = useState<IFarmSettingsUI>(null);
  const [checked, setChecked] = React.useState([]);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);
  const labels = [
    {name: FARM, value: FARM},
    {name: 'All Farms', value: ORGANIZATION},
  ];

  const handleOrganizationSelect = async (currentlySelectedOrganization: IOrganizationEntity) => {
    setSelectedOrganization(currentlySelectedOrganization);
    setSelectedFarm(null);
    setFarmSettings(null);
    // intialize the input parameters if farm not selected yet after change of selected organization
    if (selectedType === FARM) setChecked(getFeatures({...new FarmSettingsObject()}));
    // get orgnization settings
    if (selectedType === ORGANIZATION && currentlySelectedOrganization) {
      getOrganizationSettings(currentlySelectedOrganization);
    }
  };

  // fetch organization settings from the backend for selected org
  const getOrganizationSettings = async (currentlySelectedOrganization: IOrganizationEntity) => {
    setIsLoadingSettings(true);
    try {
      const response = currentlySelectedOrganization && (await axios.get(`${baseApiUrl}/organization-settings/feature/${currentlySelectedOrganization.id}`));
      // Initialize entry if not found - new OrganizationSettingsObject(), organization: currentlySelectedOrganization
      const data = response?.data || {...new OrganizationSettingsObject(), organization: currentlySelectedOrganization};
      // Get features enabled
      setChecked(getFeatures(data));
      setOrganizationSettings(data);
    } catch (e) {
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsLoadingSettings(false);
    }
  };

  // Read features only that enabled
  const getFeatures = currentFeatures => {
    // currentFeatures - An entry from the table either from farm_settings or organization_settings
    // featureGroup    - Feature names from the feature table
    return Object.entries(currentFeatures)
      .map(key => {
        // check if the feature is available in the feature group and get only currently enabled features
        if (featureGroup.includes(key[0].toUpperCase()) && key[1] === true) return key[0].toUpperCase();
      })
      .filter(f => !!f);
  };

  // get farm settings from the backend
  const handleFarmSelection = async (currentlySelectedFarm: IFarmEntity) => {
    setSelectedFarm(currentlySelectedFarm);
    setIsLoadingSettings(true);
    try {
      const response = currentlySelectedFarm && (await axios.get(`${baseApiUrl}/farm-settings/farm/${currentlySelectedFarm.id}`));
      const data = response?.data || {...new FarmSettingsObject(), farm: currentlySelectedFarm};
      // get features enabled on farm settings
      setChecked(getFeatures(data));
      setFarmSettings(data);
    } catch (e) {
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsLoadingSettings(false);
    }
  };

  const handlePermissionsSave = async () => {
    const url = selectedType === ORGANIZATION ? `${baseApiUrl}/organization-settings` : `${baseApiUrl}/farm-settings`;
    const payload = selectedType === ORGANIZATION ? organizationSettings : farmSettings;
    try {
      setIsLoadingSavePermissions(true);
      const {data} = payload && (await axios.post<any>(url, payload));
      dispatch(enqueueSnackbar({message: `${selectedType} permissions successfully updated`, options: {variant: 'success'}}));
    } catch (e) {
      dispatch(enqueueSnackbar({message: t(e.response.data.message), options: {variant: 'error'}}));
    } finally {
      setIsLoadingSavePermissions(false);
    }
  };

  const handleSwitch = (value: string) => {
    setSelectedType(value);
    if (value === FARM) {
      setChecked([]);
      setFarmSettings(null);
      setSelectedFarm(null);
    } else {
      getOrganizationSettings(selectedOrganization);
    }
  };

  const handleSubmit = (value: Date, name: string) => {
    if (selectedType === ORGANIZATION) setOrganizationSettings({...organizationSettings, [name]: Number(value)});
    else setFarmSettings({...farmSettings, [name]: value});
  };

  const handleToggle = (value: string) => {
    try {
      if (selectedType === ORGANIZATION) {
        // read feature names from the column names of organizationSettings
        Object.entries(organizationSettings).map(key => {
          if (key[0].toUpperCase() === value) {
            const updatedSettings = {...organizationSettings};
            updatedSettings[key[0]] = !organizationSettings[key[0]];
            setOrganizationSettings({...updatedSettings});
            setChecked(getFeatures(updatedSettings));
          }
        });
      } else {
        // read feature names from the column names of farmSettings
        Object.entries(farmSettings).map(key => {
          if (key[0].toUpperCase() === value) {
            const updatedSettings = {...farmSettings};
            updatedSettings[key[0]] = !farmSettings[key[0]];
            setFarmSettings({...updatedSettings});
            setChecked(getFeatures(updatedSettings));
          }
        });
      }
    } catch (e) {
      //
    }
  };

  const handleFrostShockMonth = (value: number) => {
    if (selectedType === ORGANIZATION) setOrganizationSettings({...organizationSettings, frostShockMonth: value});
    else setFarmSettings({...farmSettings, frostShockMonth: value});
  };

  const handleHeatShockMonth = (value: number) => {
    if (selectedType === ORGANIZATION) setOrganizationSettings({...organizationSettings, heatShockMonth: value});
    else setFarmSettings({...farmSettings, heatShockMonth: value});
  };

  useEffect(() => {
    if (groups) {
      const newGroups = groups.map(group => {
        if (group.includes('_')) {
          return group.split('_').reduce((acc, coll) => acc + coll);
        }
        return group;
      });

      setFeatureGroup(newGroups);
    }
  }, [farmSettings, groups, organizationSettings]);

  const isLoading = isLoadingSettings || isLoadingOrganizations || isLoadingGroups;
  return (
    <>
      {isLoading && <MapOverlay position="relative" />}
      {!isLoading && (
        <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h3" align="center" gutterBottom>
            Manage Feature Permissions
          </Typography>

          <RadioButton labels={labels} value={selectedType} handleSwitch={handleSwitch} />
          <FormWrapper padding="2rem 0">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedOrganization}
                options={sortBy(organizations, o => o.name.toLowerCase())}
                onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity)}
                getOptionLabel={({name}) => name}
                renderInput={params => <TextField {...params} label="Select an organization" variant="outlined" />}
              />

              {selectedOrganization && (
                <AddFeatureToFarm
                  selectedOrganization={selectedOrganization}
                  selectedFarm={selectedFarm}
                  groups={featureGroup}
                  currentFeaturesEnabled={checked}
                  selectedType={selectedType}
                  organizationSettings={organizationSettings}
                  farmSettings={farmSettings}
                  handleHeatShockMonth={handleHeatShockMonth}
                  handleFrostShockMonth={handleFrostShockMonth}
                  handleFarmSelection={handleFarmSelection}
                  handleToggle={handleToggle}
                  handleSubmit={handleSubmit}
                />
              )}
            </FormControl>
            <Box padding="1rem 0">
              <Button fullWidth disabled={isLoadingGroups || isLoadingSavePermissions} color="primary" variant="contained" type="submit" onClick={handlePermissionsSave}>
                Submit
                {isLoadingSavePermissions && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </FormWrapper>
        </Box>
      )}
    </>
  );
};
