import {IFileInfoEntity, IFileList, IFileTypeEntity} from '@deep-planet/api-interfaces';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fileTypesGetSelector, fileTypesSelector, organizationsSelector} from '../store/selectors';
import {IForm, ISelectedFile} from '../components/UI/FileManager/FileUploadModal';
import {flatten, union, uniqBy} from 'lodash';
import {useTranslation} from 'react-i18next';
import {getFileTypes} from '../store/actions/file';

const MAX_FILE_SIZE = 1024 * 1024 * 15; // 15mb
const ACCEPTED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.xls', '.csv', '.pdf', '.doc', '.docx', '.xlsx', '.kml', '.kmz', '.shp', '.dbf', '.prj', '.cpg', '.shx', '.sbn', '.sbx'];
const MAX_FILE_COUNT = 10;
const MAX_SUMMARY_LENGTH = 150;
const MAX_DESCRIPTION_LENGTH = 1500;

export const useFileManager = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const organizations = useSelector(organizationsSelector);
  const fileTypes = useSelector(fileTypesSelector);
  const {loading: isFileTypesLoading} = useSelector(fileTypesGetSelector);
  const [selectedType, setSelectedType] = useState<IFileTypeEntity>(null);
  const [summary, setSummary] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState<ISelectedFile[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>(null);
  const [selectedFarm, setSelectedFarm] = useState<string>(null);
  const [errors, setErrors] = useState<IForm>({
    summary: {
      value: false,
      text: '',
    },
    description: {
      value: false,
      text: '',
    },
    farm: {
      value: false,
      text: '',
    },
    organization: {
      value: false,
      text: '',
    },
    type: {
      value: false,
      text: '',
    },
    file: {
      value: false,
      text: '',
    },
  });

  useEffect(() => {
    if (!selectedOrganization && organizations) setSelectedOrganization(organizations[0].id || '');
    if (!fileTypes.length) dispatch(getFileTypes());
    //marker is specific to organization, farm and polygon hence restrict user to modify the selection on marker note
  }, [organizations, selectedOrganization, fileTypes, dispatch]);

  const handleTypeSelection = (fileTypeId: string) => {
    const type = fileTypes.find(({id}) => id === fileTypeId);
    if (type) {
      setSelectedType(type);
      setErrors({...errors, type: {value: false, text: ''}});
    }
  };

  const handleChangeSummary = (currentSummary: string) => {
    setSummary(currentSummary);
    if (currentSummary.length > MAX_SUMMARY_LENGTH) {
      setErrors({...errors, summary: {value: true, text: t('forms.shorter.or.equal', {length: MAX_SUMMARY_LENGTH})}});
    } else {
      setErrors({...errors, summary: {value: false, text: ''}});
    }
  };

  const handleNoteAttachmentCancel = (nameToCancel: string) => {
    const files = selectedFiles.filter(({name}) => name !== nameToCancel);
    setSelectedFiles(files);
  };

  const handleOrganizationSelection = (id: string) => {
    if (id) {
      setSelectedOrganization(id);
      setErrors({...errors, organization: {value: false, text: ''}});
    }
  };

  const handleFarmSelection = (farmId: string) => {
    if (farmId) {
      setSelectedFarm(farmId);
      setErrors({...errors, farm: {value: false, text: ''}});
    }
  };

  const handleChangeDescription = (currentDescription: string) => {
    setDescription(currentDescription);
    if (currentDescription.length > MAX_DESCRIPTION_LENGTH) {
      setErrors({...errors, description: {value: true, text: t('forms.shorter.or.equal', {length: MAX_DESCRIPTION_LENGTH})}});
    } else {
      setErrors({...errors, description: {value: false, text: ''}});
    }
  };

  const handleNoteAttachmentChange = (files: ISelectedFile[]) => {
    if (files.length) {
      setSelectedFiles(uniqBy(union(selectedFiles, files), 'name'));
      setErrors({...errors, file: {value: false, text: ''}});
    }
  };

  const getFilteredFilesForUser = (files: IFileInfoEntity[], farmId: string, fromDate: Date, toDate: Date): IFileList[] => {
    if (!files.length || !fromDate || !toDate) return;

    if (!farmId)
      return flatten(
        files
          .filter(f => new Date(f.date).setHours(0, 0, 0) >= new Date(fromDate).setHours(0, 0, 0) && new Date(f.date).setHours(0, 0, 0) <= new Date(toDate).setHours(0, 0, 0))
          .map((fileInfo, idx) => fileInfo.files.map(fa => ({...fa, fileName: fa.name, farmName: fileInfo.farm?.name, fileInfoId: fileInfo.id, date: fa.uploadedAt, contentType: fileInfo.type.name})))
      );

    return flatten(
      files
        .filter(f => f.farm?.id === farmId && new Date(f.date).setHours(0, 0, 0) >= new Date(fromDate).setHours(0, 0, 0) && new Date(f.date).setHours(0, 0, 0) <= new Date(toDate).setHours(0, 0, 0))
        .map((fileInfo, idx) => fileInfo.files.map(fa => ({...fa, fileName: fa.name, farmName: fileInfo.farm?.name, fileInfoId: fileInfo.id, date: fa.uploadedAt, contentType: fileInfo.type.name})))
    );
  };

  const getFilesForFarm = (files: IFileInfoEntity[], farmId: string, fromDate: Date, toDate: Date) => {
    if (!files.length || !farmId || !fromDate || !toDate) return;
    return files.filter(
      f => f.farm.id === farmId && new Date(f.date).setHours(0, 0, 0) >= new Date(fromDate).setHours(0, 0, 0) && new Date(f.date).setHours(0, 0, 0) <= new Date(toDate).setHours(0, 0, 0)
    );
  };

  return {
    summary,
    description,
    selectedType,
    selectedFarm,
    selectedFiles,
    selectedOrganization,
    MAX_FILE_SIZE,
    ACCEPTED_EXTENSIONS,
    MAX_FILE_COUNT,
    isFileTypesLoading,
    fileTypes,
    errors,
    setErrors,
    setSelectedFiles,
    getFilesForFarm,
    getFilteredFilesForUser,
    handleNoteAttachmentChange,
    handleTypeSelection,
    handleChangeSummary,
    handleNoteAttachmentCancel,
    handleOrganizationSelection,
    handleFarmSelection,
    handleChangeDescription,
  };
};
