import React, {useState} from 'react';
import {MenuItem, Select, CircularProgress, Table, TableBody, TableCell, TableRow, Paper, Box, Typography, Slider} from '@material-ui/core';
import {IOrganizationEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useHarvestSummary} from 'apps/web-portal-ui/src/app/hooks/useHarvestSummary';
import {useMeasurementConversion} from 'apps/web-portal-ui/src/app/hooks/useMeasurementConversion';
import {capitalize} from 'lodash';
import {useSoilHealthSummary} from 'apps/web-portal-ui/src/app/hooks/useSoilHealthSummary';
import {getDateString} from '../../../../../helpers/dateHelpers';
import {PanelTitle} from '../../../../../components/UI/DashboardPanel/PanelTitle';
import NoInfoOverlay from '../../../../../components/UI/DashboardPanel/NoInfoOverlay';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {harvest} from 'apps/web-portal-ui/src/app/config/const';

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TableContainer = styled(Paper)`
  height: 100%;
  margin-top: 10px;
  width: 50%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledTableRow = styled(TableRow)<{disabled?: boolean}>`
  height: 100%;
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  font-size: small !important;

  @media (max-width: 950px) {
    font-size: x-small !important;
  }
`;

const StyledTableKey = styled(TableCell)<{disabled?: boolean}>`
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  font-size: small !important;

  @media (max-width: 950px) {
    font-size: x-small !important;
  }
`;

const StyledTableValue = styled(TableCell)<{disabled?: boolean; smallertext: string}>`
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  font-size: ${props => (props.smallertext ? 'x-small' : 'small')} !important;

  @media (max-width: 950px) {
    font-size: ${props => (props.smallertext ? 'xx-small' : 'x-small')} !important;
  }
`;

const VisualisationContainer = styled(Box)`
  margin-top: 10px;
  padding: 2.5%;
  padding-bottom: 1%;
  margin-right: 5px;
  width: 50%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: whitesmoke;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const LoaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
`;

const ImageContainer = styled(Box)`
  width: 100%;
  height: 100%;
  max-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img<{zoom: number}>`
  max-height: 100%;
  width: 100%;
  object-fit: none;
  zoom: ${props => props.zoom + '%'};
  filter: drop-shadow(0.5px 0.5px 0.5px #545454);
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
`;

const ImageTypeText = styled(Typography)`
  font-weight: bolder !important;
`;

interface Props {
  user: CognitoUser;
  allFarms: IFarm[];
  selectedFarm: IFarm;
  activeFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  selectedOrganization: IOrganizationEntity;
  isMobile: boolean;
}

const HarvestSoilHealthPanel = ({user, allFarms, selectedFarm, activeFarm, selectedPolygon, selectedOrganization, isMobile}: Props) => {
  const {t} = useTranslation();
  const {polygonName, targetBaume, harvestDate, averageBaume, averagePH, averageTA, maturityImage, loadingHarvestImages, imageGenerationDate, seasonEndDate} = useHarvestSummary(
    user,
    selectedOrganization,
    selectedFarm,
    activeFarm,
    selectedPolygon
  );
  const {nitrogen, phosphorus, potassium, baselineSOC, averageSOC, soilImage, soilHealthVisualisationTitle, soilBlockValue, loadingSoilImages} = useSoilHealthSummary(
    allFarms,
    activeFarm,
    selectedFarm,
    selectedPolygon
  );
  const {sugarUnit, convertSugar, round} = useMeasurementConversion();
  const [imageZoom, setImageZoom] = useState(200); // Zoom level of image
  const [window, setWindow] = useState<string>(harvest); // Window summary being displayed
  // Handles zoom slider
  const handleSlide = (event: any, zoomLevel: number | number[]) => {
    setImageZoom(zoomLevel as number);
  };
  // Info to show when in between seasons
  const beforeImageGenerationDate = new Date(Date.now()) < imageGenerationDate;
  const beforeImageGenerationDateText = `${t('dashboard.summary.available.from')} ${getDateString(imageGenerationDate)}`;
  const beforeSeasonEndDate = new Date(Date.now()) < seasonEndDate;
  // Table data structure to hold summary information
  const table =
    window === harvest
      ? [
          // Harvest Summary
          [t('polygon.info.block.name'), polygonName],
          [`${t('dashboard.summary.target')} ${capitalize(sugarUnit)}`, convertSugar(targetBaume) || null],
          [
            `${t('dashboard.summary.average')} ${capitalize(sugarUnit)}`,
            imageGenerationDate && beforeImageGenerationDate ? beforeImageGenerationDateText : (beforeSeasonEndDate && convertSugar(averageBaume)) || null,
          ],
          [`${t('dashboard.summary.average')} pH`, imageGenerationDate && beforeImageGenerationDate ? beforeImageGenerationDateText : (beforeSeasonEndDate && round(averagePH, 2)) || null],
          [`${t('dashboard.summary.average')} TA`, imageGenerationDate && beforeImageGenerationDate ? beforeImageGenerationDateText : (beforeSeasonEndDate && round(averageTA, 2)) || null],
          [`${t('dashboard.summary.predicted.harvest.date')}`, imageGenerationDate && beforeImageGenerationDate ? beforeImageGenerationDateText : (beforeSeasonEndDate && harvestDate) || null],
        ]
      : [
          // Soil Health Summary
          [t('polygon.info.block.name'), polygonName],
          ['Nitrogen (g/kg)', round(nitrogen, 2) || null],
          ['Phosphorus (mg/kg)', round(phosphorus, 2) || null],
          ['Potassium (mg/kg)', round(potassium, 2) || null],
          [`${t('soil.organic.carbon.info.baselineSoc')} (g/kg)`, round(baselineSOC, 2) || null],
          [`Current ${t('dashboard.summary.average')} SOC (g/kg)`, round(averageSOC, 2) || null],
        ];
  const imageType = window === harvest ? t('dashboard.current.average.baume') : soilHealthVisualisationTitle; // Visualisation title
  const imageSource = window === harvest ? maturityImage : soilImage; // Visualisation image
  const blockAverage = window === harvest ? averageBaume : soilBlockValue; // Block average value
  const windowTitle = window === harvest ? t('dashboard.help.harvest') : t('navigation.accordion.vine.soil'); // Title of panel
  const loadingImages = loadingHarvestImages || loadingSoilImages;
  return (
    <Panel width="100%" height="420px" right={!isMobile}>
      <PanelTitle>{`${windowTitle} ${t('dashboard.summary.overview')}`}</PanelTitle>
      <SelectContainer>
        <Select value={window} onChange={(event: React.ChangeEvent<{value: unknown}>) => setWindow(event.target.value as string)}>
          <MenuItem value={harvest}>{t('dashboard.help.harvest')}</MenuItem>
          <MenuItem value={'soil'}>{t('navigation.accordion.vine.soil')}</MenuItem>
        </Select>
      </SelectContainer>
      <Container>
        <TableContainer elevation={1} variant="outlined" square>
          <Table>
            <TableBody>
              {table.map(([key, value], index) => {
                const disabled = !value;
                return (
                  <StyledTableRow disabled={disabled} key={index}>
                    <StyledTableKey component="th" scope="row">
                      {key}
                    </StyledTableKey>
                    <StyledTableValue align="right" smallertext={beforeImageGenerationDate ? 'true' : undefined}>
                      {value}
                    </StyledTableValue>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <VisualisationContainer>
          {loadingImages ? (
            <LoaderContainer>
              <CircularProgress size={60} />
            </LoaderContainer>
          ) : imageSource ? (
            <>
              <ImageTypeText color="primary">{imageType}</ImageTypeText>
              <PanelTitle>
                {polygonName}, {t('block.average')}: {round(blockAverage, 2)}
              </PanelTitle>
              <ImageContainer>
                <Image src={imageSource} alt={t('dashboard.summary.visualisation.map')} zoom={imageZoom} />
              </ImageContainer>
              <Slider value={imageZoom} onChange={handleSlide} min={100} max={750} />
            </>
          ) : (
            <NoInfoOverlay name={t('dashboard.summary.visualisation')} />
          )}
        </VisualisationContainer>
      </Container>
    </Panel>
  );
};

export default HarvestSoilHealthPanel;
