import React, {useState, useEffect, useRef, useCallback} from 'react';
import {BBox} from '@turf/turf';
import {GoogleMap, Marker} from '@react-google-maps/api';
import {ICenter, IMarkerColor, IPolygonEntity, polygonInfoWindow, IMarkerTypeUI} from '@deep-planet/api-interfaces';
import {useRows} from '../../../hooks/useRows';
import {IPolygonWithArea} from '../../../store/reducers/farm';
import {Row, ShowRowSwitcher} from './Row';
import {useMarker} from '../../../hooks/useMarker';
import {Button, Box, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

interface Props extends MapProps {
  mapContainerStyle?: React.CSSProperties;
  children: React.ReactNode;
}

export interface MapProps {
  center: ICenter;
  bbox?: BBox;
  polygons?: IPolygonWithArea[] | IPolygonEntity[];
  selectedPolygon?: polygonInfoWindow;
  polygonClicked?: boolean;
  displayRows?: boolean;
  selectedShowMarker?: IMarkerColor | IMarkerTypeUI;
  handleMarkerClick?: (marker) => void;
  handleMarkerMouseOver?: (marker) => void;
  handleMarkerMouseOut?: (marker) => void;
  handleOnClickPolygon?: (polygon: IPolygonEntity) => void;
  displayGraph?: () => void;
  focus?: boolean;
  irrigation?: boolean;
  showBlockName?: boolean;
  handleBlockSelection?: () => void;
  options?: google.maps.MapOptions;
}

export const Map = ({
  children,
  center,
  mapContainerStyle,
  bbox,
  polygons,
  selectedPolygon,
  polygonClicked,
  displayRows = false,
  selectedShowMarker,
  handleMarkerClick,
  handleMarkerMouseOver,
  handleMarkerMouseOut,
  options,
  focus = false,
  irrigation = false,
  displayGraph,
  showBlockName = true,
  handleBlockSelection = () => {
    return;
  },
}: Props) => {
  const {t} = useTranslation();

  // Retrieves reference of the map
  const mapRef = useRef<google.maps.Map>();
  // Reference for shaded overlay behind polygon
  const overlayRef = useRef<google.maps.Polygon>();

  const {showRows, isThereRows, handleSwitch} = useRows(polygons);
  const {markers, getMarkerIcon} = useMarker();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isZoomed, setZoomed] = useState(false);
  const [blockName, setBlockName] = useState('');
  const isCenter = center && center.lng && center.lat;
  const bounds = bbox && new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));

  const getFilteredMarkers = markers => {
    // show all markers
    if (!selectedShowMarker) return null;
    // hide all markers
    if (selectedShowMarker.feature.name.toUpperCase() === 'HIDE ALL') return null;
    // filter markers by the feature
    return selectedShowMarker.feature.name.toUpperCase() === 'SHOW ALL' ? markers : markers.filter(mf => mf.feature.name.toUpperCase() === selectedShowMarker.feature.name.toUpperCase());
  };

  const filteredMarkers = (markers && getFilteredMarkers(markers)) || null;

  const handleOnload = (map: google.maps.Map<Element>) => {
    // Get reference of current map position
    mapRef.current = map;
    // Fit border box around map bounds
    if (bbox) map.fitBounds(bounds);
  };

  // Handles user press 'Reset' button
  const resetBounds = () => {
    // Get bounds of original map position
    const bounds = bbox && new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));
    const map = mapRef.current;
    // Set current reference to bounds
    map.fitBounds(bounds);
    // Remove overlay
    overlayRef.current.setMap(null);
    setZoomed(false);
  };

  // Function to check if a polygon's bounds are in a clockwise or anticlockwise direction
  const isPolygonClockwise = polygon => {
    let sum = 0;
    for (let i = 0; i < polygon.length; i++) {
      // Define 'cur' as the current vertex in the polygon
      const cur = polygon[i];
      // Define 'next' as the next vertex in the polygon, wrapping around if at the last vertex
      const next = polygon[(i + 1) % polygon.length];
      // Calculate the produce of the difference between the next vertex and current
      sum += (next.lng - cur.lng) * (next.lat + cur.lat);
    }
    // If sum is positive, the vertices are in clockwise order, otherwise they are in counter-clockwise order
    return sum > 0;
  };

  // Function to create overlay
  const createOverlay = useCallback(
    (map, selectedPolygon) => {
      // Remove the existing overlay if it exists
      if (overlayRef.current) {
        overlayRef.current.setMap(null);
      }

      const mapBounds = map.getBounds();
      const sw = mapBounds.getSouthWest();
      const ne = mapBounds.getNorthEast();

      // Define large overlay to cover the whole map
      const overlayBounds = [
        {lat: ne.lat() + 0.1, lng: sw.lng() - 0.1},
        {lat: ne.lat() + 0.1, lng: ne.lng() + 0.1},
        {lat: sw.lat() - 0.1, lng: ne.lng() + 0.1},
        {lat: sw.lat() - 0.1, lng: sw.lng() - 0.1},
      ];

      // Find the selected polygon based on its ID
      const polygon = polygons.find(p => p.id === selectedPolygon.polygonId);
      if (polygon) {
        const polygonBounds = polygon.geoJson.geometry.coordinates[0].map(coord => ({
          lat: coord[1],
          lng: coord[0],
        }));

        // For the hole to appear, polygon must be plotted anticlockwise (how Google Maps API detects a cutout)
        if (isPolygonClockwise(polygonBounds)) {
          polygonBounds.reverse();
        }

        // Creates the overlay with a hole shaped like the selected polygon
        const overlay = new window.google.maps.Polygon({
          paths: [overlayBounds, polygonBounds],
          fillColor: 'black',
          fillOpacity: 0.5,
          strokeWeight: 0,
          strokeColor: 'black',
          strokeOpacity: 0.5,
        });

        // Set the overlay on the map
        overlay.setMap(map);
        overlayRef.current = overlay;
      }
    },
    [polygons]
  );

  // Zooms into the selected polygon
  const zoomIn = useCallback(() => {
    if (selectedPolygon) {
      const map = mapRef.current;
      const polygon = polygons.find(p => p.id === selectedPolygon.polygonId);
      if (map && polygon) {
        // Get bounds of ploygon based off it's vertices
        const newBounds = new window.google.maps.LatLngBounds();
        polygon.geoJson.geometry.coordinates[0].forEach(point => {
          newBounds.extend(new window.google.maps.LatLng(point[1], point[0]));
        });

        // Zoom into the new bounds
        map.fitBounds(newBounds);
        createOverlay(map, selectedPolygon);
        setZoomed(true);
      }
    }
  }, [createOverlay, polygons, selectedPolygon]);

  // Handles when user selects new polygon
  useEffect(() => {
    if (selectedPolygon) {
      // works with polygon selection on sidebar of the Dashboard
      // navigates/pan map to the selected block with zoom level 15
      if (selectedPolygon?.center) {
        mapRef.current?.panTo(new google.maps.LatLng({lat: selectedPolygon.center.lat, lng: selectedPolygon.center.lng}));
        mapRef.current?.setZoom(15);
      }
      const polygon = polygons.find(p => p.id === selectedPolygon.polygonId);
      if (polygon) {
        setBlockName(polygon.name);
        handleBlockSelection();
        if (polygonClicked && isZoomed) {
          zoomIn();
        }
      }
    }
  }, [selectedPolygon, polygons, polygonClicked, isZoomed, zoomIn, handleBlockSelection]);

  // Sets up bounds based on bbox or selected polygon
  useEffect(() => {
    if (focus) {
      // Retrieves reference of map element
      const map = mapRef.current;
      if (map && bbox) {
        const bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));
        // Sets out bounds
        map.fitBounds(bounds);
      }
    }
  }, [bbox, focus]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // turn-off labels and icons on google map
  const defaultOptions: google.maps.MapOptions = {
    mapTypeId: 'satellite',
    styles: [
      {featureType: 'all', elementType: 'labels.text', stylers: [{visibility: 'off'}]},
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [{visibility: 'off'}],
      },
    ],
  };

  return (
    isCenter && (
      <GoogleMap zoom={15} center={center} options={options ? options : defaultOptions} mapTypeId={window.google.maps.MapTypeId.HYBRID} mapContainerStyle={mapContainerStyle} onLoad={handleOnload}>
        {children}

        {filteredMarkers &&
          filteredMarkers.map((marker, idx) => (
            <Marker
              key={Number(marker?.latitude) + Number(marker?.longitude)}
              onMouseOver={() => handleMarkerMouseOver(marker)}
              onMouseOut={() => handleMarkerMouseOut(marker)}
              onClick={() => handleMarkerClick(marker)}
              icon={getMarkerIcon(marker?.color?.color)}
              position={{lat: parseFloat(marker?.latitude?.toString()), lng: parseFloat(marker?.longitude?.toString())}}
            />
          ))}
        {displayRows && isThereRows && !isMobileView && <ShowRowSwitcher showRows={showRows} handleSwitch={handleSwitch} />}
        {displayRows && showRows && polygons?.map(({rows}) => rows?.map(row => <Row key={row.id} row={row} />))}
        {polygons && selectedPolygon && showBlockName && !!blockName && (
          <Box
            style={{
              position: 'absolute',
              bottom: '2.75%',
              left: irrigation ? '70px' : '50px',
              display: 'flex',
              flexDirection: 'column',
              transform: 'translateX(-50%)',
              padding: 10,
              borderRadius: 3,
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderRadius: 2,
                paddingInline: 1,
                maxWidth: irrigation ? 110 : 80,
              }}
            >
              {blockName}
            </Typography>
            {!(blockName === '') && focus && (
              <>
                <Button
                  onClick={isZoomed ? resetBounds : zoomIn}
                  color={isZoomed ? 'secondary' : 'primary'}
                  style={{marginTop: 2, backgroundColor: 'white', borderRadius: 2.5, width: irrigation ? 110 : 80, fontSize: 12}}
                >
                  {isZoomed ? t('zoom.out') : t('zoom.in')}
                </Button>
                {irrigation && (
                  <Button onClick={displayGraph} color="primary" style={{marginTop: 2, backgroundColor: 'white', borderRadius: 2.5, width: 110, fontSize: 12}}>
                    {t('zoom.show.graph')}
                  </Button>
                )}
              </>
            )}
          </Box>
        )}
      </GoogleMap>
    )
  );
};
