import {useState, useEffect} from 'react';

export function useMeasurementConversion() {
  const defaultSettings = {
    area: 'hectares',
    distance: ['meters', 'centimeters'],
    temperature: 'celsius',
    weight: 'kilograms',
    sugar: 'baume',
  };

  const abbrUnits = {
    weight: {
      tonne: 't',
      pounds: 'lbs',
      kilograms: 'kg',
    },
    area: {
      hectares: 'ha',
      acres: 'ac',
    },
  };

  const [userSettings, setUserSettings] = useState(JSON.parse(localStorage.getItem('userSettings')));
  const {area: areaUnit, distance: distanceUnit, temperature: temperatureUnit, weight: weightUnit, sugar: sugarUnit} = userSettings || defaultSettings;

  const abbrWeightUnit = abbrUnits.weight[weightUnit];
  const abbrAreaUnit = abbrUnits.area[areaUnit];

  const round = (value: number, decimalPlaces: number) => {
    if (isNaN(value)) return value;
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  };

  const convertArea = (hectareValue: number) => {
    let value = null;
    if (areaUnit === 'acres') {
      value = hectareValue * 2.47105;
    } else {
      value = hectareValue;
    }

    return round(value, 2);
  };

  const convertWeight = (tonneValue: number) => {
    let multiplier = 1;
    if (weightUnit === 'kilograms') {
      multiplier = 1000;
    } else if (weightUnit === 'pounds') {
      multiplier = 2204.62;
    }

    return round(tonneValue * multiplier, 2);
  };

  const convertMmInches = (mmValue: number) => {
    let value = null;
    if (distanceUnit[1] === 'inches') {
      value = mmValue / 25.4;
    } else {
      value = mmValue;
    }

    return round(value, 2);
  };

  const convertTemperature = celsiusValue => {
    let value = null;
    if (temperatureUnit === 'fahrenheit') {
      value = (celsiusValue * 9) / 5 + 32;
    } else {
      value = celsiusValue;
    }

    return round(value, 2);
  };

  const convertSugar = baumeValue => {
    let value = baumeValue;
    if (sugarUnit === 'brix') {
      value = baumeValue * 1.8;
    } else if (sugarUnit === 'sugar') {
      value = baumeValue * 18;
    }

    return round(value, 2);
  };

  return {areaUnit, distanceUnit, temperatureUnit, weightUnit, sugarUnit, abbrAreaUnit, abbrWeightUnit, convertArea, convertWeight, convertMmInches, convertTemperature, convertSugar, round};
}
