import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {Box, Tooltip, Typography, CircularProgress} from '@material-ui/core';
import CreateNoteIcon from '@material-ui/icons/LibraryAdd';
import {useDispatch, useSelector} from 'react-redux';
import {notesSelector, userNamesSelector} from '../../../../../store/selectors';
import {createNote, getNotes} from '../../../../../store/actions';
import {CreateNoteDTO, INoteEntity, INoteTypeEntity, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {RiStickyNoteFill as NotesIcon} from 'react-icons/ri';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {useHttp} from 'apps/web-portal-ui/src/app/hooks/http';
import {baseApiUrl} from 'apps/web-portal-ui/src/app/config/const';
import UpdateNoteModal from './Modals/UpdateNote';
import {useTranslation} from 'react-i18next';
import {notesGetSelector} from 'apps/web-portal-ui/src/app/store/selectors/note';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {PanelTitleWithLink} from '../../../../../components/UI/DashboardPanel/PanelTitle';
import CreateNoteModal from '../../../Notes/CreateNoteModal';
import {ISelectedFile} from '../../../Notes/withEdit';
import NoInfoOverlay from 'apps/web-portal-ui/src/app/components/UI/DashboardPanel/NoInfoOverlay';

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SummaryContainer = styled(Box)`
  height: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
`;

const Summary = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
  background-color: whitesmoke;
  transition: background-color 0.2s ease-in-out;
  padding: 5px;
  padding-inline: 10px;
  flex: 1;
  height: 25%;

  &:hover {
    cursor: pointer;
    background-color: #ededed;
  }
`;

const SummaryTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const TypeText = styled.span`
  font-size: 10px;
  margin-left: 5px;
  @media (max-width: 950px) {
    font-size: xx-small;
  }
`;

const SummaryText = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;

  @media (max-width: 950px) {
    font-size: xx-small;
  }
`;

const NotesMessageContainer = styled.div`
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: center;
`;

const StyledCreateNoteIcon = styled(CreateNoteIcon)`
  &:hover {
    transition: transform 0.25s;
    transform: scale(1.025);
    cursor: pointer;
  }
`;

const StyledNotesIcon = styled(NotesIcon)`
  font-size: 18px;
`;

interface Props {
  selectedOrganization: IOrganizationEntity;
  farm: IFarm;
  isSuperAdmin: boolean;
}

const NotesPanel = ({selectedOrganization, farm, isSuperAdmin}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notes = useSelector(notesSelector) || null;
  const {loading: loadingNotes} = useSelector(notesGetSelector);
  const userNames = useSelector(userNamesSelector);

  const [noteDetailsModalOpen, setNoteDetailsModalOpen] = useState<boolean>(false);
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState<boolean>(false);
  const [userSelectedNote, setUserSelectedNote] = useState(null);
  const [notesEmpty, setNotesEmpty] = useState(false);

  // Filter notes to 3 most recent for panel
  const filteredNotes = useMemo(() => {
    if (notes) {
      let filteredNotes = [...notes];
      if (!isSuperAdmin && selectedOrganization) {
        filteredNotes = filteredNotes.filter(note => note?.organization?.id === selectedOrganization?.id);
      }
      const sortedNotes = filteredNotes.sort((a, b) => (a.date < b.date ? 1 : -1)); // Sort notes by descending order - from latest date to old date

      if (sortedNotes.length === 0) {
        setNotesEmpty(true);
      } else {
        setNotesEmpty(false);
      }

      return sortedNotes.slice(0, 3); // Return first 3 notes only
    }
  }, [notes, selectedOrganization, isSuperAdmin]);

  const handleNoteDetailsModalOpen = (note: INoteEntity) => {
    setNoteDetailsModalOpen(true);
    setUserSelectedNote(note);
  };

  const handleNoteDetailsModalClose = () => {
    setNoteDetailsModalOpen(false);
    setUserSelectedNote(null);
  };

  const handleCreateNoteModalOpen = () => {
    setCreateNoteModalOpen(true);
  };

  const handleCreateNoteModalClose = () => {
    setCreateNoteModalOpen(false);
  };

  const handleCreateNote = (params: CreateNoteDTO, files: ISelectedFile[]) => {
    dispatch(createNote(params, files, handleCreateNoteModalClose));
  };

  const [isTypesLoading, types] = useHttp<INoteTypeEntity[]>(`${baseApiUrl}/note/types`);

  useEffect(() => {
    dispatch(getNotes());
  }, [dispatch]);

  const submitLoading = isTypesLoading;

  return (
    <>
      <Panel width={'50%'} height={'200px'} right overflow="hidden">
        <TitleContainer>
          <PanelTitleWithLink link={'/notes'}>{t('help.notes')}</PanelTitleWithLink>
          <Tooltip title={t('note.modal.title')} placement="top" arrow>
            <StyledCreateNoteIcon fontSize="small" color="primary" onClick={handleCreateNoteModalOpen} />
          </Tooltip>
        </TitleContainer>
        <SummaryContainer>
          {loadingNotes ? (
            <NotesMessageContainer>
              <CircularProgress size={55} />
            </NotesMessageContainer>
          ) : notesEmpty ? (
            <NotesMessageContainer>
              <NoInfoOverlay name={t('help.notes')} variant="body2" />
            </NotesMessageContainer>
          ) : (
            filteredNotes.map((note, index) => {
              return (
                <Summary
                  key={index}
                  onClick={() => {
                    handleNoteDetailsModalOpen(note);
                  }}
                >
                  <StyledNotesIcon />
                  <SummaryTextContainer>
                    {note.type && <TypeText>{note.type.name}</TypeText>}
                    <SummaryText>{note.summary}</SummaryText>
                  </SummaryTextContainer>
                </Summary>
              );
            })
          )}
        </SummaryContainer>
      </Panel>
      {/* Modals */}
      {types && (
        <CreateNoteModal
          isOpen={createNoteModalOpen}
          handleClose={handleCreateNoteModalClose}
          types={types}
          farms={[farm]}
          handleSubmit={handleCreateNote}
          submitLoading={submitLoading}
          userNames={userNames}
          modalTitle={t('note.modal.title')}
        />
      )}
      {userSelectedNote && (
        <UpdateNoteModal
          isOpen={noteDetailsModalOpen}
          loading={submitLoading}
          handleClose={handleNoteDetailsModalClose}
          selectedOrganization={selectedOrganization}
          types={types}
          farm={farm}
          userNames={userNames}
          userSelectedNote={userSelectedNote}
        />
      )}
    </>
  );
};

export default NotesPanel;
