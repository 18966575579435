import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useYieldPrediction} from '../../../hooks/useYieldPrediction';
import Layout from '../../Layout';
import MapPlaceholder from '../../../components/UI/MapPlaceholder';
import MapOverlay from '../../../components/UI/MapOverlay';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import Map from './Map';
import {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import {useTranslation} from 'react-i18next';
import {withUser} from '../../../hooks/useAuth';
import {getUserGroups} from '../../authHOC';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import FarmSelectionSidebar from '../../../components/UI/Sidebar/FarmSelection';
import {useDashboard} from './../../../hooks/useDashboard';
import BlockInfo from './BlockInfo';
import {Position} from '@turf/helpers';
import * as turf from '@turf/turf';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import usePolygon from '../../../hooks/usePolygon';
import {PageTitle} from '../../../components/UI/PageTitle';
import {IBlockHealthUI, IDashboardYield} from '@deep-planet/api-interfaces';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(100% - 64px);
`;

const Dashboard = ({user}) => {
  const {t} = useTranslation();
  const {allFarms, loadingGetUser, loadingGetFarm, selectedFarm, setSelectedFarm} = useFarmSelection();
  const {loadingGetYield, latestYields} = useYieldPrediction(getUserGroups(user)?.includes('YIELD') || getUserGroups(user)?.includes('SUPERADMIN'));
  const {blockHealth, climateFeatures, yieldData} = useDashboard(selectedFarm, user);
  const {polygonClicked, activePolygon, selectedPolygon, handleOnClickPolygon, handlePolygonChange, handleSelectedPolygon} = usePolygon();
  const [totalHectares, setTotalHectares] = useState<string>(null);
  const [blockHealthInfo, setBlockHealthInfo] = useState<IBlockHealthUI[]>(null);
  const [yieldInfo, setYieldInfo] = useState<IDashboardYield>(null);

  // initialize the marker colors
  useMarkerColor();

  const handleChange = (id: string) => {
    setSelectedFarm(allFarms.find(farm => farm.id === id));
    if (new URLSearchParams(window.location.search).get('selectedFarm')) {
      const URL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.history.pushState({path: URL}, '', URL);
    }
  };

  const isSecondaryTitleNeeded = () => {
    const groups = getUserGroups(user);
    return !groups.includes('SOILSIGNAL');
  };

  //get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  useEffect(() => {
    if (selectedFarm) {
      const totalHectares = selectedFarm?.polygons
        ?.map(polygon => Math.round(turf.area(turf.polygon(polygon.geoJson.geometry.coordinates as Position[][])) / 100) / 100)
        .reduce((total, hectares) => (total += hectares))
        .toFixed(2);
      setTotalHectares(totalHectares);
    }
  }, [selectedFarm]);

  useEffect(() => {
    if (blockHealth && selectedPolygon) {
      const blockHealthInfo = blockHealth?.filter(bh => bh.polygonId === selectedPolygon?.polygonId);
      setBlockHealthInfo(blockHealthInfo);
    }
    if (yieldData && selectedPolygon) {
      const yieldInfo = yieldData?.find(y => y.polygonId === selectedPolygon?.polygonId);
      setYieldInfo(yieldInfo);
    }
  }, [blockHealth, selectedPolygon, yieldData]);

  const isLoading = loadingGetUser || loadingGetFarm || loadingGetYield;

  return (
    <Layout>
      <Container>
        {isLoading && <MapOverlay />}
        {!isLoading && selectedFarm?.polygons?.length === 0 && <MapPlaceholderNoPolygons activeFarm={selectedFarm?.id} />}
        {!isLoading && !allFarms?.length && <MapPlaceholder />}
        {!isLoading && selectedFarm && (
          <div style={{position: 'relative'}} id="layout">
            <ContentLeftSidebar
              content={
                <>
                  <PageTitle title={t('dashboard.title')} />
                  <Map
                    farm={selectedFarm}
                    polygons={selectedFarm?.polygons}
                    center={selectedFarm.farmCenter}
                    bbox={selectedFarm.bbox}
                    secondaryTitle={isSecondaryTitleNeeded()}
                    yieldPredictions={latestYields}
                    selectedPolygon={selectedPolygon}
                    polygonClicked={polygonClicked}
                    handleSelectedPolygon={handleSelectedPolygon}
                    handleOnClickPolygon={handleOnClickPolygon}
                    disableInfoBox={true}
                    takeScreenshot={takeScreenshot}
                  />
                </>
              }
              sidebar={
                <FarmSelectionSidebar
                  polygons={selectedFarm?.polygons}
                  allFarms={allFarms}
                  activeFarm={selectedFarm.id}
                  activePolygon={activePolygon}
                  handleFarmChange={handleChange}
                  loadingGetFarm={loadingGetFarm}
                  handlePolygonChange={handlePolygonChange}
                />
              }
            />
            {activePolygon && (
              <BlockInfo
                blockHealth={blockHealthInfo}
                climateFeature={climateFeatures}
                yieldData={yieldInfo}
                //@ts-ignore
                block={{...activePolygon, hectares: activePolygon?.hectares}}
                totalHectares={totalHectares}
                selectedImage={true}
              />
            )}
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default withUser(Dashboard);
