import {useState} from 'react';
import {IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {GetFarmCentre} from '../store/utility';
import {IPolygonWithArea} from '../store/reducers/farm';

const usePolygon = () => {
  const [selectedPolygon, setSelectedPolygon] = useState<polygonInfoWindow>(null);
  const [activePolygon, setActivePolygon] = useState<IPolygonEntity | IPolygonWithArea>(null);
  const [polygonClicked, setPolygonClicked] = useState(false);

  // this triggers when polygon is selected on the maps
  const handleOnClickPolygon = (polygon: IPolygonEntity) => {
    setPolygonClicked(true);
    setActivePolygon(polygon);
    setSelectedPolygon({polygonId: polygon?.id, name: polygon?.name, openWindow: true, strokeColor: '#FFEA00', strokeWeight: 5});
    setPolygonClicked(false);
  };

  // this triggers when polygon is selected on the sidebar
  const handlePolygonChange = (polygon: IPolygonEntity) => {
    // set acitve polygon here to handle the change of polygon dynamically on the sidebar as well
    setActivePolygon(polygon);
    // it opens onclick info window on the dashboard page - 25/11/2022 but the info window has been disabled
    // on dashboard so just set the polygon border color
    setSelectedPolygon({polygonId: polygon?.id, openWindow: true, strokeColor: '#FFEA00', strokeWeight: 5, center: (polygon && GetFarmCentre([polygon.geoJson.geometry.center])) || null});
  };
  const handleSelectedPolygon = (selectedBlock: polygonInfoWindow) => {
    // it closes onclick info window on the dashboard page
    if (selectedBlock?.polygonId) setSelectedPolygon({...selectedBlock});
  };

  return {polygonClicked, setPolygonClicked, activePolygon, setActivePolygon, selectedPolygon, setSelectedPolygon, handleOnClickPolygon, handlePolygonChange, handleSelectedPolygon};
};

export default usePolygon;
