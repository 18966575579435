import React from 'react';
import styled from 'styled-components';
import {FaMapSigns} from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import theme from '../../../utilities/theme';

const Container = styled.div<{width?: string; height?: string; left?: string; $light?: boolean}>`
  // z-index: 7; // Is z-index: 7 needed? It conflicts with the sidebar on some pages not allowing the user to select
  height: ${p => (p.height ? p.height : '100%')};
  width: ${p => (p.width ? p.width : '100%')};
  position: absolute;
  top: 0;
  left: ${p => (p.left ? p.left : '0')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({$light}) => ($light ? 'inherit' : theme.palette.primary.main)};

  svg {
    width: 100px;
    height: 100px;
    fill: white;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    left: 0;
  }
`;

const Text = styled(Typography)<{$light?: boolean}>`
  text-align: center;
  && {
    color: ${({$light}) => ($light ? theme.palette.primary.main : 'white')};
  }
`;

const Btn = styled(Button)`
  && {
    color: white;
  }
`;

const StyledFaMapSigns = styled(FaMapSigns)<{$light: boolean}>`
  && {
    fill: ${({$light}) => ($light ? theme.palette.primary.main : 'white')};
  }
`;

export const Forbidden = () => {
  const {t} = useTranslation();
  return (
    <Container>
      <FaMapSigns />
      <Text variant="h6">{t('no.permission.title')}</Text>
    </Container>
  );
};

const MapPlaceholder = ({width, height, left}: {width?: string; height?: string; left?: string}) => {
  const {t} = useTranslation();
  return (
    <Container width={width} height={height} left={left}>
      <FaMapSigns />
      <Text variant="h6">{t('no.farm.title')}</Text>
      <Btn variant="outlined" href="/settings">
        {t('no.farm.button')}
      </Btn>
    </Container>
  );
};

export default MapPlaceholder;

const MapPlaceholderNoPolygons = ({width, height, left, activeFarm}: {width?: string; height?: string; left?: string; activeFarm: string}) => {
  const {t} = useTranslation();
  return (
    <Container id="map-placeholder-no-polygons" width={width} height={height} left={left}>
      <FaMapSigns />
      <Text variant="h6">{t('no.polygon.title')}</Text>
      <Link
        to={{
          pathname: '/settings',
          state: {
            pageIndex: 1,
            farmId: activeFarm,
          },
        }}
      >
        <Btn color="inherit" variant="outlined">
          {t('no.polygon.button')}
        </Btn>
      </Link>
    </Container>
  );
};

export {MapPlaceholderNoPolygons};

interface MapPlaceholderNoDataProps {
  width?: string;
  height?: string;
  light?: boolean;
  title?: string;
  left?: string;
}

const MapPlaceholderNoData = ({width, height, light = false, title, left}: MapPlaceholderNoDataProps) => {
  const {t} = useTranslation();
  const titles = title && title.split('.');
  return (
    <Container width={width} height={height} $light={light} left={left}>
      <StyledFaMapSigns $light={light} />
      <br style={{margin: '2rem 1rem'}} />
      <Text $light={light} variant="subtitle1">
        {(titles && titles[0]) || t('no.data.title')}
      </Text>
      {titles && titles[1] && (
        <Text $light={light} variant="subtitle1">
          {titles[1]}
        </Text>
      )}
    </Container>
  );
};

export {MapPlaceholderNoData};
