import {useState, useEffect, useMemo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {IFarm} from '../store/reducers/farm';
import {useDashboard} from './useDashboard';
import {IOrganizationEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {useMaturityPrediction} from './useMaturityPrediction';
import {getDateString} from '../helpers/dateHelpers';
import {useMaturityImages} from './useMaturityImages';
import axios from 'axios';
import {baseApiUrl} from '../config/const';
import {CognitoUser} from 'amazon-cognito-identity-js';

export const useHarvestSummary = (user: CognitoUser, selectedOrganization: IOrganizationEntity, selectedFarm: IFarm, activeFarm: IFarm, selectedPolygon: polygonInfoWindow) => {
  const dispatch = useDispatch();
  const {yieldData} = useDashboard(selectedFarm, user);
  const {historicalMaturity, loadingGetMaturity, getHistoricalMaturity} = useMaturityPrediction(selectedFarm, selectedOrganization);
  // Start date 1 year before end date for maturity images
  const {startDate, endDate} = useMemo(() => {
    const endDate = new Date();
    const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    return {startDate, endDate};
  }, []);
  const {maturityImages, onGetMaturityImages} = useMaturityImages(selectedFarm, startDate, endDate);
  const memoizedOnGetMaturityImages = useCallback(onGetMaturityImages, [selectedOrganization, selectedFarm]);

  const [imageGenerationDate, setImageGenerationDate] = useState<Date>(null);
  const [seasonEndDate, setSeasonEndDate] = useState<Date>(null);
  const [initiallyFetchedMaturityImages, setInitiallyFetchedMaturityImages] = useState<boolean>(false);

  const polygonName = useMemo(() => {
    if (!selectedFarm) return null;
    // Find selectedPolygon in farm
    const polygon = selectedFarm?.polygons?.find(polygon => polygon.id === selectedPolygon?.polygonId);
    return polygon?.name; // Return name
  }, [selectedFarm, selectedPolygon]);

  const {averageBaume, averagePH, averageTA, maturityImage} = useMemo(() => {
    if (!maturityImages || !selectedPolygon) return {averageBaume: null, averagePH: null, averageTA: null, maturityImage: null};

    const sortedMaturityImages = [...maturityImages].sort((a, b) => b.date - a.date);

    const latestMaturityImages = sortedMaturityImages.find(slice => slice?.images?.find(image => image?.polygonId === selectedPolygon?.polygonId));

    // Find maturity images for specific polygon
    const polygonMaturityImages = latestMaturityImages?.images?.filter(image => image?.polygonId === selectedPolygon?.polygonId) || [];

    return {
      averageBaume: polygonMaturityImages.find(image => image?.type === 'BAUME')?.avgBaume || null,
      averagePH: polygonMaturityImages.find(image => image?.type === 'PH')?.avgBaume || null,
      averageTA: polygonMaturityImages.find(image => image?.type === 'TA')?.avgBaume || null,
      maturityImage: polygonMaturityImages.find(image => image?.type === 'BAUME')?.url || null,
    };
  }, [maturityImages, selectedPolygon]);

  const {harvestDate, targetBaume} = useMemo(() => {
    if (!historicalMaturity) {
      return {latestSeasonYear: undefined, harvestDate: undefined, targetBaume: null};
    }

    // Get previous seasons
    const seasons = historicalMaturity['seasons'];
    // Get season years
    const seasonYears = Object.keys(seasons).map(year => parseInt(year));
    const latestSeasonYear = Math.max(...seasonYears); // Most recent season year

    const harvestDate = getDateString(seasons[latestSeasonYear]?.harvestDate); // Access harvest date
    let targetBaume = seasons[latestSeasonYear]?.targetBaume; // Access target baume

    // Fallback -> fetch target baume from yield data
    if (!targetBaume && yieldData) {
      targetBaume = yieldData.find(polygon => polygon.polygonId === selectedPolygon?.polygonId)?.targetBaume;
    }

    return {latestSeasonYear, harvestDate, targetBaume};
  }, [historicalMaturity, yieldData, selectedPolygon]);

  useEffect(() => {
    // Fetch harvest data
    const fetchHarvestData = () => {
      const polygon = selectedFarm?.polygons?.find(polygon => polygon?.id === selectedPolygon?.polygonId);
      dispatch(getHistoricalMaturity(selectedFarm?.farmid, polygon?.id, selectedOrganization?.id));
    };
    if (selectedFarm && selectedOrganization && selectedPolygon) {
      fetchHarvestData();
    }
  }, [dispatch, getHistoricalMaturity, selectedFarm, selectedOrganization, selectedPolygon]);

  useEffect(() => {
    // Fetch farm settings
    const fetchFarmSettings = async () => {
      try {
        const url = `${baseApiUrl}/farm-settings/farm/${selectedFarm.farmid}`;
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.error('Error fetching farm settings:', error);
      }
    };

    if (selectedFarm) {
      // Only need to fetch maturity images once as useMaturityImages hook handles the rest
      if (!initiallyFetchedMaturityImages) {
        memoizedOnGetMaturityImages(selectedFarm, true); // Get maturity images
        setInitiallyFetchedMaturityImages(true);
      }
      fetchFarmSettings().then(data => {
        setImageGenerationDate(new Date(data.imageGenerationDate));
        setSeasonEndDate(new Date(data.seasonEndDate));
      });
    }
  }, [selectedFarm, memoizedOnGetMaturityImages, initiallyFetchedMaturityImages]);

  return {polygonName, targetBaume, harvestDate, averageBaume, averagePH, averageTA, maturityImage, loadingHarvestImages: loadingGetMaturity, imageGenerationDate, seasonEndDate};
};
