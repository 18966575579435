import {useState, useEffect} from 'react';
import axios from 'axios';
import {IDataRequirements, IDataRequirementsResponse, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {baseApiUrl} from '../config/const';

const defaultDataRequirements = {
  varietalInformation: false,
  soilHealthData: false,
  harvestData: false,
  smProbeAPI: false,
};

export const useDataRequirements = (selectedOrganization: IOrganizationEntity) => {
  const [dataRequirements, setDataRequirements] = useState<IDataRequirements>(defaultDataRequirements);
  const [loadingDataRequirements, setLoadingDataRequirements] = useState<boolean>(false);
  const [errorDataRequirements, setErrorDataRequirements] = useState<boolean>(false);

  useEffect(() => {
    // Function to fetch data requirements
    const fetchData = async () => {
      setLoadingDataRequirements(true);
      try {
        const url = `${baseApiUrl}/client-data-requirement/organization/${selectedOrganization.id}`;
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.error('Unable to fetch data requirements:', error);
        setLoadingDataRequirements(false);
        setErrorDataRequirements(true);
      }
    };

    if (selectedOrganization) {
      // Fetch data requirements
      fetchData().then(data => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!data) {
          const dataRequirementsResponse: IDataRequirementsResponse = data;

          // Update data requirements
          const updatedDataRequirements = {
            varietalInformation: dataRequirementsResponse.varietalInformation,
            soilHealthData: dataRequirementsResponse.soilHealthData,
            harvestData: dataRequirementsResponse.harvestData,
            smProbeAPI: dataRequirementsResponse.smProbeAPI,
          };

          setDataRequirements(updatedDataRequirements);
          setErrorDataRequirements(false);
          setLoadingDataRequirements(false);
        } else {
          setLoadingDataRequirements(false);
          setErrorDataRequirements(true);
        }
      });
    }
  }, [selectedOrganization]);

  return {dataRequirements, loadingDataRequirements, errorDataRequirements};
};
