import {ISoilNutrientImageRes, ISoilNutrientImagesUI} from '@deep-planet/api-interfaces';
import i18n from 'apps/web-portal-ui/src/i18n';
import axios from 'axios';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {Dispatch} from 'redux';
import {baseApiUrl} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {farmIdUpdate} from './farm';
import {NITROGEN} from 'apps/web-portal-ui/src/app/helpers/imageTransformers';

interface ISoilNutrientFarmGetStart {
  type: ActionTypes.SOIL_NUTRIENT_FARM_GET_START;
}

export const soilNutrientFarmGetStart = (): ISoilNutrientFarmGetStart => ({
  type: ActionTypes.SOIL_NUTRIENT_FARM_GET_START,
});

interface ISoilNutrientFarmGetFail {
  type: ActionTypes.SOIL_NUTRIENT_FARM_GET_FAIL;
  error: unknown;
}

export const soilNutrientFarmGetFail = (error): ISoilNutrientFarmGetFail => ({
  type: ActionTypes.SOIL_NUTRIENT_FARM_GET_FAIL,
  error,
});

interface ISoilNutrientFarmGetSuccess {
  type: ActionTypes.SOIL_NUTRIENT_FARM_GET_SUCCESS;
  soilNutrientFarms: IFarm[];
  activeFarm: IFarm;
}

export const soilNutrientFarmGetSuccess = (soilNutrientFarms: IFarm[], activeFarm: IFarm): ISoilNutrientFarmGetSuccess => ({
  type: ActionTypes.SOIL_NUTRIENT_FARM_GET_SUCCESS,
  soilNutrientFarms,
  activeFarm,
});

interface ISoilNutrientImagesGetStart {
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_GET_START;
}

export const soilNutrientImagesGetStart = (): ISoilNutrientImagesGetStart => ({
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_GET_START,
});

interface ISoilNutrientImagesGetFail {
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_GET_FAIL;
  error: unknown;
}

export const soilNutrientImagesGetFail = (error): ISoilNutrientImagesGetFail => ({
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_GET_FAIL,
  error,
});

interface ISoilNutrientImagesGetSuccess {
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_GET_SUCCESS;
  soilNutrientImages: ISoilNutrientImageRes[];
  soilNutrientType: string;
  activeFarmImageId: string;
  activeFarm: IFarm;
  soilNutrientTypeImages?: ISoilNutrientImagesUI[];
}
export const soilNutrientImagesGetSuccess = (
  soilNutrientImages: ISoilNutrientImageRes[],
  soilNutrientType: string,
  activeFarmImageId: string,
  activeFarm: IFarm,
  soilNutrientTypeImages?: ISoilNutrientImagesUI[]
): ISoilNutrientImagesGetSuccess => ({
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_GET_SUCCESS,
  soilNutrientImages,
  soilNutrientType,
  activeFarmImageId,
  activeFarm,
  soilNutrientTypeImages,
});

interface ISoilNutrientImagesTypeGetSuccess {
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_TYPE_GET_SUCCESS;
  soilNutrientTypeImages: ISoilNutrientImagesUI[];
  soilNutrientType: string;
}

export const soilNutrientImagesTypeGetSuccess = (soilNutrientTypeImages: ISoilNutrientImagesUI[], soilNutrientType: string): ISoilNutrientImagesTypeGetSuccess => ({
  type: ActionTypes.SOIL_NUTRIENT_IMAGE_TYPE_GET_SUCCESS,
  soilNutrientTypeImages,
  soilNutrientType,
});

interface ISoilNutrientSetSelectedDate {
  type: ActionTypes.SOIL_NUTRIENT_SET_SELECTED_DATE;
  selectedDate: Date;
}

export const soilNutrientSetSelectedDate = (selectedDate: Date): ISoilNutrientSetSelectedDate => ({
  type: ActionTypes.SOIL_NUTRIENT_SET_SELECTED_DATE,
  selectedDate,
});

export const getSoilNutrientImages = (farm: IFarm, startDate: string, endDate: string, organizationId?: string, disableSnackbar?: boolean) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(soilNutrientImagesGetStart());
    const url = `${baseApiUrl}/soil-nutrient/images/${farm.farmid}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const {data: soilNutrientImages} = await axios.get<ISoilNutrientImageRes[]>(url);
      if (!soilNutrientImages.length) {
        !disableSnackbar && dispatch(enqueueSnackbar({message: `${i18n.t('error.soil.nutrients.not.found')}  ${farm.name}`, options: {variant: 'info'}}));
        dispatch(soilNutrientImagesGetFail('not found'));
      }
      const soilNutrientType = NITROGEN;
      dispatch(soilNutrientImagesGetSuccess(soilNutrientImages || [], soilNutrientType, farm.id, farm));
      dispatch(farmIdUpdate(farm.id)); // global active farm
    } catch (err) {
      !disableSnackbar && dispatch(enqueueSnackbar({message: `${i18n.t('error.soil.nutrients.not.found')}  ${farm.name}`, options: {variant: 'info'}}));
      dispatch(soilNutrientImagesGetFail(err));
    }
  };
};
export const getSoilNutrientFarms = (allFarms: IFarm[]) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(soilNutrientFarmGetStart());
    const url = `${baseApiUrl}/farm-settings/options?portal=true`;
    try {
      const {data} = await axios.get(url);
      const farmIds = data.map(({farmId}) => farmId);
      const soilNutrientFarms = allFarms.filter(snf => farmIds.includes(snf.id));
      dispatch(soilNutrientFarmGetSuccess(soilNutrientFarms, soilNutrientFarms[0]));
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(soilNutrientFarmGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof soilNutrientFarmGetStart>
  | ReturnType<typeof soilNutrientFarmGetSuccess>
  | ReturnType<typeof soilNutrientFarmGetFail>
  | ReturnType<typeof soilNutrientImagesGetStart>
  | ReturnType<typeof soilNutrientImagesGetFail>
  | ReturnType<typeof soilNutrientImagesGetSuccess>
  | ReturnType<typeof soilNutrientImagesTypeGetSuccess>
  | ReturnType<typeof soilNutrientSetSelectedDate>
  | ReturnType<typeof farmIdUpdate>;
