import React, {useState} from 'react';
import {Box, Button, makeStyles, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import FileManagerIcon from '@material-ui/icons/InsertDriveFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {FaDrawPolygon} from 'react-icons/fa';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

// Define the styles using makeStyles
const useStyles = makeStyles(theme => ({
  menuContainer: {
    position: 'relative',
    borderRadius: '2.5px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    zIndex: 5,
    top: 10,
    left: 3,
    '& hr': {
      mx: 0,
    },
  },
  menuButton: {
    position: 'absolute',
    height: 40,
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'white !important',
    left: 183,
  },
  // Added background color and opacity for the entire menu overlay
  iconGridOverlay: {
    top: 44,
    left: 44,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black background with 70% opacity
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    width: '32%',
    padding: 0,
    margin: 0,
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
  },
  iconGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    // display: 'flex',
    gap: '2px',
    justifyItems: 'center',
    borderRadius: '4px',
    padding: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  },
  iconButton: {
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    fontFamily: 'Arial, sans-serif',
  },
  icon: {
    width: '30px',
    height: '30px',
    marginBottom: '5px',
    borderRadius: '5px',
  },
  labelText: {
    display: 'block',
    fontSize: '12px',
    color: 'white',
    flexDirection: 'column',
    // padding: '6px 0px 6px 6px',
    whiteSpace: 'normal', // Allows the text to wrap within the button
    wordBreak: 'break-word', // Ensures long words break into a new line if needed
    textAlign: 'center', // Centers the text if wrapped
  },
}));

interface Props {
  handleMultiBlockSelection: () => void;
  handleFileUpload: () => void;
  handleFileManager: () => void;
  handleDownloadMeanData: () => void;
}

const MenuIcons = ({handleMultiBlockSelection, handleFileUpload, handleFileManager, handleDownloadMeanData}: Props) => {
  const classes = useStyles(); // Use the styles
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEnabledBlockSelection, setIsEnabledBlockSelection] = useState(false);
  const {t} = useTranslation();

  // Toggle the visibility of the menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Toggle the block selection
  const handleBlockSelection = () => {
    setIsEnabledBlockSelection(!isEnabledBlockSelection);
    setIsMenuOpen(false);
    handleMultiBlockSelection();
  };

  return (
    <Box className={classes.menuContainer}>
      {/* Button to toggle menu */}
      <Button className={classes.menuButton} style={{color: 'secondary'}} onClick={toggleMenu}>
        <Typography variant="body1" style={{textTransform: 'capitalize', color: 'secondary'}}>
          {t('tools')}
        </Typography>
      </Button>

      {/* Display the icon list only if isMenuOpen is true */}
      {isMenuOpen && (
        <Box className={classes.iconGridOverlay}>
          <Box className={classes.iconGrid}>
            <Button className={(classes.iconButton, classes.labelText)} style={{backgroundColor: isEnabledBlockSelection ? 'black' : ''}} onClick={() => handleBlockSelection()}>
              <FaDrawPolygon color="#f50057" className={classes.icon} />
              <Typography style={{textTransform: 'capitalize'}}>{t('claim.blocks')}</Typography>
            </Button>
            <Button className={(classes.iconButton, classes.labelText)} onClick={() => handleFileUpload()}>
              <CloudUploadIcon color="secondary" className={classes.icon} />
              <Typography style={{textTransform: 'capitalize'}}>File Upload</Typography>
            </Button>
            <Button className={(classes.iconButton, classes.labelText)} onClick={() => handleFileManager()}>
              <FileManagerIcon color="secondary" className={classes.icon} />
              <Typography style={{textTransform: 'capitalize'}}>File Manager</Typography>
            </Button>
            <Button className={(classes.iconButton, classes.labelText)} onClick={() => handleDownloadMeanData()}>
              <CloudDownloadIcon color="secondary" className={classes.icon} />
              <Typography style={{textTransform: 'capitalize'}}>{t('download.mean.data')}</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MenuIcons;
