import {useState, useEffect} from 'react';
import axios from 'axios';
import {IFarm} from '../store/reducers/farm';
import {baseApiUrl} from '../config/const';

export const useImageryDates = (selectedFarm: IFarm, fromDate: Date, toDate: Date) => {
  const [esaImageDates, setEsaImageDates] = useState<Date[]>([]);
  const [planetImageDates, setPlanetImageDates] = useState<Date[]>([]);
  const [loadingImages, setLoadingImages] = useState<boolean>(true);
  const [errorImages, setErrorImages] = useState<boolean>(false);

  useEffect(() => {
    // Function to fetch and format unique imagery dates
    const fetchImageDates = async () => {
      const esaDateTimes = [];
      const planetDateTimes = [];

      // Request parameters
      const params = {
        farmId: selectedFarm.farmid,
        fromDate: fromDate,
        toDate: toDate,
        source: ['ESA', 'Planet'],
      };

      try {
        setLoadingImages(true);
        setErrorImages(false);
        // Fetch esa dates
        const response = await axios.get(`${baseApiUrl}/esa/dates`, {params: params});
        const data = response.data;
        data.forEach(imageDate => {
          // Get image source
          const source = imageDate.source;
          // Get image date
          const date = new Date(imageDate.date);
          if (source === 'ESA') {
            if (!esaDateTimes.includes(date.getTime())) {
              esaDateTimes.push(date.getTime()); // Push date to esa if not already included
            }
          } else if (source === 'PLANET') {
            if (!planetDateTimes.includes(date.getTime())) {
              planetDateTimes.push(date.getTime()); // Push date to planet if not already included
            }
          } else {
            console.error(`Error sorting image dates: source is neither "ESA" or "PLANET"`);
          }
        });

        // Return object of esa dates and planet dates
        const uniqueDates = {planet: planetDateTimes.map(dateTime => new Date(dateTime)), esa: esaDateTimes.map(dateTime => new Date(dateTime))};

        return uniqueDates;
      } catch (error) {
        setErrorImages(true);
        console.error('Error fetching image dates:', error);
      }
    };

    if (selectedFarm && fromDate && toDate) {
      fetchImageDates().then(uniqueDates => {
        if (uniqueDates) {
          setPlanetImageDates(uniqueDates.planet);
          setEsaImageDates(uniqueDates.esa);
          setErrorImages(false);
        }
        setLoadingImages(false);
      });
    }
  }, [selectedFarm, fromDate, toDate]);

  return {esaImageDates, planetImageDates, loadingImages, errorImages};
};
