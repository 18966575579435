import {IGetMaturityImages} from '@deep-planet/api-interfaces';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {baseApiUrl} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {farmIdUpdate} from './farm';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {flatten} from 'lodash';
import {getImageRequest} from './utils';

/////////////////////////////////////////////
// GET maturity images
/////////////////////////////////////////////

interface IMaturityImageGetStart {
  type: ActionTypes.MATURITY_IMAGE_GET_START;
}

export const maturityImageGetStart = (): IMaturityImageGetStart => ({
  type: ActionTypes.MATURITY_IMAGE_GET_START,
});

interface IMaturityImageGetSuccess {
  type: ActionTypes.MATURITY_IMAGE_GET_SUCCESS;
  images: IGetMaturityImages[];
  farmId: string;
  activeFarm: IFarm;
}

export const maturityImageGetSuccess = (images: IGetMaturityImages[], farmId: string, activeFarm: IFarm): IMaturityImageGetSuccess => ({
  type: ActionTypes.MATURITY_IMAGE_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface IMaturityImageGetFail {
  type: ActionTypes.MATURITY_IMAGE_GET_FAIL;
  error: unknown;
}

export const maturityImageGetFail = (error): IMaturityImageGetFail => ({
  type: ActionTypes.MATURITY_IMAGE_GET_FAIL,
  error,
});

interface IMaturityImageSetSelectedDate {
  type: ActionTypes.MATURITY_IMAGE_SET_SELECTED_DATE;
  selectedDate: number;
}

export const maturityImageSetSelectedDate = (selectedDate: number): IMaturityImageSetSelectedDate => ({
  type: ActionTypes.MATURITY_IMAGE_SET_SELECTED_DATE,
  selectedDate,
});

export const getMaturityImages = (farm: IFarm, startDate: string, endDate: string, organizationId?: string, disableSnackbar?: boolean) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(maturityImageGetStart());
    const maturityImageGetUrl = `${baseApiUrl}/maturity_image/${farm.id}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const response = await getImageRequest<IGetMaturityImages[]>(maturityImageGetUrl, dispatch, i18n.t('error.maturity.map.not.found'), disableSnackbar);
      const data = flatten(response);
      dispatch(maturityImageGetSuccess(data, farm.id, farm));
      dispatch(farmIdUpdate(farm.id));
    } catch (err) {
      console.log(err);
      !disableSnackbar && dispatch(enqueueSnackbar({message: i18n.t('error.maturity.map.not.found'), options: {variant: 'error'}}));
      dispatch(maturityImageGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof maturityImageGetStart>
  | ReturnType<typeof maturityImageGetSuccess>
  | ReturnType<typeof maturityImageGetFail>
  | ReturnType<typeof farmIdUpdate>
  | ReturnType<typeof maturityImageSetSelectedDate>;
