import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, CircularProgress, FormControlLabel, FormHelperText, MenuItem, Select, Switch, Typography, makeStyles} from '@material-ui/core';
import {NDVI, NITROGEN, PHOSPHORUS, POTASSIUM} from '../../../../../config/const';
import {handleDateFormat} from 'apps/web-portal-ui/src/app/helpers/dateHelpers';
import SimpleDatePicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/SimpleDatePicker';

const useStyles = makeStyles(theme => ({
  subheading: {
    marginTop: 10,
  },
  select: {
    marginBottom: 20,
  },
  dateSelect: {
    width: '100%',
    marginBottom: 10,
  },
  menuItem: {
    width: '100%',
  },
  switchContainer: {
    width: '100%',
    marginBottom: 20,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    height: 35,
    width: '90%',
    marginBottom: 10,
  },
  errorText: {
    color: 'red',
  },
  progress: {
    color: 'white',
  },
}));

interface SelectIndexProps {
  reference: string;
  isMean: boolean;
  fromDate: Date;
  toDate: Date;
  loadingZones: boolean;
  zoneError: string;
  handleReferenceChange: (date: any) => void;
  handleIsMeanChange: () => void;
  handleFromDateChange: (date: number) => void;
  handleToDateChange: (date: number) => void;
  fetchZoneData: () => void;
  imageDates: number[];
}

const SelectIndex = ({
  reference,
  handleReferenceChange,
  isMean,
  handleIsMeanChange,
  fromDate,
  handleFromDateChange,
  toDate,
  handleToDateChange,
  fetchZoneData,
  loadingZones,
  zoneError,
  imageDates,
}: SelectIndexProps) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const imageOnDate = imageDates.find(date => date === fromDate.getTime()); // Checks if from date selector is currently available in image dates

  const maxToDate = new Date(toDate);
  maxToDate.setDate(toDate.getDate() - 1); // 1 day previous to toDate

  return (
    <>
      {/* Title */}
      <Typography className={classes.subheading}>{t('soil.nutrients.vra.select.index')}</Typography>
      {/* Index selector */}
      <Select disabled={loadingZones} className={classes.select} value={reference} onChange={handleReferenceChange}>
        <MenuItem className={classes.menuItem} value={NITROGEN}>
          {t('soil.nutrients.nitrogen')}
        </MenuItem>
        <MenuItem className={classes.menuItem} value={PHOSPHORUS}>
          {t('soil.nutrients.phosphorus')}
        </MenuItem>
        <MenuItem className={classes.menuItem} value={POTASSIUM}>
          {t('soil.nutrients.potassium')}
        </MenuItem>
        <MenuItem className={classes.menuItem} value={NDVI}>
          {NDVI}
        </MenuItem>
      </Select>
      {/* Mean values switch  */}
      {reference === NDVI && (
        <Box className={classes.switchContainer}>
          <FormControlLabel disabled={loadingZones} control={<Switch checked={isMean} onChange={handleIsMeanChange} name="is-mean" color="primary" />} label={t('soil.nutrients.vra.mean.values')} />
        </Box>
      )}
      {/* From date selector  */}
      {isMean && reference === NDVI ? (
        <Box className={classes.switchContainer}>
          <Typography>{t('date.picker.start.date') + ':'}</Typography>
          <SimpleDatePicker disabled={loadingZones} onChange={date => handleFromDateChange(date.getTime())} value={new Date(fromDate)} maxDate={maxToDate} disableFuture />
        </Box>
      ) : (
        <Box className={classes.switchContainer}>
          <Typography>{isMean ? t('date.picker.start.date') + ':' : t('date.picker.date') + ':'}</Typography>
          <Select
            disabled={loadingZones}
            className={classes.dateSelect}
            value={
              imageOnDate || imageDates[0] // Defaults to first date when no imageOnDate
            }
            onChange={event => handleFromDateChange(Number(event.target.value))}
          >
            {imageDates.map(date => {
              return (
                <MenuItem key={date} className={classes.menuItem} value={date}>
                  {handleDateFormat(date)}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      )}
      {/* To date selector */}
      {isMean && reference === NDVI && (
        <Box className={classes.switchContainer}>
          <Typography>{t('date.picker.end.date') + ':'}</Typography>
          <SimpleDatePicker disabled={loadingZones} onChange={date => handleToDateChange(date.getTime())} value={new Date(toDate)} disableFuture />
        </Box>
      )}

      {/* Get zones button  */}
      <Box className={classes.buttonContainer}>
        <Button className={classes.button} variant="contained" color="primary" onClick={fetchZoneData}>
          {loadingZones ? <CircularProgress size={17} className={classes.progress} /> : 'Get Zones'}
        </Button>
        {zoneError && <Typography className={classes.errorText}>{t('soil.nutrients.vra.select.index.error')}</Typography>}
      </Box>
    </>
  );
};

export default SelectIndex;
