import React from 'react';
import {IconButton, Tooltip} from '@material-ui/core';
import styled from 'styled-components';

const StyledButton = styled(IconButton)`
  height: 40px;
  width: 40px;
`;

interface Props {
  Icon: React.ElementType;
  tooltipText: string;
  onClick: () => void;
}

const MenuIconButton = ({Icon, tooltipText, onClick}: Props) => {
  return (
    <Tooltip title={tooltipText} placement="left" arrow>
      <StyledButton color="primary" onClick={onClick}>
        <Icon style={{margin: -5}} />
      </StyledButton>
    </Tooltip>
  );
};

export default MenuIconButton;
