import React, {useState, useEffect} from 'react';
import {GroundOverlay} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import PolygonWithInfo from 'apps/web-portal-ui/src/app/components/UI/PolygonWithInfo/PolygonWithInfo';
import {Map, MapProps} from 'apps/web-portal-ui/src/app/components/UI/Map/Map';
import {INutrientTypeImageData, ISoilNutrientImageRes} from './../../../../../../../../libs/api-interfaces/src/lib/soil.type';
import OpacityPanel from 'apps/web-portal-ui/src/app/components/UI/Panel/OpacityPanel';
import Marker from 'apps/web-portal-ui/src/app/components/UI/Marker/Marker';
import {useMarker} from 'apps/web-portal-ui/src/app/hooks/useMarker';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import MapOverlay from 'apps/web-portal-ui/src/app/components/UI/MapOverlay';
import MapInfoWindow from 'apps/web-portal-ui/src/app/components/UI/PolygonWithInfo/InfoWindow';
import {useSelector} from 'react-redux';
import {markerDeleteSelector} from 'apps/web-portal-ui/src/app/store/selectors';
import {renderURL} from '../../NDVI/DownloadScreenshot';
import Legend from '../../NDVI/Legend';
import FertilizerButtonGroup from './VRA/FertilizerButtonGroup';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {SOIL_FERTILISER_ZONES} from 'apps/web-portal-ui/src/app/config/const';

const renderImage = (type: string, image: INutrientTypeImageData | any) => {
  switch (type) {
    case 'current':
      return image.curr_url;
    case 'previous':
      return image.prev_url;
    case 'average':
      return image.avg_url;
    case 'predicted':
      return image.pred_url;
    case 'vra':
      return image.url;
    default:
      return '';
  }
};

const getImageUrl = (type: string, image: INutrientTypeImageData) => {
  const url = renderImage(type, image);
  return url;
};

// Uses a polygon's render coords to create a bound box (VRA bbox not provided by backend)
const getBoundsFromRenderCoords = (
  renderCoords: {
    lat: number;
    lng: number;
  }[]
) => {
  const latitudes = renderCoords.map(coord => coord.lat);
  const longitudes = renderCoords.map(coord => coord.lng);

  const north = Math.max(...latitudes); // max latitude
  const south = Math.min(...latitudes); // min latitude
  const east = Math.max(...longitudes); // max longitude
  const west = Math.min(...longitudes); // min longitude

  // Create bbox
  return new window.google.maps.LatLngBounds(new window.google.maps.LatLng(south, west), new window.google.maps.LatLng(north, east));
};

interface Props extends MapProps {
  farm: IFarm;
  type: string;
  imagesOnSelectedDate: ISoilNutrientImageRes;
  nutrientType: string;
  handlePolygonHover?: (id: string) => void;
  takeScreenshot: () => void;
  dynamicScale: any;
  nutrientName: any;
  rightbarOpen: boolean;
  toggleRightbar: () => void;
  vraZoneData: any;
}

const SoilNutrientMap = ({
  farm,
  center,
  imagesOnSelectedDate,
  nutrientType,
  type,
  bbox,
  polygons,
  handlePolygonHover,
  takeScreenshot,
  polygonClicked,
  handleOnClickPolygon,
  selectedPolygon,
  dynamicScale,
  nutrientName,
  rightbarOpen,
  toggleRightbar,
  vraZoneData,
}: //activeFarm
Props) => {
  const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 130px)',
    transition: 'width 0.2s ease',
  };

  const farmBBox = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));

  const {t} = useTranslation();

  const [opacity, setOpacity] = useState(100);
  const [showFertilizer, setShowFertilizer] = useState(false);
  const [showIndex, setShowIndex] = useState(false);
  const [renderFirstMap, setRenderFirstMap] = useState(true);
  const [renderSecondMap, setRenderSecondMap] = useState(true);

  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  const {
    isModalOpen,
    loading,
    postMarkerLoading,
    selectedMarkerFeature,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    markerPolygon,
    selectedPin,
    handleCloseModal,
    handleOnClickMap,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
  } = useMarker(farm);

  const handleOpacityChange = (event, newValue) => {
    newValue = newValue / 100;
    setOpacity(newValue);
  };

  const reloadMaps = () => {
    setRenderFirstMap(false);
    setRenderSecondMap(false);
    setRenderFirstMap(true);
    setTimeout(() => {
      setRenderSecondMap(true);
    }, 0.5);
  };

  const handleOnClick = polygon => {
    handleOnClickPolygon(polygon);
  };

  const toggleFertilizer = () => {
    setShowFertilizer(!showFertilizer);
  };

  const toggleIndex = () => {
    setShowIndex(!showIndex);
  };

  const fertilizerButtonConditions = !vraZoneData && imagesOnSelectedDate?.fertilizers?.length > 0 && Object.keys(imagesOnSelectedDate?.fertilizers[0][nutrientType]).length > 0;
  const indexButtonConditions = !vraZoneData && imagesOnSelectedDate?.alerts?.length > 0 && Object.keys(imagesOnSelectedDate?.alerts[0][nutrientType]).length > 0;

  const selectedImages =
    // VRA images take priority
    vraZoneData?.images ||
    // If index then show alert data
    (showIndex && indexButtonConditions && imagesOnSelectedDate?.alerts) ||
    // If fertilizer ONLY then show fertilizer data
    (showFertilizer && fertilizerButtonConditions && imagesOnSelectedDate?.fertilizers) ||
    // Else show normal soil nutrient imagery
    imagesOnSelectedDate?.images;

  const vraButtonConditions = imagesOnSelectedDate && selectedImages;

  const secondaryImages =
    // If both index and fertilizer buttons -> fertilizer becomes secondary images
    showIndex && showFertilizer && fertilizerButtonConditions && indexButtonConditions ? imagesOnSelectedDate?.fertilizers : [];
  const isLoading = loading || postMarkerLoading || false;

  const secondaryMapConditions = fertilizerButtonConditions && indexButtonConditions && secondaryImages.length > 0;

  // Handles case where user selects fertilizer button then index -> ensures that fertilizer button renders second and therefore appears on top of the index map
  useEffect(() => {
    if (showFertilizer && secondaryImages.length > 0) {
      reloadMaps();
    }
    if (secondaryImages.length === 0) {
      setRenderSecondMap(false);
    } else {
      setRenderSecondMap(true);
    }
  }, [secondaryImages, showFertilizer]);

  // Toggles fertilizer switch off when it is not able to show on UI
  useEffect(() => {
    if (!fertilizerButtonConditions) {
      setShowFertilizer(false);
    }
    if (!indexButtonConditions) {
      setShowIndex(false);
    }
  }, [fertilizerButtonConditions, indexButtonConditions]);

  return (
    <>
      {isMarkerDeleteLoading && <MapOverlay position="relative" />}
      {isLoading && <MapOverlay />}
      {!isLoading && !isMarkerDeleteLoading && (
        <Map
          center={center}
          mapContainerStyle={containerStyle}
          bbox={bbox}
          polygons={polygons}
          displayRows
          selectedShowMarker={selectedMarkerFeature}
          handleMarkerClick={handleMarkerClick}
          handleMarkerMouseOver={handleMarkerMouseOver}
          handleMarkerMouseOut={handleMarkerMouseOut}
          polygonClicked={polygonClicked}
          selectedPolygon={selectedPolygon}
          focus
        >
          <FertilizerButtonGroup
            showIndex={showIndex}
            toggleIndex={toggleIndex}
            showFertilizer={showFertilizer}
            toggleFertilizer={toggleFertilizer}
            fertilizerButtonConditions={fertilizerButtonConditions}
            indexButtonConditions={indexButtonConditions}
            vraButtonConditions={vraButtonConditions}
            rightbarOpen={rightbarOpen}
            toggleRightbar={toggleRightbar}
          />
          {selectedImages && (
            <Marker
              selectedMarker={selectedMarker}
              farm={farm}
              polygon={markerPolygon}
              featureName={'SOIL'}
              isModalOpen={isModalOpen}
              isOpenModalNoteUpdate={isOpenModalNoteUpdate}
              handleAddPin={handleSelectedPin}
              handleMarkerPostRequest={handleMarkerPostRequest}
              handleMarkerFeatureSelection={handleMarkerFeatureSelection}
              handleCloseModal={handleCloseModal}
              handleMarkerSubmit={handleMarkerSubmit}
              handleNoteUpdateClose={handleNoteUpdateClose}
              handleDeleteMarker={handleDeleteMarker}
              takeScreenshot={takeScreenshot}
            />
          )}
          {!vraZoneData && (
            <Legend
              product={'soil_nutrients'}
              type={type}
              dynamicScale={dynamicScale}
              text={nutrientType === 'nitrogen' ? `${t('soil.nutrients.nitrogen')} (g/kg)` : `${nutrientName === 'Phosphorus' ? t('soil.nutrients.phosphorus') : nutrientName} (mg/kg)`}
            />
          )}

          {selectedImages && farm && <OpacityPanel opacity={opacity} handleOpacityChange={handleOpacityChange} />}

          {polygons?.map((polygon: IPolygonEntity) => {
            // Used to track if images are VRA images
            let isVra = false;

            const correspondingImage = selectedImages?.find(image => {
              if (image.polygonId === polygon.id && image.reference === SOIL_FERTILISER_ZONES) {
                isVra = true;
                return true;
              } else if (image.polygonid === polygon.id) {
                return true;
              }
              return false;
            });

            const secondaryCorrespondingImage = secondaryImages?.find(({polygonid}) => polygonid === polygon.id);

            if (correspondingImage) {
              let secondaryUrl = null;
              const url = isVra ? getImageUrl('vra', correspondingImage) : getImageUrl(type, correspondingImage[nutrientType]);
              if (secondaryCorrespondingImage) {
                secondaryUrl = getImageUrl(type, secondaryCorrespondingImage[nutrientType]);
              }

              const bounds = isVra
                ? // Create bounds from polygon coords
                  getBoundsFromRenderCoords(polygon.geoJson.geometry.renderCoords)
                : correspondingImage.boundBox
                ? // Fetch bound data
                  new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(correspondingImage.boundBox[1], correspondingImage.boundBox[0]),
                    new window.google.maps.LatLng(correspondingImage.boundBox[3], correspondingImage.boundBox[2])
                  )
                : farmBBox;

              return (
                <>
                  {renderFirstMap && <GroundOverlay key={`${nutrientType}-${type}-${url}`} url={renderURL(url)} bounds={bounds} opacity={secondaryMapConditions ? 1 : opacity} />}
                  {/* Secondary map for when both index and fertilizer are selected */}
                  {renderSecondMap && secondaryMapConditions && secondaryUrl && (
                    <GroundOverlay key={`${nutrientType}-${type}-${secondaryUrl}`} url={renderURL(secondaryUrl)} bounds={bounds} opacity={opacity} />
                  )}
                  <PolygonWithInfo
                    key={polygon.id}
                    polygon={polygon}
                    handlePolygonHover={handlePolygonHover}
                    handlePolygonClick={handleOnClick}
                    handleOnClickMap={handleOnClickMap}
                    selectedPin={!!selectedPin}
                  />
                  {mouseOverMarker && isOpenMarkerInfoWindow && (
                    <MapInfoWindow key={`${mouseOverMarker.latitude}-${mouseOverMarker.longitude}`} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                  )}
                </>
              );
            } else {
              return selectedImages?.length ? <PolygonWithInfo key={polygon.id} polygon={polygon} secondaryTitle={t('ndvi.planet.cloudy')} defaultOpacity={0.3} /> : null;
            }
          })}
        </Map>
      )}
    </>
  );
};

export default SoilNutrientMap;
