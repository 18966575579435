import {IFarmEntity, IFarmSettings, IOrganizationEntity, ISoilOrganicCarbonMapDTO} from '@deep-planet/api-interfaces';
import {Box, Button} from '@material-ui/core';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import SimpleDatePicker from '../../../../../../../components/UI/Pickers/SimpleDatePicker';
import Spinner from '../../../../../../../components/UI/Spinner';
import {baseApiUrl, baseSoilNutrientUrl} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';
import theme from '../../../../../../../utilities/theme';
import {FarmType} from './FarmType';
import {useHttp} from 'apps/web-portal-ui/src/app/hooks/http';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  farm: IFarmEntity;
  organization: IOrganizationEntity;
  farmSettings: IFarmSettings;
  isLoadingFarmSettings: boolean;
}

export const SoilCarbon = ({farm, organization, farmSettings, isLoadingFarmSettings}: Props) => {
  const [selectedFarmType, setSelectedFarmType] = useState('FARMLAND');
  const [predictionDate, setPredictionDate] = useState<Date>(new Date());
  const [baselineDate, setBaselineDate] = useState<Date>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload: ISoilOrganicCarbonMapDTO = {
        predictionDate: predictionDate,
        baselineDate: baselineDate,
        farmType: selectedFarmType,
        farmId: farm.id,
      };
      await axios.post<any>(`${baseSoilNutrientUrl}/soil/organic-carbon/farm`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      dispatch(enqueueSnackbar({message: 'Soil Orgnic Carbon images are generated', options: {variant: 'success'}}));
    } catch (e) {
      const msg = e?.response?.data?.message || e?.response?.data?.message;
      dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (farmSettings && !baselineDate) setBaselineDate(farmSettings.baselineDate);
  }, [baselineDate, farmSettings]);

  const isLoading = loading || isLoadingFarmSettings;

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <Box display="display" flexDirection="column">
            <FarmType farmType={selectedFarmType} handleFarmType={setSelectedFarmType} loading={isLoading} />
            <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={baselineDate} onChange={date => setBaselineDate(date)} label={'Baseline date'} disabled={isLoading} />
            </Box>
            <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={predictionDate} onChange={date => setPredictionDate(date)} label={'Prediction date'} disabled={isLoading} />
            </Box>
            <Box mt={4} mb={4} display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" disabled={isLoading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
                Submit
                {isLoading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </Box>
        </form>
      </FormWrapper>
    </Box>
  );
};
