import {IsDefined, IsOptional, IsUUID} from 'class-validator';
import {IOrganizationEntity} from './organization.type';
import {IUserEntity} from './user.type';

export interface IClientDataRequirement {
  id: string;
  organization: IOrganizationEntity;
  user: IUserEntity;
  clientData: boolean;
  varietalInformation: boolean;
  soilHealthData: boolean;
  harvestData: boolean;
  smProbeAPI: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class IClientDataRequirementDTO {
  @IsOptional()
  @IsUUID()
  id: string;

  @IsDefined()
  organization: IOrganizationEntity;

  @IsOptional()
  clientData: boolean;

  @IsOptional()
  varietalInformation: boolean;

  @IsOptional()
  soilHealthData: boolean;

  @IsOptional()
  harvestData: boolean;

  @IsOptional()
  smProbeAPI: boolean;
}

export class IClientDataRequirementUICheckBox {
  clientData: boolean;
  varietalInformation: boolean;
  soilHealthData: boolean;
  harvestData: boolean;
  smProbeAPI: boolean;
}
