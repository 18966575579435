import React, {useMemo, useState} from 'react';
import {Box, Typography} from '@material-ui/core';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useDisease} from '../../../hooks/useDisease';
import Layout from '../../Layout';
import Legend, {DynamicScaleType} from '../NDVI/Legend';
import Sidebar from './../MaturityMap/Sidebar';
import moment from 'moment';
import Map from './../MaturityMap/Map';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import usePolygon from '../../../hooks/usePolygon';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import useTooltipText from '../../../hooks/useTooltipText';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '../../../components/UI/PageTitle';

const Disease = () => {
  //initialize the marker colors
  useMarkerColor();
  const {t} = useTranslation();
  const {HelpDisease} = useTooltipText();
  const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dynamicScale, setDynamicScale] = useState<DynamicScaleType>(initialDynamicScale);
  const {selectedFarm, activeFarm, allFarms, loadingGetFarm, loadingGetUser, handleFarmChange, setActiveFarm} = useFarmSelection();
  const {handleOnClickPolygon, polygonClicked, selectedPolygon} = usePolygon();
  const {diseaseImages, selectedDate, handleDateChange, onGetDiseaseImages, loading: loadingGetImages} = useDisease(activeFarm, startDate, endDate);

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleImageFetching = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
    }
    onGetDiseaseImages(selectedFarm);
  };

  const handleFarmSelection = (farmId: string) => {
    handleFarmChange(farmId);
  };

  const handleSelectedDate = (date: number) => {
    handleDateChange(date);

    handleDynamicScale(diseaseImages, new Date(date));
  };

  const handleSwitch = () => {
    //@ts-ignore
    handleDynamicScale(diseaseImages, selectedDate, tonnes);
  };

  // TODO: this may required to remove in the future if thresholds are same across the farm
  const handleDynamicScale = (diseasePerPixelImages, dateSelected) => {
    const images = diseasePerPixelImages?.find(({date}) => moment(date).isSame(moment(dateSelected), 'date'))?.images;
    if (images) {
      const {thresholdLow, thresholdHigh} = images[0];
      const step = (thresholdHigh - thresholdLow) / 7;
      // @ts-ignore
      const currentDynamicScale: DynamicScaleType = [];
      for (let i = 0; i <= 7; i++) {
        const value = parseFloat(thresholdLow + step * i).toFixed(2);
        currentDynamicScale.push(Number(value));
      }
      setDynamicScale(currentDynamicScale);
    }
  };

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const isLoading = loadingGetFarm || loadingGetUser || loadingGetImages;
  const filteredImages = useMemo(() => diseaseImages?.find(({date}) => moment(date).isSame(moment(selectedDate), 'date')), [diseaseImages, selectedDate]);

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && activeFarm && !activeFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={activeFarm.id} />}
      {!isLoading && activeFarm && (
        <Box>
          <div id="layout">
            <Legend product="disease" type="current" dynamicScale={dynamicScale} />
            <ContentLeftSidebar
              sidebar={
                <Sidebar
                  allFarms={allFarms}
                  activeFarm={selectedFarm}
                  images={diseaseImages}
                  startDate={startDate}
                  endDate={endDate}
                  selectedDate={selectedDate}
                  handleFarmChange={handleFarmSelection}
                  handleDateChange={handleSelectedDate}
                  handleImageFetching={handleImageFetching}
                  handleStartDateSelection={handleStartDateSelection}
                  handleEndDateSelection={handleEndDateSelection}
                  loading={isLoading}
                />
              }
              content={
                <>
                  <PageTitle title={t('disease')} tooltip={HelpDisease} />
                  <Map
                    farm={activeFarm}
                    center={activeFarm.farmCenter}
                    images={filteredImages?.images}
                    bbox={activeFarm.bbox}
                    polygons={activeFarm.polygons}
                    handleSwitch={handleSwitch}
                    takeScreenshot={takeScreenshot}
                    handlePolygonClick={handleOnClickPolygon}
                    selectedPolygon={selectedPolygon}
                    polygonClicked={polygonClicked}
                  />
                </>
              }
            />
          </div>
        </Box>
      )}
    </Layout>
  );
};
export default Disease;
