import React, {useState, useEffect} from 'react';
import {Box, Button, Divider, FormControl, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, SelectProps, Tooltip, makeStyles} from '@material-ui/core';
import Room from '@material-ui/icons/Room';
import PinDrop from '@material-ui/icons/AddLocation';
import LocationOn from '@material-ui/icons/LocationOn';
import FilterList from '@material-ui/icons/FilterList';
import ScreenshotIcon from '@material-ui/icons/CenterFocusWeak';
import {useTranslation} from 'react-i18next';
import DatePeriodPicker from '../Pickers/DatePeriodPicker';
import styled from 'styled-components';
import {useMarker} from '../../../hooks/useMarker';
import {IFarm} from '../../../store/reducers/farm';
import {useSelector} from 'react-redux';
import {organizationsSelector, selectedOrganizationSelector} from '../../../store/selectors';
import {capitalize, sortBy} from 'lodash';
import {getUserNamesForOrganization} from '../../../containers/pages/Notes/OrganizationUsers';
import {ICreateNoteDTO, IMarker, IPolygonEntity} from '@deep-planet/api-interfaces';
import {ISelectedFile} from '../../../containers/pages/Notes/withEdit';
import MarkerNote from './MarkerNote';
import {ALL} from '../../../containers/pages/Notes/getUserFarms';

const StyledSelect = styled(Select)<SelectProps>`
  width: auto;
`;

interface Props {
  farm: IFarm;
  polygon: IPolygonEntity;
  isModalOpen?: boolean;
  isOpenModalNoteUpdate?: boolean;
  featureName?: string;
  selectedMarker?: IMarker;
  handleCloseModal: () => void;
  handleAddPin: (e) => void;
  handleMarkerSubmit: (params: ICreateNoteDTO, files: ISelectedFile[]) => void;
  handleMarkerPostRequest: () => void;
  handleMarkerFeatureSelection: (m) => void;
  handleNoteUpdateClose?: () => void;
  handleNoteAttachmentRemove?: (value: string) => void;
  handleDeleteMarker?: (value: string) => void;
  takeScreenshot?: () => void;
  isEnabledClaimBlocks?: boolean;
  disableScreenshot?: boolean;
}

const Marker = ({
  farm,
  polygon,
  handleAddPin,
  isModalOpen,
  featureName,
  selectedMarker,
  isOpenModalNoteUpdate,
  handleMarkerPostRequest,
  handleMarkerFeatureSelection,
  handleCloseModal,
  handleMarkerSubmit,
  handleNoteUpdateClose,
  handleDeleteMarker,
  takeScreenshot,
  isEnabledClaimBlocks,
  disableScreenshot = false,
}: Props) => {
  const {t} = useTranslation();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [addPin, setAddPin] = useState<boolean>(false);
  const [markerDateFilter, setMarkerDateFilter] = useState<boolean>();
  const [markerFeatureFilter, setMarkerFeatureFilter] = useState<boolean>();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const organizations = useSelector(organizationsSelector);
  // markers custom hook
  const {markers, markerColors, loading, startDate, endDate, selectedUser, handleStartDateSelection, handleEndDateSelection, handleGetMarkers, handleUserSelection} = useMarker(farm);

  const useStyles = makeStyles(theme => ({
    screenshot: {
      position: 'absolute',
      width: isMobileView ? 30 : 40,
      backgroundColor: 'white',
      borderRadius: '2.5px',
      paddingInline: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2,
      top: '54px',
      right: '10px',
      '& hr': {
        mx: 0,
      },
    },

    listStyle: {
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      right: '53px',
      position: 'absolute',
      borderRadius: '2px',
    },

    markerDateSelection: {
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      right: '52px',
      position: 'absolute',
      width: '160px',
      padding: '0px 7px',
      margin: '0px 0px !important',
      borderRadius: '2px',
      [theme.breakpoints.down('md')]: {
        right: '53px',
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // select a pin to create a new marker
  const handleSelectedDroppedPin = pin => {
    // disable the item menu
    setAddPin(!addPin);
    // set the selected pin on custom hook
    handleAddPin(pin);
  };

  // control the marker feature item menu
  const handleShowMarkerFeatureFilter = m => {
    setMarkerFeatureFilter(false);
    handleMarkerFeatureSelection(m);
  };

  // control the new pin item menu
  const handleCreateMarkerMenu = () => {
    const addPIN = !addPin;
    setAddPin(addPIN);
    if (addPIN) {
      setMarkerFeatureFilter(false);
      setMarkerDateFilter(false);
    }
  };

  // control to show/hide markers on the map
  const handleDisplayMarkers = () => {
    const enableFeatureFilter = !markerFeatureFilter;
    setMarkerFeatureFilter(enableFeatureFilter);
    if (enableFeatureFilter) {
      setAddPin(false);
      setMarkerDateFilter(false);
    }
  };

  // control get markers item menu
  const handleMarkerDateFilter = () => {
    // toggle the item menu
    const dateFilter = !markerDateFilter;
    setMarkerDateFilter(dateFilter);
    if (dateFilter) {
      setAddPin(false);
      setMarkerFeatureFilter(false);
    }
  };

  // get all markers
  const handleSubmit = () => {
    setMarkerDateFilter(!markerDateFilter);
    handleGetMarkers();
    handleMarkerPostRequest();
    handleMarkerFeatureSelection({color: 'BLUE', feature: {name: 'Show All'}});
  };

  const featureAcronyms = ['NDVI', 'NDWI'];
  const markerColor = markerFeatureFilter ? 'secondary' : 'primary';
  const pinColor = addPin ? 'secondary' : 'primary';
  const markerFilterColor = markerDateFilter ? 'secondary' : 'primary';
  const submitLoading = loading;
  const featureMarkers = markerColors && [{color: 'BLUE', feature: {name: 'Show All'}}, {color: 'GREY', feature: {name: 'Hide All'}}, ...markerColors];
  return (
    <>
      {/*
       * showMarker to show existing markers
       */}
      <Box display="flex" flexDirection="column" className={classes.screenshot} boxShadow={1}>
        {!disableScreenshot && (
          <Tooltip title={t('screenshot')} placement={'left'} arrow>
            <Button aria-label={t('screenshot')} onClick={takeScreenshot} style={{color: 'secondary'}}>
              <ScreenshotIcon color="primary" fontSize="large" />
            </Button>
          </Tooltip>
        )}
        {/** Disable marker icons on Dashboard when claim block feature is enabled*/}
        {!isEnabledClaimBlocks && (
          <>
            <Divider style={{height: '1px'}} orientation="horizontal" variant="fullWidth" flexItem />
            <Tooltip title={t('marker.filter')} placement={'left'} arrow>
              <Button aria-label={t('marker.filter')} onClick={handleMarkerDateFilter} style={{color: 'secondary'}}>
                <FilterList color={markerFilterColor} fontSize="large" />
              </Button>
            </Tooltip>
            <Divider orientation="horizontal" variant="fullWidth" flexItem />
            <Tooltip title={t('marker.show.markers')} placement={'left'} arrow>
              <Button aria-label={t('marker.show.markers')} onClick={handleDisplayMarkers} style={{bottom: '3px', color: 'secondary'}}>
                <Room color={markerColor} fontSize="large" />
              </Button>
            </Tooltip>
            {/*
             * Show Pin is to create a new marker or pin on the map
             */}
            <Divider style={{height: '1px'}} orientation="horizontal" variant="fullWidth" flexItem />
            <Tooltip title={t('marker.create.pin')} placement={'left'} arrow>
              <Button aria-label={t('marker.create.pin')} onClick={handleCreateMarkerMenu} style={{bottom: '6px', color: 'secondary'}}>
                <PinDrop color={pinColor} fontSize="large" />
              </Button>
            </Tooltip>
          </>
        )}
      </Box>
      {addPin && (
        <Box className={classes.listStyle} display={'grid'}>
          <List dense={true}>
            {markerColors &&
              markerColors.map(m => (
                <ListItem key={m.feature.name} button onClick={() => handleSelectedDroppedPin(m)}>
                  <ListItemIcon>
                    <PinDrop htmlColor={m.color} />
                  </ListItemIcon>
                  <ListItemText primary={featureAcronyms.includes(m.feature.name) ? m.feature.name : capitalize(m.feature.name.replace(/_/g, ' ').toLowerCase())} />
                </ListItem>
              ))}
          </List>
        </Box>
      )}

      {markerDateFilter && (
        <Box className={classes.markerDateSelection} boxShadow={3} display={'grid'}>
          <DatePeriodPicker
            disableToolbar={false}
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateSelection}
            handleEndDateChange={handleEndDateSelection}
            disableFuture={true}
          />
          <FormControl fullWidth disabled={submitLoading}>
            <InputLabel id="user-label">{t('user')}</InputLabel>
            <StyledSelect labelId="user-label" value={selectedUser || ALL} name="username" onChange={({target: {value}}) => handleUserSelection(value as string)}>
              {selectedOrganization ? sortBy(getUserNamesForOrganization(organizations, selectedOrganization.id)) : <MenuItem>{''}</MenuItem>}
            </StyledSelect>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleSubmit} style={{margin: '1rem 0'}}>
            {t('forms.submit')}
          </Button>
        </Box>
      )}

      {markerFeatureFilter && (
        <Box className={classes.listStyle} boxShadow={3} display={'grid'}>
          <List dense={true}>
            {featureMarkers &&
              featureMarkers.map(m => (
                <ListItem key={m.feature.name} button onClick={() => handleShowMarkerFeatureFilter(m)}>
                  <ListItemIcon>
                    <LocationOn htmlColor={m.color} />
                  </ListItemIcon>
                  <ListItemText primary={featureAcronyms.includes(m.feature.name) ? m.feature.name : capitalize(m.feature.name.replace(/_/g, ' ').toLowerCase())} />
                </ListItem>
              ))}
          </List>
        </Box>
      )}
      <MarkerNote
        farm={farm}
        polygon={polygon}
        isModalOpen={isModalOpen}
        isOpenModalNoteUpdate={isOpenModalNoteUpdate}
        selectedMarker={selectedMarker}
        handleCloseModal={handleCloseModal}
        handleNoteSubmit={handleMarkerSubmit}
        handleNoteUpdateClose={handleNoteUpdateClose}
        handleDeleteMarker={handleDeleteMarker}
      />
    </>
  );
};

export default React.memo(Marker);
