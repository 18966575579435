import React from 'react';
import styled from 'styled-components';
import {List, ListItemText, Collapse, Divider, ListItem as Listitem, Typography} from '@material-ui/core';
import ListItem from './ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {useTranslation} from 'react-i18next';

const Text = styled(ListItemText)`
  && {
    & span {
      font-size: 50% !important;
    }
 `;

const StyledListItem = styled(Listitem)`
  && * {
    text-align: left !important;
    font-size: 100% !important;
  }
`;

interface Props {
  group: string;
  groupName: string;
  path: string;
  open: boolean;
  items;
  groupPermissions: any;
  handleClick: (group: string, path: string) => void;
}

// expand and collapse the sidebar list
const DrawerGroup = ({group, groupName, path, open, items, groupPermissions, handleClick}: Props) => {
  const {t} = useTranslation();
  let name = groupName;

  switch (name) {
    case 'Water':
      name = 'navigation.accordion.soil.water';
      break;
    case 'Plant':
      name = 'navigation.accordion.plant';
      break;
    case 'Harvest':
      name = 'navigation.accordion.harvest';
      break;
    case 'Vine Health':
      name = 'navigation.accordion.vine.health';
      break;
    case 'Soil Health':
      name = 'navigation.accordion.vine.soil';
      break;
    case 'disease':
      name = 'navigation.accordion.disease.per.pixel';
      break;
    default:
      name = groupName;
  }

  return (
    <>
      <StyledListItem key={group} button onClick={() => handleClick(group, path)}>
        <Text primary={<Typography>{t(name)}</Typography>} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItem>
      <Collapse key={group + groupName} in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items
            .filter(key => key[1].group === group)
            .map(key => {
              // enable tooltip for features that are disabled to provide information about the feture importance
              const isFeatureEnabled = groupPermissions.includes(key[1].role);
              return <ListItem key={key[0]} slug={key[1].slug} title={key[1].title} group={group} isFeatureDisabledForUser={!isFeatureEnabled} />;
            })}
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export default DrawerGroup;
