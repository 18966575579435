import React from 'react';
import {FaDrawPolygon as ClaimBlocksIcon} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import MenuIconButton from './MenuIconButton';
import {Box} from '@material-ui/core';
import styled from 'styled-components';

const ButtonContainer = styled(Box)`
  position: absolute;
  top: 202px;
  right: 10px;
  background-color: white;
  border-radius: 3px;
`;

interface Props {
  handleMultiBlockSelection: () => void;
}

const ClaimBlocksButton = ({handleMultiBlockSelection}: Props) => {
  const {t} = useTranslation();
  return (
    <ButtonContainer>
      <MenuIconButton Icon={ClaimBlocksIcon} tooltipText={t('claim.blocks')} onClick={handleMultiBlockSelection} />
    </ButtonContainer>
  );
};

export default ClaimBlocksButton;
