import i18n from 'apps/web-portal-ui/src/i18n';
import axios from 'axios';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {Dispatch} from 'redux';
import {baseApiUrl} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {ISoilOrganicCarbonImageResDTO, ISoilOrganicCarbonImagesUI} from '@deep-planet/api-interfaces';
import {farmIdUpdate} from './farm';

interface ISoilOrganicCarbonFarmGetStart {
  type: ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_START;
}

export const soilOrganicCarbonFarmGetStart = (): ISoilOrganicCarbonFarmGetStart => ({
  type: ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_START,
});

interface ISoilOrganicCarbonFarmGetFail {
  type: ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_FAIL;
  error: unknown;
}

export const soilOrganicCarbonFarmGetFail = (error): ISoilOrganicCarbonFarmGetFail => ({
  type: ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_FAIL,
  error,
});

interface ISoilOrganicCarbonFarmGetSuccess {
  type: ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_SUCCESS;
  soilOrganicCarbonFarms: IFarm[];
  activeFarm: IFarm;
}

export const soilOrganicCarbonFarmGetSuccess = (soilOrganicCarbonFarms: IFarm[], activeFarm: IFarm): ISoilOrganicCarbonFarmGetSuccess => ({
  type: ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_SUCCESS,
  soilOrganicCarbonFarms,
  activeFarm,
});

interface ISoilOrganicCarbonImagesGetStart {
  type: ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_START;
}

export const soilOrganicCarbonImagesGetStart = (): ISoilOrganicCarbonImagesGetStart => ({
  type: ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_START,
});

interface ISoilOrganicCarbonImagesGetFail {
  type: ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_FAIL;
  error: unknown;
}

export const soilOrganicCarbonImagesGetFail = (error): ISoilOrganicCarbonImagesGetFail => ({
  type: ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_FAIL,
  error,
});

interface ISoilOrganicCarbonSetSelectedDate {
  type: ActionTypes.SOIL_ORGANIC_CARBON_SET_SELECTED_DATE;
  selectedDate: Date;
}

export const soilOrganicCarbonSetSelectedDate = (selectedDate: Date): ISoilOrganicCarbonSetSelectedDate => ({
  type: ActionTypes.SOIL_ORGANIC_CARBON_SET_SELECTED_DATE,
  selectedDate,
});

interface ISoilOrganicCarbonImagesGetSuccess {
  type: ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_SUCCESS;
  soilOrganicCarbonImages: ISoilOrganicCarbonImageResDTO[];
  activeFarmImageId: string;
  activeFarm: IFarm;
  soilOrganicCarbonTypeImages?: ISoilOrganicCarbonImagesUI[];
}
export const soilOrganicCarbonImagesGetSuccess = (
  soilOrganicCarbonImages: ISoilOrganicCarbonImageResDTO[],
  activeFarmImageId: string,
  activeFarm: IFarm,
  soilOrganicCarbonTypeImages?: ISoilOrganicCarbonImagesUI[]
): ISoilOrganicCarbonImagesGetSuccess => ({
  type: ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_SUCCESS,
  soilOrganicCarbonImages,
  activeFarmImageId,
  activeFarm,
  soilOrganicCarbonTypeImages,
});

export const getSoilOrganicCarbonImages = (farm: IFarm, startDate: string, endDate: string, organizationId?: string, disableSnackbar?: boolean) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(soilOrganicCarbonImagesGetStart());
    const url = `${baseApiUrl}/soil-organic-carbon/images/${farm.farmid}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get<ISoilOrganicCarbonImageResDTO[]>(url);
      if (!data.length) {
        !disableSnackbar && dispatch(enqueueSnackbar({message: `${i18n.t('error.soil.organic.carbon.not.found')}  ${farm.name}`, options: {variant: 'info'}}));
        dispatch(soilOrganicCarbonImagesGetFail('not found'));
      }
      dispatch(soilOrganicCarbonImagesGetSuccess(data || [], farm.id, farm));
      dispatch(farmIdUpdate(farm.id)); // global active farm
    } catch (err) {
      !disableSnackbar && dispatch(enqueueSnackbar({message: `${i18n.t('error.soil.organic.carbon.not.found')}  ${farm.name}`, options: {variant: 'info'}}));
      dispatch(soilOrganicCarbonImagesGetFail(err));
    }
  };
};

export const getSoilOrganicCarbonFarms = (allFarms: IFarm[]) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(soilOrganicCarbonFarmGetStart());
    const url = `${baseApiUrl}/farm-settings/options?portal=true`;
    try {
      const {data} = await axios.get(url);
      const farmIds = data.map(({farmId}) => farmId);
      const soilOrganicCarbonFarms = allFarms.filter(snf => farmIds.includes(snf.id));
      dispatch(soilOrganicCarbonFarmGetSuccess(soilOrganicCarbonFarms, soilOrganicCarbonFarms[0]));
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'info'}}));
      dispatch(soilOrganicCarbonFarmGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof soilOrganicCarbonFarmGetStart>
  | ReturnType<typeof soilOrganicCarbonFarmGetSuccess>
  | ReturnType<typeof soilOrganicCarbonFarmGetFail>
  | ReturnType<typeof soilOrganicCarbonImagesGetStart>
  | ReturnType<typeof soilOrganicCarbonImagesGetFail>
  | ReturnType<typeof soilOrganicCarbonImagesGetSuccess>
  | ReturnType<typeof soilOrganicCarbonSetSelectedDate>
  | ReturnType<typeof farmIdUpdate>;
