import React from 'react';
import styled from 'styled-components';
import {Box, Typography} from '@material-ui/core';
import {Variant} from '@material-ui/core/styles/createTypography';
import {useTranslation} from 'react-i18next';

const LoaderContainer = styled(Box)`
  height: stretch;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  font-weight: 500 !important;
  position: relative;
  top: -5%;
`;

interface Props {
  name: string;
  variant?: Variant;
}

const NoInfoOverlay = ({name, variant = 'h6'}: Props) => {
  const {t} = useTranslation();
  return (
    <LoaderContainer>
      <StyledTypography color="primary" variant={variant}>
        {`${name} ${t('dashboard.not.available')}`}
      </StyledTypography>
    </LoaderContainer>
  );
};

export default NoInfoOverlay;
