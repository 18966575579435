import React, {useCallback} from 'react';
import {Box, TextField, Typography, TypographyProps, Button, withStyles, BoxProps, FormControl, InputLabel, MenuItem, Select, SelectProps} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import {IFarm} from '../../../store/reducers/farm';
import theme from '../../../utilities/theme';
import {ALL, getOrganizationFarms, getUserFarms, NOTE_PUBLIC} from './getUserFarms';
import {NoteActionPopper, NoteDeleteDialog} from './NoteDetailsAction';
import {INoteForm, ISelectedFile} from './withEdit';
import SimpleDatePicker from '../../../components/UI/Pickers/SimpleDatePicker';
import {useDispatch, useSelector} from 'react-redux';
import {ICreateNoteDTO, IFarmEntity, IMarker, INoteEntity, INoteTypeEntity, IPolygonEntity} from '@deep-planet/api-interfaces';
import {noteAttachmentDeleteSelector, notePutSelector, noteAttachmentsAddSelector, organizationsSelector} from '../../../store/selectors';
import {chain} from 'lodash';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import {deleteNoteAttachment, enqueueSnackbar} from '../../../store/actions';
import Spinner from '../../../components/UI/Spinner';
import ClearIcon from '@material-ui/icons/Clear';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import NoteShareModal from './NoteShareModal';
import {getUserNamesForOrganization} from './OrganizationUsers';

interface Props extends WithUserProps {
  summary: string;
  location: string;
  description: string;
  selectedAssignee: string;
  userNames: string[];
  errors: INoteForm;
  selectedType: INoteTypeEntity;
  selectedDate: Date;
  selectedFarm: IFarm | IFarmEntity;
  selectedPolygon: IPolygonEntity;
  setErrors: React.Dispatch<React.SetStateAction<INoteForm>>;
  handleChangeSummary: (value: string) => void;
  handleAssigneeSelection: (value: string) => void;
  handleChangeLocation: (value: string) => void;
  handleChangeDescription: (value: string) => void;
  handleTypeSelection: (value: string) => void;
  handleDateSelection: (value: Date) => void;
  handleFarmSelection: (value: string) => void;
  handlePolygonSelection: (value: string) => void;
  note: INoteEntity;
  types: INoteTypeEntity[];
  farms: IFarm[];
  closeDetails: () => void;
  isDialogOpen: boolean;
  isShareModalOpen: boolean;
  handleOpenShareModal: () => void;
  handleCloseShareModal: () => void;
  handleCloseDialog: () => void;
  handleOpenDialog: () => void;
  isActionsOpen: boolean;
  isShareActionsOpen: boolean;
  handleCloseAction: () => void;
  handleCloseShareAction: () => void;
  handleOpenAction: () => void;
  handleOpenShareAction: () => void;
  isDeleteLoading: boolean;
  handleNoteDelete: (noteId: string) => void;
  handleNoteUpdate: (noteId: string, params: ICreateNoteDTO, files: ISelectedFile[]) => void;
  selectedFiles: ISelectedFile[];
  handleNoteAttachmentChange: (files: ISelectedFile[]) => void;
  handleNoteAttachmentCancel: (nameToCancel: string) => void;
  //note update 13.01.2022
  selectedOrganization: string;
  selectedVisibility: string;
  selectedUserName: string;
  handleOrganizationSelection: (value: string) => void;
  handleUserNameSelection: (Value: string) => void;
  padding?: number;
  marker?: IMarker;
  isMarkerNote?: boolean;
  disableDeleteButton?: boolean;
  handleDeleteMarker?: () => void;
  claimTypes?: INoteTypeEntity[];
  selectedClaimType?: INoteTypeEntity;
  handleClaimTypeSelection?: (value: number) => void;
  readOnly?: boolean;
}

const TitleWrapper = styled(Box)<BoxProps>`
  align-items: baseline !important;
  ${theme.breakpoints.down('sm')} {
    align-items: flex-start !important;
  }
`;

interface BoldTypographyProps extends TypographyProps {
  disabled?: boolean;
}

const BoldTypography = styled(Typography)<BoldTypographyProps>`
  font-weight: 500 !important;
  color: ${props => (props.disabled ? 'grey' : 'default')};
`;

const StyledSelect = styled(Select)<SelectProps>`
  width: auto;
`;

const InputWrapperBox = styled(Box)<BoxProps>`
  margin-right: 16px;
  width: 200px;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

const IconsWrapper = styled(Box)`
  flex-direction: row;
  ${theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

const StyledTextField = styled(TextField)`
  & textarea {
    font-size: 20px;
    font-weight: 500;
  }
`;

const StyledInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary,
    },
    '& .MuiInput-underline:before': {
      border: 'unset',
    },
  },
})(StyledTextField);

const DropArea = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: auto;
  width: 100%;
  padding: 18.5px 14px;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('sm')} {
    height: 100%;
    width: auto;
  }
`;

const FlexWrap = styled(Box)`
  display: flex;
  align-items: baseline;
  ${theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const MAX_FILE_SIZE = 1024 * 1024 * 15; // 15MB
const ACCEPTED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.xls', '.pdf', '.doc', '.docx', '.xlsx', '.kml', '.kmz', '.shp', '.dbf', '.prj', '.cpg', '.shx', '.sbn', '.sbx'];
const MAX_FILE_COUNT = 10;

const NoteDetails = ({
  note,
  closeDetails,
  isDialogOpen,
  isShareModalOpen,
  handleOpenShareModal,
  handleCloseShareModal,
  isActionsOpen,
  isShareActionsOpen,
  handleCloseAction,
  handleCloseShareAction,
  handleOpenAction,
  handleOpenShareAction,
  handleCloseDialog,
  handleOpenDialog,
  isDeleteLoading,
  handleNoteDelete,
  farms,
  types,
  claimTypes,
  handleNoteUpdate,
  summary,
  location,
  description,
  errors,
  selectedType,
  selectedClaimType,
  selectedDate,
  selectedFarm,
  selectedPolygon,
  setErrors,
  handleChangeSummary,
  handleChangeLocation,
  handleChangeDescription,
  handleTypeSelection,
  handleDateSelection,
  handleFarmSelection,
  handlePolygonSelection,
  selectedFiles,
  handleNoteAttachmentCancel,
  handleNoteAttachmentChange,
  selectedAssignee,
  user,
  selectedOrganization,
  selectedVisibility,
  selectedUserName,
  handleOrganizationSelection,
  handleUserNameSelection,
  padding,
  marker,
  isMarkerNote,
  handleDeleteMarker,
  disableDeleteButton,
  handleClaimTypeSelection,
  readOnly = false,
}: Props) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const shareAnchorRef = React.useRef<HTMLButtonElement>(null);
  const {loading: isUpdateLoading} = useSelector(notePutSelector);
  const {loading: isAttachmentUploadLoading} = useSelector(noteAttachmentsAddSelector);
  const noteAttachmentDeletes = useSelector(noteAttachmentDeleteSelector);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const organizations = useSelector(organizationsSelector);
  const handleOpenActions = () => handleOpenAction();
  const handleCloseActions = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    handleCloseAction();
  };

  const handleOpenShareActions = () => handleOpenShareAction();
  const handleCloseShareActions = (event: React.MouseEvent<EventTarget>) => {
    if (shareAnchorRef.current && shareAnchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    handleCloseShareAction();
  };
  const handleDeleteClick = () => handleNoteDelete(note.id);

  const onDrop = useCallback(
    acceptedFiles => {
      const newSize = acceptedFiles.reduce((acc, item) => item.size + acc, 0);
      const previousSize = note.attachments.reduce((acc, item) => Number(item.size) + acc, 0);
      const size = newSize + previousSize;
      if (note.attachments.length + acceptedFiles.length <= MAX_FILE_COUNT && size <= MAX_FILE_SIZE) {
        handleNoteAttachmentChange(acceptedFiles);
      } else if (note.attachments.length + acceptedFiles.length > MAX_FILE_COUNT) {
        dispatch(enqueueSnackbar({message: t('file.max.number', {number: 10}), options: {variant: 'error'}}));
      } else if (size > MAX_FILE_SIZE) {
        dispatch(enqueueSnackbar({message: t('file.max.size'), options: {variant: 'error'}}));
      }
    },
    [dispatch, handleNoteAttachmentChange, note.attachments, t]
  );

  const onDropRejected = (e: {errors: {message: string}[]}[]) => {
    const errorsArray = e.map(({errors}) => errors.map(({message}) => message));
    const errors = chain(errorsArray).flatten().uniq().value();
    for (const error of errors) {
      dispatch(enqueueSnackbar({message: error, options: {variant: 'error'}}));
    }
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDropRejected,
    onDrop,
    accept: ACCEPTED_EXTENSIONS,
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_FILE_COUNT,
    disabled: isUpdateLoading || isAttachmentUploadLoading,
  });

  const handleUpdateClick = () => {
    if (!selectedAssignee) {
      return setErrors({...errors, assignee: {value: true, text: t('forms.mandatory.to.fill')}});
    } else {
      setErrors({...errors, assignee: {value: false, text: ''}});
    }
    if (summary.length) {
      const params: ICreateNoteDTO = {
        summary,
        description,
        location,
        typeId: selectedType.id,
        date: selectedDate,
        polygonId: selectedPolygon?.id,
        farmId: selectedFarm?.id,
        attachments: note.attachments,
        toUserName: selectedVisibility === NOTE_PUBLIC ? 'ALL' : selectedAssignee,
        organizationId: selectedOrganization || null,
        claimTypeId: selectedClaimType?.id,
      };
      handleNoteUpdate(note.id, params, selectedFiles);
    } else {
      setErrors({...errors, summary: {value: true, text: t('forms.mandatory.to.fill')}});
    }
  };

  const getFarms = () => {
    return selectedAssignee !== ALL ? getUserFarms(organizations, selectedAssignee, selectedOrganization) : getOrganizationFarms(organizations, selectedOrganization);
  };
  //0 doesn't validate true/false -  so check for zero
  const boxPadding = padding === 0 ? 0 : 4;
  return (
    <Box p={boxPadding} height="100%" overflow="auto">
      <form style={{height: 'inherit'}}>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <Box>
            <TitleWrapper display="flex" justifyContent="space-between">
              <StyledInput
                multiline
                fullWidth
                style={{padding: '12px 0'}}
                value={summary}
                onChange={({target: {value}}) => handleChangeSummary(value)}
                required
                error={errors.summary.value}
                helperText={errors.summary.value ? errors.summary.text : ''}
                disabled={readOnly}
              />
              {!isMarkerNote && (
                <IconsWrapper display="flex">
                  {!readOnly && (
                    <>
                      <IconButton ref={shareAnchorRef} onClick={handleOpenShareActions}>
                        <ShareIcon />
                      </IconButton>
                      <IconButton ref={anchorRef} onClick={handleOpenActions}>
                        <MoreVertIcon />
                      </IconButton>
                      <NoteActionPopper open={isActionsOpen} handleClose={handleCloseActions} handleDialogOpen={handleOpenDialog} anchorEl={anchorRef} menuTitle={'Delete'} />
                      <NoteActionPopper open={isShareActionsOpen} handleClose={handleCloseShareActions} handleDialogOpen={handleOpenShareModal} anchorEl={shareAnchorRef} menuTitle={'Email'} />
                      <NoteDeleteDialog open={isDialogOpen} handleClose={handleCloseDialog} handleSubmit={handleDeleteClick} isLoading={isDeleteLoading} />
                      {isShareModalOpen && <NoteShareModal isOpen={isShareModalOpen} handleClose={handleCloseShareModal} noteId={note.id} />}
                    </>
                  )}
                  <IconButton onClick={closeDetails}>
                    <CloseIcon />
                  </IconButton>
                </IconsWrapper>
              )}
            </TitleWrapper>
            <FlexWrap>
              {types && (
                <InputWrapperBox>
                  <FormControl fullWidth disabled={isUpdateLoading}>
                    <InputLabel id="type-label">Type</InputLabel>
                    <StyledSelect labelId="type-label" value={selectedType?.name} name="type" onChange={({target: {value}}) => handleTypeSelection(value as string)} disabled={readOnly}>
                      {types.map(({id, name}) => (
                        <MenuItem key={id} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </InputWrapperBox>
              )}
              <InputWrapperBox>
                <SimpleDatePicker value={selectedDate} onChange={handleDateSelection} label={'Note Date'} disabled={isUpdateLoading || isMarkerNote || readOnly} />
              </InputWrapperBox>
            </FlexWrap>
            <FlexWrap>
              <FlexWrap mt={2}>
                <InputWrapperBox>
                  <FormControl fullWidth disabled={isUpdateLoading || isMarkerNote}>
                    <InputLabel id="organization-label">Organization</InputLabel>
                    <StyledSelect
                      labelId="organization-label"
                      value={selectedOrganization || ''}
                      name="organization"
                      onChange={({target: {value}}) => handleOrganizationSelection(value as string)}
                      disabled={readOnly}
                    >
                      {organizations &&
                        organizations.map(o => (
                          <MenuItem key={o.id} value={o.id}>
                            {o.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                </InputWrapperBox>
              </FlexWrap>
              <FlexWrap mt={2}>
                <InputWrapperBox>
                  <FormControl fullWidth disabled={isUpdateLoading}>
                    <InputLabel id="username-label">Members</InputLabel>
                    <StyledSelect
                      labelId="username-label"
                      value={selectedUserName ? selectedUserName : ''}
                      name="username"
                      onChange={({target: {value}}) => handleUserNameSelection(value as string)}
                      disabled={readOnly}
                    >
                      {selectedOrganization ? getUserNamesForOrganization(organizations, selectedOrganization) : <MenuItem>{''}</MenuItem>}
                    </StyledSelect>
                  </FormControl>
                </InputWrapperBox>
              </FlexWrap>
            </FlexWrap>
            <FlexWrap>
              <FlexWrap mt={2}>
                <InputWrapperBox>
                  <InputLabel id="assignee-label">Assignee</InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    required
                    placeholder="assignee"
                    value={selectedAssignee ? selectedAssignee : ''}
                    error={errors.assignee.value}
                    helperText={errors.assignee.value ? errors.assignee.text : ''}
                  />
                </InputWrapperBox>
              </FlexWrap>
              <FlexWrap mt={2}>
                <InputWrapperBox>
                  <BoldTypography variant="subtitle1" disabled={readOnly}>
                    {'Note Visibilty'}
                  </BoldTypography>
                  <TextField disabled fullWidth placeholder="Visibility" value={selectedVisibility ? selectedVisibility : ''} />
                </InputWrapperBox>
              </FlexWrap>
            </FlexWrap>
            <FlexWrap mt={2}>
              <InputWrapperBox>
                <FormControl fullWidth disabled={isUpdateLoading || isMarkerNote}>
                  <InputLabel id="farm-label">Farm</InputLabel>
                  <StyledSelect labelId="farm-label" value={selectedFarm?.id || ''} name="farm" onChange={({target: {value}}) => handleFarmSelection(value as string)} disabled={readOnly}>
                    {organizations &&
                      getFarms()?.map(({id, name}) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                </FormControl>
              </InputWrapperBox>
              {/** if note is type of claimed blocks then disable the block selection*/}
              {!selectedClaimType && (
                <InputWrapperBox>
                  <FormControl fullWidth disabled={!selectedFarm || isUpdateLoading || isMarkerNote}>
                    <InputLabel id="block-label">Block</InputLabel>
                    <StyledSelect labelId="block-label" value={selectedPolygon?.id || ''} name="block" onChange={({target: {value}}) => handlePolygonSelection(value as string)} disabled={readOnly}>
                      {selectedFarm?.polygons &&
                        selectedFarm.polygons?.map(({id, name}) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                </InputWrapperBox>
              )}
              {claimTypes && selectedClaimType && (
                <InputWrapperBox>
                  <FormControl fullWidth disabled={isUpdateLoading}>
                    <InputLabel id="claim-type-label">Block Status</InputLabel>
                    <StyledSelect
                      labelId="claim-type-label"
                      value={selectedClaimType?.id}
                      name="claimType"
                      onChange={({target: {value}}) => handleClaimTypeSelection(value as number)}
                      disabled={readOnly}
                    >
                      {claimTypes.map(({id, name}) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </InputWrapperBox>
              )}
            </FlexWrap>
            <Box mt={2} mb={2}>
              <BoldTypography variant="subtitle1" disabled={readOnly}>
                {'Location description'}
              </BoldTypography>
              <TextField
                multiline
                fullWidth
                disabled={isUpdateLoading || readOnly}
                variant="outlined"
                value={location}
                placeholder={t('note.label.location.placeholder')}
                onChange={({target: {value}}) => handleChangeLocation(value)}
                error={errors.location.value}
                helperText={errors.location.value ? errors.location.text : ''}
              />
            </Box>
            <Box mt={2}>
              <BoldTypography variant="subtitle1" disabled={readOnly}>
                {'Description'}
              </BoldTypography>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                placeholder={t('note.label.description.placeholder')}
                value={description}
                onChange={({target: {value}}) => handleChangeDescription(value)}
                error={errors.description.value}
                helperText={errors.description.value ? errors.description.text : ''}
                disabled={readOnly}
              />
            </Box>
            {!readOnly && (
              <Box mt={2}>
                <BoldTypography variant="subtitle1">{'Attachments'}</BoldTypography>
                <DropArea {...getRootProps()}>
                  {isAttachmentUploadLoading ? (
                    <Spinner size={20} />
                  ) : (
                    <>
                      <input {...getInputProps()} />
                      {isDragActive ? <Typography>{t('setting.create.farm.step.uploading.drop')}</Typography> : <Typography>{t('setting.create.farm.step.uploading.click')}</Typography>}
                    </>
                  )}
                </DropArea>
              </Box>
            )}
            <Box display="flex" flexDirection="column" mt={2} mb={2}>
              {selectedFiles.map(file => (
                <Box alignItems="center" display="flex" key={file.name}>
                  <Typography>{file.name}</Typography>
                  <IconButton disabled={isUpdateLoading || readOnly} onClick={() => handleNoteAttachmentCancel(file.name)}>
                    <ClearIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Box mt={2}>
              <Box display="flex" flexDirection="column">
                {note.attachments.map(attachment => {
                  const noteAttachmentDelete = noteAttachmentDeletes.find(({noteId, attachmentId}) => noteId === note.id && attachmentId === attachment.id);
                  const isLoading = !!noteAttachmentDelete;
                  return (
                    <Box display="flex" alignItems="center" key={attachment.url}>
                      <a href={attachment.url} target="_blank" rel="noopener noreferrer" download>
                        {attachment.name}
                      </a>
                      <IconButton onClick={() => dispatch(deleteNoteAttachment(note.id, attachment.id, marker, isMarkerNote))} disabled={isLoading || isUpdateLoading}>
                        <DeleteIcon />
                      </IconButton>
                      {isLoading && <Spinner size={15} color="primary" />}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          {!readOnly && (
            <Box mt={2} marginLeft="50%" mb={4} display="flex" justifyContent={isMarkerNote ? 'space-evenly' : 'flex-end'}>
              <Button variant="contained" color="primary" disabled={isUpdateLoading} onClick={handleUpdateClick} style={{height: 'auto'}}>
                Update
              </Button>
              {isMarkerNote && (
                <Button variant="contained" color="primary" disabled={isUpdateLoading || disableDeleteButton} onClick={handleDeleteMarker} style={{height: 'auto'}}>
                  Delete
                </Button>
              )}
              {isUpdateLoading && <Spinner size={15} color="primary" />}
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default withUser(NoteDetails);
