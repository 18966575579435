import React, {useState, useEffect} from 'react';
import {Box, Button, Divider, Tooltip, Typography, makeStyles} from '@material-ui/core';
import MailIcon from '@material-ui/icons/MailOutline';
import DownloadIcon from '@material-ui/icons/GetApp';
import RestoreIcon from '@material-ui/icons/Restore';
import {useTranslation} from 'react-i18next';
import {IFarm} from '../../../store/reducers/farm';
import {ICreateNoteDTO, INoteTypeEntity, IPolygonEntity} from '@deep-planet/api-interfaces';
import CreateNoteModal from '../Notes/CreateNoteModal';
import {baseApiUrl} from '../../../config/const';
import {useHttp} from '../../../hooks/http';
import {useDispatch, useSelector} from 'react-redux';
import {notePostSelector} from '../../../store/selectors';
import {ISelectedFile} from '../Notes/withEdit';
import {createNote} from '../../../store/actions';
import {getDateString} from '../../../helpers/dateHelpers';

interface Props {
  farm: IFarm;
  featureName?: string;
  selectedBlocks: IPolygonEntity[];
  isMultiBlockSelection: boolean;
  handleReset: () => void;
}

const BlockSelectionNote = ({farm, selectedBlocks, isMultiBlockSelection, handleReset}: Props) => {
  const {t} = useTranslation();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isTypesLoading, types] = useHttp<INoteTypeEntity[]>(`${baseApiUrl}/note/types`);
  const [isClamingBlockTypesLoading, claimingBlockTypes] = useHttp<INoteTypeEntity[]>(`${baseApiUrl}/note/claiming-block-types`);
  const {loading: isPostLoading} = useSelector(notePostSelector);
  const [isModalOpen, SetIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const closeModal = () => SetIsModalOpen(false);

  const useStyles = makeStyles(theme => ({
    noteButton: {
      position: 'absolute',
      bottom: '4%',
      left: '50%',
      display: 'flex',
      alignItems: 'center',
      transform: 'translateX(-50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: 10,
      borderRadius: 3,
    },

    message: {
      position: 'absolute',
      bottom: '4%',
      left: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transform: 'translateX(-50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: 10,
      borderRadius: 3,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // toggle the model dialog box for creating a note
  const handleNote = () => {
    SetIsModalOpen(!isModalOpen);
  };

  // Create a note
  const handleNoteSubmit = (form: ICreateNoteDTO, files: ISelectedFile[]) => {
    dispatch(createNote({...form, claimBlocksNote: true}, files, closeModal, handleReset));
  };

  const handleCloseModal = () => {
    SetIsModalOpen(false);
  };

  // Download selected blocks converted into geojson format
  const handleDownload = () => {
    if (!selectedBlocks.length) return;
    const geoJson = {
      type: 'FeatureCollection',
      features: selectedBlocks.map(polygon => ({
        ...polygon.geoJson,
        properties: {...polygon.geoJson.properties, blockName: polygon.name},
      })),
    };
    // Convert GeoJSON object to a Blob
    const blob = new Blob([JSON.stringify(geoJson, null, 2)], {type: 'application/json'});
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create a link element to download a file
    const link = document.createElement('a');
    link.href = url;
    link.download = `ClaimBlocks_${farm.name}_${getDateString(new Date())}T${new Date().getTime()}.geojson`;
    document.body.appendChild(link);
    link.click();
    // Clean up by revoking the object URL and removing the anchor element
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  // block names
  const blockNames = selectedBlocks && selectedBlocks && selectedBlocks.map(sb => sb.name);
  return (
    <>
      {/*
       * show bottom menu when user select a block to claim
       */}
      {isMultiBlockSelection && selectedBlocks?.length && (
        <Box display="flex" className={classes.noteButton} boxShadow={1}>
          {/** reset button */}
          <Tooltip title={t('reset')} placement={'left'} arrow>
            <Button aria-label={t('reset')} onClick={handleReset} style={{color: 'primary'}}>
              <RestoreIcon color="secondary" fontSize="large" />
            </Button>
          </Tooltip>
          {/** note button for creating a note */}
          <Tooltip title={t('claim.blocks.send.note')} placement={'top'} arrow>
            <Button aria-label={t('claim.blocks.send.note')} onClick={handleNote} style={{color: 'primary'}}>
              <MailIcon color="secondary" fontSize="large" />
            </Button>
          </Tooltip>
          {/** download selected files */}
          <Tooltip title={t('download')} placement={'right'} arrow>
            <Button aria-label={t('download')} onClick={handleDownload} style={{color: 'primary'}}>
              <DownloadIcon color="secondary" fontSize="large" />
            </Button>
          </Tooltip>
          <Divider style={{height: '1px'}} orientation="horizontal" variant="fullWidth" flexItem />
        </Box>
      )}
      {/** display a message until the user select atleast one block */}
      {isMultiBlockSelection && !selectedBlocks?.length && (
        <Box display="flex" flexDirection="column" className={classes.noteButton} boxShadow={1}>
          <Typography style={{color: 'white'}}>{t('claim.blocks.message')}</Typography>
          <Divider style={{height: '1px'}} orientation="horizontal" variant="fullWidth" flexItem />
        </Box>
      )}
      {/**open modal dialog box for creating a note with selected box */}
      {isModalOpen && (
        <CreateNoteModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          types={types}
          farms={[farm]}
          submitLoading={isPostLoading}
          handleSubmit={handleNoteSubmit}
          userNames={['support@deepplanet.ai']}
          modalTitle={t('Claim Blocks')}
          claimBlockNote={true}
          blockNames={blockNames}
          claimedBlocks={selectedBlocks}
          claimTypes={claimingBlockTypes}
        />
      )}
    </>
  );
};

export default BlockSelectionNote;
