import React, {ChangeEvent} from 'react';
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import {getUserGroups} from '../../../containers/authHOC';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {IFarm} from '../../../store/reducers/farm';
import * as Sentry from '@sentry/react';
import {useTranslation} from 'react-i18next';

interface Props extends WithUserProps {
  activeFarm: string;
  allFarms: IFarm[];
  handleChange: (farmId: string) => void;
  disabled?: boolean;
}

const FarmPicker = ({activeFarm, allFarms, handleChange, user, disabled = false}: Props) => {
  const {t} = useTranslation();
  const onChange = (event: ChangeEvent<{name?: string; value: string}>) => handleChange(event.target.value);
  const isSuperUser = getUserGroups(user).includes('SUPERADMIN');
  return (
    <FormControl style={{width: '100%'}}>
      <InputLabel id="farm-selection">{t('farm.title')}</InputLabel>
      <Select labelId="farm-selection" value={activeFarm} onChange={onChange} displayEmpty name="activeFarm" style={{width: '100%'}} fullWidth disabled={disabled}>
        {!!allFarms &&
          allFarms.map(({name, farmid, ownerOrganization}, idx) => {
            return (
              <MenuItem key={idx} value={farmid}>
                {`${name} ${(isSuperUser && `(${ownerOrganization?.name})`) || ''}`}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default withUser(FarmPicker);
