import React from 'react';
import styled from 'styled-components';
import {Paper} from '@material-ui/core';

const StyledPaper = styled(Paper)<{width: string; height: string; marginright: string; paddinginline: string; padding: string; overflow: string}>`
  min-width: ${props => `calc(${props.width} - ${props.marginright})`};
  max-width: ${props => `calc(${props.width} - ${props.marginright})`};
  height: ${props => props.height};
  margin-right: ${props => props.marginright};

  overflow-y: auto;

  background-color: silver;
  border-radius: 2px;
  margin-bottom: 10px;
  padding-block: 8px;
  padding-inline: ${props => props.paddinginline || '12px'};
  padding: ${props => props.padding};
  overflow: ${props => props.overflow};

  @media (max-width: 950px) {
    width: 100%;
  }
`;

interface Props {
  width: string;
  height: string;
  square?: boolean;
  right?: boolean;
  paddingInline?: string;
  padding?: string;
  children?: React.ReactNode;
  overflow?: string;
}

const Panel = ({width, height, square = false, right = false, children, paddingInline, padding, overflow}: Props) => {
  const marginRight = right ? '10px' : '0px';

  return (
    <StyledPaper elevation={0} width={width} height={height} marginright={marginRight} square={square} paddinginline={paddingInline} padding={padding} overflow={overflow}>
      {children}
    </StyledPaper>
  );
};

export default Panel;
