import {IForecastWeather} from '@deep-planet/api-interfaces';
import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {handleDateFormat} from '../../../../../helpers/dateHelpers';
import WeatherIcon from '../../../WeatherForecastPage/WeatherIcon';
import {useMeasurementConversion} from '../../../../../hooks/useMeasurementConversion';
import {useTranslation} from 'react-i18next';

const Container = styled.div`
  margin-block: 7px;
`;

const WeatherDateContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: fit-content;
  min-width: 92px;
`;

const DateFont = styled(Typography)`
  font-weight: 600;
`;

const Icon = styled.div`
  & svg {
    width: 40px;
    height: 40px;
  }
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const DegreesTypography = styled(Typography)`
  min-width: 110px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 5%;
  width: 100%;

  @media (max-width: 950px) {
    overflow-x: scroll;
  }
`;

const Col = styled.div`
  min-height: 50px;
  margin-inline: 20px;
`;

const SeparatorLine = styled.span`
  color: black;
  margin-inline: 5px;
`;

interface Props {
  forecastWeather: IForecastWeather[];
}

const WeatherForecast = ({forecastWeather}: Props) => {
  const {t} = useTranslation();
  const {temperatureUnit, convertTemperature, round} = useMeasurementConversion();
  const todayDate = new Date();

  return (
    <Container>
      <Row>
        {!!forecastWeather &&
          forecastWeather.map((dateWeather, index) => {
            const weatherDate = new Date(dateWeather.epochTime * 1000); // Fetch date of current weather data
            return (
              <Col key={index}>
                <WeatherDateContainer>
                  <DateFont variant="body1">
                    <strong>
                      {
                        todayDate.getDate() === weatherDate.getDate()
                          ? t('dashboard.weather.today') // If date is today, display "Today"
                          : todayDate.getDate() + 1 === weatherDate.getDate()
                          ? t('dashboard.weather.tomorrow') // If date is tomorrow, display "Tomorrow"
                          : handleDateFormat(dateWeather.epochTime * 1000) // Else, show date
                      }
                    </strong>
                  </DateFont>
                  <Icon>
                    <WeatherIcon icon={dateWeather.day.icon} />
                  </Icon>
                  <BoxRow>
                    <DegreesTypography variant="body1" color="primary">
                      {/* Display min and max weather values */}
                      <strong>
                        {round(convertTemperature(dateWeather.temperature.minimum.value), 0)} °{temperatureUnit[0].toUpperCase()}
                        <SeparatorLine>|</SeparatorLine>
                        {round(convertTemperature(dateWeather.temperature.maximum.value), 0)} °{temperatureUnit[0].toUpperCase()}
                      </strong>
                    </DegreesTypography>
                  </BoxRow>
                </WeatherDateContainer>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

export default WeatherForecast;
