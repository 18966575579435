import React from 'react';
import {Box} from '@material-ui/core';
import ExpandMapIcon from '@material-ui/icons/ZoomOutMap';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import styled from 'styled-components';
import Map from '../../../../Dashboard/Map';
import MenuIconButton from 'apps/web-portal-ui/src/app/components/UI/UtilityMenu/MenuIconButton';

const IconButtonWrapper = styled(Box)`
  background-color: white;
  border-radius: 2px;
`;

const ExpandButtonContainer = styled(IconButtonWrapper)`
  position: absolute;
  top: 9px;
  right: 10px;
`;

const mapContainerStyles = {
  width: '100%',
  height: '83%',
};

// Turn-off all options provided by Google Maps API
const options: google.maps.MapOptions = {
  mapTypeId: 'satellite',
  disableDefaultUI: true, // Disables all default UI elements
  zoomControl: false, // Disables zoom controls
  fullscreenControl: false, // Disables fullscreen control
  mapTypeControl: false, // Disables map/satellite toggle
  streetViewControl: false, // Disables street view control
  scaleControl: false, // Disables scale
  styles: [
    {featureType: 'all', elementType: 'labels.text', stylers: [{visibility: 'off'}]},
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}],
    },
  ],
};

interface Props {
  selectedFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  handleOnClickPolygon: (polygon: IPolygonEntity) => void;
  toggleShowMapWindow: () => void;
  handleOpenModal: () => void;
  takeScreenshot: () => void;
  handleSelectedPolygon: (selectedBlock: polygonInfoWindow) => void;
  polygonClicked: boolean;
}

const MapMinimised = ({selectedFarm, selectedPolygon, handleOnClickPolygon, toggleShowMapWindow, handleOpenModal, takeScreenshot, handleSelectedPolygon, polygonClicked}: Props) => {
  return selectedFarm ? (
    <Map
      farm={selectedFarm}
      polygons={selectedFarm?.polygons}
      center={selectedFarm.farmCenter}
      bbox={selectedFarm.bbox}
      selectedPolygon={selectedPolygon}
      polygonClicked={polygonClicked}
      handleSelectedPolygon={handleSelectedPolygon}
      handleOnClickPolygon={handleOnClickPolygon}
      takeScreenshot={takeScreenshot}
      yieldPredictions={[]}
      containerStyles={mapContainerStyles}
      options={options}
      disableInfoBox
      disableScreenshot
    >
      <ExpandButtonContainer>
        <MenuIconButton Icon={ExpandMapIcon} tooltipText="Expand" onClick={handleOpenModal} />
      </ExpandButtonContainer>
    </Map>
  ) : null;
};

export default MapMinimised;
