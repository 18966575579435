import React from 'react';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {FormControlLabel, Checkbox, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Skeleton} from '@material-ui/lab';
import {PanelTitle} from '../../../../../components/UI/DashboardPanel/PanelTitle';
import {useTranslation} from 'react-i18next';
import {useDataRequirements} from 'apps/web-portal-ui/src/app/hooks/useDataRequirements';
import NoInfoOverlay from '../../../../../components/UI/DashboardPanel/NoInfoOverlay';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCheckbox = styled(Checkbox)`
  background-color: transparent;
  &:hover {
    cursor: default;
    background-color: transparent;
  }
`;

const StyledFormControl = styled(FormControlLabel)`
  &:hover {
    cursor: default;
  }
`;

const Label = styled(Typography)`
  color: black;
`;

const Container = styled.div`
  height: 90%;
`;

interface Props {
  selectedOrganization: IOrganizationEntity;
  isMobile: boolean;
}
const DataRequirementsPanel = ({selectedOrganization, isMobile}: Props) => {
  const {t} = useTranslation();
  const {dataRequirements, loadingDataRequirements, errorDataRequirements} = useDataRequirements(selectedOrganization);

  return (
    <Panel width={'50%'} height={'200px'} right={!isMobile}>
      <PanelTitle>{t('data.requirements')}</PanelTitle>
      <Container>
        {errorDataRequirements ? (
          <NoInfoOverlay name={t('data.requirements')} variant="body2" />
        ) : (
          <CheckboxContainer>
            {Object.keys(dataRequirements).map((key, index) => {
              return loadingDataRequirements ? (
                <Skeleton key={index} height={42} />
              ) : (
                <StyledFormControl
                  key={index}
                  control={<StyledCheckbox disabled checked={dataRequirements[key]} disableRipple disableFocusRipple disableTouchRipple color="primary" />}
                  label={<Label variant="body1">{t(`data.requirements.${key}`)}</Label>}
                />
              );
            })}
          </CheckboxContainer>
        )}
      </Container>
    </Panel>
  );
};

export default DataRequirementsPanel;
