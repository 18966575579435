import React, {useState} from 'react';
import {Button, useMediaQuery} from '@material-ui/core';
import styled from 'styled-components';
import FileManagerIcon from '@material-ui/icons/InsertDriveFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {IFileManagerDTO} from '@deep-planet/api-interfaces';
import {FileManager} from '../../../../../components/UI/FileManager/FileManager';
import {filePostSelector} from 'apps/web-portal-ui/src/app/store/selectors';
import {ISelectedFile} from '../../../Notes/withEdit';
import {createFile} from 'apps/web-portal-ui/src/app/store/actions/file';
import DownloadMeanDataModal from 'apps/web-portal-ui/src/app/components/UI/Tools/DownloadMeanData';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {PanelTitle} from '../../../../../components/UI/DashboardPanel/PanelTitle';

const ToolRow = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  margin-block: 10px;
  margin-inline: 7%;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ToolPanel = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const buttonSize = useMediaQuery('(max-width:1450px') ? 'small' : 'medium';

  const {loading: isPostFileLoading} = useSelector(filePostSelector);
  const isLoading = false;

  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [isFileManagerModalOpen, setIsFileManagerModalOpen] = useState(false);
  const [isDownloadMeanDataModalOpen, setIsDownloadMeanDataModalOpen] = useState(false);

  const handleFileUploadButton = () => {
    setIsFileUploadModalOpen(!isFileUploadModalOpen);
  };
  const handleFileManagerButton = () => {
    setIsFileManagerModalOpen(!isFileUploadModalOpen);
  };

  const handleDownloadMeanData = () => {
    // download mean data
    setIsDownloadMeanDataModalOpen(!isDownloadMeanDataModalOpen);
  };

  const closeModal = () => {
    setIsFileUploadModalOpen(false);
    setIsFileManagerModalOpen(false);
    setIsDownloadMeanDataModalOpen(false);
  };

  const handleFileSubmit = (form: IFileManagerDTO, files: ISelectedFile[]) => {
    dispatch(createFile(form, files, closeModal));
  };

  return (
    <>
      <Panel width={'100%'} height={'120px'} overflow="hidden">
        <PanelTitle>{t('tools')}</PanelTitle>
        <ToolRow>
          <ButtonContainer>
            <StyledButton color="primary" size={buttonSize} onClick={handleFileManagerButton}>
              <ButtonContent>
                <FileManagerIcon />
                {t('dashboard.tools.file.manager')}
              </ButtonContent>
            </StyledButton>
          </ButtonContainer>
          <ButtonContainer>
            <StyledButton color="primary" size={buttonSize} onClick={handleFileUploadButton}>
              <ButtonContent>
                <CloudUploadIcon />
                {t('dashboard.tools.file.upload')}
              </ButtonContent>
            </StyledButton>
          </ButtonContainer>
          <ButtonContainer>
            <StyledButton color="primary" size={buttonSize} onClick={handleDownloadMeanData}>
              <ButtonContent>
                <CloudDownloadIcon />
                {t('dashboard.tools.csv.download')}
              </ButtonContent>
            </StyledButton>
          </ButtonContainer>
        </ToolRow>
      </Panel>
      {/* Modals */}
      <FileManager
        isLoading={isPostFileLoading}
        isFileUploadModalOpen={isFileUploadModalOpen}
        isFileManagerModalOpen={isFileManagerModalOpen}
        handleSubmit={handleFileSubmit}
        handleCloseModal={closeModal}
      />
      {isDownloadMeanDataModalOpen && <DownloadMeanDataModal isOpen={isDownloadMeanDataModalOpen} handleClose={closeModal} submitLoading={isLoading} />}
    </>
  );
};

export default ToolPanel;
