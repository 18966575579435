import {GROWING_DEGREE_DAYS} from '../config/const';

export const useSeasonIndices = () => {
  // Capitalises index titles correctly
  const getIndexTitle = (index: string) => {
    if (index === 'gdd') {
      return GROWING_DEGREE_DAYS;
    } else if (index === 'ndvi' || index === 'ndwi') {
      return index.toUpperCase();
    } else {
      return index[0].toUpperCase() + index.slice(1);
    }
  };

  // Generates an array containing the start of every month within a specified period of years for ticks
  const generateMappedDates = (startYear: number, endYear: number) => {
    const dates = [];
    for (let year = startYear; year <= endYear; year++) {
      for (let month = 1; month <= 12; month++) {
        // Ensure month is always two digits
        const monthStr = month < 10 ? `0${month}` : `${month}`;
        dates.push(`${year}-${monthStr}-01`);
      }
    }
    return dates;
  };

  // Extrapolates all previous traces so that they overlap on top of eachother
  const extrapolateTraces = (traces: any[]) => {
    const nowDate = new Date(Date.now());
    traces.forEach(trace => {
      const newXValues = [];
      // Time to add -> the amount of years needed to add to the trace so that it overlaps with the current trace
      const timeToAdd = nowDate.getFullYear() - trace.x[0].getFullYear();
      for (const date of trace.x) {
        // Updates the x values (dates) with the added time to add (so they can overlap)
        newXValues.push(new Date(date.getFullYear() + timeToAdd, date.getMonth(), date.getDate()));
      }
      trace.x = newXValues;
      // Store the original x values as custom data
      trace.customdata = trace.originalDates;
      // Ensure that on hover information retains the original x data (dates)
      trace.hovertemplate = `<b>%{customdata|%Y}:</b> %{y:.4f} <extra></extra>`; // On hover show original year and then y value
      delete trace.originalDates; // Remove originalDate as not needed anymore -> used only for hoverinfo
      if (timeToAdd === 0) {
        trace.opacity = 0.85; // Make previous non-current seasons less visible
      }
    });

    return traces;
  };

  return {getIndexTitle, generateMappedDates, extrapolateTraces};
};
