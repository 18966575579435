import {ISeasonIndicesResponse, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {colors, GROWING_DEGREE_DAYS} from '../config/const';
import {useMeasurementConversion} from './useMeasurementConversion';

// Capitalises index titles correctly
const getIndexTitle = (index: string) => {
  if (index === 'gdd') {
    return GROWING_DEGREE_DAYS;
  } else if (index === 'ndvi' || index === 'ndwi') {
    return index.toUpperCase();
  } else {
    return index[0].toUpperCase() + index.slice(1);
  }
};

// Generates an array containing the start of every month within a specified period of years for ticks
const generateMappedDates = (startYear: number, endYear: number) => {
  const dates = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 1; month <= 12; month++) {
      // Ensure month is always two digits
      const monthStr = month < 10 ? `0${month}` : `${month}`;
      dates.push(`${year}-${monthStr}-01`);
    }
  }
  return dates;
};

// Extrapolates all previous traces so that they overlap on top of eachother
const extrapolateTraces = (traces: any[]) => {
  const nowDate = new Date(Date.now());
  traces.forEach(trace => {
    const newXValues = [];
    // Time to add -> the amount of years needed to add to the trace so that it overlaps with the current trace
    const timeToAdd = nowDate.getFullYear() - trace.x[0].getFullYear();
    for (const date of trace.x) {
      // Updates the x values (dates) with the added time to add (so they can overlap)
      newXValues.push(new Date(date.getFullYear() + timeToAdd, date.getMonth(), date.getDate()));
    }
    trace.x = newXValues;
    // Store the original x values as custom data
    trace.customdata = trace.originalDates;
    // Ensure that on hover information retains the original x data (dates)
    trace.hovertemplate = `<b>%{customdata|%Y}:</b> %{y:.4f} <extra></extra>`; // On hover show original year and then y value
    delete trace.originalDates; // Remove originalDate as not needed anymore -> used only for hoverinfo
    if (timeToAdd === 0) {
      trace.opacity = 0.85; // Make previous non-current seasons less visible
    }
  });

  return traces;
};

// Function to check if prevDate -> currentDate crosses a season
// Parameters -> prevDate: date of previous selected trace, currentDate: date of current selected trace, seasonMonths: array holding all months in season, in sequential order
const checkCrossedSeason = (prevDate: Date, currentDate: Date, seasonMonths: number[]) => {
  const prevDateMonth = prevDate.getMonth() + 1; // Months +1 for -1 index
  const currentDateMonth = currentDate.getMonth() + 1; // Months +1 for -1 index

  const prevDateIndex = seasonMonths.indexOf(prevDateMonth); // Get index of prev date in seasonMonths array

  let crossedSeason = true;
  // Loop to end of seasonMonths array
  for (let i = prevDateIndex; i < seasonMonths.length; i++) {
    if (
      seasonMonths[i] === currentDateMonth || // If value at index equals currentDate's month
      seasonMonths[0] > currentDateMonth // Or current date's month is less than first month in seasonMonths array
    ) {
      crossedSeason = false; // It has not crossed a season
    }
  }
  return crossedSeason; // It has crossed a season
};

export const useSeasonIndices = (selectedPolygon: polygonInfoWindow, seasonMonths: number[]) => {
  const {distanceUnit, convertMmInches} = useMeasurementConversion();

  const newSeasonDetectionValue = 3.5 * 30 * 24 * 60 * 60 * 1000; // 3.5 months
  // Remove first color off colors for the graph colors to ensure it matches other
  const graphColors = colors.slice(1); // Used to color each trace
  const polygonId = selectedPolygon?.polygonId;

  // Gets data values for given index
  const getValuesIndex = (data: ISeasonIndicesResponse[], index: string) => {
    const traces = [];
    let xValues = [];
    let yValues = [];

    let originalDates = []; // Keeps track of the original date for each plot
    let colorIndex = 0; // Tracks trace color
    let prevDate = null; // Tracks date of previous plot for cross year functionality
    let crossedYear = false; // Used to check if plots have crossed past the next year (i.e. January) so that it can display correct year on hover

    data?.forEach(item => {
      // If polygon does not match do not show data
      if (item.polygonId !== polygonId) {
        return;
      }

      const currentDate = new Date(item.date);
      // If current time between prevDate and currentDate has crossed the season
      if (prevDate && checkCrossedSeason(prevDate, currentDate, seasonMonths)) {
        // Pushes separate trace for each season
        traces.push({
          x: xValues,
          y: yValues,
          originalDates: originalDates,
          name: crossedYear ? `${currentDate.getFullYear() - 1}/${currentDate.getFullYear() + -2000}` : currentDate.getFullYear() - 1,
          opacity: 0.35,
          hoverinfo: 'x+y',
          mode: 'lines',
          line: {
            color: graphColors[colorIndex],
          },
        });
        // Reset values and initialise for next curve
        crossedYear = false;
        xValues = [currentDate];
        yValues = [index === 'rainfall' ? convertMmInches(parseFloat(item[index])).toString() : item[index]];
        originalDates = [currentDate];
      }
      // No new trace detected -> continue plotting current plot
      else {
        // Checks if previous date has a different year
        if (prevDate && prevDate.getFullYear() === currentDate.getFullYear() - 1) {
          crossedYear = true;
        }
        // Adds plot values to array
        xValues.push(currentDate);
        yValues.push(index === 'rainfall' ? convertMmInches(parseFloat(item[index])).toString() : item[index]);
        originalDates.push(currentDate);
      }
      prevDate = currentDate;
      colorIndex++;
    });

    // Handle last trace
    if (xValues.length > 0) {
      traces.push({
        x: xValues,
        y: yValues,
        originalDates: originalDates,
        name: crossedYear ? `${new Date().getFullYear() - 1}/${new Date().getFullYear() + -2000}` : new Date().getFullYear(),
        hoverinfo: 'x+y',
        mode: 'lines',
        line: {
          color: 'blue',
        },
      });
    }

    // Now all dates are plotted, extrapolate them so that they overlap
    return extrapolateTraces(traces);
  };

  return {distanceUnit, getIndexTitle, generateMappedDates, getValuesIndex};
};
