import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Box, List, ListItem, ListItemProps, ListItemText, MenuItem, Select, SelectProps} from '@material-ui/core';
import {getUserGroups} from '../../../containers/authHOC';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import Spinner from '../Spinner';
import {IFarm} from '../../../store/reducers/farm';
import {IPolygonEntity} from '@deep-planet/api-interfaces';

const SidebarTitle = styled(ListItem)<ListItemProps>`
  max-width: calc(100% - 60px);
  margin-top: -48px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
`;

const StyledSelect = styled(Select)<SelectProps>`
  && {
    width: 100%;
  }
`;

interface Props extends WithUserProps {
  polygons: IPolygonEntity[];
  allFarms: IFarm[];
  activeFarm: string;
  loadingGetFarm: boolean;
  showVariety?: boolean;
  children?: JSX.Element;
  activePolygon?: IPolygonEntity;
  handleFarmChange: (selectedFarm: string) => void;
  handlePolygonChange?: (selectedPolygon: IPolygonEntity) => void;
  disableFarmSelection?: boolean;
}

const FarmSelectionSidebar = ({
  polygons,
  allFarms,
  activeFarm,
  handleFarmChange,
  loadingGetFarm,
  children,
  activePolygon,
  handlePolygonChange,
  showVariety = true,
  user,
  disableFarmSelection = false,
}: Props) => {
  const [selectedPolygon, setSelectedPolygon] = useState<IPolygonEntity>(polygons[0]);
  const handleFarmSelection = (event: React.ChangeEvent<{name?: string; value: string}>) => {
    if (handlePolygonChange) {
      // set polygon selection to null when new farm is selected
      handlePolygonChange(null);
    }
    handleFarmChange(event.target.value);
  };
  const isSuperUser = getUserGroups(user).includes('SUPERADMIN');

  const handleClickOnPolygon = (polygon: IPolygonEntity) => {
    setSelectedPolygon(polygon);
    if (handlePolygonChange) {
      handlePolygonChange(polygon);
    }
  };

  const memoizedPolygons = React.useMemo(() => polygons, [polygons]);

  useEffect(() => {
    if (activePolygon) setSelectedPolygon(activePolygon);
  }, [activePolygon]);
  return (
    <Container>
      {/*// eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*// @ts-ignore*/}
      <SidebarTitle button={false}>
        {!!activeFarm && loadingGetFarm ? (
          <Spinner size={15} color="primary" />
        ) : (
          !disableFarmSelection && (
            <StyledSelect value={activeFarm} onChange={handleFarmSelection} displayEmpty name="farm">
              {allFarms.map(({name, farmid, ownerOrganization}, idx) => {
                return (
                  <MenuItem key={idx} value={farmid}>
                    {`${name} ${(isSuperUser && `(${ownerOrganization?.name})`) || ''}`}
                  </MenuItem>
                );
              })}
            </StyledSelect>
          )
        )}
      </SidebarTitle>
      <Box overflow="auto" display="flex" flexDirection="column" justifyContent="space-between" minHeight="calc(100% - 64px)">
        <List>
          <ListItem />
          {!!memoizedPolygons &&
            memoizedPolygons.map(polygon => {
              const cropType = showVariety && polygon.geoJson.properties.cropType !== 'N/A' ? polygon.geoJson.properties.cropType : null;
              return (
                <ListItem
                  button
                  key={polygon.id}
                  onClick={() => handleClickOnPolygon(polygon)}
                  style={{backgroundColor: activePolygon?.id === polygon.id || selectedPolygon?.id === polygon.id ? '#DBDBDB' : 'unset'}}
                >
                  <ListItemText primary={polygon.name} secondary={cropType} />
                </ListItem>
              );
            })}
        </List>
      </Box>
      {children}
    </Container>
  );
};

export default React.memo(withUser(FarmSelectionSidebar));
