import {useEffect, useState} from 'react';
import {DOWNY_MILDEW, POWDERY_MILDEW, BOTRYTIS} from '../config/const';
import {IDiseaseIndividualResponse} from '@deep-planet/api-interfaces';

export const useDiseaseLabels = (imagesOnDate: IDiseaseIndividualResponse[]) => {
  const [mixedColors, setMixedColors] = useState<string[]>([]);
  const [probabilityThreshold, setProbabilityThreshold] = useState<number>(-1);
  const [diseaseTypes, setDiseaseTypes] = useState<string[]>([DOWNY_MILDEW]);
  const [marks, setMarks] = useState([]);

  // Mapping of disease types to labels and colors
  const diseaseTypeLabels = {
    DOWNY_MILDEW: {label: 'Downy Mildew', color: 'yellow'},
    POWDERY_MILDEW: {label: 'Powdery Mildew', color: '#CD7F32'},
    BOTRYTIS: {label: 'Botrytis', color: 'purple'},
  };

  // Handle toggling of disease types in the selection
  const handleDiseaseTypeToggle = (type: string) => {
    setDiseaseTypes(prevTypes => (prevTypes.includes(type) ? prevTypes.filter(diseaseType => diseaseType !== type) : [...prevTypes, type]));
  };

  // Handle changes to the probability threshold
  const handleProbabilityThresholdChange = (threshold: number) => {
    setProbabilityThreshold(threshold);
  };

  // Function to calculate mixed colors based on disease type combinations
  // - Takes in current selected disease labels by user (i.e. downy mildew and botrytis)
  // - Combines disease types using ">" to join them
  // - Searches through color combinations object for the matching colors (matching colors when the diseases are combined)
  // - Returns colors of the combined diseases
  const calculateMixedColors = (diseaseTypes: string[]) => {
    const getMixedColors = (diseaseTypes: string[]) => {
      let mixedColors = null;
      switch (diseaseTypes.toString()) {
        case [DOWNY_MILDEW, POWDERY_MILDEW].toString():
          mixedColors = ['orange'];
          break;
        case [BOTRYTIS, DOWNY_MILDEW].toString():
          mixedColors = ['#F89880'];
          break;
        case [BOTRYTIS, POWDERY_MILDEW].toString():
          mixedColors = ['#DC143C'];
          break;
        case [BOTRYTIS, DOWNY_MILDEW, POWDERY_MILDEW].toString():
          mixedColors = ['#F89880', '#DC143C', '#FF7518'];
      }

      return mixedColors;
    };

    if (diseaseTypes.length > 1) {
      diseaseTypes = diseaseTypes.sort(); // Sort disease types so that they appear in order
      return getMixedColors(diseaseTypes);
    }

    return [];
  };

  // Update mixed colors whenever disease types change
  useEffect(() => {
    if (calculateMixedColors(diseaseTypes)) {
      setMixedColors(calculateMixedColors(diseaseTypes));
    }
  }, [diseaseTypes]);

  // Update marks and threshold when images change
  useEffect(() => {
    // Function to process image thresholds and update marks
    const processImageThresholds = (images: IDiseaseIndividualResponse[]) => {
      const thresholds = images?.flatMap(file => Object.values(file.images).flatMap(polygons => polygons.map(polygon => polygon.threshold)));

      if (thresholds && thresholds.length > 0) {
        const sortedThresholds = thresholds.sort((a, b) => a - b);

        if (probabilityThreshold === -1) {
          setProbabilityThreshold(sortedThresholds[0]); // Set initial threshold
        }

        const uniqueThresholds = [...new Set(sortedThresholds)];
        return uniqueThresholds.map(threshold => ({value: threshold, label: null}));
      }

      return [];
    };

    if (imagesOnDate) {
      setMarks(processImageThresholds(imagesOnDate));
    }
  }, [imagesOnDate, probabilityThreshold]);

  return {
    diseaseTypeLabels,
    marks,
    mixedColors,
    diseaseTypes,
    probabilityThreshold,
    handleDiseaseTypeToggle,
    handleProbabilityThresholdChange,
  };
};
