import {Box, Button, ButtonGroup} from '@material-ui/core';
import React, {useState} from 'react';
import {BAUME, SUGAR} from '../../../config/const';

interface Props {
  handleMaturityType: (type: string) => void;
}
// button names on UI
export const maturityTypes = ['baume', 'pH', 'Ta'];

export const ButtonGroupBar = ({handleMaturityType}: Props) => {
  const [nutrientType, setNutrientType] = useState<string>('baume');
  const handleMaturityTypeChange = value => {
    setNutrientType(value);
    handleMaturityType(value);
  };
  return (
    <Box
      m={2}
      ml={2}
      display="flex"
      alignItems="center"
      style={{
        backgroundColor: 'white',
        borderRadius: `3px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        position: 'absolute',
        right: '50px',
        marginTop: '20px',
      }}
    >
      <ButtonGroup size="small" aria-label="small contained button group">
        {maturityTypes.map(item => (
          <Button key={item} variant="contained" color={item === nutrientType ? 'secondary' : 'default'} onClick={() => handleMaturityTypeChange(item)}>
            {item === BAUME ? SUGAR : item}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};
