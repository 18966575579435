import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography, Button} from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MapIcon from '@material-ui/icons/Map';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Loader from './Loader';

import {MAP, HALF, FULL} from '../../../../config/const';

interface OverlayProps {
  screenMode: string;
  setScreenMode: (mode: string) => void;
  polygonName: string;
  loadingSeasonIndices: boolean;
  children: React.ReactNode;
}

const Overlay = ({screenMode, setScreenMode, polygonName, loadingSeasonIndices, children}: OverlayProps) => {
  const {t} = useTranslation();

  const containerStyle: React.CSSProperties = {
    width: (screenMode === HALF && '50%') || (screenMode === FULL && '100%') || '85px',
    height: 'calc(100vh - 130px)',
    position: 'relative',
  };

  return (
    <Box style={containerStyle}>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 20,
        }}
      >
        {/* Loading wheel */}
        {loadingSeasonIndices && screenMode !== MAP && <Loader />}
        {/* Top bar with buttons */}
        <Button
          onClick={() => setScreenMode(screenMode === HALF ? MAP : HALF)}
          style={{
            marginTop: 10,
            marginLeft: 10,
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {screenMode !== MAP ? (
            <>
              <Typography style={{fontWeight: 500}}>{screenMode !== FULL ? t('season.indices.overlay.collapse') : t('season.indices.overlay.map')}</Typography>
              {screenMode !== FULL ? <ChevronRightIcon style={{fontSize: 30}} /> : <MapIcon style={{fontSize: 25, margin: 3}} />}
            </>
          ) : (
            <ChevronLeftIcon style={{fontSize: 30}} />
          )}
        </Button>
        {screenMode !== MAP && <div style={{flex: 1}} />}
        {screenMode !== MAP && (
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bolder',
              fontSize: 17,
              color: '#3f51b5',
              marginLeft: 5,
              paddingTop: 10,
            }}
          >
            {polygonName}
          </Typography>
        )}
        {screenMode !== MAP && <div style={{flex: 1}} />}
        {screenMode === FULL && <div style={{width: 130}} />}
        {screenMode === HALF && (
          <Button
            onClick={() => setScreenMode(FULL)}
            style={{
              marginTop: 10,
              marginRight: 10,
              textTransform: 'none',
              width: 130,
            }}
          >
            <Typography style={{fontWeight: 500, marginRight: 3}}>{t('season.indices.overlay.expand')}</Typography>
            <FullscreenIcon style={{fontSize: 30}} />
          </Button>
        )}
      </Box>
      {screenMode !== MAP && (
        <Box
          style={{
            height: 'calc(100% - 130px)',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: !polygonName && 'center',
          }}
        >
          {/* No graph data instruction */}
          {!polygonName && !loadingSeasonIndices && (
            <Typography
              variant="h5"
              style={{
                marginBottom: 100,
                textAlign: 'center',
                color: 'black',
                opacity: 0.5,
              }}
            >
              {t('season.indices.overlay.instruction')}
            </Typography>
          )}
          {/* Render plots */}
          {polygonName && children}
        </Box>
      )}
    </Box>
  );
};

export default Overlay;
