import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import SelectIndex from './SelectIndex';
import CalculateInput from './CalculateInput';
import {IUserZoneLevels} from '@deep-planet/api-interfaces';
import {capitalize} from 'lodash';

const useStyles = makeStyles(theme => ({
  mainContainer: {display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: 20},
  heading: {fontSize: 12},
  subheading: {
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: 12,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    width: '100%',
    textAlign: 'left',
  },
}));

const getTotalHectares = (selectedFarm: IFarm) => {
  let hectares = 0;
  selectedFarm.polygons.forEach(polygon => {
    // Sum of each polygon's hectares
    hectares += Number(polygon.hectares);
  });
  return hectares;
};

const formatDate = (date: Date) => {
  return `${date.getDate()} ${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()}`;
};

interface VariableRateApplicationProps {
  selectedFarm: IFarm;
  imageDates: number[];
  zones: number;
  zonesCalculated: boolean;
  reference: string;
  isMean: boolean;
  fromDate: Date;
  toDate: Date;
  loadingZones: boolean;
  zoneError: string;
  zoneThresholds: number[];
  userZoneLevels: IUserZoneLevels;
  totalInput: number;
  fetchZoneData: () => void;
  handleZoneInputChange: (event: any, index: number) => void;
  handleReferenceChange: (reference: string) => void;
  handleIsMeanChange: () => void;
  handleFromDateChange: (date: number) => void;
  handleToDateChange: (date: number) => void;
  resetZones: () => void;
}

const VariableRateApplication = ({
  selectedFarm,
  imageDates,
  zones,
  zonesCalculated,
  reference,
  isMean,
  fromDate,
  toDate,
  loadingZones,
  zoneError,
  zoneThresholds,
  userZoneLevels,
  totalInput,
  fetchZoneData,
  handleZoneInputChange,
  handleReferenceChange,
  handleIsMeanChange,
  handleFromDateChange,
  handleToDateChange,
  resetZones,
}: VariableRateApplicationProps) => {
  const totalHectares = getTotalHectares(selectedFarm); // Calculates total hectares based off farm data
  const {t} = useTranslation();

  const classes = useStyles({isMean, zonesCalculated});

  return (
    <Box className={classes.mainContainer}>
      {/* Update subheading/heading based on current window */}
      {!zonesCalculated ? (
        <Typography variant="h6" className={classes.title}>
          Variable Rate Application
        </Typography>
      ) : (
        <>
          {reference && (
            <Typography variant="h6" className={classes.title}>
              {reference.toLowerCase() === 'ndvi' ? reference.toUpperCase() : capitalize(reference)}
            </Typography>
          )}
          <Typography variant="body1" className={classes.heading}>
            {t('soil.nutrients.vra.showing.zones.for')}
          </Typography>
          <Typography variant="h6" className={classes.subheading}>
            {isMean ? ` ${formatDate(fromDate)} - ${formatDate(toDate)}` : formatDate(fromDate)}
          </Typography>
        </>
      )}

      {/* Show index input page if no zone data */}
      {!zonesCalculated && (
        <SelectIndex
          reference={reference}
          imageDates={imageDates}
          handleReferenceChange={handleReferenceChange}
          isMean={isMean}
          handleIsMeanChange={handleIsMeanChange}
          fromDate={fromDate}
          handleFromDateChange={handleFromDateChange}
          toDate={toDate}
          handleToDateChange={handleToDateChange}
          fetchZoneData={fetchZoneData}
          loadingZones={loadingZones}
          zoneError={zoneError}
        />
      )}

      {/* Show calculate input window when zones are fetched and no errors */}
      {!!zonesCalculated && !zoneError && (
        <CalculateInput
          zones={zones}
          totalInput={totalInput}
          zoneThresholds={zoneThresholds}
          totalHectares={totalHectares}
          userZoneLevels={userZoneLevels}
          resetZones={resetZones}
          handleZoneInputChange={handleZoneInputChange}
        />
      )}
    </Box>
  );
};

export default VariableRateApplication;
