import React, {useEffect} from 'react';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import Plot from 'react-plotly.js';
import styled from 'styled-components';
import {IOrganizationEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {CircularProgress, MenuItem, Select} from '@material-ui/core';
import {useVineHeartbeat} from 'apps/web-portal-ui/src/app/hooks/useVineHeartbeat';
import {useTranslation} from 'react-i18next';
import {PanelTitleWithLink} from '../../../../../components/UI/DashboardPanel/PanelTitle';
import NoInfoOverlay from '../../../../../components/UI/DashboardPanel/NoInfoOverlay';

const LoadingContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -10px;
`;

const StyledSelect = styled(Select)`
  z-index: 2;
`;

const PlotContainer = styled.div`
  height: 160%;
  width: calc(100% + 50px);
  margin-inline: -25px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  height: 80%;
`;

const plotStyle = {
  width: '100%',
  height: '100%',
};

interface Props {
  selectedOrganization: IOrganizationEntity;
  selectedFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  preUrl: string;
  isMobile: boolean;
}

const VineHealthHeartbeat = ({selectedOrganization, selectedFarm, selectedPolygon, preUrl, isMobile}: Props) => {
  const {t} = useTranslation();
  const {product, handleProductChange, loadingHeartbeat, traces} = useVineHeartbeat(selectedOrganization, selectedFarm, selectedPolygon);

  return (
    <Panel width={isMobile ? '100%' : '65%'} height={'263px'} overflow="hidden" right={!isMobile}>
      <PanelTitleWithLink link={preUrl + `/${product.toLowerCase()}-esa`}>{t('dashboard.vine.health.heartbeat')}</PanelTitleWithLink>
      <SelectContainer>
        <StyledSelect value={product} onChange={(event: any) => handleProductChange(event.target.value)}>
          <MenuItem value={'NDVI'}>NDVI</MenuItem>
          <MenuItem value={'NDWI'}>NDWI</MenuItem>
        </StyledSelect>
      </SelectContainer>
      <Container>
        {loadingHeartbeat ? (
          <LoadingContainer>
            <CircularProgress size={65} />
          </LoadingContainer>
        ) : traces.length > 0 ? (
          <PlotContainer>
            <Plot
              data={traces}
              layout={{
                paper_bgcolor: 'rgba(0, 0, 0, 0)',
                plot_bgcolor: 'rgba(0, 0, 0, 0)',
                hovermode: 'closest',
                showlegend: false,
              }}
              config={{displayModeBar: false}}
              style={plotStyle}
            />
          </PlotContainer>
        ) : (
          <NoInfoOverlay name={t('dashboard.vine.health.heartbeat')} variant="subtitle1" />
        )}
      </Container>
    </Panel>
  );
};

export default VineHealthHeartbeat;
