// Import necessary React hooks and components
import React, {useState} from 'react';
import {MenuItem, Select, Box, BoxProps, InputLabel, FormControl, SelectProps, CircularProgress, FormControlLabel, Checkbox, TextField, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {organizationsSelector} from '../../../store/selectors';
import theme from '../../../utilities/theme';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../Modal/Modal';
import DatePeriodPicker from '../Pickers/DatePeriodPicker';
import {useDownloadMeanData} from '../../../hooks/useDownloadMeanData';
import {Autocomplete} from '@material-ui/lab';
import {sortBy} from 'lodash';
import {baseApiUrl} from '../../../config/const';
import {useHttp} from '../../../hooks/http';
import {IFeature, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {IFarm} from '../../../store/reducers/farm';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import {enqueueSnackbar} from '../../../store/actions/snackbar';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  submitLoading: boolean;
  modalTitle?: string;
}

export interface ISelectedFile {
  name: string;
  type: string;
  size: number;
}
const StyledSelect = styled(Select)<SelectProps>`
  width: auto;
`;

const InputWrapperBox = styled(Box)<BoxProps>`
  margin-right: 16px;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

const InputWrapperBoxDate = styled(Box)<BoxProps>`
  margin-right: 16px;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

const DownloadMeanDataModal = ({isOpen, handleClose, submitLoading, modalTitle}: Props) => {
  const {t} = useTranslation();
  const organizations = useSelector(organizationsSelector);
  const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 14)));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [isLoadingGroups, groups] = useHttp<IFeature[]>(`${baseApiUrl}/farm-settings/feature-with-mean`);
  const dispatch = useDispatch();
  // Handle the actions in a hook
  const {
    selectedFarm,
    selectedOrganization,
    selectedPolygon,
    selectedGroup,
    setSelectedPolygon,
    setIsLoadingPolygons,
    isLoadingPolygons,
    checked,
    farms,
    setSelectedGroup,
    handleFarmSelection,
    handleOrganizationSelection,
    handleCheckboxChange,
  } = useDownloadMeanData();
  // get the mean data from the backend for selected farm and polygon
  const onClickSubmit = async () => {
    setIsLoadingPolygons(true);
    try {
      // polygonId
      const polygonId = (checked && selectedPolygon.id) || null;
      // selected feature
      const product = selectedGroup.name;
      const url = `${baseApiUrl}/${selectedGroup.meanURL}?farmId=${selectedFarm.id}&product=${product}&fromDate=${fromDate}&toDate=${toDate}&polygonId=${polygonId}&organizationId=${selectedOrganization.id}`;
      // get mean data from the portal
      const {data} = await axios.get(url);
      if (data.length) {
        const csv = convertToCSV(data);
        const blob = new Blob(['\ufeff', csv], {type: 'text/csv;charset=utf-8'});
        FileSaver.saveAs(blob, `MeanData_${selectedGroup.name}_${new Date()}.csv`);
      } else {
        // Alert the user that data is not available for the selected duration and product
        const msg = t('error.http.response.data.not.found');
        dispatch(enqueueSnackbar({message: msg, options: {variant: 'info'}}));
      }
    } catch (e) {
      const msg = e.response.data.message || t('error.http.response.data.not.found');
      dispatch(enqueueSnackbar({message: msg, options: {variant: 'info'}}));
      console.log(e);
    }
    setIsLoadingPolygons(false);
  };

  // Convert array of data into CSV format
  const convertToCSV = data => {
    // get dynamic headers
    const headers = Object.keys(data[0]);
    // Start with the headers
    let csv = headers.join(',') + '\n';
    // Loop over each report object and format the fields
    for (const d of data) {
      const row = [headers.map(h => d[h])];
      csv += row.join(',') + '\n';
    }
    return csv;
  };

  const isLoading = submitLoading || isLoadingPolygons || isLoadingGroups;
  return (
    // Prompt modal dialog box with the below input components
    <Modal
      title={t('download.mean.data')}
      submitText={t('Download')}
      isOpen={isOpen}
      isSubmitButtonDisabled={submitLoading}
      isLoading={isLoading}
      handleClose={handleClose}
      handleSubmit={onClickSubmit}
      modalWidth={'444px'}
    >
      <form>
        <Box marginBottom={'10px'}>
          {/** Circular progress to display full width corresponds to parent div */}
          {isLoading && (
            <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="primary" />
            </Box>
          )}
          <Typography variant="body2">* {t('download.mean.data.description')}</Typography>
          <InputWrapperBox marginTop={'0.5rem'}>
            {/* Wrapper for farm selection input */}
            <FormControl fullWidth disabled={isLoading}>
              <Autocomplete
                value={selectedGroup}
                options={sortBy(groups, g => g.name)}
                onChange={(e, value) => setSelectedGroup(value as IFeature)}
                getOptionLabel={({description}) => description}
                renderInput={params => <TextField {...params} label={t('Feature')} variant="outlined" />}
              />
            </FormControl>
          </InputWrapperBox>
          <InputWrapperBox marginTop={'1rem'}>
            {/* Display form controls for organization and farm selection */}
            <FormControl required fullWidth disabled={isLoading}>
              <Autocomplete
                value={selectedOrganization}
                options={sortBy(organizations, o => o.name.toLowerCase())}
                onChange={(e, value) => handleOrganizationSelection(value as IOrganizationEntity)}
                getOptionLabel={({name}) => name}
                renderInput={params => <TextField {...params} label="Organization" variant="outlined" />}
              />
            </FormControl>
          </InputWrapperBox>
          {/** List of farms */}
          <InputWrapperBox marginTop={'1rem'}>
            {/* Wrapper for farm selection input */}
            <FormControl fullWidth disabled={!selectedOrganization || isLoading}>
              <Autocomplete
                value={selectedFarm}
                options={sortBy(farms, f => f.name.toLowerCase())}
                onChange={(e, value) => handleFarmSelection(value as IFarm)}
                getOptionLabel={({name}) => name}
                renderInput={params => <TextField {...params} label={t('note.table.farm')} variant="outlined" />}
              />
            </FormControl>
          </InputWrapperBox>
          {/** Check box to enable/disable block selection */}
          <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckboxChange} color="primary" />} label={t('block.level')} />
          {/** List of polygons */}
          {checked && (
            <InputWrapperBox>
              <FormControl fullWidth disabled={!selectedFarm || isLoading}>
                <InputLabel id="block-label">{t('note.table.block')}</InputLabel>
                <StyledSelect
                  labelId="block-label"
                  value={selectedPolygon?.id || ''}
                  name="block"
                  onChange={({target: {value}}) => setSelectedPolygon(selectedFarm.polygons.find(({id}) => id === value) || null)}
                >
                  {selectedFarm?.polygons &&
                    selectedFarm.polygons.map(({id, name}) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                </StyledSelect>
              </FormControl>
            </InputWrapperBox>
          )}
          {/* render the date period picker */}
          <InputWrapperBoxDate>
            <DatePeriodPicker disableToolbar={false} startDate={fromDate} endDate={toDate} handleStartDateChange={setFromDate} handleEndDateChange={setToDate} disableFuture={true} variant="inline" />
          </InputWrapperBoxDate>
        </Box>
      </form>
    </Modal>
  );
};

export default DownloadMeanDataModal;
