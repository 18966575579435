import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, IconButton, makeStyles, TextField, Typography} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {ZONE_DESCRIPTIONS, VRA_MAP_COLORS} from 'apps/web-portal-ui/src/app/config/const';
import {useMeasurementConversion} from 'apps/web-portal-ui/src/app/hooks/useMeasurementConversion';
import {IUserZoneLevels} from '@deep-planet/api-interfaces';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: -20,
  },
  iconButton: {
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
  },
  chevronLeftIcon: {
    fontSize: 23,
  },
  splitter: {
    display: 'flex',
    flex: 1,
  },
  totalInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  boldText: {
    fontWeight: 'bold',
  },
  spacer: {
    width: 50,
  },
  zoneInput: {
    display: 'flex',
    flexDirection: 'column',
    marginBlock: 5,
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'rgba(1, 1, 1, 0.1)',
  },
  zoneHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  zoneTitle: {
    fontSize: 12,
    fontWeight: 'normal',
  },
  zoneColorBox: {
    width: 20,
    height: 20,
    marginInline: 5,
    borderRadius: 2,
  },
  zoneStats: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  zoneTextField: {
    fontSize: 12,
    width: 30,
    marginRight: 5,
  },
  zoneText: {
    fontSize: 12,
    marginRight: 5,
  },
  plainText: {
    fontSize: 12,
  },
}));

interface CalculateInputProps {
  zones: number;
  totalInput: number;
  resetZones: () => void;
  zoneThresholds: number[];
  totalHectares: number;
  userZoneLevels: IUserZoneLevels;
  handleZoneInputChange: (event: any, index: number) => void;
}

const CalculateInput = ({resetZones, zones, zoneThresholds, totalHectares, userZoneLevels, handleZoneInputChange, totalInput}: CalculateInputProps) => {
  const {t} = useTranslation();
  const {abbrAreaUnit, abbrWeightUnit, convertArea, round} = useMeasurementConversion();
  const classes = useStyles();

  // Render a given amount of zone input panels
  const renderZones = (amount: number) => {
    const zoneInputs = [];
    for (let i = 1; i < amount + 1; i++) {
      zoneInputs.push(renderZoneInput(i, amount));
    }
    return zoneInputs;
  };

  const renderZoneInput = (index: number, zoneNum: number) => {
    return (
      <Box key={index + zoneNum} className={classes.zoneInput}>
        <Box className={classes.zoneHeader}>
          <Typography className={classes.zoneTitle}>
            {`${t('soil.nutrients.zone')} ${index}`} {' - '} {ZONE_DESCRIPTIONS[zoneNum][index - 1]}
          </Typography>
          <div style={{display: 'flex', flex: 1}} />
          <Box className={classes.zoneColorBox} style={{backgroundColor: VRA_MAP_COLORS[zoneNum][index - 1]}} />
        </Box>
        <Box className={classes.zoneStats}>
          {/* Display percentage value */}
          <Typography className={classes.zoneText} style={{fontWeight: 'bold'}}>
            {round((zoneThresholds[index - 1] / totalHectares) * 100, 2)}%
          </Typography>
          {/* Display hectares value */}
          <Typography className={classes.zoneText}>({convertArea(zoneThresholds[index - 1]) + ' ' + abbrAreaUnit})</Typography>
          <div style={{display: 'flex', flex: 1}} />
          <TextField
            value={userZoneLevels[index] || ''}
            onChange={event => handleZoneInputChange(event, index)}
            inputProps={{style: {fontSize: 12, fontWeight: 'bold', textAlign: 'right'}}}
            className={classes.zoneTextField}
          />
          <Typography className={classes.plainText}>
            {abbrWeightUnit}/{abbrAreaUnit}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.container}>
        <IconButton onClick={resetZones} className={classes.iconButton}>
          <ChevronLeftIcon className={classes.chevronLeftIcon} />
        </IconButton>
        <div className={classes.splitter} />
        <Typography variant="h6" className={classes.boldText}>
          {zones} {t('soil.nutrients.zones')}
        </Typography>
        <div className={classes.splitter} />
        {/* Adds spacing to the right of the title so its centered */}
        <Box className={classes.spacer} />
      </Box>

      {/* Render zone panel for every selected zone */}
      {renderZones(zones)}

      <Box className={classes.totalInputContainer}>
        <Typography color="primary" className={classes.boldText}>
          {t('soil.nutrients.vra.total.input')}: {totalInput} {abbrWeightUnit}
        </Typography>
      </Box>
    </>
  );
};

export default CalculateInput;
