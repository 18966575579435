import {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {IFarm} from '../store/reducers/farm';
import {
  soilOrganicCarbonFarmsSelector,
  soilOrganicCarbonFarmGetSelector,
  soilOrganicCarbonImagesSelector,
  soilOrganicCarbonImagesGetSelector,
  soilOrganicCarbonActiveFarmSelector,
  soilOrganicCarbonSelectedDateSelector,
} from '../store/selectors/soilOrganicCarbon';
import {getSoilOrganicCarbonFarms, getSoilOrganicCarbonImages, soilOrganicCarbonSetSelectedDate} from '../store/actions/soilOrganicCarbon';
import {getStringDate} from '../helpers/dateHelpers';
import {ISoilOrganicCarbonHistData, ISoilOrganicCarbonHistoricalUI} from '@deep-planet/api-interfaces';
import {baseApiUrl} from '../config/const';
import axios from 'axios';
import {enqueueSnackbar} from '../store/actions/snackbar';
import i18n from '../../i18n';
import moment from 'moment';
import {usePrevious} from './usePrevious';

/**
  1. First farm is a default selection on initialization
  2. First farm is a default selection if some NON-CARBON farm is selected on another feature.
  3. select a carbon farm and click update button. Switch to other feature and select a NON-CARBON farm on other feature,
     previously selected carbon farm will be remain active on the soil organic carbon page.
 *  */
export const useSoilOrganicCarbonSelection = (allFarms: IFarm[], activeFarm: IFarm, selectedFarm: IFarm) => {
  const [socActiveFarm, setSocActiveFarm] = useState<IFarm>();
  const [socSelectedFarm, setSocSelectedFarm] = useState<IFarm>();
  const [selectedDate, setSelectedDate] = useState<number>();
  const soilOrganicCarbonFarms = useSelector(soilOrganicCarbonFarmsSelector);
  const soilOrganicCarbonImages = useSelector(soilOrganicCarbonImagesSelector);
  const activeSoilOrganicCarbonFarm = useSelector(soilOrganicCarbonActiveFarmSelector);
  const {loading: loadingImages, error} = useSelector(soilOrganicCarbonImagesGetSelector);
  const {loading: loadingSoilOrganicCarbonFarms} = useSelector(soilOrganicCarbonFarmGetSelector);
  const dispatch = useDispatch();
  const [historicalSoc, setHistoricalSoc] = useState<ISoilOrganicCarbonHistoricalUI[]>();
  const soilOrganicCarbonSelectedDate = useSelector(soilOrganicCarbonSelectedDateSelector);
  const prevSocFarm = usePrevious<IFarm>(activeSoilOrganicCarbonFarm);

  const handleFarmChange = (farmId: string) => {
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setSocSelectedFarm(selectedFarm);
  };

  const handleDateChange = (date: number) => {
    setSelectedDate(date);
    dispatch(soilOrganicCarbonSetSelectedDate(new Date(date)));
  };

  const handleGetImagesForDuration = (farm: IFarm, startDate: Date, endDate: Date, organizationId?: string, disableSnackbar?: boolean) => {
    dispatch(getSoilOrganicCarbonImages(farm, getStringDate(startDate), getStringDate(endDate), organizationId, disableSnackbar));
    setSocSelectedFarm(farm);
    setSocActiveFarm(farm);
  };

  const handleGetHistoricalSoc = async (polygonId: string, duration: string) => {
    let startDate = new Date();
    const selectedNumOfyears = parseInt(duration.charAt(0));
    // subtract date in months and year as per period or duration
    if (duration.includes('M')) {
      startDate = new Date(startDate.setMonth(startDate.getMonth() - selectedNumOfyears));
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    } else if (duration.includes('Y')) {
      // subtract years based on selection  - `parseInt(duration.charAt(0)) contains a value of "number of years"`
      startDate = new Date(startDate.setFullYear(startDate.getFullYear() - selectedNumOfyears + 1));
      //initialize date from the begining of the year
      startDate = new Date(`${startDate.getFullYear()}-01-01`);
    } else {
      startDate = new Date(startDate.setFullYear(startDate.getFullYear() - 2));
    }

    // we calculate average Soc per calender year hence,
    // if current year is less than 6 months, get last year's historical data as well.
    if (moment(new Date()).diff(moment(startDate), 'days') < 180) startDate = new Date(`${startDate.getFullYear() - 1}-01-01`);
    // if 3 calender years selected, but days are less then 2yrs + 6months then fetch for previous year of beginning of historical year
    if (selectedNumOfyears === 3 && moment(new Date()).diff(moment(startDate), 'days') < 910) startDate = new Date(`${startDate.getFullYear() - 1}-01-01`);
    // if 5 calender years selected, but days are less then 4yrs + 6months then fetch for previous year of beginning of historical year
    if (selectedNumOfyears === 5 && moment(new Date()).diff(moment(startDate), 'days') < 1640) startDate = new Date(`${startDate.getFullYear() - 1}-01-01`);

    const url = `${baseApiUrl}/soil-organic-carbon/historical/${polygonId}?fromDate=${getStringDate(startDate)}&toDate=${new Date()}`;
    try {
      const {data} = await axios.get<ISoilOrganicCarbonHistData[]>(url);
      //@ts-ignore
      if (data?.message) {
        socMessage();
      } else {
        setHistoricalSoc(data);
      }
    } catch (err) {
      socMessage();
    }
  };
  // information message
  const socMessage = () => {
    setHistoricalSoc([]);
    dispatch(enqueueSnackbar({message: `${i18n.t('error.soil.organic.carbon.historical.not.found')}`, options: {variant: 'info'}}));
  };

  useEffect(() => {
    // selectedFarm and ActiveFarm is set globally across all the features upon action of update on the sidebar,
    // but sometimes selected farm on one feature may not available in other features due to a feature configuration.
    // therefore, keep the previous farm ramain selected if previously selected on the irrigation page, else set first farm as a default farm.
    if (selectedFarm && soilOrganicCarbonFarms && !socSelectedFarm) {
      const isFound = soilOrganicCarbonFarms.some(farm => farm.id === selectedFarm.id);
      if (!isFound) setSocSelectedFarm(activeSoilOrganicCarbonFarm || soilOrganicCarbonFarms[0]);
      else setSocSelectedFarm(selectedFarm);
    }
    // if global activeFarm is not available in the carbon farms, set previously selected farm, else first farm as default one
    if (activeFarm && soilOrganicCarbonFarms && !socActiveFarm) {
      const isFound = soilOrganicCarbonFarms.some(farm => farm.id === activeFarm.id);
      if (!isFound) setSocActiveFarm(activeSoilOrganicCarbonFarm || soilOrganicCarbonFarms[0]);
      else setSocActiveFarm(activeFarm);
    }
  }, [activeFarm, activeSoilOrganicCarbonFarm, selectedFarm, socActiveFarm, socSelectedFarm, soilOrganicCarbonFarms]);

  useEffect(() => {
    if (!soilOrganicCarbonFarms && allFarms) {
      dispatch(getSoilOrganicCarbonFarms(allFarms));
    }

    if (!selectedDate && soilOrganicCarbonSelectedDate) {
      setSelectedDate(Number(new Date(soilOrganicCarbonSelectedDate).getTime()));
    }
    // clear historical SOC data if farm changes
    if (prevSocFarm && activeSoilOrganicCarbonFarm && historicalSoc && prevSocFarm.id !== activeSoilOrganicCarbonFarm.id) {
      setHistoricalSoc([]);
    }
  }, [soilOrganicCarbonFarms, dispatch, allFarms, selectedFarm, activeFarm, activeSoilOrganicCarbonFarm, selectedDate, soilOrganicCarbonSelectedDate, prevSocFarm, historicalSoc]);

  return {
    soilOrganicCarbonImages,
    soilOrganicCarbonFarms,
    loadingSoilOrganicCarbonFarms,
    loadingImages,
    soilOrganicCarbonSelectedFarm: socSelectedFarm,
    soilOrganicCarbonActiveFarm: socActiveFarm,
    selectedDate,
    historicalSoc,
    error,
    setSocSelectedFarm,
    setSocActiveFarm,
    handleFarmChange,
    handleGetImagesForDuration,
    handleGetHistoricalSoc,
    handleDateChange,
  };
};
