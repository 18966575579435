import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Box, Typography} from '@material-ui/core';
import * as turf from '@turf/turf';
import {Position} from '@turf/helpers';
import {Skeleton} from '@material-ui/lab';
import {useTranslation} from 'react-i18next';
import {useMeasurementConversion} from 'apps/web-portal-ui/src/app/hooks/useMeasurementConversion';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {IPolygonEntity, polygonInfoWindow} from '../../../../../../../../../libs/api-interfaces/src/lib/polygon.type';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';

const MapInfoContainer = styled.div`
  background-color: white;
  padding-inline: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MapInfoBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MapInfoLabel = styled(Typography)`
  font-size: 9px !important;
`;

const NoBlockInfoTypography = styled(Typography)`
  font-size: medium;
`;

const NoBlockInfoContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SkeletonContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  min-height: 32px;
  width: 65%;
`;

const StyledTypography = styled(Typography)`
  font-weight: bold !important;
`;

interface Props {
  selectedFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  activePolygon: IPolygonEntity;
}

const PolygonInfo = ({selectedFarm, selectedPolygon, activePolygon}: Props) => {
  const {t} = useTranslation();
  const {areaUnit, convertArea} = useMeasurementConversion();

  const polygonName = useMemo(() => {
    const polygon = selectedFarm?.polygons?.find(polygon => polygon.id === selectedPolygon?.polygonId);
    return polygon?.name;
  }, [selectedFarm, selectedPolygon]);

  const polygonVariety = useMemo(() => {
    return activePolygon?.geoJson.properties.cropType;
  }, [activePolygon]);

  const polygonHectares = useMemo(() => {
    if (!selectedPolygon || !selectedFarm) return null;

    // Find selected polygon
    const polygon = selectedFarm.polygons?.find(polygon => polygon.id === selectedPolygon.polygonId);

    if (!polygon?.geoJson?.geometry?.coordinates) return null;

    try {
      // Use turf to accumulate polygon's coordinates into a total area
      const area = turf.area(turf.polygon(polygon.geoJson.geometry.coordinates as Position[][]));
      return (Math.round(area / 100) / 100).toFixed(2); // Return area
    } catch {
      return null;
    }
  }, [selectedFarm, selectedPolygon]);

  return (
    <Panel width={'100%'} height={'58px'} square>
      <MapInfoContainer>
        {!selectedFarm ? (
          <SkeletonContainer>
            <StyledSkeleton />
          </SkeletonContainer>
        ) : polygonName && polygonHectares && polygonVariety ? (
          <>
            <MapInfoBlock>
              <MapInfoLabel>{t('polygon.info.block.name')}</MapInfoLabel>
              <StyledTypography color="primary">{polygonName}</StyledTypography>
            </MapInfoBlock>
            <MapInfoBlock>
              <MapInfoLabel>{t('dashboard.variety')}</MapInfoLabel>
              <StyledTypography color="primary">{polygonVariety}</StyledTypography>
            </MapInfoBlock>
            <MapInfoBlock>
              <MapInfoLabel>{areaUnit}</MapInfoLabel>
              <StyledTypography color="primary">{convertArea(parseFloat(polygonHectares))}</StyledTypography>
            </MapInfoBlock>
          </>
        ) : (
          <NoBlockInfoContainer>
            <NoBlockInfoTypography>{t('dashboard.select.block.info')}</NoBlockInfoTypography>
          </NoBlockInfoContainer>
        )}
      </MapInfoContainer>
    </Panel>
  );
};

export default PolygonInfo;
