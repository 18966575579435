import {useCallback, useEffect, useMemo} from 'react';
import {IFarm} from '../store/reducers/farm';
import {useSoilNutrientsSelection} from 'apps/web-portal-ui/src/app/hooks/useSoilNutrientImage';
import {polygonInfoWindow} from '@deep-planet/api-interfaces';
import {useSoilOrganicCarbonSelection} from './useSoilOrganicCarbonImage';
import {useDispatch} from 'react-redux';
import {capitalize} from 'lodash';

const formatTitle = (title: string) => {
  const titleSplit = title.split(' ');
  if (titleSplit.length === 1) return capitalize(title);
  let validatedTitle = '';
  titleSplit.forEach(word => {
    if (validatedTitle.length > 1) {
      validatedTitle += ' ';
    }
    validatedTitle += capitalize(word);
  });
  return validatedTitle;
};

export const useSoilHealthSummary = (allFarms: IFarm[], activeFarm: IFarm, selectedFarm: IFarm, selectedPolygon: polygonInfoWindow) => {
  const dispatch = useDispatch();
  // Fetch soil nutrient images
  const {soilNutrientImages, loadingImages: loadingSoilNutrientsImages, getSoilNutirentTypeImages} = useSoilNutrientsSelection(allFarms, activeFarm, selectedFarm);
  // Fetch soc images
  const {soilOrganicCarbonImages, loadingImages: loadingSoilOrganicCarbonImages, handleGetImagesForDuration} = useSoilOrganicCarbonSelection(allFarms, activeFarm, selectedFarm);
  const memoizedHandleGetImagesForDuration = useCallback(handleGetImagesForDuration, [dispatch]);

  const loadingSoilImages = loadingSoilNutrientsImages || loadingSoilOrganicCarbonImages;

  // Start date 1 year before end date for maturity images
  const {startDate, endDate} = useMemo(() => {
    const endDate = new Date();
    const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    return {startDate, endDate};
  }, []);

  // Baseline & average SOC values
  const {baselineSOC, averageSOC, SOCImage} = useMemo(() => {
    if (soilOrganicCarbonImages && selectedPolygon) {
      // Fetch most recent SOC image
      const recentImage = soilOrganicCarbonImages?.sort((a, b) => b.date - a.date)[0];
      // Match image to selected polygon
      const polygonImage = recentImage?.images.find(image => image?.polygonid === selectedPolygon?.polygonId);
      // Return baseline & average
      return {baselineSOC: polygonImage?.soilOrganicCarbonValues?.baselineSoc, averageSOC: polygonImage?.soilOrganicCarbonValues?.averageSoc, SOCImage: polygonImage?.current_url};
    }
    return {baselineSOC: null, averageSOC: null, SOCImage: null};
  }, [selectedPolygon, soilOrganicCarbonImages]);

  // Soil nutrient values and images
  const {nitrogen, phosphorus, potassium, nitrogenImage, phosphorusImage, potassiumImage} = useMemo(() => {
    if (soilNutrientImages && selectedPolygon) {
      // Fetch most recent soil nutrient image
      const recentImage = soilNutrientImages?.sort((a, b) => b.date - a.date)[0];

      // Match image to selected polygon
      const polygonSoilHealth = recentImage?.soilHealth?.find(image => image?.polygonid === selectedPolygon?.polygonId);
      const polygonImages = recentImage?.images?.find(image => image?.polygonid === selectedPolygon?.polygonId);

      // Extract nutrient values and image URLs
      return {
        nitrogen: polygonSoilHealth?.nitrogen?.mean ?? null,
        phosphorus: polygonSoilHealth?.phosphorus?.mean ?? null,
        potassium: polygonSoilHealth?.potassium?.mean ?? null,
        nitrogenImage: polygonImages?.nitrogen?.avg_url ?? null,
        phosphorusImage: polygonImages?.phosphorus?.avg_url ?? null,
        potassiumImage: polygonImages?.potassium?.avg_url ?? null,
      };
    }

    return {
      nitrogen: null,
      phosphorus: null,
      potassium: null,
      nitrogenImage: null,
      phosphorusImage: null,
      potassiumImage: null,
    };
  }, [selectedPolygon, soilNutrientImages]);

  // Title for visualisation
  const soilHealthVisualisationKey = JSON.parse(localStorage.getItem('userSettings'))?.soilHealthVisualisation || 'nitrogen'; // Fetch preference from user settings

  const soilImageMap = {
    nitrogen: {image: nitrogenImage, blockValue: nitrogen},
    phosphorus: {image: phosphorusImage, blockValue: phosphorus},
    potassium: {image: potassiumImage, blockValue: potassium},
    'soil organic carbon': {image: SOCImage, blockValue: averageSOC},
  };

  const soilImageMapKeys = Object.keys(soilImageMap);

  // Select relevant values based on user settings preference
  const soilImage = soilImageMapKeys.includes(soilHealthVisualisationKey) ? soilImageMap[soilHealthVisualisationKey].image : nitrogenImage; // Image
  const soilBlockValue = soilImageMapKeys.includes(soilHealthVisualisationKey) ? soilImageMap[soilHealthVisualisationKey].blockValue : nitrogen; // Block average value
  const soilHealthVisualisationTitle = soilImageMapKeys.includes(soilHealthVisualisationKey) ? formatTitle(soilHealthVisualisationKey) : 'Nitrogen';

  useEffect(() => {
    if (selectedFarm) {
      getSoilNutirentTypeImages(selectedFarm, startDate, endDate, true); // Fetch soil nutrient data
      memoizedHandleGetImagesForDuration(selectedFarm, startDate, endDate, null, true); // Fetch soc data
    }
  }, [endDate, getSoilNutirentTypeImages, memoizedHandleGetImagesForDuration, selectedFarm, startDate]);

  return {nitrogen, phosphorus, potassium, baselineSOC, averageSOC, soilHealthVisualisationTitle, soilImage, soilBlockValue, loadingSoilImages};
};
