interface IRoute {
  slug: string;
  title: string;
  role?: string;
  doNotDisplayInAccordion?: boolean;
  group?: string;
}

/////////////////////////////////////
// Crop Signal routes
/////////////////////////////////////

type CROP_SIGNAL_ROUTES = 'CROPDISCOVERY';

type ICropSignalRoutes = {
  [K in CROP_SIGNAL_ROUTES]?: IRoute;
};

export const cropSignalRoutes: ICropSignalRoutes = {
  CROPDISCOVERY: {
    slug: '/crop-signal/crop-discovery',
    title: 'navigation.accordion.crop.discovery',
    role: 'CROPDISCOVERY',
  },
};

/////////////////////////////////////
// ADMIN routes
/////////////////////////////////////

type ADMIN_ROUTES = 'MOISTURESENSORS' | 'FARMS';

type IAdminRoutes = {
  [K in ADMIN_ROUTES]?: IRoute;
};

export const adminRoutes: IAdminRoutes = {
  MOISTURESENSORS: {
    slug: '/admin/moisturesensors',
    title: 'navigation.accordion.admin.sensors',
    role: 'ADMIN',
  },
  FARMS: {
    slug: '/admin/farms',
    title: 'navigation.accordion.admin.farms',
    role: 'ADMIN',
  },
};

/////////////////////////////////////
// SUPER ADMIN routes
/////////////////////////////////////

type SUPER_ADMIN_ROUTES = 'USERS' | 'ORGANIZATIONS' | 'REPORTS' | 'MATURITY_MAP' | 'NOTIFICATIONS' | 'CONFIGURATION' | 'ADVANCED_PERMISSIONS' | 'UTILITIES' | 'ACCOUNT_SETUP';

type ISuperAdminRoutes = {
  [K in SUPER_ADMIN_ROUTES]?: IRoute;
};

export const superAdminRoutes: ISuperAdminRoutes = {
  USERS: {
    slug: '/admin/users',
    title: 'navigation.accordion.admin.users',
    role: 'SUPERADMIN',
  },
  ORGANIZATIONS: {
    slug: '/admin/organizations',
    title: 'navigation.accordion.admin.organizations',
    role: 'SUPERADMIN',
  },
  REPORTS: {
    slug: '/admin/reports',
    title: 'navigation.accordion.admin.reports',
    role: 'SUPERADMIN',
  },
  NOTIFICATIONS: {
    slug: '/admin/notifications',
    title: 'navigation.accordion.admin.notifications',
    role: 'SUPERADMIN',
  },
  ADVANCED_PERMISSIONS: {
    slug: '/admin/permissions',
    title: 'navigation.accordion.admin.advanced.permissions',
    role: 'SUPERADMIN',
    group: 'CONFIG',
  },
  ACCOUNT_SETUP: {
    slug: '/admin/account-setup',
    title: 'navigation.accordion.admin.account.setup',
    role: 'SUPERADMIN',
    group: 'CONFIG',
  },
  UTILITIES: {
    slug: '/admin/utilities',
    title: 'navigation.accordion.admin.utilities',
    role: 'SUPERADMIN',
    group: 'CONFIG',
  },
};

/////////////////////////////////////
// Vine Signal routes
/////////////////////////////////////

type VINE_SIGNAL_ROUTES =
  | 'DASHBOARD'
  | 'NDVI'
  | 'NDWI'
  | 'NDVIPLANET'
  | 'SOILMOISTUREINTERPOLATION'
  | 'SOILNUTRIENT'
  | 'SOILTEMPERATURE'
  | 'SOILSALINITY'
  | 'YIELD'
  | 'MY_YIELD'
  | 'HARVEST'
  | 'HARVEST_FORECAST'
  | 'MATURITY_MAP'
  | 'SEASON_INDICES'
  | 'WEATHER'
  | 'TIMELAPSE'
  | 'NOTES'
  | 'IRRIGATION'
  | 'SOIL_ORGANIC_CARBON'
  | 'YIELD_PER_PIXEL'
  | 'DISEASE';

type IVineSignalRoutes = {
  [K in VINE_SIGNAL_ROUTES]?: IRoute;
};

export const vineSignalRoutes: IVineSignalRoutes = {
  DASHBOARD: {
    slug: '/',
    title: 'navigation.accordion.vine.dashboard',
    role: 'DASHBOARD',
  },
  NDVI: {
    slug: '/vine-signal/ndvi-esa',
    title: 'navigation.accordion.vine.ndvi',
    role: 'NDVI',
    group: 'VINE_HEALTH',
  },
  NDWI: {
    slug: '/vine-signal/ndwi-esa',
    title: 'navigation.accordion.vine.ndwi',
    role: 'NDWI',
    group: 'VINE_HEALTH',
  },
  NDVIPLANET: {
    slug: '/vine-signal/ndvi-planet',
    title: 'navigation.accordion.vine.planet',
    role: 'NDVIPLANET',
    group: 'VINE_HEALTH',
  },
  SOILNUTRIENT: {
    slug: '/vine-signal/soil-nutrient',
    title: 'navigation.accordion.soil.nutrient',
    role: 'SOILNUTRIENT',
    group: 'SOIL_HEALTH',
  },
  IRRIGATION: {
    slug: '/vine-signal/irrigation',
    title: 'navigation.accordion.irrigation',
    role: 'IRRIGATION',
    group: 'WATER',
  },
  SOIL_ORGANIC_CARBON: {
    slug: '/vine-signal/soil-organic-carbon',
    title: 'navigation.accordion.soil.organic.carbon',
    role: 'SOIL_ORGANIC_CARBON',
    group: 'SOIL_HEALTH',
  },
  SOILMOISTUREINTERPOLATION: {
    slug: '/vine-signal/soil-moisture-interpolation',
    title: 'navigation.accordion.vine.soil.moisture.interpolation',
    role: 'SOILMOISTUREINTERPOLATION',
    group: 'WATER',
  },
  YIELD: {
    slug: '/vine-signal/yield-prediction',
    title: 'navigation.accordion.vine.yield',
    role: 'YIELD',
    group: 'HARVEST',
  },
  MY_YIELD: {
    slug: '/vine-signal/my-yield',
    title: 'navigation.accordion.vine.yield.historical',
    role: 'YIELD',
    doNotDisplayInAccordion: true,
    group: 'HARVEST',
  },
  HARVEST_FORECAST: {
    slug: '/vine-signal/harvest-forecast',
    title: 'navigation.accordion.vine.harvest.forecast',
    role: 'HARVEST',
    group: 'HARVEST',
  },
  MATURITY_MAP: {
    slug: '/vine-signal/maturity-map',
    title: 'navigation.accordion.admin.maturity.map',
    role: 'MATURITY_MAP',
    group: 'HARVEST',
  },
  SEASON_INDICES: {
    slug: '/vine-signal/season-indices',
    title: 'navigation.accordion.season.indices',
    role: 'SEASON_INDICES',
  },
  WEATHER: {
    slug: '/vine-signal/weather',
    title: 'navigation.accordion.vine.weather',
    role: 'WEATHER',
  },
  TIMELAPSE: {
    slug: '/vine-signal/timelapse',
    title: 'navigation.accordion.vine.timelapse',
    role: 'TIMELAPSE',
  },
  NOTES: {
    slug: '/notes',
    title: 'navigation.accordion.notes',
    role: 'DASHBOARD',
  },
  YIELD_PER_PIXEL: {
    slug: '/vine-signal/yield-per-pixel',
    title: 'navigation.accordion.yield.per.pixel',
    role: 'YIELD_PER_PIXEL',
    group: 'HARVEST',
  },
  DISEASE: {
    slug: '/vine-signal/disease-per-pixel',
    title: 'navigation.accordion.disease.per.pixel',
    role: 'DISEASE',
    group: 'DISEASE',
  },
};

/////////////////////////////////////
// Soil Signal routes
/////////////////////////////////////

type SOIL_SIGNAL_ROUTES =
  | 'DASHBOARD'
  | 'NDVI'
  | 'NDWI'
  | 'NDVIPLANET'
  | 'SUMMARY'
  | 'OVERGRAZED_AREA'
  | 'GRASSLAND_BIOMASS'
  | 'FOREST_BIOMASS'
  | 'BARE_SOIL'
  | 'LEAF_AREA'
  | 'PLANT_DENSITY'
  | 'PLANT_MATURE'
  | 'PLANT_YOUNG'
  | 'PLANT_HEIGHT'
  | 'NOTES'
  | 'SOILNUTRIENT'
  | 'IRRIGATION'
  | 'SEASON_INDICES'
  | 'WEATHER'
  | 'TIMELAPSE'
  | 'SOILMOISTUREINTERPOLATION'
  | 'SOIL_ORGANIC_CARBON'
  | 'DISEASE';

type ISoilSignalRoutes = {
  [K in SOIL_SIGNAL_ROUTES]?: IRoute;
};

export const soilSignalRoutes: ISoilSignalRoutes = {
  DASHBOARD: {
    slug: '/',
    title: 'navigation.accordion.vine.dashboard',
    role: 'SOILSIGNAL',
  },
  NDVI: {
    slug: '/soil-signal/ndvi-esa',
    title: 'navigation.accordion.vine.ndvi',
    role: 'NDVI',
    group: 'VINE_HEALTH',
  },
  NDWI: {
    slug: '/soil-signal/ndwi-esa',
    title: 'navigation.accordion.vine.ndwi',
    role: 'NDWI',
    group: 'VINE_HEALTH',
  },
  NDVIPLANET: {
    slug: '/soil-signal/ndvi-planet',
    title: 'navigation.accordion.vine.planet',
    role: 'NDVIPLANET',
    group: 'VINE_HEALTH',
  },
  SOILMOISTUREINTERPOLATION: {
    slug: '/soil-signal/soil-moisture-interpolation',
    title: 'navigation.accordion.vine.soil.moisture.interpolation',
    role: 'SOILMOISTUREINTERPOLATION',
    group: 'WATER',
  },
  SOILNUTRIENT: {
    slug: '/soil-signal/soil-nutrient',
    title: 'navigation.accordion.soil.nutrient',
    role: 'SOILNUTRIENT',
    group: 'SOIL_HEALTH',
  },
  IRRIGATION: {
    slug: '/soil-signal/irrigation',
    title: 'navigation.accordion.irrigation',
    role: 'IRRIGATION',
    group: 'WATER',
  },
  SOIL_ORGANIC_CARBON: {
    slug: '/soil-signal/soil-organic-carbon',
    title: 'navigation.accordion.soil.organic.carbon',
    role: 'SOIL_ORGANIC_CARBON',
    group: 'SOIL_HEALTH',
  },
  SEASON_INDICES: {
    slug: '/soil-signal/season-indices',
    title: 'navigation.accordion.season.indices',
    role: 'SEASON_INDICES',
  },
  WEATHER: {
    slug: '/soil-signal/weather',
    title: 'navigation.accordion.vine.weather',
    role: 'WEATHER',
  },
  TIMELAPSE: {
    slug: '/soil-signal/timelapse',
    title: 'navigation.accordion.vine.timelapse',
    role: 'TIMELAPSE',
  },
  SUMMARY: {
    slug: '/soil-signal/summary',
    title: 'navigation.accordion.soil.summary',
    role: 'SUMMARY',
  },
  OVERGRAZED_AREA: {
    slug: '/soil-signal/overgrazed-area',
    title: 'navigation.accordion.soil.overgrazed',
    role: 'OVERGRAZED',
  },
  GRASSLAND_BIOMASS: {
    slug: '/soil-signal/grassland-biomass',
    title: 'navigation.accordion.soil.grass.biomass',
    role: 'GRASSLANDBIOMASS',
    group: 'BIOMASS',
  },
  FOREST_BIOMASS: {
    slug: '/soil-signal/forest-biomass',
    title: 'navigation.accordion.soil.forest.biomass',
    role: 'FORESTBIOMASS',
    group: 'BIOMASS',
  },
  BARE_SOIL: {
    slug: '/soil-signal/bare-soil',
    title: 'navigation.accordion.soil.bare.soil',
    role: 'BARESOIL',
    group: 'PLANT',
  },
  LEAF_AREA: {
    slug: '/soil-signal/leaf-area',
    title: 'navigation.accordion.soil.leaf.area',
    role: 'LEAFAREA',
    group: 'PLANT',
  },
  PLANT_DENSITY: {
    slug: '/soil-signal/plant-density',
    title: 'navigation.accordion.soil.plant.density',
    role: 'PLANTDENSITY',
    group: 'PLANT',
  },
  PLANT_MATURE: {
    slug: '/soil-signal/plant-mature',
    title: 'navigation.accordion.soil.plant.mature',
    role: 'PLANTMATURE',
    group: 'PLANT',
  },
  PLANT_YOUNG: {
    slug: '/soil-signal/plant-young',
    title: 'navigation.accordion.soil.plant.young',
    role: 'PLANTYOUNG',
    group: 'PLANT',
  },
  PLANT_HEIGHT: {
    slug: '/soil-signal/plant-height',
    title: 'navigation.accordion.soil.plant.height',
    role: 'PLANTHEIGHT',
    group: 'PLANT',
  },
  NOTES: {
    slug: '/soil-signal/notes',
    title: 'navigation.accordion.notes',
    role: 'SOILSIGNAL',
  },
  DISEASE: {
    slug: '/soil-signal/disease-per-pixel',
    title: 'navigation.accordion.disease.per.pixel',
    role: 'DISEASE',
    group: 'DISEASE',
  },
};

/////////////////////////////////////
// Navigation routes
/////////////////////////////////////

type NAVIGATION_ROUTES = 'SETTINGS';

type INavigationRoutes = {
  [K in NAVIGATION_ROUTES]?: IRoute;
};

export const navRoutes: INavigationRoutes = {
  SETTINGS: {
    slug: '/settings',
    title: 'navigation.settings.title',
  },
};

/////////////////////////////////////
// Authentication routes
/////////////////////////////////////

type AUTHENTICATION_ROUTES = 'SIGN_IN' | 'REGISTER' | 'RESET' | 'CHANGE_PASSWORD' | 'CHANGE_EMAIL' | 'RESET_PASSWORD';

type IAuthenticationRoutes = {
  [K in AUTHENTICATION_ROUTES]?: IRoute;
};

export const authRoutes: IAuthenticationRoutes = {
  SIGN_IN: {
    slug: '/login',
    title: '',
  },
  REGISTER: {
    slug: '/register',
    title: '',
  },
  RESET: {
    slug: '/reset',
    title: '',
  },
  CHANGE_PASSWORD: {
    slug: '/change-password',
    title: '',
  },
  CHANGE_EMAIL: {
    slug: '/change-email',
    title: '',
  },
  RESET_PASSWORD: {
    slug: '/reset-password',
    title: '',
  },
};

/////////////////////////////////////
// Shared routes
/////////////////////////////////////

type SHARED_ROUTES = 'MY_ACCOUNT';

type ISharedRoutes = {
  [K in SHARED_ROUTES]?: IRoute;
};

export const commonRoutes: ISharedRoutes = {
  MY_ACCOUNT: {
    slug: '/my-account',
    title: 'navigation.account',
  },
};
