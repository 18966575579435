import React, {useEffect, useState} from 'react';
import {Box, FormControlLabel, Checkbox, FormGroup, Grid, Typography, FormControl, CircularProgress, Switch, ListItem, ListItemText, ListItemSecondaryAction, List} from '@material-ui/core';
import {IClientDataRequirement, IClientDataRequirementUICheckBox} from 'libs/api-interfaces/src/lib/clientDataRequirment';
import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import {baseApiUrl} from 'apps/web-portal-ui/src/app/config/const';
import axios from 'axios';
import _ from 'lodash';

interface Props {
  selectedOrganization: IOrganizationEntity;
}

// Extract keys dynamically for checkboxes
const dataRequirementKeys = Object.keys({...new IClientDataRequirementUICheckBox()});

const DataRequirements = ({selectedOrganization}: Props) => {
  const [clientDataRequirement, setClientDataRequirement] = useState<IClientDataRequirement | null>(null);
  const [loading, setLoading] = useState(true);
  const clientData = 'clientData';

  // Fetch data from the backend
  useEffect(() => {
    axios
      .get<IClientDataRequirement>(`${baseApiUrl}/client-data-requirement/organization/${selectedOrganization.id}`) // Replace with actual API endpoint
      .then(response => {
        if (response.data) setClientDataRequirement(response.data);
        else intializeDefaultState();
      })
      .catch(() => {
        // If API fails, initialize with all values as false
        intializeDefaultState();
      })
      .finally(() => setLoading(false));
  }, [selectedOrganization]);

  // Handle checkbox change and update backend
  const handleChange = event => {
    if (!clientDataRequirement) return;

    //update data
    const updatedData = {
      ...clientDataRequirement,
      organization: selectedOrganization,
      [event.target.name]: event.target.checked,
    };

    //udpate the state
    setClientDataRequirement(updatedData);

    // Send updated data to the backend
    axios
      .post(`${baseApiUrl}/client-data-requirement/update`, updatedData)
      .then(response => {
        if (response?.data) setClientDataRequirement(response.data);
      })
      .catch(error => console.error('Error updating data:', error));
  };

  // intialize the default state when no data is previously available
  const intializeDefaultState = () => {
    //set false
    const defaultState = dataRequirementKeys.reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as IClientDataRequirement);
    setClientDataRequirement(defaultState);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box p={2} width="100%" padding={'16px 0px'}>
      {/* Header */}
      <Typography variant="h6" gutterBottom>
        Data Requirements from Client
      </Typography>
      <Typography variant="body2" gutterBottom>
        Note : Data Requirements from Client is specific to organization
      </Typography>
      <FormGroup>
        <List>
          <ListItem key="unique-client-data">
            <ListItemText id={clientData} primary={'Is Client Data Required?'} />
            <ListItemSecondaryAction>
              <Switch disabled={!selectedOrganization} name={clientData} edge="end" onChange={handleChange} checked={clientDataRequirement?.[clientData] || false} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Grid container spacing={2}>
          {dataRequirementKeys.map(
            key =>
              key !== clientData && (
                <Grid item xs={6} key={key}>
                  <FormControlLabel
                    control={<Checkbox key={key} checked={clientDataRequirement?.[key] || false} disabled={!clientDataRequirement?.[clientData]} onChange={handleChange} name={key} color="primary" />}
                    label={key
                      .replace(/([A-Z])/g, ' $1')
                      .trim()
                      .replace(/\b\w/g, char => char.toUpperCase())} // Converts camelCase varible into multiple variables, with each word capitalization
                  />
                </Grid>
              )
          )}
        </Grid>
      </FormGroup>
    </Box>
  );
};

export default DataRequirements;
