import React, {useState} from 'react';
import styled from 'styled-components';
import Minimised from './Minimised';
import Expanded from './Expanded';
import {Modal, Box} from '@material-ui/core';
import PolygonInfo from './PolygonInfo';
import {IFarm, IPolygonWithArea} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {useMarkerColor} from '../../../../../hooks/useMarkerColor';

const ExpandedContainer = styled(Box)`
  margin-top: 3%;
  margin-inline: 10%;
`;

interface Props {
  selectedFarm: IFarm;
  takeScreenshot: () => void;
  selectedPolygon: polygonInfoWindow;
  activePolygon: IPolygonEntity | IPolygonWithArea;
  handleOnClickPolygon: (polygon: IPolygonEntity) => void;
  loadingGetUser: boolean;
  loadingGetFarm: boolean;
  allFarms: IFarm[];
  handlePolygonChange: (polygon: IPolygonEntity) => void;
  polygonClicked: boolean;
  handleSelectedPolygon: (selectedBlock: polygonInfoWindow) => void;
  isMobile: boolean;
}

const MapPanel = ({
  selectedFarm,
  takeScreenshot,
  selectedPolygon,
  activePolygon,
  handleOnClickPolygon,
  loadingGetUser,
  loadingGetFarm,
  allFarms,
  handlePolygonChange,
  polygonClicked,
  handleSelectedPolygon,
  isMobile,
}: Props) => {
  //initialize the marker colors
  useMarkerColor();
  const [showMapWindow, setShowMapWindow] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const toggleShowMapWindow = () => {
    setShowMapWindow(!showMapWindow);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Panel width={isMobile ? '100%' : '50%'} height="398px" padding="0px" overflow="hidden">
        <>
          {/* Minimised version of map */}
          <Minimised
            selectedFarm={selectedFarm}
            selectedPolygon={selectedPolygon}
            handleSelectedPolygon={handleSelectedPolygon}
            handleOnClickPolygon={handleOnClickPolygon}
            toggleShowMapWindow={toggleShowMapWindow}
            handleOpenModal={handleOpenModal}
            takeScreenshot={takeScreenshot}
            polygonClicked={polygonClicked}
          />
          {/* Sub Panel showing some summary information */}
          <PolygonInfo selectedFarm={selectedFarm} selectedPolygon={selectedPolygon} activePolygon={activePolygon} />
        </>
      </Panel>
      {/* Modal containing expanded map */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ExpandedContainer>
          <Expanded
            handleCloseModal={handleCloseModal}
            selectedFarm={selectedFarm}
            loadingGetUser={loadingGetUser}
            loadingGetFarm={loadingGetFarm}
            allFarms={allFarms}
            activePolygon={activePolygon}
            handlePolygonChange={handlePolygonChange}
            selectedPolygon={selectedPolygon}
            polygonClicked={polygonClicked}
            handleSelectedPolygon={handleSelectedPolygon}
            handleOnClickPolygon={handleOnClickPolygon}
          />
        </ExpandedContainer>
      </Modal>
    </>
  );
};

export default MapPanel;
