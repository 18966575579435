import React, {useEffect} from 'react';
import {forecastWeatherSelector, getForecastWeatherSelector} from '../../../../../store/selectors';
import {useSelector, useDispatch} from 'react-redux';
import {getForecastWeather} from '../../../../../store/actions';
import i18n from 'apps/web-portal-ui/src/i18n';
import {Box, CircularProgress} from '@material-ui/core';
import styled from 'styled-components';
import WeatherForecast from './WeatherForecast';
import {Link} from 'react-router-dom';
import CloudIcon from '@material-ui/icons/Cloud';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import {PanelTitle} from '../../../../../components/UI/DashboardPanel/PanelTitle';
import {useTranslation} from 'react-i18next';
import NoInfoOverlay from '../../../../../components/UI/DashboardPanel/NoInfoOverlay';

const LoaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
`;

const ErrorContainer = styled(Box)`
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface Props {
  activeFarm: IFarm;
  preUrl: string;
}

const WeatherPanel = ({activeFarm, preUrl}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const forecastWeather = useSelector(forecastWeatherSelector);
  const {loading: loadingForecast, error: errorForecast} = useSelector(getForecastWeatherSelector);

  useEffect(() => {
    if (activeFarm?.farmCenter) {
      dispatch(getForecastWeather(activeFarm.farmCenter, i18n.language || window.navigator.language));
    }
  }, [activeFarm, dispatch]);

  return (
    <Link to={preUrl + '/weather'}>
      <Panel width={'100%'} height={'150px'}>
        <PanelTitle>{t('weather.forecast')}</PanelTitle>
        {!errorForecast ? (
          !forecastWeather || loadingForecast ? (
            <LoaderContainer>
              <CircularProgress size={55} />
            </LoaderContainer>
          ) : (
            <WeatherForecast forecastWeather={forecastWeather} />
          )
        ) : (
          <ErrorContainer>
            <CloudIcon fontSize="large" />
            <NoInfoOverlay name={t('weather.forecast')} />
          </ErrorContainer>
        )}
      </Panel>
    </Link>
  );
};

export default WeatherPanel;
