import {Typography, Link as Anchor} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';

const featureTooltipText = (featureName: string) => {
  switch (featureName) {
    case 'navigation.accordion.vine.ndvi':
      return {
        title: 'Normalised Difference Vegetation Index (NDVI)',
        description1: 'help.ndvi.title',
        description2: 'help.ndvi.starter.description',
        description3: null,
      };
    case 'navigation.accordion.vine.ndwi':
      return {
        title: 'Normalised Difference Water Index (NDWI)',
        description1: 'help.ndwi.title',
        description2: 'help.ndwi.description.1',
        description3: null,
      };
    case 'navigation.accordion.vine.planet':
      return {
        title: 'tooltip.premium.title.ndvi.planet',
        description1: 'tooltip.premium.ndvi.planet',
        description2: null,
        description3: 'tooltip.premium.ndvi.planet.upgrade',
      };
    case 'navigation.accordion.soil.nutrient':
      return {
        title: 'tooltip.premium.title.soil.nutrients',
        description1: 'tooltip.premium.soil.nutrients',
        description2: null,
        description3: 'tooltip.premium.soil.carbon.nutrient.upgrade',
      };
    case 'navigation.accordion.soil.organic.carbon':
      return {
        title: 'tooltip.premium.title.soil.carbon',
        description1: 'tooltip.premium.soil.carbon',
        description2: null,
        description3: 'tooltip.premium.soil.carbon.nutrient.upgrade',
      };
    case 'navigation.accordion.irrigation':
      return {
        title: 'tooltip.premium.title.irrigation',
        description1: 'tooltip.premium.irrigation',
        description2: null,
        description3: 'tooltip.premium.soil.carbon.nutrient.upgrade',
      };
    case 'navigation.accordion.disease.per.pixel':
      return {
        title: 'tooltip.premium.title.disease',
        description1: 'tooltip.premium.disease',
        description2: null,
        description3: 'tooltip.premium.disease.upgrade',
      };
    case 'navigation.accordion.vine.soil.moisture':
      return {
        title: 'help.soil.title',
        description1: null,
        description2: null,
        description3: 'tooltip.premium.soil.carbon.nutrient.upgrade',
      };
    case 'navigation.accordion.vine.soil.moisture.interpolation':
      return {
        title: 'tooltip.premium.title.soil.moisture.interpolation',
        description1: 'tooltip.premium.soil.moisture.interpolation',
        description2: null,
        description3: 'tooltip.premium.soil.carbon.nutrient.upgrade',
      };
    case 'navigation.accordion.vine.yield':
      return {
        title: 'tooltip.premium.title.yield',
        description1: 'tooltip.premium.yield',
        description2: null,
        description3: 'tooltip.premium.harvest.upgrade',
      };
    case 'YIELD MAP':
      return {
        title: 'tooltip.premium.title.yield',
        description1: 'tooltip.premium.yield',
        description2: null,
        description3: 'tooltip.premium.harvest.upgrade',
      };
    case 'navigation.accordion.vine.harvest.curves':
      return {
        title: 'tooltip.premium.title.harvest.curves',
        description1: 'tooltip.premium.harvest.curves',
        description2: null,
        description3: 'tooltip.premium.harvest.upgrade',
      };
    case 'navigation.accordion.vine.harvest.forecast':
      return {
        title: 'navigation.accordion.vine.harvest.forecast',
        description1: 'tooltip.premium.harvest.curves',
        description2: null,
        description3: 'tooltip.premium.harvest.upgrade',
      };
    case 'navigation.accordion.admin.maturity.map':
      return {
        title: 'tooltip.premium.title.maturity.map',
        description1: 'tooltip.premium.maturity.map',
        description2: null,
        description3: 'tooltip.premium.harvest.upgrade',
      };
    case 'navigation.accordion.season.indices':
      return {
        title: null,
        description1: 'help.season.indices.title',
        description2: null,
        description3: 'tooltip.premium.ndvi.planet.upgrade',
      };
    case 'navigation.accordion.vine.timelapse':
      return {
        title: null,
        description1: 'help.timelapse.title',
        description2: null,
        description3: 'tooltip.premium.ndvi.planet.upgrade',
      };
    default:
      return {
        title: '',
        description1: '',
        description2: null,
        description3: 'tooltip.premium.ndvi.planet.upgrade',
      };
  }
};

const TooltipDescription = ({title}: {title: string}) => {
  const featureTooltip = featureTooltipText(title);
  const deepPlanetLink = 'https://www.deepplanet.ai/vinesignal';
  const {t} = useTranslation();
  return (
    <>
      <Typography color="inherit" align="center">
        {featureTooltip.title ? t(featureTooltip.title) : t(title)}
      </Typography>
      {featureTooltip.description1 && <Typography color="inherit">{t(featureTooltip.description1)}</Typography>}
      {featureTooltip.description2 && (
        <>
          <br />
          <Typography color="inherit">{t(featureTooltip.description2)}</Typography>
        </>
      )}
      {featureTooltip.description3 && (
        <>
          <br />
          <Typography color="inherit">{t(featureTooltip.description3)}</Typography>
        </>
      )}
      <br />
      <Typography color="inherit">{t('tooltip.premium.find.more.here')}</Typography>
      <Anchor target="_blank" href={deepPlanetLink} rel="noopener noreferrer" underline="always">
        <Typography color="textPrimary" variant="subtitle2">
          {deepPlanetLink}
        </Typography>
      </Anchor>
    </>
  );
};

export default TooltipDescription;
