import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';

import theme from '../../../utilities/theme';
import Layout from '../../Layout';
import Sidebar from './Sidebar';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import MapContent from '../../../components/UI/Map/MapContent';
import {Error} from '../../../components/UI/Error/Error';
import Legend, {DynamicScaleType} from '../NDVI/Legend';
import InfoSection from '../NDVI/InfoSection';
import BlockInfo from './BlockInfo';
import GraphSidebar from './GraphSidebar';
import {PlotChart} from './plot';

import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useSoilOrganicCarbonSelection} from '../../../hooks/useSoilOrganicCarbonImage';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import usePolygon from '../../../hooks/usePolygon';

import {getThresholds} from 'apps/web-portal-ui/src/app/helpers/imageTransformers';
import {getScreenshot} from '../NDVI/DownloadScreenshot';

import {IPolygonEntity} from '@deep-planet/api-interfaces';

import {G_PER_KG, PERCENTAGE} from '../../../config/const';
import useTooltipText from '../../../hooks/useTooltipText';
import {PageTitle} from '../../../components/UI/PageTitle';

const StyledPaper = styled(Paper)`
  height: calc(100%);
  display: 'flex';
  ${theme.breakpoints.only('md')} {
    height: calc(100% - 103px);
  }
  ${theme.breakpoints.only('sm')} {
    height: calc(100% - 129px);
    flex-direction: column;
    margin-left: 0;
  }
  ${theme.breakpoints.only('xs')} {
    height: calc(100% - 121px);
    flex-direction: column;
    margin-left: 0;
  }
`;

const SoilOrganicCarbon = ({user}: WithUserProps) => {
  //initialize the marker colors
  useMarkerColor();
  const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
  const [dynamicScale, setDynamicScale] = useState<DynamicScaleType>(initialDynamicScale);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [type, setType] = useState<'BASELINE' | 'CURRENT' | '1M' | '3M' | '6M' | '1Y' | '3Y' | '5Y'>('CURRENT');
  const {t} = useTranslation();
  const [showChart, setShowChart] = useState<boolean>(false);
  // view change over
  const [graphCurrentBlock, setGraphCurrentBlock] = useState<IPolygonEntity>();
  const [duration, setDuration] = useState<string>();

  const [metric, setMetric] = useState<string>('g/kg');
  const [conversion, setConversion] = useState<number>(1);
  // collect farm data from selector hook
  const {allFarms, selectedFarm, setSelectedFarm, activeFarm, setActiveFarm, loadingGetFarm: loadingFarms} = useFarmSelection();
  const {handleOnClickPolygon, selectedPolygon, setSelectedPolygon, polygonClicked} = usePolygon();
  const {HelpSOC} = useTooltipText();

  const changeOverDurationList = [
    // {duration: '1M', span: 'Month'},
    // {duration: '6M', span: 'Months'},
    {duration: '1Y', span: 'Year'},
    {duration: '3Y', span: 'Years'},
    {duration: '5Y', span: 'Years'},
  ];

  // collect the soilOrganicCarbon data from selector hook
  const {
    soilOrganicCarbonImages,
    loadingSoilOrganicCarbonFarms,
    loadingImages,
    selectedDate,
    soilOrganicCarbonActiveFarm,
    error,
    historicalSoc,
    setSocSelectedFarm,
    setSocActiveFarm,
    handleDateChange,
    handleGetImagesForDuration,
    handleGetHistoricalSoc,
  } = useSoilOrganicCarbonSelection(allFarms, activeFarm, selectedFarm);

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleUpdateDateIndex = (date: number, type: 'BASELINE' | 'CURRENT' | '1M' | '3M' | '6M' | '1Y' | '3Y' | '5Y') => {
    setType(type);
    handleDateChange(date);
  };

  const handleClickCompare = type => {
    setType(type);
  };

  const handleFarmChange = (farmId: string) => {
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setSocSelectedFarm(selectedFarm);
    setSelectedFarm(selectedFarm);
  };

  const handleGetRequest = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
      setSocActiveFarm(selectedFarm);
    }
    handleGetImagesForDuration(selectedFarm, startDate, endDate);
  };

  const handleShowChangeOverScreen = () => {
    setShowChart(!showChart);
  };

  const handleOnClick = (polygon: IPolygonEntity) => {
    // Handles zoom feature functionality
    handleOnClickPolygon(polygon);
    // it opens onclick info window on the soilOrganicCarbon page
    setSelectedPolygon({polygonId: polygon?.id, openWindow: true, strokeColor: '#FFEA00', strokeWeight: 3});
    setGraphCurrentBlock(polygon);
  };

  const handlePolygonHover = (id: string) => {
    if (id) {
      let {thresholdLow, thresholdHigh} = getThresholds(imagesOnSelectedDate.images, id, type);
      thresholdLow *= conversion;
      thresholdHigh *= conversion;
      const step = (thresholdHigh - thresholdLow) / 7;
      // @ts-ignore
      const currentDynamicScale: DynamicScaleType = [];
      // show threshold values on colorbar on map
      for (let i = 0; i <= 7; i++) {
        currentDynamicScale.push(Number((thresholdLow + step * i).toFixed(2)));
      }
      setDynamicScale(currentDynamicScale);
    } else {
      setDynamicScale(initialDynamicScale);
    }
  };

  const handleMetricChange = () => {
    if (metric === G_PER_KG) {
      setMetric(PERCENTAGE);
      setConversion(0.1);
    } else if (metric === PERCENTAGE) {
      setMetric(G_PER_KG);
      setConversion(1);
    }
  };

  //event handlers
  const handleBlockChangeOnChart = (blockId: string) => {
    setGraphCurrentBlock(activeFarm.polygons.find(({id}) => id === blockId));
    setSelectedPolygon({polygonId: blockId, openWindow: true, strokeColor: '#FFEA00', strokeWeight: 3});
  };

  const handleDurationChangeOver = (duration: string) => {
    setDuration(duration);
  };

  const handlePostRequest = () => {
    handleGetHistoricalSoc(selectedPolygon.polygonId, duration);
  };

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  useEffect(() => {
    if (metric === 'g/kg') {
      setConversion(1);
    } else if (metric === '%') {
      setConversion(0.1);
    }
  }, [metric]);

  const selectedActiveFarm = activeFarm || soilOrganicCarbonActiveFarm || (allFarms && allFarms[0]);
  const isLoading = loadingSoilOrganicCarbonFarms || loadingFarms || loadingImages;
  // find images for selected date and farm
  const imagesOnSelectedDate = useMemo(() => soilOrganicCarbonImages?.find(({date, farmid}) => date === selectedDate && farmid === selectedActiveFarm?.id), [
    selectedDate,
    soilOrganicCarbonImages,
    selectedActiveFarm,
  ]);

  // get carbon info box data
  const soilOrganicCarbonInfo =
    useMemo(() => imagesOnSelectedDate?.images.find(({polygonid}) => polygonid === selectedPolygon?.polygonId), [imagesOnSelectedDate, selectedPolygon])?.soilOrganicCarbonValues || null;

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && selectedActiveFarm && !selectedActiveFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={selectedActiveFarm.id} />}
      {!isLoading && error && <Error onClick={handleGetRequest} />}
      {!isLoading && selectedActiveFarm && (
        <div style={{overflow: 'hidden', position: 'relative'}} id="layout">
          {!showChart && (
            <>
              {!!soilOrganicCarbonInfo && (
                <BlockInfo
                  soilOrganicCarbonInfo={soilOrganicCarbonInfo}
                  blockName={selectedPolygon?.name}
                  handleShowChangeOverScreen={handleShowChangeOverScreen}
                  buttonText={t('soil.organic.carbon.view.change.over')}
                  selectedImage={!!selectedDate || false}
                  metric={metric}
                  conversion={conversion}
                  handleMetricChange={handleMetricChange}
                />
              )}
              <Legend product={'soilOrganicCarbon'} type={type} dynamicScale={dynamicScale} text={metric} />
            </>
          )}
          <ContentLeftSidebar
            sidebar={
              showChart ? (
                <GraphSidebar
                  activeBlock={graphCurrentBlock}
                  duration={duration}
                  allBlocks={activeFarm.polygons}
                  handleBlockChangeOnChart={handleBlockChangeOnChart}
                  handlePostRequest={handlePostRequest}
                  changeOver={changeOverDurationList}
                  handleDurationChangeOver={handleDurationChangeOver}
                />
              ) : (
                <Sidebar
                  type={type}
                  activeFarm={selectedFarm}
                  allFarms={allFarms}
                  images={soilOrganicCarbonImages}
                  startDate={startDate}
                  endDate={endDate}
                  selectedDate={selectedDate}
                  error={error}
                  handleChange={handleFarmChange}
                  handlePostRequest={handleGetRequest}
                  handleUpdateDateIndex={handleUpdateDateIndex}
                  handleStartDateSelection={handleStartDateSelection}
                  handleEndDateSelection={handleEndDateSelection}
                />
              )
            }
            content={
              showChart ? (
                <StyledPaper>
                  <PlotChart
                    blockName={graphCurrentBlock?.name}
                    historicalSoc={historicalSoc}
                    soilOrganicCarbonInfo={soilOrganicCarbonInfo}
                    handleShowChangeOverScreen={handleShowChangeOverScreen}
                    buttonText={t('soil.organic.carbon.spatial.interpolation')}
                    showChart={showChart}
                    metric={metric}
                    conversion={conversion}
                  />
                  <BlockInfo
                    soilOrganicCarbonInfo={soilOrganicCarbonInfo}
                    blockName={selectedPolygon?.name}
                    handleShowChangeOverScreen={handleShowChangeOverScreen}
                    buttonText={t('soil.organic.carbon.spatial.interpolation')}
                    showChart={showChart}
                    selectedImage={!!selectedDate || false}
                    metric={metric}
                    conversion={conversion}
                    handleMetricChange={handleMetricChange}
                  />
                </StyledPaper>
              ) : (
                <>
                  <PageTitle
                    title={
                      <InfoSection
                        selectedDate={selectedDate}
                        type={type}
                        product={'SoilOrganicCarbon'}
                        description={'Soil Organic Carbon'}
                        handleClickCompare={handleClickCompare}
                        prevImageUrl={!!selectedDate}
                        soilOrganicCarbonEnabled={true}
                      />
                    }
                    tooltip={HelpSOC}
                  />
                  <MapContent
                    farm={selectedActiveFarm}
                    center={selectedActiveFarm.farmCenter}
                    images={imagesOnSelectedDate?.images}
                    type={type}
                    bbox={selectedActiveFarm.bbox}
                    polygons={selectedActiveFarm.polygons}
                    handlePolygonHover={handlePolygonHover}
                    selectedPolygon={selectedPolygon}
                    handleOnClick={handleOnClick}
                    handleMetricChange={handleMetricChange}
                    disableInfoWindow={true}
                    feature="SOILCARBON"
                    takeScreenshot={takeScreenshot}
                    polygonClicked={polygonClicked}
                    irrigation={false}
                    metric={metric}
                  />
                </>
              )
            }
            wider={!showChart}
          />
        </div>
      )}
    </Layout>
  );
};

export default withUser(SoilOrganicCarbon);
