import React, {useState, useMemo} from 'react';
import Panel from '../../../../../components/UI/DashboardPanel/Panel';
import ImageryCalendar from 'apps/web-portal-ui/src/app/components/UI/Calendar/ImageryCalendar';
import {FormControlLabel, Checkbox, CircularProgress} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useImageryDates} from 'apps/web-portal-ui/src/app/hooks/useImageryDates';
import {PanelTitle} from '../../../../../components/UI/DashboardPanel/PanelTitle';
import NoInfoOverlay from '../../../../../components/UI/DashboardPanel/NoInfoOverlay';

const StyledTypography = styled.div`
  font-size: xx-small;
`;

const PaddedContainer = styled.div`
  padding-inline: 10px;
`;

const LoadingContainer = styled.div`
  background-color: whitesmoke;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoInfoContainer = styled.div`
  margin-top: 10px;
`;

interface Props {
  selectedFarm: IFarm;
  isMobile: boolean;
}

const LatestImageryPanel = ({selectedFarm, isMobile}: Props) => {
  const {t} = useTranslation();

  const [fromDate, toDate] = useMemo(() => {
    const dayInMilliseconds = 86400000;
    const toDate = new Date();
    const fromDateTime = toDate.getTime() - 30 * dayInMilliseconds;
    const fromDate = new Date(fromDateTime);
    return [fromDate, toDate];
  }, []);
  const {esaImageDates, planetImageDates, loadingImages, errorImages} = useImageryDates(selectedFarm, fromDate, toDate);
  const [showEsa, setShowEsa] = useState<boolean>(true);
  const [showPlanet, setShowPlanet] = useState<boolean>(true);

  const toggleShowEsa = () => setShowEsa(!showEsa);
  const toggleShowPlanet = () => setShowPlanet(!showPlanet);

  return (
    <Panel width={isMobile ? '100%' : '35%'} height={'263px'} right={!isMobile} paddingInline="0px">
      <PaddedContainer>
        <PanelTitle>{t('dashboard.latest.imagery')}</PanelTitle>
      </PaddedContainer>
      {loadingImages ? (
        <LoadingContainer>
          <CircularProgress size={45} />
        </LoadingContainer>
      ) : (
        <ImageryCalendar fromDate={fromDate} toDate={toDate} esaImageDates={showEsa ? esaImageDates : []} planetImageDates={showPlanet ? planetImageDates : []} disabled={errorImages} />
      )}
      <PaddedContainer>
        {!errorImages && !loadingImages && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showEsa}
                  onClick={toggleShowEsa}
                  disabled={errorImages || esaImageDates?.length === 0}
                  color={!errorImages && esaImageDates?.length > 0 ? 'primary' : 'default'}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" color={!errorImages && esaImageDates?.length > 0 ? 'primary' : 'disabled'} />}
                  checkedIcon={<CheckBoxIcon fontSize="small" color={!errorImages && esaImageDates?.length > 0 ? 'primary' : 'disabled'} />}
                />
              }
              label={<StyledTypography>ESA</StyledTypography>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={showPlanet}
                  onClick={toggleShowPlanet}
                  disabled={errorImages || planetImageDates?.length === 0}
                  color={!errorImages && planetImageDates?.length > 0 ? 'secondary' : 'default'}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" color={!errorImages && planetImageDates?.length > 0 ? 'secondary' : 'disabled'} />}
                  checkedIcon={<CheckBoxIcon fontSize="small" color={!errorImages && planetImageDates?.length > 0 ? 'secondary' : 'disabled'} />}
                />
              }
              label={<StyledTypography>Planet</StyledTypography>}
            />
          </>
        )}
        {errorImages && (
          <NoInfoContainer>
            <NoInfoOverlay name="Calendar" variant="caption" />
          </NoInfoContainer>
        )}
      </PaddedContainer>
    </Panel>
  );
};

export default LatestImageryPanel;
