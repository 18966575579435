import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {noteAttachmentsAddSelector, noteDeleteSelector, notePutSelector} from '../../../../../../store/selectors';
import {CreateNoteDTO, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import useEdit, {ISelectedFile} from '../../../../Notes/withEdit';
import NoteDetails from '../../../../Notes/NoteDetails';
import {deleteNote, updateNote} from '../../../../../../store/actions';
import Modal from '../../../../../../components/UI/Modal/Modal';

interface Props {
  isOpen: boolean;
  loading: boolean;
  handleClose: () => void;
  selectedOrganization: IOrganizationEntity;
  types: any;
  farm: IFarm;
  userNames: string[];
  userSelectedNote: any;
}

const UpdateNote = ({isOpen, loading, handleClose, selectedOrganization, types, farm, userNames, userSelectedNote}: Props) => {
  const dispatch = useDispatch();
  const {
    summary,
    location,
    description,
    errors,
    selectedType,
    selectedDate,
    selectedFarm,
    selectedPolygon,
    setErrors,
    selectedNote,
    selectedFiles,
    selectedAssignee,
    selectedVisibility,
    selectedUserName,
    handleChangeSummary,
    handleChangeLocation,
    handleChangeDescription,
    handleTypeSelection,
    handleDateSelection,
    handleFarmSelection,
    handlePolygonSelection,
    handleNoteAttachmentChange,
    handleNoteAttachmentCancel,
    handleNoteSelection: setSelectedNote,
    handleAssigneeSelection,
    handleOrganizationSelection,
    handleUserNameSelection,
  } = useEdit({types, farms: [farm], userNames});
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [isShareActionsOpen, setSetActionsOpen] = useState(false);
  const {loading: isDeleteLoading} = useSelector(noteDeleteSelector);
  const {loading: isUpdateLoading} = useSelector(notePutSelector);
  const {loading: isAttachmentUploadLoading} = useSelector(noteAttachmentsAddSelector);
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleOpenShareModal = () => setShareModalOpen(true);
  const handleCloseShareModal = () => setShareModalOpen(false);
  const handleOpenAction = () => setActionsOpen(true);
  const handleCloseAction = () => setActionsOpen(false);
  const handleOpenShareAction = () => setSetActionsOpen(true);
  const handleCloseShareAction = () => setSetActionsOpen(false);
  // update marker note
  const handleNoteUpdate = (noteId: string, params: CreateNoteDTO, files: ISelectedFile[]) => {
    dispatch(updateNote(noteId, params, files));
  };
  const handleNoteDelete = (noteId: string) => {
    handleClose();
    dispatch(deleteNote(noteId));
  };

  const submitLoading = isDeleteLoading || isUpdateLoading || isAttachmentUploadLoading || loading;

  useEffect(() => {
    const handleNoteSelection = () => {
      setSelectedNote(userSelectedNote);
    };

    if (userSelectedNote) {
      handleNoteSelection();
    }
  }, [userSelectedNote, setSelectedNote]);

  return (
    <Modal isOpen={isOpen} isSubmitButtonDisabled={submitLoading} isLoading={submitLoading} handleClose={handleClose}>
      {selectedOrganization && selectedNote && (
        <NoteDetails
          selectedFiles={selectedFiles}
          handleNoteAttachmentCancel={handleNoteAttachmentCancel}
          handleNoteAttachmentChange={handleNoteAttachmentChange}
          errors={errors}
          summary={summary}
          location={location}
          description={description}
          selectedDate={selectedDate}
          selectedFarm={selectedFarm}
          selectedPolygon={selectedPolygon}
          selectedType={selectedType}
          setErrors={setErrors}
          handleChangeDescription={handleChangeDescription}
          handleChangeLocation={handleChangeLocation}
          handleChangeSummary={handleChangeSummary}
          handleDateSelection={handleDateSelection}
          handleFarmSelection={handleFarmSelection}
          handlePolygonSelection={handlePolygonSelection}
          handleTypeSelection={handleTypeSelection}
          //@ts-ignore
          note={selectedNote}
          types={types}
          farms={[farm]}
          closeDetails={handleClose}
          isDialogOpen={isDialogOpen}
          isShareModalOpen={isShareModalOpen}
          handleOpenShareModal={handleOpenShareModal}
          handleCloseShareModal={handleCloseShareModal}
          isActionsOpen={isActionsOpen}
          isShareActionsOpen={isShareActionsOpen}
          isDeleteLoading={isDeleteLoading}
          handleOpenDialog={handleOpenDialog}
          handleCloseDialog={handleCloseDialog}
          handleOpenAction={handleOpenAction}
          handleOpenShareAction={handleOpenShareAction}
          handleCloseShareAction={handleCloseShareAction}
          handleCloseAction={handleCloseAction}
          handleNoteUpdate={handleNoteUpdate}
          selectedAssignee={selectedAssignee}
          handleAssigneeSelection={handleAssigneeSelection}
          userNames={userNames}
          selectedOrganization={selectedOrganization.id}
          selectedVisibility={selectedVisibility}
          selectedUserName={selectedUserName}
          handleOrganizationSelection={handleOrganizationSelection}
          handleUserNameSelection={handleUserNameSelection}
          padding={0} //used only for marker note update
          handleNoteDelete={handleNoteDelete}
          readOnly
        />
      )}
    </Modal>
  );
};

export default UpdateNote;
