import {FarmTypeEnum, IFarmEntity, IFarmSettings, IOrganizationEntity, IOrganizationSettings, IUserEntity} from '@deep-planet/api-interfaces';

export class FarmSettingsObject implements IFarmSettings {
  id: string;
  farm: IFarmEntity;
  esa: boolean;
  planet: boolean;
  maturityMap: boolean;
  maturityCurve: boolean;
  yield: boolean;
  soilNutrient: boolean;
  irrigation: boolean;
  createdAt: Date;
  updatedAt: Date;
  farmType: FarmTypeEnum;
  soilOrganicCarbon: boolean;
  seasonStartDate: Date;
  seasonEndDate: Date;
  fruitSetDate: Date;
  baselineDate: Date;
  planetLimit: boolean;
  perPixelYield: boolean;
  portal: boolean;
  user: IUserEntity;
  heatShockMonth: number;
  frostShockMonth: number;
  disease: boolean;
  isCalibratedMaturity: boolean;
  phTa: boolean;
  seasonIndices: boolean;
  soilNutrientFertilizer: boolean;
}

export class OrganizationSettingsObject implements IOrganizationSettings {
  id: string;
  organization: IOrganizationEntity;
  esa: boolean;
  planet: boolean;
  maturityMap: boolean;
  maturityCurve: boolean;
  yield: boolean;
  soilNutrient: boolean;
  irrigation: boolean;
  soilOrganicCarbon: boolean;
  seasonStartDate: Date;
  seasonEndDate: Date;
  fruitSetDate: Date;
  heatShockMonth: number;
  frostShockMonth: number;
  baselineDate: Date;
  planetLimit: boolean;
  perPixelYield: boolean;
  createdAt: Date;
  updatedAt: Date;
  user: IUserEntity;
  portal: boolean;
  disease: boolean;
  isCalibratedMaturity: boolean;
  phTa: boolean;
  seasonIndices: boolean;
  soilNutrientFertilizer: boolean;
}
