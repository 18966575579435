import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select, Typography, Box, Button, FormHelperText} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {enqueueSnackbar} from '../../../store/actions';
import {useDispatch} from 'react-redux';

const createTitle = (title: string, alternateSeparator?: string) => {
  if (!title) return;
  const words = title.split(alternateSeparator || ' ');
  if (words.length !== 1) {
    let wordStr = '';
    let firstWord = true;
    words.forEach(word => {
      const capitalizedWord = word[0].toUpperCase() + word.slice(1);
      if (firstWord) {
        wordStr += capitalizedWord;
      } else {
        wordStr += alternateSeparator ? alternateSeparator + capitalizedWord : ' ' + capitalizedWord;
      }
      firstWord = false;
    });
    return wordStr;
  } else return title[0].toUpperCase() + title.slice(1);
};

const Preferences = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const selectContainerStyles = {display: 'flex', justifyContent: 'space-between', marginBlock: '2vh', width: '100%'};
  const selectStyles = {width: '175px', marginLeft: 10};

  const [defaultMeasurement, setDefaultMeasurement] = useState('');

  const defaultSettings = {
    metric: {
      area: 'acres',
      distance: ['meters', 'centimeters'],
      temperature: 'celsius',
      weight: 'kilograms',
    },
    uscustomary: {
      area: 'hectares',
      distance: ['feet', 'inches'],
      temperature: 'fahrenheit',
      weight: 'pounds',
    },
    sugar: 'baume',
    soilHealthVisualisation: 'nitrogen',
  };

  const {uscustomary, metric, sugar: defaultSugar, soilHealthVisualisation: defaultSoilHealthVisualisation} = defaultSettings;
  const {area: defaultArea} = uscustomary;
  const {distance: defaultDistance, temperature: defaultTemperature, weight: defaultWeight} = metric;

  const [area, setArea] = useState(createTitle(defaultArea));
  const [length, setLength] = useState(createTitle(defaultDistance.join('/'), '/'));
  const [temperature, setTemperature] = useState(createTitle(defaultTemperature));
  const [weight, setWeight] = useState(createTitle(defaultWeight));
  const [sugar, setSugar] = useState(createTitle(defaultSugar));
  const [soilHealthVisualisation, setSoilHealthVisualisation] = useState(createTitle(defaultSoilHealthVisualisation));

  const handleDefaultSettingsChange = (defaultMeasurement: string) => {
    setDefaultMeasurement(defaultMeasurement);
    const settingsTitle = defaultMeasurement.toLowerCase().replace(/\s/g, '');

    setTemperature(createTitle(defaultSettings[settingsTitle].temperature));
    setArea(createTitle(defaultSettings[settingsTitle].area));
    setLength(createTitle(defaultSettings[settingsTitle].distance.join('/'), '/'));
    setWeight(createTitle(defaultSettings[settingsTitle].weight));
  };

  const handleTemperatureChange = (temperature: string) => {
    setTemperature(temperature);
  };

  const handleAreaChange = (area: string) => {
    setArea(area);
  };

  const handleLengthChange = (length: string) => {
    setLength(length);
  };

  const handleWeightChange = (weight: string) => {
    setWeight(weight);
  };

  const handleSugarChange = (sugar: string) => {
    setSugar(sugar);
  };

  const handleSoilHealthVisualisationChange = (soilHealthVisualisation: string) => {
    setSoilHealthVisualisation(soilHealthVisualisation);
  };

  const handleMeasurementFormSubmit = async () => {
    const settings = {
      area: area.toLowerCase(),
      distance: length.split('/').map(item => item.toLowerCase()),
      temperature: temperature.toLowerCase(),
      weight: weight.toLowerCase(),
      sugar: sugar === 'Sugar (%)' ? sugar.slice(0, sugar.length - 4).toLowerCase() : sugar.toLowerCase(),
      soilHealthVisualisation: soilHealthVisualisation.toLowerCase(),
    };

    localStorage.setItem('userSettings', JSON.stringify(settings));
    dispatch(enqueueSnackbar({message: t('account.measurement.success'), options: {variant: 'success'}}));
    return;
  };

  // Detects for user settings and sets individual selectors to user values
  useEffect(() => {
    const userSettings = JSON.parse(localStorage.getItem('userSettings'));
    if (userSettings) {
      setTemperature(createTitle(userSettings?.temperature || defaultSettings.metric.temperature));
      setArea(createTitle(userSettings?.area || defaultSettings.metric.area));
      setLength(createTitle(userSettings?.distance?.join('/') || defaultSettings.metric.distance?.join('/'), '/'));
      setWeight(createTitle(userSettings?.weight || defaultSettings.metric.weight));
      setSugar(createTitle(userSettings?.sugar === 'sugar' ? 'sugar (%)' : userSettings?.sugar || defaultSettings.sugar));
      setSoilHealthVisualisation(createTitle(userSettings?.soilHealthVisualisation || defaultSettings.soilHealthVisualisation));
    }
  }, []);

  // Detect if settings match a default measurement -> Dynamically sets title
  useEffect(() => {
    if (temperature.toLowerCase() === 'celsius' && area.toLowerCase() === 'acres' && length.toLowerCase().split('/')[0] === 'meters' && weight.toLowerCase() === 'kilograms') {
      setDefaultMeasurement('Metric');
    } else if (temperature.toLowerCase() === 'fahrenheit' && area.toLowerCase() === 'hectares' && length.toLowerCase().split('/')[0] === 'feet' && weight.toLowerCase() === 'pounds') {
      setDefaultMeasurement('US Customary');
    } else {
      setDefaultMeasurement('');
    }
  }, [temperature, area, length, weight]);

  const renderUnitSelector = (title: string, measurement: string, units: string[], handleChange: (unit: string) => void) => {
    return (
      <Box style={selectContainerStyles}>
        <Typography variant="body1" style={{marginTop: 5, minWidth: 100}}>
          {createTitle(title)}
        </Typography>
        <div style={{flex: 1}} />
        <FormControl>
          <Select
            value={measurement}
            onChange={event => {
              handleChange(event.target.value as string);
            }}
            style={selectStyles}
          >
            {units.map(unit => {
              return (
                <MenuItem key={unit} value={unit.toString()}>
                  {unit}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      <Typography variant="h5" style={{fontWeight: 'bold', marginBottom: 10}}>
        {t('account.measurement.settings')}
      </Typography>
      <Typography variant="body1" style={{marginBottom: 10}}>
        {t('account.measurement.instruction')}
      </Typography>
      <FormControl>
        <InputLabel>{t('account.measurement.default.units')}</InputLabel>
        <Select
          value={defaultMeasurement}
          onChange={event => {
            handleDefaultSettingsChange(event.target.value as string);
          }}
        >
          <MenuItem value="Metric">Metric</MenuItem>
          <MenuItem value="US Customary">US Customary</MenuItem>
        </Select>
        <FormHelperText>{t('account.measurement.helper')}</FormHelperText>
      </FormControl>
      <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
        <Box style={{width: '100%'}}>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleMeasurementFormSubmit();
            }}
            style={{width: '100%'}}
          >
            <Box>
              <Box style={{...selectContainerStyles, marginBottom: 5}}>
                <Typography variant="h6" style={{marginTop: 5, fontWeight: 'bold', color: '#3f51b5'}}>
                  {t('account.measurement.measurement')}
                </Typography>
                <Typography variant="h6" style={{marginTop: 5, marginRight: 5, fontWeight: 'bold', color: '#3f51b5'}}>
                  {t('account.measurement.unit')}
                </Typography>
              </Box>

              {renderUnitSelector(t('account.measurement.temperature'), temperature, ['Celsius', 'Fahrenheit'], handleTemperatureChange)}
              {renderUnitSelector(t('account.measurement.land.area'), area, ['Acres', 'Hectares'], handleAreaChange)}
              {renderUnitSelector(t('account.measurement.length'), length, ['Meters/Centimeters', 'Feet/Inches'], handleLengthChange)}
              {renderUnitSelector(t('account.measurement.weight'), weight, ['Kilograms', 'Pounds', 'Tonnes'], handleWeightChange)}
            </Box>

            <Typography variant="h6" style={{fontWeight: 'bold', marginTop: 20, marginBottom: 10, color: '#3f51b5'}}>
              {t('account.measurement.additional.measurements')}
            </Typography>
            {renderUnitSelector(t('account.measurement.sugar'), sugar, ['Baume', 'Brix', 'Sugar (%)'], handleSugarChange)}
            {renderUnitSelector('Soil Health Visualisation', soilHealthVisualisation, ['Nitrogen', 'Phosphorus', 'Potassium', 'Soil Organic Carbon'], handleSoilHealthVisualisationChange)}
            <Button type="submit" variant="contained" color="primary" disabled={false} style={{width: '100%'}}>
              {t('account.personal.information.save')}
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default Preferences;
