import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Box} from '@material-ui/core';

export const StyledDiv = styled.div`
  font-size: small;

  @media (max-width: 950px) {
    font-size: x-small;
  }
`;

const LinkContainer = styled(Box)`
  position: relative;
  z-index: 3;
  width: fit-content;
`;

interface SubTitleProps {
  children: ReactNode;
}

export const PanelTitle = ({children}: SubTitleProps) => {
  return <StyledDiv>{children}</StyledDiv>;
};

interface SubTitleWithLinkProps {
  children: ReactNode;
  link: string;
}

export const PanelTitleWithLink = ({children, link}: SubTitleWithLinkProps) => {
  return (
    <LinkContainer>
      <Link to={link}>
        <PanelTitle>{children}</PanelTitle>
      </Link>
    </LinkContainer>
  );
};
