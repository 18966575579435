import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Typography, Box} from '@material-ui/core';
import * as turf from '@turf/turf';
import {Skeleton} from '@material-ui/lab';
import {useMeasurementConversion} from 'apps/web-portal-ui/src/app/hooks/useMeasurementConversion';
import Panel from '../../../../../../components/UI/DashboardPanel/Panel';
import {PanelTitle} from '../../../../../../components/UI/DashboardPanel/PanelTitle';
import {useTranslation} from 'react-i18next';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';

const SubContainer = styled(Box)`
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledSkeleton = styled(Skeleton)`
  min-width: 80px;
`;

const StyledTypography = styled.span`
  font-weight: bold;
  font-size: large;
  text-align: center;

  @media (max-width: 950px) {
    font-size: 12.5px;
  }
`;

const Title = styled.span`
  font-size: small;
  margin-top: 5px;
  text-align: center;
  font-weight: 500;

  @media (max-width: 950px) {
    font-size: x-small;
  }
`;

interface Props {
  selectedFarm: IFarm;
}

const TotalHectares = ({selectedFarm}: Props) => {
  const {t} = useTranslation();
  const {areaUnit, abbrAreaUnit, convertArea} = useMeasurementConversion();

  const totalHectares = useMemo(() => {
    if (selectedFarm?.polygons?.length) {
      const area = selectedFarm.polygons
        .map(polygon => turf.area(turf.polygon(polygon.geoJson.geometry.coordinates as turf.Position[][])) / 10000) // Convert to hectares
        .reduce((total, hectares) => total + hectares, 0);
      return area;
    }
    return false;
  }, [selectedFarm]);

  return (
    <Panel width={'25%'} height={'125px'} right>
      <PanelTitle>{selectedFarm ? selectedFarm.name : <StyledSkeleton variant="text" />}</PanelTitle>
      <SubContainer>
        <Title>{`${t('dashboard.total')} ${areaUnit}`}</Title>
        <Typography variant="h6" color="primary">
          <StyledTypography>{totalHectares ? `${convertArea(totalHectares)} ${abbrAreaUnit}` : <StyledSkeleton variant="text" />}</StyledTypography>
        </Typography>
      </SubContainer>
    </Panel>
  );
};

export default TotalHectares;
