import {Box, Tooltip, Typography, makeStyles} from '@material-ui/core';
import {Slider as MuiSlider} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface Props {
  opacity: number;
  handleOpacityChange: (event, value) => void;
}

const OpacityPanel = ({opacity, handleOpacityChange}: Props) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [showSlider, setShowSlider] = useState<boolean>(false);

  const useStyles = makeStyles(theme => ({
    container: {
      position: 'absolute',
      cursor: 'pointer',
      top: 10,
      right: 54,
      height: 40,
      backgroundColor: 'white',
      borderRadius: '2.5px',
      zIndex: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingInline: 12,
    },
    slider: {
      marginRight: 12,
      marginLeft: 10,
    },
    icon: {
      fontSize: 23,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    setShowSlider(true);
  };

  const handleMouseLeave = () => {
    setShowSlider(false);
  };

  return !showSlider ? (
    <Tooltip title={'Adjust opacity'} placement={'left'} arrow>
      <Box className={classes.container} onClick={handleClick} onMouseLeave={handleMouseLeave}>
        <VisibilityIcon className={classes.icon} color="primary" />
      </Box>
    </Tooltip>
  ) : (
    <Box className={classes.container} onClick={handleClick} onMouseLeave={handleMouseLeave}>
      <MuiSlider className={classes.slider} style={{width: isMobileView ? 50 : 80}} value={opacity * 100} onChange={handleOpacityChange} defaultValue={100} />
      <VisibilityIcon className={classes.icon} color="primary" />
    </Box>
  );
};

export default OpacityPanel;
