import React, {useCallback, useEffect, useLayoutEffect} from 'react';
import {Typography, TextField, MenuItem, Select, Box, BoxProps, InputLabel, FormControl, SelectProps} from '@material-ui/core';
import styled from 'styled-components';
import {IFarm} from '../../../store/reducers/farm';
import {organizationsSelector} from '../../../store/selectors';
import theme from '../../../utilities/theme';
import IconButton from '@material-ui/core/IconButton';
import {ICreateNoteDTO, INoteTypeEntity, IPolygonEntity} from '@deep-planet/api-interfaces';
import {chain, some, sortBy} from 'lodash';
import {getUserFarms} from './getUserFarms';
import useEdit, {ISelectedFile} from './withEdit';
import SimpleDatePicker from '../../../components/UI/Pickers/SimpleDatePicker';
import {useDropzone} from 'react-dropzone';
import {enqueueSnackbar} from '../../../store/actions';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import Modal from '../../../components/UI/Modal/Modal';
import {getUserNamesForOrganization} from './OrganizationUsers';
import {getDateString} from '../../../helpers/dateHelpers';

interface Props extends WithUserProps {
  isOpen: boolean;
  handleClose: () => void;
  types: INoteTypeEntity[];
  farms: IFarm[];
  handleSubmit: (params: ICreateNoteDTO, files: ISelectedFile[]) => void;
  submitLoading: boolean;
  userNames: string[];
  modalTitle?: string;
  markerNote?: boolean;
  markerPolygon?: IPolygonEntity;
  claimBlockNote?: boolean;
  blockNames?: string[];
  claimedBlocks?: IPolygonEntity[];
  claimTypes?: INoteTypeEntity[];
}

const StyledSelect = styled(Select)<SelectProps>`
  width: auto;
`;

const InputWrapperBox = styled(Box)<BoxProps>`
  margin-right: 16px;
  width: 200px;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

const DropArea = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: auto;
  width: 100%;
  padding: 18.5px 14px;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('sm')} {
    height: 100%;
    width: 100%;
  }
`;

const MAX_FILE_SIZE = 1024 * 1024 * 15; // 15mb
const ACCEPTED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.xls', '.pdf', '.doc', '.docx', '.xlsx', '.kml', '.kmz', '.shp', '.dbf', '.prj', '.cpg', '.shx', '.sbn', '.sbx', '.csv', '.json', '.geojson'];
const MAX_FILE_COUNT = 10;

const CreateNoteModal = ({
  isOpen,
  handleClose,
  types,
  farms,
  handleSubmit,
  submitLoading,
  modalTitle,
  markerNote,
  user,
  userNames,
  markerPolygon,
  blockNames,
  claimBlockNote,
  claimedBlocks,
  claimTypes,
}: Props) => {
  const {
    summary,
    setDescription,
    description,
    location,
    setErrors,
    errors,
    selectedFarm,
    selectedType,
    selectedDate,
    selectedPolygon,
    setSelectedType,
    selectedFiles,
    setSelectedFiles,
    selectedAssignee,
    setSelectedAssignee,
    selectedOrganization,
    selectedClaimType,
    setSelectedClaimType,
    setSelectedFarm,
    setSelectedOrganization,
    setSelectedPolygon,
    handleChangeDescription,
    handleChangeLocation,
    handleChangeSummary,
    handleDateSelection,
    handleFarmSelection,
    handlePolygonSelection,
    handleTypeSelection,
    handleNoteAttachmentChange,
    handleNoteAttachmentCancel,
    handleAssigneeSelection,
    handleOrganizationSelection,
    handleClaimBlockTypeSelection,
  } = useEdit({types, farms, claimTypes});
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const organizations = useSelector(organizationsSelector);

  useLayoutEffect(() => {
    if (!selectedType) {
      const observationType = types.find(t => t.name === 'Observation');
      if (observationType) {
        setSelectedType(observationType);
      } else {
        setSelectedType(types?.[0]);
      }
    }

    if (!selectedClaimType && claimTypes) {
      setSelectedClaimType(claimTypes[0]);
    }
    if (blockNames?.length) {
      setDescription(`Claimed Blocks are [ ${blockNames.reduce((c, acc) => acc + `, ${c} `)}]`);
    }
  }, [blockNames, selectedType, setSelectedType, setDescription, types, selectedClaimType, setSelectedClaimType, claimTypes]);

  useEffect(() => {
    if (!selectedOrganization && organizations) setSelectedOrganization(organizations[0].id || '');
    // Marker is specific to organization, farm and polygon hence restrict user from choosing a different farm and organization
    if ((markerNote || claimBlockNote) && !selectedFarm && farms) setSelectedFarm(farms[0]);
    if ((markerNote || claimBlockNote) && farms) setSelectedOrganization(farms[0]?.ownerOrganization?.id);
    if (markerNote && markerPolygon) setSelectedPolygon(markerPolygon);
    if (claimBlockNote && userNames) setSelectedAssignee(userNames[0]);
  }, [
    blockNames,
    claimBlockNote,
    farms,
    markerNote,
    markerPolygon,
    organizations,
    selectedFarm,
    selectedOrganization,
    setSelectedAssignee,
    setSelectedFarm,
    setSelectedOrganization,
    setSelectedPolygon,
    userNames,
    claimedBlocks,
  ]);

  const onClickSubmit = async () => {
    if (summary.length) {
      const params: ICreateNoteDTO = {
        summary,
        description,
        location,
        typeId: selectedType.id,
        date: selectedDate,
        polygonId: selectedPolygon?.id,
        farmId: selectedFarm?.id,
        attachments: [],
        organizationId: selectedOrganization || organizations[0].id,
        toUserName: selectedAssignee || 'ALL',
        claimTypeId: selectedClaimType?.id,
      };
      handleSubmit(params, selectedFiles);
    } else {
      setErrors({...errors, summary: {value: true, text: t('forms.mandatory.to.fill')}});
    }
  };

  // Memoize the handleNoteAttachmentChange function if it's not already
  const memoizedHandleNoteAttachmentChange = useCallback(handleNoteAttachmentChange, [handleNoteAttachmentChange]);

  const onDrop = useCallback(
    async (acceptedFiles: ISelectedFile[]) => {
      const size = acceptedFiles.reduce((acc, item) => item.size + acc, 0);
      if (acceptedFiles.length <= MAX_FILE_COUNT && size <= MAX_FILE_SIZE) {
        memoizedHandleNoteAttachmentChange(acceptedFiles);
      } else if (size > MAX_FILE_SIZE) {
        dispatch(enqueueSnackbar({message: t('file.max.size'), options: {variant: 'error'}}));
      } else {
        dispatch(enqueueSnackbar({message: t('file.upload.invalid'), options: {variant: 'error'}}));
      }
    },
    [dispatch, memoizedHandleNoteAttachmentChange, t]
  );

  const onDropRejected = (e: {errors: {message: string}[]}[]) => {
    const errorsArray = e.map(({errors}) => errors.map(({message}) => message));
    const errors = chain(errorsArray).flatten().uniq().value();
    for (const error of errors) {
      dispatch(enqueueSnackbar({message: error, options: {variant: 'error'}}));
    }
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDropRejected,
    onDrop,
    accept: ACCEPTED_EXTENSIONS,
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_FILE_COUNT,
    disabled: submitLoading,
  });

  // auto attach a file when user claim blocks on the dashhboard
  const generateFile = useCallback(
    async (claimedBlocks: IPolygonEntity[]) => {
      if (!claimedBlocks?.length) return;
      const geoJson = {
        type: 'FeatureCollection',
        features: claimedBlocks.map(polygon => ({
          ...polygon.geoJson,
          properties: {...polygon.geoJson.properties, blockName: polygon.name},
        })),
      };
      const blob = new Blob([JSON.stringify(geoJson, null, 2)], {type: 'application/json'});
      const file = new File([blob], `ClaimBlocks_${farms[0].name}_${getDateString(new Date())}T${new Date().getTime()}.geojson`, {type: 'application/geo+json'});
      setSelectedFiles([file]);
    },
    [farms, setSelectedFiles]
  );
  // auto attach a file when user claim blocks on the dashhboard
  useEffect(() => {
    // Simulate file generation when the component mounts (or after some action)
    if (!selectedFiles?.length && claimedBlocks) generateFile(claimedBlocks);
  }, [claimedBlocks, generateFile, selectedFiles]);

  const isAnyError = some(errors, ({value}) => value);
  const userFarms = markerNote || claimBlockNote ? farms : getUserFarms(organizations, selectedAssignee, selectedOrganization);

  return (
    <Modal
      title={modalTitle || t('note.modal.title')}
      submitText={t('forms.save')}
      isOpen={isOpen}
      isSubmitButtonDisabled={submitLoading || isAnyError}
      isLoading={submitLoading}
      handleClose={handleClose}
      handleSubmit={onClickSubmit}
    >
      <form>
        <Box display="flex" alignItems="baseline" flexWrap="wrap">
          <InputWrapperBox>
            <FormControl fullWidth disabled={submitLoading}>
              <InputLabel id="type-label">{t('note.table.type')}</InputLabel>
              <StyledSelect labelId="type-label" value={selectedType?.name} name="type" onChange={({target: {value}}) => handleTypeSelection(value as string)}>
                {types.map(({id, name}) => (
                  <MenuItem key={id} value={name}>
                    {t(`note.filter.type.${name.toLowerCase()}`)}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </InputWrapperBox>
          {claimBlockNote && claimTypes && (
            <InputWrapperBox>
              <FormControl fullWidth disabled={submitLoading}>
                <InputLabel id="type-label">{'Block Status'}</InputLabel>
                <StyledSelect labelId="type-label" value={selectedClaimType?.id} name="type" onChange={({target: {value}}) => handleClaimBlockTypeSelection(value as number)}>
                  {claimTypes.map(({id, name}) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </InputWrapperBox>
          )}
          <InputWrapperBox>
            <SimpleDatePicker value={selectedDate} onChange={handleDateSelection} label={t('note.label.date')} disabled={submitLoading} />
          </InputWrapperBox>
          {!markerNote && !claimBlockNote && organizations && (
            <InputWrapperBox>
              <FormControl required fullWidth disabled={submitLoading}>
                <InputLabel id="organization-label">Organization</InputLabel>
                <StyledSelect required labelId="organization-label" value={selectedOrganization} name="organization" onChange={({target: {value}}) => handleOrganizationSelection(value as string)}>
                  {organizations &&
                    organizations.map(o => (
                      <MenuItem key={o.id} value={o.id}>
                        {o.name}
                      </MenuItem>
                    ))}
                </StyledSelect>
              </FormControl>
            </InputWrapperBox>
          )}
        </Box>
        <Box display="flex" alignItems="baseline" flexWrap="wrap">
          {
            <InputWrapperBox>
              <FormControl required fullWidth disabled={submitLoading}>
                <InputLabel id="assignee-label">{t('note.table.assignee')}</InputLabel>
                <StyledSelect labelId="assignee-label" value={selectedAssignee || 'ALL'} name="username" onChange={({target: {value}}) => handleAssigneeSelection(value as string)}>
                  {!claimBlockNote && selectedOrganization ? sortBy(getUserNamesForOrganization(organizations, selectedOrganization)) : <MenuItem>{''}</MenuItem>}
                  {claimBlockNote &&
                    userNames.map(username => (
                      <MenuItem key={username} value={username}>
                        {username}
                      </MenuItem>
                    ))}
                </StyledSelect>
              </FormControl>
            </InputWrapperBox>
          }
          <InputWrapperBox>
            <FormControl fullWidth disabled={submitLoading || markerNote || claimBlockNote}>
              <InputLabel id="farm-label">{t('note.table.farm')}</InputLabel>
              <StyledSelect labelId="farm-label" value={selectedFarm?.id || ''} name="farm" onChange={({target: {value}}) => handleFarmSelection(value as string)}>
                {userFarms &&
                  userFarms.map(({id, name}) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
              </StyledSelect>
            </FormControl>
          </InputWrapperBox>
          {!claimBlockNote && (
            <InputWrapperBox>
              <FormControl fullWidth disabled={!selectedFarm || submitLoading || markerNote}>
                <InputLabel id="block-label">{t('note.table.block')}</InputLabel>
                <StyledSelect labelId="block-label" value={selectedPolygon?.id || ''} name="block" onChange={({target: {value}}) => handlePolygonSelection(value as string)}>
                  {selectedFarm?.polygons ? (
                    selectedFarm.polygons.map(({id, name}) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{''}</MenuItem>
                  )}
                </StyledSelect>
              </FormControl>
            </InputWrapperBox>
          )}
          {!claimBlockNote && (
            <InputWrapperBox>
              <TextField
                error={errors.location.value}
                helperText={errors.location.value ? errors.location.text : ''}
                fullWidth
                disabled={submitLoading}
                label={t('note.label.location')}
                value={location}
                onChange={({target: {value}}) => handleChangeLocation(value)}
                placeholder={'i.e. row 10A, 15m in'}
              />
            </InputWrapperBox>
          )}
        </Box>

        <Box display="flex" mt={2} mb={2}>
          <TextField
            placeholder={claimBlockNote ? 'Please enter your email address here' : ''}
            required
            error={errors.summary.value}
            helperText={errors.summary.value ? errors.summary.text : ''}
            disabled={submitLoading}
            value={summary}
            onChange={({target: {value}}) => handleChangeSummary(value)}
            variant="outlined"
            label={claimBlockNote ? 'Email Address' : t('note.label.summary')}
            fullWidth
          />
        </Box>
        <Box display="flex" mt={2} mb={2}>
          <TextField
            error={errors.description.value}
            helperText={errors.description.value ? errors.description.text : ''}
            disabled={submitLoading}
            value={description}
            onChange={({target: {value}}) => handleChangeDescription(value)}
            variant="outlined"
            multiline
            rows={5}
            label={t('note.label.description')}
            fullWidth
          />
        </Box>
        <Box display="flex" mt={2} mb={2}>
          <DropArea {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? <Typography>{t('setting.create.farm.step.uploading.drop')}</Typography> : <Typography align="center">{t('setting.create.farm.step.uploading.click')}</Typography>}
          </DropArea>
        </Box>
        <Box display="flex" flexDirection="column" mt={2} mb={2}>
          {selectedFiles.map(file => (
            <Box alignItems="center" display="flex" key={file.name}>
              <Typography>{file.name}</Typography>
              <IconButton disabled={submitLoading} onClick={() => handleNoteAttachmentCancel(file.name)}>
                <ClearIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </form>
    </Modal>
  );
};

export default withUser(CreateNoteModal);
